import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { sortBy } from 'lodash-es';

import { CLUSTER, DEFAULT_LOCALE, SelectOptionType } from '@/shared/config';

type CountryConfig = {
  names: Record<string, string>;
  sign_up: boolean;
};

type Countries = Record<string, CountryConfig>;
type Clusters = 'ca' | 'eu' | 'oceania' | 'ru' | 'us';
type ClustersOptions = {
  sign_up_allowed_countries: string[];
  all_countries: string[];
  url: string;
  languages: string[];
};

type ClusterConfig = Record<Clusters, ClustersOptions>;

export const getClusterPrefix = () => window.origin.split('.').pop();

type UseCountries = {
  // Control from 'react-hook-form'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  fieldName?: string;
};

const getCountries = async (): Promise<Countries> =>
  import('../../../shared/localizations/countries.json').then(
    (countries) => countries.default,
  );
const getClustersConfig = async (): Promise<ClusterConfig> =>
  import('../../../shared/localizations/clusters.json').then(
    (clustersConfig) => clustersConfig.default,
  );

const transformCountries = (
  countries: Countries,
  languageKey = DEFAULT_LOCALE,
) =>
  sortBy(
    Object.keys(countries).map((countryKey) => {
      const countryName = countries[countryKey].names[languageKey];
      return {
        value: countryKey,
        label: countryName,
      };
    }),
    'label',
  );

export const useCountries = ({
  control,
  fieldName = 'language',
}: UseCountries) => {
  const [countries, setCountries] = useState<SelectOptionType[]>([]);

  const cluster = getClusterPrefix();

  const language = useWatch({
    control,
    name: fieldName,
  });

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const allClustersConfig = await getClustersConfig();
        const allCountries = await getCountries();
        const clusterConfig = allClustersConfig[CLUSTER as Clusters];
        const availableCountries = clusterConfig?.sign_up_allowed_countries;

        if (clusterConfig && availableCountries?.length > 0) {
          setCountries(
            transformCountries(allCountries, language).filter(
              (country: SelectOptionType) =>
                availableCountries.includes(country.value),
            ),
          );
        } else {
          setCountries(transformCountries(allCountries, language));
        }
      } catch (error: unknown) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    fetchCountries();
  }, [cluster, language]);

  return countries;
};

export const useCountriesOptions = (
  locale: string | undefined,
): SelectOptionType[] => {
  const [countries, setCountries] = useState<SelectOptionType[]>([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const countries = await getCountries();

        setCountries(transformCountries(countries, locale));
      } catch (error: unknown) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    fetchCountries();
  }, [locale]);

  return countries;
};
