import { FC } from 'react';

import {
  BasicSkeleton,
  BasicSkeletonProps,
} from './components/BasicSkeleton/BasicSkeleton';
import { SkeletonFilter } from './components/SkeletonFilter/SkeletonFilter';
import { SkeletonGrid } from './components/SkeletonGrid/SkeletonGrid';
import { SkeletonRow } from './components/SkeletonRow/SkeletonRow';
import { SkeletonMobileRow } from './components/SkeletonMobileRow/SkeletonMobileRow';

type CompoundedComponent = FC<BasicSkeletonProps> & {
  Filter: typeof SkeletonFilter;
  Row: typeof SkeletonRow;
  Grid: typeof SkeletonGrid;
  MobileRow: typeof SkeletonMobileRow;
};

const Skeleton = BasicSkeleton as CompoundedComponent;

Skeleton.Filter = SkeletonFilter;
Skeleton.Row = SkeletonRow;
Skeleton.Grid = SkeletonGrid;
Skeleton.MobileRow = SkeletonMobileRow;

export { Skeleton };
