import { FC, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Control, Controller } from 'react-hook-form';
import { components, MenuProps } from 'react-select';

import { Modal, Select, Button } from '@/shared/ui';
import { useMedia } from '@/shared/hooks';

import { additionalInfoSelectStyles } from '../AdditionalInfoSelect/AdditionalInfoSelect.styles';
import { useGetInterestOptions } from '../../hooks/useGetInterestOptions';
import { AdditionalInfoFormPayload } from '../../config/types';
import styles from '../../Steps.module.scss';

type FocusOfInterestStepProps = {
  control: Control<AdditionalInfoFormPayload>;
  className?: string;
  testID?: string;
};

const MobileMenu = ({ children, ...props }: MenuProps<unknown>) => {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(true);

  const handleSave = () => {
    setIsOpen(false);
  };

  return (
    <components.Menu {...props}>
      <Modal
        isOpen={isOpen}
        title={formatMessage({
          id: 'focusOfInterest.select',
          defaultMessage: 'Select',
        })}
        onCancel={() => setIsOpen(false)}
        footer={
          <Button variant="secondary" onClick={handleSave}>
            {formatMessage({
              id: 'global.save',
              defaultMessage: 'Save',
            })}
          </Button>
        }
      >
        {children}
      </Modal>
    </components.Menu>
  );
};

export const FocusOfInterestStep: FC<FocusOfInterestStepProps> = (props) => {
  const { control, className, testID } = props;
  const { isMobile } = useMedia();

  const interestOptions = useGetInterestOptions();

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      <div className={styles.selectWithText}>
        <Controller
          control={control}
          name="FocusOfInterest"
          render={({ field: { ref, value, name, onBlur, onChange } }) => (
            <Select
              closeMenuOnSelect={false}
              options={interestOptions}
              isRequired
              isMulti
              ref={ref}
              value={value}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              styles={additionalInfoSelectStyles}
              components={{
                MultiValueLabel: components.MultiValueLabel,
                MultiValueRemove: components.MultiValueRemove,
                // WARN!: This is necessary to prevent a strange error
                ...(isMobile
                  ? {
                      Menu: MobileMenu,
                    }
                  : {}),
              }}
            />
          )}
        />

        <p className={cn(styles.severalOptionsText, 'p3')}>
          <FormattedMessage
            id="additionalInfo.focusOfInterest.severalOptions"
            defaultMessage="You can choose several options here"
          />
        </p>
      </div>
    </div>
  );
};
