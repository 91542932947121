import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';

import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';
import { trackPageView } from '@/shared/lib';
import { MobileLayout } from '@/shared/ui';
import { useMedia, useAppDispatch } from '@/shared/hooks';

import { ReportLayout, i18n, reportsModel } from '@/entities/reports';

import { MaskTooltip, MaskFilters } from '@/features/renderMasks';

import { Conclusion } from '@/widgets/Conclusion';
import { IOXRayReportRender } from '@/widgets/IOXRayReportRender';
import { ReportActions } from '@/widgets/ReportActions';
import { Report2DToothChart } from '@/widgets/Report2DToothChart';
import { ReportToothCardList } from '@/widgets/ReportToothCardList';
import { MobileHeader } from '@/widgets/Header';

import { Toolbar } from '../Toolbar/Toolbar';
import { IOXRayImageSlider } from '../IOXrayImageSlider/IOXRayImageSlider';
import { IOXRayChangeTeethNumberModal } from '../IOXRayChangeTeethNumberModal/IOXRayChangeTeethNumberModal';

import styles from './IOXRayReport.module.scss';

export const IOXRayReport = () => {
  const { patientID, reportID } = useParams();
  const [searchParams] = useSearchParams();
  const { formatMessage } = useIntl();
  const { isMobile } = useMedia();
  const dispatch = useAppDispatch();

  useEffect(() => {
    trackPageView('IOXRayReport Page Viewed');
  }, []);

  useEffect(() => {
    // Get activeItemID from URL and set focusedMetaImageID
    const activeItemID = searchParams.get('activeItemID');
    if (activeItemID) {
      dispatch(reportsModel.actions.setFocusedMetaImageID(activeItemID));
    }
  }, [searchParams, dispatch]);

  if (isMobile) {
    return (
      <MobileLayout>
        <MobileHeader />
        <MobileLayout.Main>
          <IOXRayReportRender>
            <Toolbar />
            <MaskFilters />
            <MaskTooltip />
            <IOXRayImageSlider />
          </IOXRayReportRender>
          <Report2DToothChart />
          <ReportToothCardList />
          <Conclusion />
        </MobileLayout.Main>
        <MobileLayout.Footer>
          <ReportActions
            patientID={patientID as string}
            reportID={reportID as string}
          />
        </MobileLayout.Footer>

        {/* Modals */}
        <IOXRayChangeTeethNumberModal />
      </MobileLayout>
    );
  }

  return (
    <>
      <ReportLayout.LeftColumn>
        <h1>{formatMessage(i18n[ReportType.ReportType_IOXRay_GP])}</h1>
        <IOXRayReportRender>
          <Toolbar />
          <MaskFilters className={styles.maskFilters} />
          <MaskTooltip />
          <IOXRayImageSlider />
        </IOXRayReportRender>
      </ReportLayout.LeftColumn>
      <ReportLayout.RightColumn>
        <Report2DToothChart />

        <ReportToothCardList />

        <Conclusion />

        <ReportActions
          className={styles.reportActions}
          patientID={patientID as string}
          reportID={reportID as string}
        />
      </ReportLayout.RightColumn>

      {/* Modals */}
      <IOXRayChangeTeethNumberModal />
    </>
  );
};
