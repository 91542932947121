// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/core/svc_asset.proto (package com.diagnocat.core, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { Asset } from "../../model/dto_asset_pb.js";
import { Annotation } from "../../model/dto_common_image_annotations_pb.js";
import { MedicalImageViewOptions } from "../../model/dto_common_image_view_options_pb.js";
import { User } from "../../model/dto_user_pb.js";
import { Organization } from "../../model/dto_organization_pb.js";
import { Tooth } from "../../model/dto_report_tooth_pb.js";
import { Study } from "../../model/dto_study_pb.js";
import { Report } from "../../model/dto_report_pb.js";

/**
 * @generated from message com.diagnocat.core.GetReportUploadedAssetsReq
 */
export const GetReportUploadedAssetsReq = proto3.makeMessageType(
  "com.diagnocat.core.GetReportUploadedAssetsReq",
  () => [
    { no: 1, name: "ReportID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.GetReportUploadedAssetsResp
 */
export const GetReportUploadedAssetsResp = proto3.makeMessageType(
  "com.diagnocat.core.GetReportUploadedAssetsResp",
  () => [
    { no: 1, name: "Assets", kind: "message", T: Asset, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetTopLayerAnnotationsReq
 */
export const SetTopLayerAnnotationsReq = proto3.makeMessageType(
  "com.diagnocat.core.SetTopLayerAnnotationsReq",
  () => [
    { no: 1, name: "AssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Annotations", kind: "message", T: Annotation, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetTopLayerAnnotationsResp
 */
export const SetTopLayerAnnotationsResp = proto3.makeMessageType(
  "com.diagnocat.core.SetTopLayerAnnotationsResp",
  () => [
    { no: 1, name: "Asset", kind: "message", T: Asset },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetMedicalImageViewOptionsReq
 */
export const SetMedicalImageViewOptionsReq = proto3.makeMessageType(
  "com.diagnocat.core.SetMedicalImageViewOptionsReq",
  () => [
    { no: 1, name: "AssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "MedicalImageViewOptions", kind: "message", T: MedicalImageViewOptions },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetMedicalImageViewOptionsResp
 */
export const SetMedicalImageViewOptionsResp = proto3.makeMessageType(
  "com.diagnocat.core.SetMedicalImageViewOptionsResp",
  () => [
    { no: 1, name: "Asset", kind: "message", T: Asset },
  ],
);

/**
 * @generated from message com.diagnocat.core.DeleteAssetReq
 */
export const DeleteAssetReq = proto3.makeMessageType(
  "com.diagnocat.core.DeleteAssetReq",
  () => [
    { no: 1, name: "AssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.DeleteAssetByFileIDReq
 */
export const DeleteAssetByFileIDReq = proto3.makeMessageType(
  "com.diagnocat.core.DeleteAssetByFileIDReq",
  () => [
    { no: 1, name: "FileID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.DeleteAssetResp
 */
export const DeleteAssetResp = proto3.makeMessageType(
  "com.diagnocat.core.DeleteAssetResp",
  () => [
    { no: 1, name: "Asset", kind: "message", T: Asset },
    { no: 11, name: "User", kind: "message", T: User, oneof: "Result" },
    { no: 12, name: "Organization", kind: "message", T: Organization, oneof: "Result" },
    { no: 13, name: "Tooth", kind: "message", T: Tooth, oneof: "Result" },
  ],
);

/**
 * @generated from message com.diagnocat.core.DeleteStudyAssetByExternalIDReq
 */
export const DeleteStudyAssetByExternalIDReq = proto3.makeMessageType(
  "com.diagnocat.core.DeleteStudyAssetByExternalIDReq",
  () => [
    { no: 1, name: "StudyID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ExternalID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.DeleteStudyAssetByExternalIDResp
 */
export const DeleteStudyAssetByExternalIDResp = proto3.makeMessageType(
  "com.diagnocat.core.DeleteStudyAssetByExternalIDResp",
  () => [
    { no: 1, name: "DeletedAsset", kind: "message", T: Asset },
    { no: 2, name: "Study", kind: "message", T: Study },
    { no: 3, name: "Reports", kind: "message", T: Report, repeated: true },
    { no: 4, name: "Teeth", kind: "message", T: Tooth, repeated: true },
    { no: 11, name: "DeletedToothIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 12, name: "DeletedConditionIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 13, name: "DeletedToothLandmarkIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

