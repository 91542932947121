// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file pipelines/report_ios_jaw_classification.proto (package com.diagnocat.pipelines, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { IOSJawTypeClassification } from "../model/dto_report_segmentron_common_pb.js";

/**
 * @generated from message com.diagnocat.pipelines.IOSJawTypeClassifications_Update
 */
export const IOSJawTypeClassifications_Update = proto3.makeMessageType(
  "com.diagnocat.pipelines.IOSJawTypeClassifications_Update",
  () => [
    { no: 1, name: "Jaws", kind: "message", T: IOSJawTypeClassification, repeated: true },
  ],
);

