import { Patient } from '@/shared/api/protocol-ts/model/dto_patient_pb';
import { PatientPermissions } from '@/shared/api/protocol-ts/model/dto_patient_permissions_pb';

export const getPatientYourPermissions = (
  patient?: Patient,
): PatientPermissions => {
  const permissions = patient?.YourPermissions;

  const plainPermissions = Object.fromEntries(
    Object.entries(permissions ?? {}).map(([key, value]) => [
      key,
      value ?? false,
    ]),
  );

  return new PatientPermissions(plainPermissions);
};
