import { useAppDispatch } from '@/shared/hooks';
import {
  ReportRequest_InputType_CBCT_IOS_Superimposition,
  ReportRequest_InputType_StudioImplant,
  ReportRequest_InputType_StudioOrtho,
} from '@/shared/api/protocol-ts/model/dto_report_request_pb';
import { RequestReportReq } from '@/shared/api/protocol-ts/api/core/svc_report_pb';

import { reportsModel } from '@/entities/reports';

export const useNewReport = () => {
  const dispatch = useAppDispatch();

  const requestIOXRayGPReport = async (studyID: string) => {
    const { Report } = await dispatch(
      reportsModel.thunks.requestReport(
        new RequestReportReq({
          Request: {
            Input: {
              case: 'InputIOXRayGP',
              value: {
                IntraoralXRayStudyID: studyID,
              },
            },
          },
        }),
      ),
    ).unwrap();

    if (Report) {
      dispatch(reportsModel.actions.setOne(Report));
    }
  };

  const requestPanoGPReport = async (studyID: string) => {
    const { Report } = await dispatch(
      reportsModel.thunks.requestReport(
        new RequestReportReq({
          Request: {
            Input: {
              case: 'InputPanoGP',
              value: {
                PanoramicXRayStudyID: studyID,
              },
            },
          },
        }),
      ),
    ).unwrap();

    if (Report) {
      dispatch(reportsModel.actions.setOne(Report));
    }
  };

  const requestCBCTGPReport = async (studyID: string) => {
    const { Report } = await dispatch(
      reportsModel.thunks.requestReport(
        new RequestReportReq({
          Request: {
            Input: {
              case: 'InputCBCTGP',
              value: {
                CBCTStudyID: studyID,
              },
            },
          },
        }),
      ),
    ).unwrap();

    if (Report) {
      dispatch(reportsModel.actions.setOne(Report));
    }
  };

  const requestCBCTOrthoReport = async (CBCTStudyID: string) => {
    const { Report } = await dispatch(
      reportsModel.thunks.requestReport(
        new RequestReportReq({
          Request: {
            Input: {
              case: 'InputCBCTOrtho',
              value: {
                CBCTStudyID,
              },
            },
          },
        }),
      ),
    ).unwrap();

    if (Report) {
      dispatch(reportsModel.actions.setOne(Report));
    }
  };

  const requestCBCTSegmentationReport = async (CBCTStudyID: string) => {
    const { Report } = await dispatch(
      reportsModel.thunks.requestReport(
        new RequestReportReq({
          Request: {
            Input: {
              case: 'InputCBCTSegmentation',
              value: {
                CBCTStudyID,
              },
            },
          },
        }),
      ),
    ).unwrap();

    if (Report) {
      dispatch(reportsModel.actions.setOne(Report));
    }
  };

  const requestStudioOrthoReport = async ({
    CBCTStudyID,
    IOSMeshesStudyID,
    DentalPhotoStudyIDs,
    SecondaryCBCTStudyID,
  }: ReportRequest_InputType_StudioOrtho) => {
    const { Report } = await dispatch(
      reportsModel.thunks.requestReport(
        new RequestReportReq({
          Request: {
            Input: {
              case: 'InputStudioOrtho',
              value: {
                CBCTStudyID,
                IOSMeshesStudyID,
                DentalPhotoStudyIDs,
                SecondaryCBCTStudyID,
              },
            },
          },
        }),
      ),
    ).unwrap();

    if (Report) {
      dispatch(reportsModel.actions.setOne(Report));
    }
  };

  const requestStudioImplantReport = async ({
    CBCTStudyID,
    IOSMeshesStudyID,
    DentalPhotoStudyIDs,
  }: ReportRequest_InputType_StudioImplant) => {
    const { Report } = await dispatch(
      reportsModel.thunks.requestReport(
        new RequestReportReq({
          Request: {
            Input: {
              case: 'InputStudioImplant',
              value: {
                CBCTStudyID,
                IOSMeshesStudyID,
                DentalPhotoStudyIDs,
              },
            },
          },
        }),
      ),
    ).unwrap();

    if (Report) {
      dispatch(reportsModel.actions.setOne(Report));
    }
  };

  const requestCBCTIOSSuperimpositionReport = async ({
    CBCTStudyID,
    STLStudyID,
    DentalPhotoStudyIDs,
  }: ReportRequest_InputType_CBCT_IOS_Superimposition) => {
    const { Report } = await dispatch(
      reportsModel.thunks.requestReport(
        new RequestReportReq({
          Request: {
            Input: {
              case: 'InputCBCTIOSSuperimposition',
              value: {
                CBCTStudyID,
                STLStudyID,
                DentalPhotoStudyIDs,
              },
            },
          },
        }),
      ),
    ).unwrap();

    if (Report) {
      dispatch(reportsModel.actions.setOne(Report));
    }
  };

  const requestIOSSegmentationReport = async (IOSMeshesStudyID: string) => {
    const { Report } = await dispatch(
      reportsModel.thunks.requestReport(
        new RequestReportReq({
          Request: {
            Input: {
              case: 'InputIOSSegmentation',
              value: {
                IOSMeshesStudyID,
              },
            },
          },
        }),
      ),
    ).unwrap();

    if (Report) {
      dispatch(reportsModel.actions.setOne(Report));
    }
  };

  return {
    requestIOXRayGPReport,
    requestPanoGPReport,
    requestCBCTGPReport,
    requestCBCTSegmentationReport,
    requestStudioOrthoReport,
    requestStudioImplantReport,
    requestCBCTIOSSuperimpositionReport,
    requestCBCTOrthoReport,
    requestIOSSegmentationReport,
  };
};
