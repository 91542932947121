import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Back, Breadcrumbs } from '@/shared/ui';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { useAppSelector } from '@/shared/hooks';

import { organizationModel } from '@/entities/organization';

import { useBreadcrumbs } from '../hooks/useBreadcrumbs';

import { ThemeSelector } from './ThemeSelector/ThemeSelector';
import { Logo } from './Logo/Logo';
import { ControlPanel } from './ControlPanel/ControlPanel';
import styles from './Header.module.scss';
import { LanguageSelector } from './LanguageSelector/LanguageSelector';
import { LearningHub } from './LearningHub/LearningHub';

type HeaderProps = {
  fullWidth?: boolean;
  className?: string;
  back?: boolean;
};

export const Header: FC<HeaderProps> = (props) => {
  const { fullWidth = false, className, back = false } = props;

  const showNotForCommercialUseBanner = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Show_NotForCommercialUseBanner,
    ),
  );

  const breadCrumbs = useBreadcrumbs();

  return (
    <header
      className={cn(styles.container, fullWidth && styles.fullWidth, className)}
    >
      <Logo className={styles.logo} />

      <div className={styles.navigation}>
        {back && <Back />}

        {!back && <Breadcrumbs breadCrumbs={breadCrumbs} />}
      </div>

      {showNotForCommercialUseBanner && (
        <div className={styles.warning}>
          <FormattedMessage
            id="header.betaWarning"
            defaultMessage="WARNING: Not for commercial use - Beta Testing"
          />
        </div>
      )}

      <div className={styles.settingsWrapper}>
        <LearningHub />

        <LanguageSelector />

        <ThemeSelector />

        <ControlPanel />
      </div>
    </header>
  );
};
