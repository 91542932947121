import React, { FC } from 'react';
import cn from 'classnames';

import styles from './MobileFooter.module.scss';

type MobileFooterProps = {
  className?: string;
  testID?: string;
  children?: React.ReactNode;
};

export const MobileFooter: FC<MobileFooterProps> = (props) => {
  const { className, testID, children } = props;

  return (
    <footer className={cn(styles.container, className)} data-testid={testID}>
      {children}
    </footer>
  );
};
