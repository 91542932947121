import React, { FC, memo, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { useAppSelector } from '@/shared/hooks';

import { slicesModel } from '@/entities/slices';

import { useAddAndRemoveMedicalImage } from '../../hooks/useAddAndRemoveMedicalImage';

import styles from './MedicalImagesDnD.module.scss';

type MedicalImagesDnDProps = {
  toothID: string;
  checkIsTheMedicalImageSelected: (assetID: string) => boolean;
  className?: string;
};

export const MedicalImagesDnD: FC<MedicalImagesDnDProps> = memo((props) => {
  const { className, toothID, checkIsTheMedicalImageSelected } = props;

  const draggedSliceID = useAppSelector(
    slicesModel.selectors.selectDraggedSliceID,
  );

  const [dragOvered, setDragOvered] = useState(false);

  const { addMedicalImageToSelected } = useAddAndRemoveMedicalImage();

  const handleDragOver = (event: React.MouseEvent) => {
    event.preventDefault();
    setDragOvered(true);
  };

  const handleDragLeave = (event: React.MouseEvent) => {
    event.preventDefault();
    setDragOvered(false);
  };

  const handleDrop = (event: React.MouseEvent) => {
    event.preventDefault();
    if (!checkIsTheMedicalImageSelected(draggedSliceID)) {
      addMedicalImageToSelected(draggedSliceID, toothID);
    }

    setDragOvered(false);
  };

  return (
    <div
      className={cn(
        className,
        styles.container,
        draggedSliceID && styles.dragged,
        dragOvered && styles.dragOvered,
      )}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      <h6 className={cn(styles.title, 'h6')}>
        <FormattedMessage
          id="selectedMedicalImages.addImage"
          defaultMessage="Add image"
        />
      </h6>

      <span className={cn(styles.description, 'p3')}>
        <FormattedMessage
          id="selectedMedicalImages.dragDropDescription"
          defaultMessage="drag&drop images here"
        />
      </span>
    </div>
  );
});
