// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/core/svc_study.proto (package com.diagnocat.core, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { Patient } from "../../model/dto_patient_pb.js";

/**
 * @generated from message com.diagnocat.core.DeleteStudyReq
 */
export const DeleteStudyReq = proto3.makeMessageType(
  "com.diagnocat.core.DeleteStudyReq",
  () => [
    { no: 1, name: "StudyID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.DeleteStudyResp
 */
export const DeleteStudyResp = proto3.makeMessageType(
  "com.diagnocat.core.DeleteStudyResp",
  () => [
    { no: 1, name: "DeletedStudyID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "DeletedReportIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "DeletedToothIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "DeletedConditionIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "DeletedTeethLandmarkIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "DeletedAssetIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "ChangedPatient", kind: "message", T: Patient },
  ],
);

