import { sortBy } from 'lodash-es';

import {
  LOWER_JAW_TEETH_ISO_NUMBERS,
  UPPER_JAW_TEETH_ISO_NUMBERS,
} from '@/shared/config';

import { GroupedIOXRayImages, IOXrayImageInterface } from '..';

/**
 * FMX (Full Mouth X-ray) Matrix Grouping Documentation
 *
 * The matrix is divided into 9 sectors:
 * - Upper: Left, Middle, Right
 * - Middle: Left, Middle, Right (for bitewing x-rays)
 * - Lower: Left, Middle, Right
 *
 * Grouping logic:
 * 1. Images are first checked for special cases (handleSpecialCases):
 *    - Bitewing images (containing teeth from both jaws)
 *    - Images with specific teeth groups (upper/lower, left/right)
 *
 * 2. If no special case applies, sector is determined by:
 *    - Average tooth number position
 *    - Image width (wide vs narrow)
 *    - Jaw location (upper/lower)
 *
 * 3. Images within each sector are sorted by:
 *    - Center tooth number
 *    - Maximum tooth number
 *    - Custom sort order per sector (some sectors are reversed)
 */

// Teeth groups for sector identification
const TEETH_GROUPS = {
  MOLARS: [18, 17, 16, 28, 27, 26, 48, 47, 46, 38, 37, 36],
  UPPER_LEFT: [18, 17, 16],
  UPPER_RIGHT: [26, 27, 28],
  LOWER_LEFT: [48, 47, 46],
  LOWER_RIGHT: [36, 37, 38],
  UPPER_CENTRAL: [11, 21],
  LOWER_CENTRAL: [31, 41],
  MIDDLE_LEFT: [16, 17, 46, 47],
  MIDDLE_RIGHT: [26, 27, 36, 37],
};

// Threshold values for sector determination
const THRESHOLDS = {
  UPPER_RIGHT: 14,
  UPPER_LEFT: 24,
  MIDDLE_RIGHT: 30,
  LOWER_RIGHT: 34,
  LOWER_LEFT: 44,
  UPPER_JAW_SIDE: 20,
  LOWER_JAW_SIDE: 40,
};

// Sector sorting configuration
const SECTOR_SORT_CONFIG: Record<
  keyof GroupedIOXRayImages,
  { reverse?: boolean; useMiddleSort?: boolean }
> = {
  UpperLeft: { reverse: true },
  UpperMiddle: { useMiddleSort: true },
  UpperRight: { reverse: false },
  MiddleLeft: { reverse: true },
  MiddleMiddle: { reverse: false },
  MiddleRight: { reverse: false },
  LowerLeft: { reverse: true },
  LowerMiddle: { reverse: true },
  LowerRight: { reverse: false },
};

// Pure utility functions
const getCenterToothNumber = (teethNumbers: number[]) =>
  teethNumbers[Math.floor(teethNumbers.length / 2)];

const isUpperJaw = (teethNumbers: number[]) =>
  teethNumbers.every((tooth) => UPPER_JAW_TEETH_ISO_NUMBERS.includes(tooth));

const isLowerJaw = (teethNumbers: number[]) =>
  teethNumbers.every((tooth) => LOWER_JAW_TEETH_ISO_NUMBERS.includes(tooth));

const hasTeethInUpperJaw = (teethNumbers: number[]) =>
  teethNumbers.some((tooth) => UPPER_JAW_TEETH_ISO_NUMBERS.includes(tooth));

const hasTeethInLowerJaw = (teethNumbers: number[]) =>
  teethNumbers.some((tooth) => LOWER_JAW_TEETH_ISO_NUMBERS.includes(tooth));

const isBitewing = (teethNumbers: number[]) =>
  hasTeethInUpperJaw(teethNumbers) && hasTeethInLowerJaw(teethNumbers);

const hasAnyTeethFrom = (teethNumbers: number[], teethSet: number[]) =>
  teethNumbers.some((tooth) => teethSet.includes(tooth));

const calculateAverage = (numbers: number[]) =>
  numbers.length
    ? numbers.reduce((sum, num) => sum + num, 0) / numbers.length
    : 0;

/**
 * Determines if an image is "wide" (width > height)
 * Wide images usually correspond to lateral sectors or bitewings
 * @param image Image object with dimension information
 * @returns true if the image is "wide", false if it's "tall"
 */
const isWideImage = (image: IOXrayImageInterface): boolean => {
  // Check if the image has dimension information
  const width = image?.originalSize?.width;
  const height = image?.originalSize?.height;

  if (width && height) {
    return width > height;
  }

  // If dimension information is not available, use heuristics based on teeth
  const { teethISONumbers } = image;

  // If the image has teeth from both jaws or has molars, consider it wide
  return (
    isBitewing(teethISONumbers) ||
    hasAnyTeethFrom(teethISONumbers, TEETH_GROUPS.MOLARS)
  );
};

/**
 * Determines teeth to use for analysis based on jaw and side
 * @param sortedTeeth Sorted array of teeth numbers
 * @param hasUpperTeeth Whether there are teeth from upper jaw
 * @param hasLowerTeeth Whether there are teeth from lower jaw
 * @returns Array of teeth to use for analysis
 */
const getTeethForAnalysis = (
  sortedTeeth: number[],
  hasUpperTeeth: boolean,
  hasLowerTeeth: boolean,
): number[] => {
  // If it's a bitewing or there are 4 or fewer teeth, use all teeth
  if ((hasUpperTeeth && hasLowerTeeth) || sortedTeeth.length <= 4) {
    return sortedTeeth;
  }

  const avgAllTeeth = calculateAverage(sortedTeeth);

  // For upper jaw only
  if (hasUpperTeeth && !hasLowerTeeth) {
    return avgAllTeeth < THRESHOLDS.UPPER_JAW_SIDE
      ? sortedTeeth.slice(1) // Right side - discard the leftmost tooth
      : sortedTeeth.slice(0, -1); // Left side - discard the rightmost tooth
  }

  // For lower jaw only
  if (hasLowerTeeth && !hasUpperTeeth) {
    return avgAllTeeth < THRESHOLDS.LOWER_JAW_SIDE
      ? sortedTeeth.slice(1) // Right side - discard the leftmost tooth
      : sortedTeeth.slice(0, -1); // Left side - discard the rightmost tooth
  }

  // Default case - use all teeth
  return sortedTeeth;
};

/**
 * Determines the sector based on teeth numbers and image aspect ratio
 * Uses an approach with discarding extreme teeth depending on the side
 */
const determineSector = (
  image: IOXrayImageInterface,
): keyof GroupedIOXRayImages => {
  const { teethISONumbers } = image;

  // If no teeth, return UpperMiddle by default
  if (!teethISONumbers.length) {
    return 'UpperMiddle';
  }

  // Sort teeth by numbers
  const sortedTeeth = [...teethISONumbers].sort((a, b) => a - b);

  // Check if there are teeth from upper and lower jaws
  const hasUpperTeeth = hasTeethInUpperJaw(sortedTeeth);
  const hasLowerTeeth = hasTeethInLowerJaw(sortedTeeth);
  const isBitewingImage = isBitewing(sortedTeeth);

  // Determine which teeth to use for analysis
  const teethForAnalysis = getTeethForAnalysis(
    sortedTeeth,
    hasUpperTeeth,
    hasLowerTeeth,
  );

  // Calculate the average of teeth for analysis
  const avgTooth = calculateAverage(teethForAnalysis);

  // Check the aspect ratio of the image
  const isWide = isWideImage(image);

  // Determine sector based on jaw and teeth characteristics
  if (isBitewingImage) {
    return avgTooth < THRESHOLDS.MIDDLE_RIGHT ? 'MiddleRight' : 'MiddleLeft';
  }

  if (hasUpperTeeth && !hasLowerTeeth) {
    if (avgTooth < THRESHOLDS.UPPER_RIGHT && isWide) return 'UpperRight';
    if (avgTooth > THRESHOLDS.UPPER_LEFT && isWide) return 'UpperLeft';
    return 'UpperMiddle';
  }

  if (hasLowerTeeth && !hasUpperTeeth) {
    if (avgTooth < THRESHOLDS.LOWER_RIGHT && isWide) return 'LowerRight';
    if (avgTooth > THRESHOLDS.LOWER_LEFT && isWide) return 'LowerLeft';
    return 'LowerMiddle';
  }

  // Default case
  return 'UpperMiddle';
};

/**
 * Special cases for problematic images
 */
const handleSpecialCases = (
  image: IOXrayImageInterface,
): keyof GroupedIOXRayImages | null => {
  const { teethISONumbers } = image;
  const isWide = isWideImage(image);

  // Bitewing case (teeth from both jaws)
  if (isBitewing(teethISONumbers)) {
    if (hasAnyTeethFrom(teethISONumbers, TEETH_GROUPS.MIDDLE_LEFT)) {
      return 'MiddleLeft';
    }
    if (hasAnyTeethFrom(teethISONumbers, TEETH_GROUPS.MIDDLE_RIGHT)) {
      return 'MiddleRight';
    }
    return 'MiddleLeft'; // Default for bitewings
  }

  // Upper jaw cases
  if (isUpperJaw(teethISONumbers)) {
    if (hasAnyTeethFrom(teethISONumbers, TEETH_GROUPS.UPPER_LEFT) && isWide) {
      return 'UpperLeft';
    }
    if (hasAnyTeethFrom(teethISONumbers, TEETH_GROUPS.UPPER_RIGHT) && isWide) {
      return 'UpperRight';
    }
    if (
      hasAnyTeethFrom(teethISONumbers, TEETH_GROUPS.UPPER_CENTRAL) &&
      !isWide
    ) {
      return 'UpperMiddle';
    }
  }

  // Lower jaw cases
  if (isLowerJaw(teethISONumbers)) {
    if (hasAnyTeethFrom(teethISONumbers, TEETH_GROUPS.LOWER_LEFT) && isWide) {
      return 'LowerLeft';
    }
    if (hasAnyTeethFrom(teethISONumbers, TEETH_GROUPS.LOWER_RIGHT) && isWide) {
      return 'LowerRight';
    }
    if (
      hasAnyTeethFrom(teethISONumbers, TEETH_GROUPS.LOWER_CENTRAL) &&
      !isWide
    ) {
      return 'LowerMiddle';
    }
  }

  // No special case found
  return null;
};

/**
 * Sorts images by center tooth and max tooth number
 * @param images Array of X-ray images
 * @param reverse Whether to reverse the sort order
 * @returns Sorted array of images
 */
const sortImagesByCenterTooth = (
  images: IOXrayImageInterface[],
  reverse = false,
): IOXrayImageInterface[] => {
  const sorted = sortBy(images, (image) => {
    const { teethISONumbers } = image;
    const centerNumber = getCenterToothNumber(teethISONumbers);
    const maxNumber = Math.max(...teethISONumbers);
    return [maxNumber, centerNumber];
  });

  return reverse ? sorted.reverse() : sorted;
};

/**
 * Groups X-ray images by partition based on teeth numbers and image characteristics
 * @param IOXRayImages Array of X-ray images
 * @returns Object with images grouped by partition
 */
export const groupIOXRayImagesByPartition = (
  IOXRayImages: IOXrayImageInterface[],
): GroupedIOXRayImages => {
  const initialGroupedImages: GroupedIOXRayImages = {
    UpperLeft: [],
    UpperMiddle: [],
    UpperRight: [],
    MiddleLeft: [],
    MiddleMiddle: [],
    MiddleRight: [],
    LowerLeft: [],
    LowerMiddle: [],
    LowerRight: [],
  };

  if (!IOXRayImages) {
    return initialGroupedImages;
  }

  const groupedImages = IOXRayImages.reduce((grouped, image) => {
    const specialCase = handleSpecialCases(image);
    const sector = specialCase || determineSector(image);

    return {
      ...grouped,
      [sector]: [...grouped[sector], image],
    };
  }, initialGroupedImages);

  // Sort images in each sector
  return Object.entries(groupedImages).reduce((result, [sector, images]) => {
    const config = SECTOR_SORT_CONFIG[sector as keyof GroupedIOXRayImages];
    return {
      ...result,
      [sector]: sortImagesByCenterTooth(images, config?.reverse),
    };
  }, {} as GroupedIOXRayImages);
};
