import cn from 'classnames';

import { PatientsFilter, PatientsFiltersType } from '@/entities/patient';

import { ViewSwitcher } from '@/features/viewSwitcher';

import { PatientsFilters } from '../PatientsFilters/PatientsFilters';
import { PatientListSearch } from '../PatientListSearch/PatientListSearch';

import styles from './PatientListControls.module.scss';

type PatientListControlsProps = {
  amount: {
    sharedWithMeCount: number;
    sharedByMeCount: number;
    totalOrganizationPatientCount: number;
  };
  onInputChange: (value: string) => void;
  onFilterChange: (searchValue: string) => void;
  currentPatientsFilter: PatientsFiltersType;
  className?: string;
};

export const PatientListControls = ({
  onInputChange,
  onFilterChange,
  amount,
  currentPatientsFilter,
  className,
}: PatientListControlsProps) => {
  const isAllPatientList =
    currentPatientsFilter === PatientsFilter.all ||
    currentPatientsFilter === null;

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.mainFilters}>
        <PatientsFilters
          amount={amount}
          onFilterChange={onFilterChange}
          className={styles.patientFilters}
        />

        <PatientListSearch
          className={styles.search}
          onInputChange={onInputChange}
          currentPatientsFilter={currentPatientsFilter}
        />

        {isAllPatientList && <ViewSwitcher className={styles.viewSwitcher} />}
      </div>
    </div>
  );
};
