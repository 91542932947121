// @generated by protoc-gen-connect-web v0.6.0 with parameter "target=ts,ts_nocheck=false"
// @generated from file api/core/svc_study.proto (package com.diagnocat.core, syntax proto3)
/* eslint-disable */

import { DeleteStudyReq, DeleteStudyResp } from "./svc_study_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service com.diagnocat.core.StudyService
 */
export const StudyService = {
  typeName: "com.diagnocat.core.StudyService",
  methods: {
    /**
     * @generated from rpc com.diagnocat.core.StudyService.DeleteStudy
     */
    deleteStudy: {
      name: "DeleteStudy",
      I: DeleteStudyReq,
      O: DeleteStudyResp,
      kind: MethodKind.Unary,
    },
  }
} as const;

