import cn from 'classnames';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { omit } from 'ramda';
import { useSelector } from 'react-redux';

import { MedicalImageRender } from '@/shared/graphics/medicalImageRender/MedicalImageRender';
import {
  ExitFromFullscreenButton,
  Icon,
  IconNames,
  Tooltip,
} from '@/shared/ui';
import {
  CombinedToolNames,
  combinedToolNames,
  toggledToolNames,
  ToggledToolNames,
  ToolNames,
} from '@/shared/config';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { MedicalImageViewOptions } from '@/shared/api/protocol-ts/model/dto_common_image_view_options_pb';
import {
  ReportSettings,
  ReportType,
} from '@/shared/api/protocol-ts/model/dto_report_pb';
import { SetReportSettingsReq } from '@/shared/api/protocol-ts/api/core/svc_report_pb';

import { reportsModel, transformViewOptions } from '@/entities/reports';
import { toolbarMIRModel } from '@/entities/toolbarMIR';

import { ToolbarOptions } from '../../config/types';
import { toolsMessages } from '../../config/i18n';
import { CONTROL_TOOLS_ICONS } from '../../config/constants';

import styles from './MedicalImageToolbar.module.scss';

export type MedicalImageToolbarProps<T extends ToolbarOptions[]> = {
  toolbarID: string;
  className?: string;
  testID?: string;
  defaultControl?: (T[number]['control'] & ToggledToolNames) | undefined;
  controlsOptions: T;
};

const isCombinedTool = (control: ToolNames): control is CombinedToolNames =>
  [...combinedToolNames].includes(control as CombinedToolNames);
const isToggledTool = (control: ToolNames): control is ToggledToolNames =>
  [...toggledToolNames].includes(control as ToggledToolNames);

export const MedicalImageToolbar = <T extends ToolbarOptions[]>(
  props: MedicalImageToolbarProps<T>,
) => {
  const { toolbarID, className, testID, controlsOptions, defaultControl } =
    props;

  const dispatch = useAppDispatch();

  const currentReport = useAppSelector(
    reportsModel.selectors.selectCurrentReport,
  );

  const { currentAngle, focusedImageMetaID, isTurnToolDisabled } =
    useAppSelector(reportsModel.selectors.selectRotatedImageMetaData);

  const isInvert = currentReport?.MedicalImageFeatures?.ViewOptions?.Invert;
  const showPanoramaSplitInsteadOfSingle =
    currentReport?.Settings?.ShowPanoramaSplitInsteadOfSingle;

  const activeTools = useSelector(
    toolbarMIRModel.selectors.selectActiveTools(toolbarID),
  );

  const isControlActive = (control: ToolNames) => {
    return activeTools.includes(control as ToggledToolNames);
  };

  useEffect(() => {
    dispatch(
      toolbarMIRModel.actions.initToolbarMIR({
        toolbarID,
        initialState: {
          split: !!showPanoramaSplitInsteadOfSingle,
          invert: !!isInvert,
          expand: false,
          hideMarks: false,
          activeControl: defaultControl,
        },
      }),
    );
    return () => {
      dispatch(toolbarMIRModel.actions.deleteToolbarMIR(toolbarID));
    };
  }, [toolbarID]);

  useEffect(() => {
    const handleFullScreenChange = (e: Event) => {
      console.log(e);
      if (!document.fullscreenElement) {
        dispatch(
          toolbarMIRModel.actions.expand({ toolbarID: 'main', value: false }),
        );
      }
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  const { formatMessage } = useIntl();
  // TODO: add try/catch block into async operations
  const handleReset = async () => {
    const { Report } = await dispatch(
      reportsModel.thunks.setReportMedicalImageViewOptions({
        ReportID: currentReport?.ID as string,
        MedicalImageViewOptions: {
          // NOTE: resetting inversion needs to be done both in the report (handleReset) and in MedicalImageRender
          ...(isInvert && { Invert: false }),
        } as MedicalImageViewOptions,
      }),
    ).unwrap();

    if (Report) {
      dispatch(reportsModel.actions.setNewestOne(Report));
    }
  };

  const handleSplit = async () => {
    const { Report } = await dispatch(
      reportsModel.thunks.setReportSettings({
        ReportID: currentReport?.ID as string,
        Settings: {
          ...(currentReport?.Settings as ReportSettings),
          ShowPanoramaSplitInsteadOfSingle: !showPanoramaSplitInsteadOfSingle,
        },
      } as SetReportSettingsReq),
    ).unwrap();
    if (Report) {
      dispatch(reportsModel.actions.setNewestOne(Report));
    }
  };

  useEffect(() => {
    dispatch(
      toolbarMIRModel.actions.split({
        toolbarID: toolbarID,
        value: !!showPanoramaSplitInsteadOfSingle,
      }),
    );
  }, [showPanoramaSplitInsteadOfSingle]);

  const rotateImageHandle = async () => {
    if (focusedImageMetaID && currentReport?.ID && currentAngle !== undefined) {
      const newAngle = (currentAngle - 90) % 360;

      const { Report } = await dispatch(
        reportsModel.thunks.setImageMetaOrientationAngle({
          ReportID: currentReport.ID,
          ImageMetaID: focusedImageMetaID,
          OrientationAngle: newAngle,
        }),
      ).unwrap();

      if (Report) {
        dispatch(reportsModel.actions.setNewestOne(Report));
      }
    }
  };

  const handleControlClick = async (control: ToolNames, isActive: boolean) => {
    if (control === 'expand') {
      if (!isActive) {
        dispatch(toolbarMIRModel.actions.expand({ toolbarID, value: true }));

        const fullscreenOverlay = document.getElementById('report_render');

        if (fullscreenOverlay) {
          fullscreenOverlay.requestFullscreen();
        }
      } else {
        dispatch(toolbarMIRModel.actions.expand({ toolbarID, value: false }));
        document.exitFullscreen();
      }
      return;
    }

    if (control === 'reset') {
      activeTools
        .filter((tool) => tool !== 'expand' && tool !== 'split')
        .forEach((tool) =>
          dispatch(
            toolbarMIRModel.actions.activateTool({
              toolbarID,
              control: tool,
              value: false,
            }),
          ),
        );
      handleReset();
      MedicalImageRender.resetUndoStack('main');
      return;
    }

    if (isCombinedTool(control) || isToggledTool(control)) {
      dispatch(
        toolbarMIRModel.actions.activateTool({
          toolbarID,
          control,
          value: !isActive,
        }),
      );
    }

    if (control === 'split') {
      handleSplit();
    }

    if (control === 'turn') {
      rotateImageHandle();
    }
  };

  const handleViewOptionsChange = (
    event:
      | {
          type: 'sharpnessChanged';
          id: string;
          sharpness: number;
          meta?: Record<string, unknown>;
        }
      | {
          type: 'screenSharpnessChanged';
          sharpness: number;
          meta?: Record<string, unknown>;
        }
      | {
          type: 'brightnessContrastChanged';
          id: string;
          brightness: number;
          contrast: number;
          meta?: Record<string, unknown>;
        }
      | {
          type: 'screenBrightnessContrastChanged';
          brightness: number;
          contrast: number;
          meta?: Record<string, unknown>;
        },
  ) => {
    const viewOptions = transformViewOptions(
      omit(['id', 'type', 'meta'], event),
    ) as MedicalImageViewOptions;

    switch (event.type) {
      case 'sharpnessChanged':
      case 'brightnessContrastChanged': {
        if (currentReport?.Type === ReportType.ReportType_CBCT_GP) {
          dispatch(
            reportsModel.thunks.setReportMedicalImageViewOptions({
              ReportID: currentReport?.ID,
              MedicalImageViewOptions: viewOptions,
            }),
          );
        } else {
          dispatch(
            reportsModel.thunks.setImageMetaViewOptions({
              ReportID: currentReport?.ID,
              ImageMetaID: event.meta?.imageMetaID as string,
              MedicalImageViewOptions: viewOptions,
            }),
          );
        }
        break;
      }
      case 'screenSharpnessChanged':
      case 'screenBrightnessContrastChanged': {
        dispatch(
          reportsModel.thunks.setReportMedicalImageViewOptions({
            ReportID: currentReport?.ID as string,
            MedicalImageViewOptions: viewOptions as MedicalImageViewOptions,
          }),
        );
        break;
      }
    }
  };

  // set tools
  useEffect(() => {
    if (currentReport) {
      const disposers = [
        MedicalImageRender.addEventListener(
          'brightnessContrastChanged',
          handleViewOptionsChange,
        ),
        MedicalImageRender.addEventListener(
          'sharpnessChanged',
          handleViewOptionsChange,
        ),
        MedicalImageRender.addEventListener(
          'screenSharpnessChanged',
          handleViewOptionsChange,
        ),
        MedicalImageRender.addEventListener(
          'screenBrightnessContrastChanged',
          handleViewOptionsChange,
        ),
      ];

      return () => disposers.forEach((disposer) => disposer && disposer());
    }
  }, [currentReport?.ID]);

  return (
    <>
      <div className={cn(styles.container, className)} data-testid={testID}>
        {controlsOptions.map((controlOption) => (
          <Tooltip.Primary
            key={controlOption.control}
            side="right"
            content={
              <span className="p3">
                {formatMessage(toolsMessages[controlOption.control])}
              </span>
            }
          >
            <Icon
              name={CONTROL_TOOLS_ICONS[controlOption.control] as IconNames}
              className={cn(
                styles.icon,
                isControlActive(controlOption.control) && styles.active,
                isTurnToolDisabled &&
                  controlOption.control === 'turn' &&
                  styles.disabled,
              )}
              size={32}
              onClick={() => {
                if (isTurnToolDisabled && controlOption.control === 'turn') {
                  return;
                }

                const isActive = isControlActive(controlOption.control);
                if (controlOption.onClick) {
                  controlOption.onClick(controlOption.control, isActive);
                } else {
                  handleControlClick(controlOption.control, isActive);
                }
              }}
            />
          </Tooltip.Primary>
        ))}
      </div>
      {activeTools.includes('expand') && (
        <ExitFromFullscreenButton
          onClick={() =>
            dispatch(
              toolbarMIRModel.actions.expand({ toolbarID, value: false }),
            )
          }
        />
      )}
    </>
  );
};
