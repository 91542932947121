import { FC, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Icon } from '@/shared/ui';
import { useAppDispatch } from '@/shared/hooks';
import {
  Invitation,
  Invitation_InvitationStatus,
} from '@/shared/api/protocol-ts/model/dto_access_pb';

import { ModalID, modalModel } from '@/entities/modal';

import styles from './SharedDoctorRow.module.scss';

type SharedDoctorRowProps = {
  sharedPatientInvitation: Invitation;
  canCancelSharing: boolean;
  className?: string;
  testID?: string;
  lessInfo?: boolean;
  isMobile?: boolean;
  openShowAllModal?: () => void;
};

export const SharedDoctorRow: FC<SharedDoctorRowProps> = (props) => {
  const {
    sharedPatientInvitation,
    canCancelSharing,
    className,
    testID,
    lessInfo,
    isMobile,
    openShowAllModal,
  } = props;

  const { formatDate } = useIntl();

  const dispatch = useAppDispatch();

  const [copied, setCopied] = useState(false);

  const email = sharedPatientInvitation.Recipient?.Email;

  const accessCode = sharedPatientInvitation.Secret;

  const isInviteAccepted =
    sharedPatientInvitation.Status ===
    Invitation_InvitationStatus.StatusAccepted;

  const openRevokeSharedPatientModal = () => {
    if (sharedPatientInvitation.Target?.Target.case === 'Patient') {
      const patientID =
        sharedPatientInvitation.Target?.Target.value.PatientID ?? '';

      dispatch(
        modalModel.actions.openModal({
          modalID: ModalID.RevokeSharedPatientModal,
          data: {
            patientID,
            sharedPatientDoctorID: sharedPatientInvitation.Recipient?.UserID,
            invitationID: sharedPatientInvitation.ID,
            isInviteAccepted,
          },
        }),
      );
    }
  };

  const copyToClipBoard = async () => {
    try {
      await navigator.clipboard.writeText(accessCode);

      setCopied(true);

      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      setTimeout(() => setCopied(false), 2000);
    }
  };

  const statusIconName = isInviteAccepted ? 'check' : 'time';
  const statusIconSize = isInviteAccepted ? 24 : 32;

  if (isMobile) {
    return (
      <div
        className={cn(
          styles.container,
          !isInviteAccepted && styles.waitForAccept,
          className,
        )}
        data-testid={testID}
        onClick={openRevokeSharedPatientModal}
      >
        <p className={cn(styles.email, 'p2')}>{email}</p>
        {!isInviteAccepted && <Icon name="time" size={32} />}
      </div>
    );
  }

  return (
    <div
      className={cn(styles.container, className)}
      data-testid={testID}
      onClick={openShowAllModal}
    >
      <div
        className={cn(
          styles.statusIcon,
          isInviteAccepted && styles.isInviteAccepted,
        )}
      >
        <Icon name={statusIconName} size={statusIconSize} />
      </div>

      <div className={cn(styles.content, lessInfo && styles.showLess)}>
        <p className={cn(styles.email, 'p2')}>{email}</p>

        {!lessInfo && (
          <>
            <p className={cn(styles.sharedDataList, 'p3')}>
              <FormattedMessage
                id="sharedDoctor.sharingDate"
                defaultMessage="Sharing Date:"
              />

              {sharedPatientInvitation.Created?.At ? (
                <span className={styles.sharedInfo}>
                  {sharedPatientInvitation &&
                    formatDate(
                      new Date(sharedPatientInvitation.Created.At.toDate()),
                      {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      },
                    )}
                </span>
              ) : null}
            </p>

            {!isInviteAccepted && (
              <p className={cn(styles.sharedDataList, 'p3')}>
                <FormattedMessage
                  id="sharedDoctor.accessCode"
                  defaultMessage="Access code:"
                />
                <span className={styles.sharedInfo}>{accessCode}</span>

                <Icon
                  name={copied ? 'check' : 'copy'}
                  size={18}
                  onClick={copyToClipBoard}
                  className={cn(!copied && styles.copyIcon)}
                />
              </p>
            )}
          </>
        )}
      </div>

      <div className={styles.controls}>
        {canCancelSharing && (
          <button
            type="button"
            className={styles.button}
            onClick={(event) => {
              event.stopPropagation();
              openRevokeSharedPatientModal();
            }}
          >
            <Icon name="delete" size={32} />
          </button>
        )}
      </div>
    </div>
  );
};
