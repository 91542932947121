import { Variants } from 'framer-motion';

import {
  MOBILE_MODAL_ANIMATION_DURATION,
  MODAL_ANIMATION_DURATION,
} from '@/shared/config';

export const defaultModalAnimationVariants: Variants = {
  initial: {
    scale: 0,
    opacity: 0,
  },
  exit: {
    scale: 0,
    opacity: 0,
  },
  open: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: MODAL_ANIMATION_DURATION,
    },
  },
  closed: {
    scale: 0,
    opacity: 0,
    transition: {
      duration: MODAL_ANIMATION_DURATION,
    },
  },
};

export const mobileModalAnimationVariants: Variants = {
  initial: {
    transform: 'translateY(100%)',
    opacity: 0,
  },
  exit: {
    transform: 'translateY(100%)',
    opacity: 0,
  },
  open: {
    transform: 'translateY(0)',
    opacity: 1,
    transition: {
      ease: 'easeOut',
      duration: MOBILE_MODAL_ANIMATION_DURATION,
    },
  },
  closed: {
    transform: 'translateY(100%)',
    opacity: 0,
    transition: {
      ease: 'easeOut',
      duration: MOBILE_MODAL_ANIMATION_DURATION,
    },
  },
};
