// Queue state and pipelines progress notifications sent by taskmaster and pipelines

// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file pipelines/report_progress.proto (package com.diagnocat.pipelines, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { ToothNumeration } from "../model/dto_report_common_pb.js";

/**
 * @generated from message com.diagnocat.pipelines.ProgressUpdate
 */
export const ProgressUpdate = proto3.makeMessageType(
  "com.diagnocat.pipelines.ProgressUpdate",
  () => [
    { no: 1, name: "EndOfToothDetections", kind: "message", T: EndOfToothDetections, oneof: "Barrier" },
    { no: 2, name: "EndOfToothConditions", kind: "message", T: EndOfToothConditions, oneof: "Barrier" },
    { no: 3, name: "EndOfToothSlices", kind: "message", T: EndOfToothSlices, oneof: "Barrier" },
  ],
);

/**
 * @generated from message com.diagnocat.pipelines.EndOfToothDetections
 */
export const EndOfToothDetections = proto3.makeMessageType(
  "com.diagnocat.pipelines.EndOfToothDetections",
  () => [
    { no: 1, name: "Numeration", kind: "message", T: ToothNumeration },
  ],
);

/**
 * @generated from message com.diagnocat.pipelines.EndOfToothConditions
 */
export const EndOfToothConditions = proto3.makeMessageType(
  "com.diagnocat.pipelines.EndOfToothConditions",
  () => [
    { no: 1, name: "Numeration", kind: "message", T: ToothNumeration },
  ],
);

/**
 * @generated from message com.diagnocat.pipelines.EndOfToothSlices
 */
export const EndOfToothSlices = proto3.makeMessageType(
  "com.diagnocat.pipelines.EndOfToothSlices",
  () => [
    { no: 1, name: "Numeration", kind: "message", T: ToothNumeration },
  ],
);

