import { FC, memo, useCallback } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Checkbox, MedicalImage } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { MedicalImageInterface } from '@/shared/config';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';

import { organizationModel } from '@/entities/organization';
import { reportsModel } from '@/entities/reports';

import styles from './GroupMedicalImagesList.module.scss';

type GroupMedicalImagesListProps = {
  focusedImageID?: string;
  zoomedImageID?: string;
  className?: string;
  medicalImages?: MedicalImageInterface[];
  selectedMedicalImages?: MedicalImageInterface[];
  canUserDeleteSlices?: boolean;
  onHoveredGuideChanged?: (assetID: string) => void;
  toggleSelected?: (medicalImage: MedicalImageInterface) => void;
};

export const GroupMedicalImagesList: FC<GroupMedicalImagesListProps> = memo(
  (props) => {
    const {
      className,
      focusedImageID,
      medicalImages = [],
      selectedMedicalImages,
      onHoveredGuideChanged,
      toggleSelected,
      canUserDeleteSlices,
    } = props;

    const isFDA = useAppSelector(
      organizationModel.selectors.selectFeatureFlag(
        FeatureFlag.FeatureFlag_FDA_SubmissionView,
      ),
    );

    const showPeriapicalRadiolucencyMasks = useAppSelector(
      reportsModel.selectors.selectShowPeriapicalRadiolucencyMasks,
    );

    const dispatch = useAppDispatch();

    const handleShowPeriapicalRadiolucencyMasks = () => {
      dispatch(
        reportsModel.actions.setShowPeriapicalRadiolucencyMasks(
          !showPeriapicalRadiolucencyMasks,
        ),
      );
    };

    const checkIsTheMedicalImageSelected = useCallback(
      (src: string) =>
        selectedMedicalImages?.some(
          (medicalImage) => medicalImage.src === src,
        ) ?? false,
      [selectedMedicalImages],
    );

    const hasMasks = medicalImages.some((medicalImage) => medicalImage?.masks);

    return (
      <div
        className={cn(styles.container, className)}
        onMouseLeave={() => onHoveredGuideChanged && onHoveredGuideChanged('')}
      >
        {hasMasks && !isFDA && (
          <div className={styles.medicalImagesActions}>
            <Checkbox
              checked={showPeriapicalRadiolucencyMasks}
              onChange={handleShowPeriapicalRadiolucencyMasks}
            >
              <FormattedMessage
                id="global.showMasks"
                defaultMessage="Show Masks"
              />
            </Checkbox>
          </div>
        )}

        <div className={styles.medicalImages}>
          {medicalImages.map((medicalImage) => (
            <div
              key={medicalImage.src}
              onMouseEnter={() =>
                onHoveredGuideChanged &&
                onHoveredGuideChanged(medicalImage.assetID)
              }
              className={styles.medicalImageWrapper}
            >
              <MedicalImage
                draggable
                key={medicalImage.src}
                medicalImage={medicalImage}
                toggleSelected={toggleSelected}
                focused={focusedImageID === medicalImage.assetID}
                order={medicalImage.order}
                canUserDeleteSlices={canUserDeleteSlices}
                isImgSelected={checkIsTheMedicalImageSelected(medicalImage.src)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  },
);
