import { compose, divide, map, prop, propOr, sum } from 'ramda';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import * as Progress from '@radix-ui/react-progress';

import { Button } from '@/shared/ui';

import { UploadFile, UploadSessionPull } from '@/features/uploadAsset';
import { SupportTooltip } from '@/features/supportTooltip';

import { UploadStudyAssetType } from '@/widgets/UploadStudyModalForm/config';
import { selectUploadStudyTypes } from '@/widgets/UploadStudyModalForm/config/i18n';

import { uploadProgressBarText } from '@/app/config/i18n';

import { UploadStatusIcon } from '../UploadStudyIcon/UploadStudyIcon';

import styles from './StudyUploadProgressItem.module.scss';

type StudyUploadProgressItemProps = {
  data: UploadSessionPull;
  sessionStartAt: number;
  onHideProgressItem: (sessionID: string) => void;
  onCancelProgress: (sessionID: string, studyID: string) => void;
};

export const StudyUploadProgressItem = (
  props: StudyUploadProgressItemProps,
) => {
  const { data, sessionStartAt, onHideProgressItem, onCancelProgress } = props;

  const { formatMessage } = useIntl();

  const files = Object.values(data.files);

  const sessionStackTotalProgress = divide(
    compose(
      sum,
      map<UploadFile, number>((file) => prop('progress', file) ?? 0),
    )(files),
    files.length,
  );

  const loaded = compose(
    sum,
    map<UploadFile, number>(propOr(1, 'loaded')),
  )(files);

  // calculate byte per ms to byte per seconds
  const timeElapsed = (Date.now() - sessionStartAt) / 1000;
  const uploadSpeed = loaded / timeElapsed;

  const sessionUploadSpeed = divide(
    compose(sum, map<UploadFile, number>(prop('rate')))(files),
    files.length,
  );

  const currentSpeed = sessionUploadSpeed
    ? (sessionUploadSpeed / 1024 / 1024).toFixed(2)
    : (uploadSpeed / 1024 / 1024).toFixed(2);

  const isFailed = data.status === 'failed' || data.status === 'unsuccessful';

  const uploadProgressPercent = isFailed
    ? 0
    : Math.round(sessionStackTotalProgress * 100);

  return (
    <div className={styles.fileStatusContainer} key={data.sessionID}>
      <Progress.Root
        className={cn(styles.fileProcessBar, isFailed && styles.failed)}
        value={uploadProgressPercent}
      >
        <Progress.Indicator
          className={styles.fileProcessLine}
          style={{ transform: `translateX(-${100 - uploadProgressPercent}%)` }}
        />

        <div className={styles.fileProcessInfo}>
          {!isFailed && (
            <span className="p3b greyText">{uploadProgressPercent}%</span>
          )}

          {data.status === 'uploading' && (
            <span className="p3 greyText">
              {currentSpeed}{' '}
              {formatMessage({
                id: 'uploadProgressSpeed',
                defaultMessage: 'MB/s',
              })}
            </span>
          )}

          <div
            className={cn(
              styles.fileProcessTextWrapper,
              isFailed && styles.failed,
              'p3',
            )}
          >
            {formatMessage(uploadProgressBarText[data.status])}

            {isFailed ? (
              <SupportTooltip errorMessage={data.errorMessage}>
                <UploadStatusIcon status="failed" />
              </SupportTooltip>
            ) : (
              <UploadStatusIcon status={data.status} />
            )}
          </div>
        </div>
      </Progress.Root>

      <div className={styles.progressItemFooter}>
        <div className={cn(styles.fileInfo, 'p2')}>
          {data?.meta?.patientName as string}{' '}
          {!!data?.meta?.studyType &&
            formatMessage(
              selectUploadStudyTypes[
                data.meta.studyType as UploadStudyAssetType
              ],
            )}
        </div>
        <div>
          {(data.status === 'success' || isFailed) && (
            <Button
              variant="tertiary"
              onClick={() => onHideProgressItem(data.sessionID)}
              size="small"
            >
              {formatMessage({ id: 'global.hide', defaultMessage: 'Hide' })}
            </Button>
          )}

          {data.status === 'uploading' && (
            <Button
              variant="tertiary"
              onClick={() => {
                onCancelProgress(data.sessionID, data?.StudyID ?? '');
              }}
              size="small"
            >
              {formatMessage({ id: 'global.cancel', defaultMessage: 'Cancel' })}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
