import { OrganizationUserRoleType } from '@/shared/api/protocol-ts/model/dto_organization_pb';

import { PermissionStatuses } from './i18n';

export type PermissionType =
  | 'Full Access'
  | 'Clinical Access'
  | 'Limited Clinical Access'
  | 'Non-clinical Member';

export const TREATING_DOCTORS_AVAILABLE_ROLES = [
  OrganizationUserRoleType.OrganizationUserRoleType_LimitedClinicalAccess,
  OrganizationUserRoleType.OrganizationUserRoleType_ClinicalAccess,
  OrganizationUserRoleType.OrganizationUserRoleType_FullAccess,
];

export const PERMISSIONS: Record<PermissionType, PermissionStatuses[]> = {
  'Full Access': [
    PermissionStatuses['Manage payments and subscriptions'],
    PermissionStatuses['Manage company settings'],
    PermissionStatuses['View and manage staff'],
    PermissionStatuses['Share patients'],
    PermissionStatuses['Manage patient’s reports'],
    PermissionStatuses['View and manage patients'],
  ],
  'Clinical Access': [
    PermissionStatuses['Share patients'],
    PermissionStatuses['View and manage staff'],
    PermissionStatuses['Manage patient’s reports'],
    PermissionStatuses['View and manage patients'],
  ],
  'Limited Clinical Access': [
    PermissionStatuses['Share patients'],
    PermissionStatuses['Manage patient’s reports'],
    PermissionStatuses['View and manage patients'],
  ],
  'Non-clinical Member': [PermissionStatuses['View and manage patients']],
};
