import { Dispatch, FC, SetStateAction } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { useAppSelector } from '@/shared/hooks';
import { getImageSrc } from '@/shared/lib';

import { albumModel } from '@/entities/album';

import { DentalPhotoCheckbox } from '../DentalPhotoCheckbox/DentalPhotoCheckbox';

import styles from './DentalPhotosStep.module.scss';

type DentalPhotosStepProps = {
  selectedIDs: string[];
  setSelectedIDs: Dispatch<SetStateAction<string[]>>;
  className?: string;
  testID?: string;
};

export const DentalPhotosStep: FC<DentalPhotosStepProps> = (props) => {
  const { className, testID, selectedIDs, setSelectedIDs } = props;

  const { formatMessage } = useIntl();

  const albums = useAppSelector(albumModel.selectors.selectAlbumsWithAssets);

  if (albums.length === 0) {
    return null;
  }

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      <div className={styles.title}>
        <h5 className="h5">
          {formatMessage({
            id: 'orderReportModal.dentalPhotoStep.title',
            defaultMessage: 'Step 3: Select dental photo',
          })}{' '}
          (
          {formatMessage({
            id: 'global.optional',
            defaultMessage: 'Optional',
          }).toLowerCase()}
          )
        </h5>

        <span className={cn(styles.counter, 'p1')}>
          {formatMessage(
            {
              id: 'orderReportModal.dentalPhotoStep.counter',
              defaultMessage: '{count} selected',
            },
            { count: selectedIDs.length },
          )}
        </span>
      </div>

      <div className={styles.innerContainer}>
        {albums.map(({ ID, StudyAssetIDs }) => (
          <div key={ID} className={styles.album}>
            {StudyAssetIDs.map((assetID) => (
              <DentalPhotoCheckbox
                key={assetID}
                name={assetID}
                src={getImageSrc(assetID, 'preview')}
                checked={selectedIDs.includes(assetID)}
                onChange={() =>
                  setSelectedIDs((prevIDs) =>
                    prevIDs.includes(assetID)
                      ? prevIDs.filter((prevID) => prevID !== assetID)
                      : prevIDs.concat(assetID),
                  )
                }
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
