import { FC, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Button, Textarea } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { Tooth_SuggestedTreatmentStatus } from '@/shared/api/protocol-ts/model/dto_report_tooth_pb';

import { organizationModel } from '@/entities/organization';
import { toothModel } from '@/entities/tooth';

import { SuggestedTreatmentButton } from '@/features/ToothCard/ui/SuggestedTreatmentButton/SuggestedTreatmentButton';

import styles from './CommentTooth.module.scss';

type CommentToothProps = {
  comment?: string;
  newComment?: string;
  onSaveComment?: () => void;
  onOpenChangeComment?: () => void;
  onCancel?: () => void;
  onChangeCommentInEditor?: (text: string) => void;
  isNewCommentLoading?: boolean;
  isCommentEditorVisible?: boolean;
  className?: string;
  testID?: string;
  autoFocus?: boolean;
  toothID: string;
};

export const CommentTooth: FC<CommentToothProps> = (props) => {
  const {
    className,
    testID,
    comment,
    newComment = '',
    isNewCommentLoading,
    isCommentEditorVisible,
    autoFocus,
    onSaveComment,
    onOpenChangeComment,
    onCancel,
    onChangeCommentInEditor,
    toothID,
  } = props;

  const showSuggestedTreatmentButton = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Enable_ToothSuggestedTreatment,
    ),
  );

  const suggestedTreatment = useAppSelector(
    toothModel.selectors.selectToothSuggestedTreatment(toothID),
  );

  const isSuggestedTreatmentLoading =
    suggestedTreatment?.Status === Tooth_SuggestedTreatmentStatus.IN_PROGRESS;

  const isCommentExists = Boolean(comment);

  const handleKeyboard = useCallback(
    (event: KeyboardEventInit) => {
      if (event.key === 'Enter' && event.ctrlKey && isCommentEditorVisible) {
        if (onSaveComment) {
          onSaveComment();
        }
      }
    },
    [isCommentEditorVisible, onSaveComment],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboard);
    return () => {
      window.removeEventListener('keydown', handleKeyboard);
    };
  }, [handleKeyboard]);

  return isCommentExists || isCommentEditorVisible ? (
    <div className={cn(styles.container, className)} data-testid={testID}>
      {isSuggestedTreatmentLoading && (
        <div className={styles.suggestedTreatmentLoading}>
          <FormattedMessage
            id="commentTooth.suggestedTreatmentLoading"
            defaultMessage="In progress..."
          />
        </div>
      )}

      {isCommentEditorVisible && !isSuggestedTreatmentLoading && (
        <Textarea
          autoFocus={autoFocus}
          className={styles.textarea}
          value={newComment}
          onChange={(event) => {
            if (onChangeCommentInEditor) {
              onChangeCommentInEditor(event.target.value);
            }
          }}
        />
      )}

      {!isCommentEditorVisible && !isSuggestedTreatmentLoading && (
        <div className={styles.comment} onClick={onOpenChangeComment}>
          {comment}
        </div>
      )}

      <footer
        className={cn(
          styles.footer,
          isCommentEditorVisible && styles.footerPadding,
        )}
      >
        {isCommentEditorVisible && (
          <>
            {showSuggestedTreatmentButton && (
              <SuggestedTreatmentButton
                closeTextarea={onCancel}
                toothID={toothID}
              />
            )}
            <Button variant="tertiary" size="medium" onClick={onCancel}>
              <FormattedMessage id="global.cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              variant="secondary"
              size="medium"
              loading={isNewCommentLoading}
              onClick={onSaveComment}
            >
              <FormattedMessage id="commentTooth.save" defaultMessage="Save" />
            </Button>
          </>
        )}
      </footer>
    </div>
  ) : null;
};
