import { useState } from 'react';
import { ConnectError } from '@bufbuild/connect';
import { Code } from '@bufbuild/connect';

import { useAppDispatch } from '@/shared/hooks';
import api from '@/shared/api/api';
import { debug, isConnectNetworkError } from '@/shared/lib';

import { accessModel } from '@/entities/access';

import { SharedWithMeFilterSettingsType } from '../config/types';

export type UseSharedWithMeStreamArgs = {
  organizationID?: string;
  searchValue?: string;
};

let abortController: AbortController;

export const useSharedWithMeStream = ({
  organizationID,
  searchValue = '',
}: UseSharedWithMeStreamArgs) => {
  // use to restore patients state for infinity scroll
  // must be reset after any filters update
  const [resumeToken, setResumeToken] = useState('');

  const dispatch = useAppDispatch();

  const openSharedWithMeStream = async (
    filterSettings: SharedWithMeFilterSettingsType = {
      searchString: searchValue,
      id: '',
    },
  ) => {
    abortController = new AbortController();

    const { searchString, id, sortBy } = filterSettings;

    dispatch(accessModel.actions.setLoading('pending'));

    try {
      const sharedWithMeStream = api.access.sharedWithMeStream({
        OrganizationID: organizationID,
        ResumeToken: resumeToken,
        SearchString: searchString,
        Sort: sortBy,
        StartFromInvitationID: id,
        Limit: 20,
      });
      for await (const { Update, ResumeToken } of sharedWithMeStream) {
        switch (Update.case) {
          case 'InitialInvitationsList': {
            dispatch(accessModel.actions.addMany(Update.value.Invitations));

            dispatch(accessModel.actions.setLoading('succeeded'));
            break;
          }
          case 'TotalOrganizationPatientCount': {
            dispatch(
              accessModel.actions.setTotalOrganizationPatientCount(
                Update.value,
              ),
            );
            break;
          }
          case 'SharedByMeCount': {
            dispatch(accessModel.actions.setSharedByMeCount(Update.value));
            break;
          }
          case 'SharedWithMeCount': {
            dispatch(accessModel.actions.setSharedWithMeCount(Update.value));
            break;
          }
          case 'InvitationUpdated': {
            dispatch(accessModel.actions.setNewestOne(Update.value));
            break;
          }
        }

        if (ResumeToken) {
          setResumeToken(ResumeToken);
        }
      }
    } catch (error) {
      const connectError = ConnectError.from(error);

      if (isConnectNetworkError(connectError)) {
        debug('Network error detected, attempting to restart stream...');
        closeSharedWithMeStream(false);
        openSharedWithMeStream();
      }

      if (error instanceof ConnectError && error.code !== Code.Canceled) {
        dispatch(accessModel.actions.setLoading('failed'));
      }
    }
  };

  const closeSharedWithMeStream = (resetStore = true) => {
    if (abortController) {
      abortController.abort();
    }

    if (resetStore) {
      dispatch(accessModel.actions.removeAll());
    }
  };

  return { openSharedWithMeStream, closeSharedWithMeStream };
};
