import { defineMessages } from 'react-intl';

export const i18n = defineMessages({
  title: {
    id: 'treatingDoctors.title',
    defaultMessage: 'Add treating doctors',
  },
  mobileTitle: {
    id: 'treatingDoctors.mobileTitle',
    defaultMessage: 'Treating doctors',
  },
  applyButtonText: {
    id: 'treatingDoctors.applyButtonText',
    defaultMessage: 'Add doctors',
  },
  searchPlaceholder: {
    id: 'treatingDoctors.searchPlaceholder',
    defaultMessage: 'Search by doctor’s name',
  },
});
