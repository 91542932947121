import { defineMessages } from 'react-intl';

import { AssetType } from '@/shared/api/protocol-ts/model/dto_asset_pb';
import { OrderReportType } from '@/shared/config';

import { UploadStudyAssetType } from './types';

export const selectUploadStudyTypes = defineMessages<UploadStudyAssetType>({
  [AssetType.AssetType_Study_IntraoralXRay]: {
    id: 'uploadStudyType.intraoralXRay',
    defaultMessage: 'IOXRay',
  },
  [AssetType.AssetType_Study_CBCT]: {
    id: 'uploadAssetType.CBCT',
    defaultMessage: 'CBCT',
  },
  [AssetType.AssetType_Study_DentalPhoto]: {
    id: 'uploadAssetType.dentalPhoto',
    defaultMessage: 'Dental photo',
  },
  [AssetType.AssetType_Study_PanoramicXRay]: {
    id: 'uploadAssetType.panoramicXRay',
    defaultMessage: 'Pano',
  },
  [AssetType.AssetType_Study_IOS_Meshes]: {
    id: 'uploadAssetType.ios_stl',
    defaultMessage: 'Intraoral Scan',
  },
  [AssetType.AssetType_Document]: {
    id: 'uploadAssetType.document',
    defaultMessage: 'Document',
  },
  [AssetType.AssetType_User_Signature]: {
    id: 'uploadAssetType.document',
    defaultMessage: 'Document',
  },
  [AssetType.AssetType_Organization_EmployeeStamp]: {
    id: 'uploadAssetType.document',
    defaultMessage: 'Document',
  },
  [AssetType.AssetType_Organization_Stamp]: {
    id: 'uploadAssetType.document',
    defaultMessage: 'Document',
  },
  [AssetType.AssetType_Organization_Logo]: {
    id: 'uploadAssetType.document',
    defaultMessage: 'Document',
  },
  [AssetType.AssetType_User_Avatar]: {
    id: 'uploadAssetType.UserAvatar',
    defaultMessage: 'User Avatar',
  },
});

export const orderReportTitle = defineMessages<OrderReportType>({
  CBCT: {
    id: 'orderReportTitle.CBCT',
    defaultMessage: 'Order CBCT AI Report',
  },
  Pano: {
    id: 'orderReportTitle.Pano',
    defaultMessage: 'Order Pano AI Report',
  },
  IOXRay: {
    id: 'orderReportTitle.IOXRay',
    defaultMessage: 'Order IOXRay AI Report',
  },
  '3DStudio': {
    id: 'orderReportTitle.3DStudio',
    defaultMessage: 'Order 3D Model',
  },
  ImplantStudio: {
    id: 'orderReportTitle.ImplantStudio',
    defaultMessage: 'Order Implant Report',
  },
  OrthoStudio: {
    id: 'orderReportTitle.OrthoStudio',
    defaultMessage: 'Order Ortho AI Report',
  },
  IOSSegmentation: {
    id: 'orderReportTitle.IOSSegmentation',
    defaultMessage: 'Order Intraoral Scan Report',
  },
});
