import { FC, useState } from 'react';
import cn from 'classnames';
import { VirtuosoGrid } from 'react-virtuoso';
import { useIntl } from 'react-intl';
import { generatePath, Link, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { getImageSrc, getName } from '@/shared/lib';
import {
  NEW_LINE,
  PATHS,
  PatientsListColumnName,
  SortingType,
} from '@/shared/config';
import studyPlaceholderImage from '@/shared/assets/images/cats/patientListStudyPlaceholder.png';
import { Icon, Skeleton } from '@/shared/ui';

import { patientModel, StudiesCounters } from '@/entities/patient';
import { userModel } from '@/entities/user';
import { ModalID, modalModel } from '@/entities/modal';

import { EmptyResult } from '../EmptyResult/EmptyResult';
import { usePatientsSortAndFilters } from '../../hooks/usePatientsSortAndFilters';

import styles from './MobilePatientList.module.scss';

type MobilePatientListProps = {
  organizationID: string;
  reFetchPatients: (id: string) => void;
  onAddPatient: () => void;
  className?: string;
  testID?: string;
};

export const MobilePatientList: FC<MobilePatientListProps> = (props) => {
  const { className, testID, organizationID, reFetchPatients, onAddPatient } =
    props;

  const [searchParams] = useSearchParams();
  const { formatMessage, formatDate } = useIntl();
  const dispatch = useAppDispatch();

  const [nameSorting, setNameSorting] = useState<SortingType>('default');

  const userLocale = useAppSelector(userModel.selectors.selectUserLocale);
  const patientListLoading = useAppSelector(
    patientModel.selectors.selectLoading,
  );
  const patientList = useAppSelector(patientModel.selectors.selectAll);

  const search = searchParams.get('search');

  const showSkeleton =
    (patientListLoading === 'pending' || patientListLoading === 'idle') &&
    patientList.length === 0;
  const showEmpty =
    patientListLoading === 'succeeded' &&
    patientList.length === 0 &&
    Boolean((search?.length ?? 0) === 0);
  const showNothingFound =
    patientListLoading === 'succeeded' &&
    patientList.length === 0 &&
    Boolean(search);

  const { sortByForAllPatients } = usePatientsSortAndFilters({
    organizationID,
    isAllPatientList: true,
    isSharedWithMeList: false,
    isSharedByMeOrSharedByStaffList: false,
  });

  const loadMore = () => {
    const patient = patientList[patientList.length - 1];

    reFetchPatients(patient?.ID ?? '');
  };

  const openTreatingDoctorsFilterDrawer = () => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.TreatingDoctorsFilterDrawer,
      }),
    );
  };

  const patientListItem = (index: number) => {
    const patient = patientList[index];
    const patientID = patient?.ID ?? '';
    const displayAssetID = patient.DisplayAssetID ?? '';
    const formattedDate = patient.PersonalData?.DateOfBirth
      ? formatDate(new Date(patient.PersonalData?.DateOfBirth), {
          dateStyle: 'long',
        })
      : '';
    const patientName = getName(
      patient?.PersonalData?.FirstName,
      patient?.PersonalData?.LastName,
      { userLocale },
    );

    return (
      <Link
        className={styles.patientItem}
        to={generatePath(PATHS.patientProfile_DEPRECATED, { patientID })}
      >
        {displayAssetID ? (
          <object
            className={styles.preview}
            width={104}
            height={70}
            data={getImageSrc(displayAssetID, 'thumbnail')}
            type="image/png"
          >
            <img
              className={styles.preview}
              src={studyPlaceholderImage}
              alt={`${patientName}-avatar`}
            />
          </object>
        ) : (
          <img
            className={styles.preview}
            width={104}
            height={70}
            src={studyPlaceholderImage}
            alt={`${patientName}-avatar`}
          />
        )}

        <div className={styles.patientDetail}>
          <div className={styles.patientInfo}>
            <span className={cn(styles.patientName, 'h4m')}>{patientName}</span>
            <span className={cn(styles.patientDoB, 'p2m')}>
              {formattedDate}
            </span>
          </div>

          <StudiesCounters
            className={styles.studyCounters}
            iconSize={24}
            patientID={patientID}
          />
        </div>
      </Link>
    );
  };

  const handlePatientNameSortChange = () => {
    setNameSorting((prevSorting) =>
      prevSorting === 'default' || prevSorting === 'desc' ? 'asc' : 'desc',
    );

    sortByForAllPatients(
      PatientsListColumnName.PatientName,
      nameSorting === 'asc',
    );
  };

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      <div className={styles.filters}>
        <button type="button" onClick={handlePatientNameSortChange}>
          {formatMessage({
            id: 'patientList.patientName',
            defaultMessage: 'Patient name',
          })}

          <span className={styles.arrowIconsWrapper}>
            <Icon
              name="arrowUp2"
              size={8}
              className={cn(
                styles.arrow,
                nameSorting === 'asc' && styles.active,
              )}
            />
            <Icon
              name="arrowDown2"
              size={8}
              className={cn(
                styles.arrow,
                nameSorting === 'desc' && styles.active,
              )}
            />
          </span>
        </button>

        <button type="button" onClick={openTreatingDoctorsFilterDrawer}>
          {formatMessage({
            id: 'patientList.treatingDoctors',
            defaultMessage: 'Treating doctors',
          })}

          <Icon name="filter" size={18} />
        </button>
      </div>

      {showEmpty && (
        <EmptyResult
          handleButtonClick={onAddPatient}
          emptyText={formatMessage({
            id: 'patientList.emptyAll',
            defaultMessage: 'There are no patients yet',
          })}
        />
      )}

      {showNothingFound && (
        <EmptyResult
          textClassName={styles.emptyResultText}
          emptyText={formatMessage(
            {
              id: 'patientList.nothingFound',
              defaultMessage: `It looks like there are no patients with these search parameters.{newLine}Try to change search or filtering parameters`,
            },
            { newLine: NEW_LINE },
          )}
        />
      )}

      {showSkeleton && <Skeleton.MobileRow />}

      {!showNothingFound && !showSkeleton && (
        <VirtuosoGrid
          className={styles.patientListContainer}
          listClassName={styles.patientList}
          overscan={1200}
          totalCount={patientList.length}
          itemContent={patientListItem}
          endReached={loadMore}
        />
      )}
    </div>
  );
};
