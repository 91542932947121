import {
  CBCT_SectionOrientation,
  FileMetadata_Image,
} from '@/shared/api/protocol-ts/model/dto_asset_common_pb';
import { AssetContent_Generated_CBCT_ToothPathologySlice_PathologyType } from '@/shared/api/protocol-ts/model/dto_asset_pb';
import { MedicalImageInterface } from '@/shared/config';
import { AnnotationType_Guide } from '@/shared/api/protocol-ts/model/dto_common_image_annotations_pb';
import {
  CBCTGPToothSliceMetadataGuide,
  CBCTGPToothSliceMetadataGuideFrontal,
} from '@/shared/api/protocol-ts/model/dto_asset_cbct_gp_pb';
import { AssetContent_Generated_CBCT_Endo_ReportSection } from '@/shared/api/protocol-ts/model/dto_asset_cbct_endo_pb';

export interface ToothImage {
  // TODO: remove after migration to new api
  id?: string;
  reportId?: number;
  toothNumber?: number;
  key: string;
  revision?: number;
  revisionOrigin?: string;
  nameRu?: string;
  nameEn?: string;
  s3Endpoint?: string;
  s3Bucket?: string;
  s3ObjectName?: string;
  url?: string;
  isSelected?: boolean;
  format?: number;
  meta?: string;
  createdAt?: string;
  updatedAt?: string;
  report?: unknown;
  name?: string;
  type?: string;
  order: number | null;
  section?: string;
  thicknessMm?: number;
  strideMm?: number;
  misc?: string;
  annotations?: string;
  viewport?: string;
  displayKey?: string;
  selectedOrder?: number;
  maskUrl?: string;
  fileName?: string;
  attributeId?: string;
  autoDelete?: boolean;
}

export type GuideType =
  | (CBCTGPToothSliceMetadataGuide & { Lines?: AnnotationType_Guide[] })
  | (CBCTGPToothSliceMetadataGuideFrontal & { Lines?: AnnotationType_Guide[] });

export type Guide = {
  id?: string;
  assetID?: string;
  guide?: GuideType;
  src: string;
  image: FileMetadata_Image;
  guideAxesName?: string;
};

export type Group = {
  type?: MedicalImagesGroups;
  guides: Guide[];
  medicalImages: MedicalImageInterface[];
};

export type PathologyGroup = {
  type: MedicalImagesGroups;
  medicalImages?: MedicalImageInterface[];
  masks?: string[];
  guides?: Guide[];
};

export enum GuideAxesNames {
  SectionOrientationInvalidValue = 'SectionOrientationInvalidValue',
  SectionOrientationAxial = 'SectionOrientationAxial',
  SectionOrientationVestibuloOral = 'SectionOrientationVestibuloOral',
  SectionOrientationMesioDistal = 'SectionOrientationMesioDistal',
}

export type GroupConfigsType = {
  groupName: MedicalImagesGroups;
  groupCode:
    | CBCT_SectionOrientation
    | AssetContent_Generated_CBCT_ToothPathologySlice_PathologyType
    | AssetContent_Generated_CBCT_Endo_ReportSection;
};

export enum MedicalImagesGroups {
  CBCTGPToothSliceGuideFrontal = 'CBCTGPToothSliceGuideFrontal',
  OrientationAxial = 'OrientationAxial',
  OrientationMesioDistal = 'OrientationMesioDistal',
  CBCTEndoFurcationGuide = 'CBCTEndoFurcationGuide',
  OrientationVestibuloOral = 'OrientationVestibuloOral',
  Caries = 'Caries',
  PeriapicalLesion = 'PeriapicalLesion',
  EndoReportSectionFurcation = 'Endo_ReportSection_Furcation',
  EndoReportSectionRootCanalShape = 'Endo_ReportSection_RootCanalShape',
  EndoReportSectionRootCanalSpace = 'Endo_ReportSection_RootCanalSpace',
  EndoReportSectionRootCanalSystem = 'Endo_ReportSection_RootCanalSystem',
  UserUpload = 'UserUpload',
  SavedMPR = 'SavedMPR',
}

export enum MedicalImagesLabels {
  OrientationAxial = 'OrientationAxial',
  OrientationMesioDistal = 'OrientationMesioDistal',
  CBCTEndoFurcationGuide = 'CBCTEndoFurcationGuide',
  OrientationVestibuloOral = 'OrientationVestibuloOral',
  Caries = 'Caries',
  PeriapicalLesion = 'PeriapicalLesion',
  UserUpload = 'UserUpload',
  SavedMPR = 'SavedMPR',
}
