// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file pipelines/report_landmarks.proto (package com.diagnocat.pipelines, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { CephalometricLandmark, ToothLandmark } from "../model/dto_report_landmark_pb.js";

/**
 * @generated from message com.diagnocat.pipelines.CephalometricLandmarks_Update
 */
export const CephalometricLandmarks_Update = proto3.makeMessageType(
  "com.diagnocat.pipelines.CephalometricLandmarks_Update",
  () => [
    { no: 1, name: "CephalometricLandmarks", kind: "message", T: CephalometricLandmark, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.pipelines.TeethLandmarks_Update
 */
export const TeethLandmarks_Update = proto3.makeMessageType(
  "com.diagnocat.pipelines.TeethLandmarks_Update",
  () => [
    { no: 1, name: "TeethLandmarks", kind: "message", T: ToothLandmark, repeated: true },
  ],
);

