import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';

import sadCatImg from '@/shared/assets/images/sad-cat.png';
import { Button, Result } from '@/shared/ui';

import styles from './EmptyResult.module.scss';

type EmptyResultProps = {
  className?: string;
  handleButtonClick?: () => void;
  emptyText?: string;
  textClassName?: string;
};

export const EmptyResult: FC<EmptyResultProps> = (props) => {
  const { className, handleButtonClick, emptyText, textClassName } = props;

  const { formatMessage } = useIntl();

  return (
    <Result
      className={cn(styles.container, className)}
      icon={
        <img
          src={sadCatImg}
          width={130}
          height={130}
          alt={formatMessage({
            id: 'imgAltText.sadCat',
            defaultMessage: 'Sad cat',
          })}
        />
      }
      text={emptyText}
      textClassName={textClassName}
      extra={
        handleButtonClick ? (
          <Button variant="secondary" icon="plus" onClick={handleButtonClick}>
            <FormattedMessage
              id="patientList.addPatient"
              defaultMessage="Add new patient"
            />
          </Button>
        ) : (
          ''
        )
      }
    />
  );
};
