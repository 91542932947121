import {
  forwardRef,
  ForwardRefRenderFunction,
  ImgHTMLAttributes,
  useState,
} from 'react';

import studyPlaceholderImage from '../../assets/images/cats/patientListStudyPlaceholder.png';

type ImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  className?: string;
  testID?: string;
};

const InternalImage: ForwardRefRenderFunction<HTMLImageElement, ImageProps> = (
  props,
  ref,
) => {
  const { className, testID, onError, ...restBaseProps } = props;

  const [isError, setIsError] = useState<boolean>(false);

  return isError ? (
    <img {...restBaseProps} src={studyPlaceholderImage} />
  ) : (
    <img
      {...restBaseProps}
      ref={ref}
      data-testid={testID}
      className={className}
      onError={(errorEvent) => {
        setIsError(true);

        if (typeof onError === 'function') {
          onError(errorEvent);
        }
      }}
    />
  );
};

export const Image = forwardRef<HTMLImageElement, ImageProps>(InternalImage);
