import { FC, useState } from 'react';
import cn from 'classnames';
import { VirtuosoGrid } from 'react-virtuoso';
import { useIntl } from 'react-intl';
import { generatePath, Link, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { PATHS, PatientsListColumnName, SortingType } from '@/shared/config';
import { Icon, Image, Skeleton } from '@/shared/ui';
import studyPlaceholderImage from '@/shared/assets/images/cats/patientListStudyPlaceholder.png';
import { Invitation_InvitationStatus } from '@/shared/api/protocol-ts/model/dto_access_pb';

import { accessModel } from '@/entities/access';
import { getDisplayAssetSrcByPatientID } from '@/entities/assets';
import { ModalID, modalModel } from '@/entities/modal';

import { usePatientsSortAndFilters } from '../../hooks/usePatientsSortAndFilters';
import { EmptyResult } from '../EmptyResult/EmptyResult';
import { ListLoadingFailed } from '../ListLoadingFailed/ListLoadingFailed';

import styles from './MobileSharedPatientList.module.scss';

type MobileSharedPatientListProps = {
  organizationID: string;
  reFetchPatients: (id: string) => void;
  isSharedByMeOrSharedByStaffList?: boolean;
  className?: string;
  testID?: string;
};

export const MobileSharedPatientList: FC<MobileSharedPatientListProps> = (
  props,
) => {
  const {
    organizationID,
    isSharedByMeOrSharedByStaffList = false,
    reFetchPatients,
    className,
    testID,
  } = props;

  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  const [nameSorting, setNameSorting] = useState<SortingType>('default');
  const [statusSorting, setStatusSorting] = useState<SortingType>('default');

  const { formatMessage } = useIntl();

  const invitationsList = useAppSelector(accessModel.selectors.selectAll);
  const accessListLoading = useAppSelector(
    accessModel.selectors.selectAccessLoading,
  );

  const search = searchParams.get('search');

  const isListLoadingFailed = accessListLoading === 'failed';
  const showSkeleton =
    (accessListLoading === 'pending' || accessListLoading === 'idle') &&
    invitationsList.length === 0;
  const showNothingFound =
    accessListLoading === 'succeeded' &&
    invitationsList.length === 0 &&
    Boolean(search);
  const showEmpty =
    accessListLoading === 'succeeded' &&
    invitationsList.length === 0 &&
    !search;

  const { sortByForSharedWithMe, sortByForSharedByMe } =
    usePatientsSortAndFilters({
      organizationID,
      isSharedByMeOrSharedByStaffList,
      isSharedWithMeList: !isSharedByMeOrSharedByStaffList,
      isAllPatientList: false,
    });

  const loadMore = () => {
    const invitation = invitationsList[invitationsList.length - 1];
    reFetchPatients(invitation?.ID ?? '');
  };

  const handlePatientNameSortChange = () => {
    const isNameSortingAscending = nameSorting === 'asc';

    setStatusSorting('default');

    setNameSorting((prevSorting) =>
      prevSorting === 'default' || prevSorting === 'desc' ? 'asc' : 'desc',
    );

    if (isSharedByMeOrSharedByStaffList) {
      sortByForSharedByMe(
        PatientsListColumnName.PatientName,
        isNameSortingAscending,
      );
    } else {
      sortByForSharedWithMe(
        PatientsListColumnName.PatientName,
        isNameSortingAscending,
      );
    }
  };

  const handleStatusSortChange = () => {
    const isStatusSortingAscending =
      statusSorting !== 'default' ? statusSorting === 'asc' : false;

    setNameSorting('default');

    setStatusSorting((prevSorting) =>
      prevSorting === 'default' || prevSorting === 'desc' ? 'asc' : 'desc',
    );

    sortByForSharedByMe(
      PatientsListColumnName.Status,
      isStatusSortingAscending,
    );
  };

  const sharedPatientItem = (index: number) => {
    const invitation = invitationsList[index];

    if (!invitation) return null;

    const isInvitationAccepted =
      invitation?.Status === Invitation_InvitationStatus.StatusAccepted;
    const patientID =
      invitation.Target?.Target?.case === 'Patient'
        ? invitation?.Target?.Target?.value?.PatientID
        : '';

    const senderEmail = invitation.TargetDetails?.SenderEmail ?? '';
    const patientFirstName = invitation.TargetDetails?.PatientFirstName ?? '';
    const patientLastName = invitation.TargetDetails?.PatientLastName ?? '';
    const patientName = `${patientFirstName} ${patientLastName}`.trim();

    const displayAssetSrc = getDisplayAssetSrcByPatientID(
      patientID ?? '',
      'thumbnail',
    );

    const openRevokeSharedPatientModal = () => {
      dispatch(
        modalModel.actions.openModal({
          modalID: ModalID.RevokeSharedPatientModal,
          data: {
            patientID,
            sharedPatientDoctorID: invitation?.Recipient?.UserID,
            invitationID: invitation?.ID,
            isInviteAccepted: isInvitationAccepted,
          },
        }),
      );
    };

    return (
      <div
        className={cn(
          styles.rowContainer,
          isSharedByMeOrSharedByStaffList && styles.withDeleteIcon,
        )}
      >
        <Link
          className={styles.patientItem}
          to={generatePath(PATHS.patientProfile_DEPRECATED, { patientID })}
        >
          <Image
            className={styles.preview}
            width={104}
            height={70}
            src={
              isSharedByMeOrSharedByStaffList || isInvitationAccepted
                ? displayAssetSrc
                : studyPlaceholderImage
            }
            alt={`${patientName}-avatar`}
          />

          <div
            className={cn(
              styles.patientDetails,
              isSharedByMeOrSharedByStaffList &&
                styles.sharedByMePatientDetails,
            )}
          >
            <div className={styles.patientInfo}>
              <div className={styles.patientName}>
                {isSharedByMeOrSharedByStaffList || isInvitationAccepted
                  ? patientName
                  : formatMessage({
                      id: 'sharedPatient.nameHidden',
                      defaultMessage: 'Name hidden',
                    })}
              </div>

              <div className={styles.patientSharedBy}>{senderEmail}</div>
            </div>

            <div className={styles.invitationStatus}>
              {isInvitationAccepted ? (
                <>
                  <Icon
                    className={styles.invitationAccepted}
                    name="check"
                    size={24}
                  />

                  {formatMessage({
                    id: 'sharedPatient.accepted',
                    defaultMessage: 'accepted',
                  })}
                </>
              ) : (
                <>
                  <Icon name="time" size={24} />

                  {formatMessage({
                    id: 'sharedPatient.notAccepted',
                    defaultMessage: 'not accepted',
                  })}
                </>
              )}
            </div>
          </div>
        </Link>

        {isSharedByMeOrSharedByStaffList && (
          <button
            type="button"
            className={styles.removeInvitation}
            onClick={openRevokeSharedPatientModal}
          >
            <Icon name="delete" size={24} />
          </button>
        )}
      </div>
    );
  };

  if (isListLoadingFailed) {
    return <ListLoadingFailed />;
  }

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      <div className={styles.filters}>
        <button type="button" onClick={handlePatientNameSortChange}>
          {formatMessage({
            id: 'patientList.patientName',
            defaultMessage: 'Patient name',
          })}

          <span className={styles.arrowIconsWrapper}>
            <Icon
              name="arrowUp2"
              size={8}
              className={cn(
                styles.arrow,
                statusSorting === 'default' &&
                  nameSorting === 'asc' &&
                  styles.active,
              )}
            />

            <Icon
              name="arrowDown2"
              size={8}
              className={cn(
                styles.arrow,
                statusSorting === 'default' &&
                  nameSorting === 'desc' &&
                  styles.active,
              )}
            />
          </span>
        </button>

        {isSharedByMeOrSharedByStaffList && (
          <button type="button" onClick={handleStatusSortChange}>
            {formatMessage({
              id: 'sharedPatient.Status',
              defaultMessage: 'Status',
            })}
            <span className={styles.arrowIconsWrapper}>
              <Icon
                name="arrowUp2"
                size={8}
                className={cn(
                  styles.arrow,
                  nameSorting === 'default' &&
                    statusSorting === 'desc' &&
                    styles.active,
                )}
              />

              <Icon
                name="arrowDown2"
                size={8}
                className={cn(
                  styles.arrow,
                  nameSorting === 'default' &&
                    statusSorting === 'asc' &&
                    styles.active,
                )}
              />
            </span>
          </button>
        )}
      </div>

      {showNothingFound ||
        (showEmpty && (
          <EmptyResult
            textClassName={styles.emptyResultText}
            emptyText={
              isSharedByMeOrSharedByStaffList
                ? formatMessage({
                    id: 'patientList.emptySharedByMeOrTeam',
                    defaultMessage: 'You have not shared any patients yet',
                  })
                : formatMessage({
                    id: 'patientList.emptySharedWithMe',
                    defaultMessage: 'No one has shared a patient with you yet',
                  })
            }
          />
        ))}

      {showSkeleton && <Skeleton.MobileRow />}

      {!showSkeleton && !showEmpty && !showNothingFound && (
        <VirtuosoGrid
          className={styles.patientListContainer}
          listClassName={styles.patientList}
          overscan={1200}
          totalCount={invitationsList.length}
          itemContent={sharedPatientItem}
          endReached={loadMore}
        />
      )}
    </div>
  );
};
