import { useIntl } from 'react-intl';
import { useMemo } from 'react';

import { OrganizationUserRoleType } from '@/shared/api/protocol-ts/model/dto_organization_pb';

import { i18n } from '@/entities/organization';

export const usePermissionOptions = () => {
  const { formatMessage } = useIntl();

  const permissionOptions = useMemo(
    () => [
      {
        label: formatMessage(
          i18n.permissionOwners[
            OrganizationUserRoleType.OrganizationUserRoleType_ClinicalAccess
          ],
        ),
        value: OrganizationUserRoleType.OrganizationUserRoleType_ClinicalAccess,
        comment: formatMessage(
          i18n.permissionOwnersComments[
            OrganizationUserRoleType.OrganizationUserRoleType_ClinicalAccess
          ],
        ),
      },
      {
        label: formatMessage(
          i18n.permissionOwners[
            OrganizationUserRoleType
              .OrganizationUserRoleType_LimitedClinicalAccess
          ],
        ),
        value:
          OrganizationUserRoleType.OrganizationUserRoleType_LimitedClinicalAccess,
        comment: formatMessage(
          i18n.permissionOwnersComments[
            OrganizationUserRoleType
              .OrganizationUserRoleType_LimitedClinicalAccess
          ],
        ),
      },
      {
        label: formatMessage(
          i18n.permissionOwners[
            OrganizationUserRoleType.OrganizationUserRoleType_NonClinicalMember
          ],
        ),
        value:
          OrganizationUserRoleType.OrganizationUserRoleType_NonClinicalMember,
        comment: formatMessage(
          i18n.permissionOwnersComments[
            OrganizationUserRoleType.OrganizationUserRoleType_NonClinicalMember
          ],
        ),
      },
    ],
    [formatMessage],
  );

  return permissionOptions;
};
