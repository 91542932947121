// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_landmark.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { ToothNumeration } from "./dto_report_common_pb.js";
import { FloatPoint3D } from "./dto_common_geometry_pb.js";
import { CephalometricLandmark_CBCT_Code, ToothLandmark_CBCT_Code, ToothLandmark_Image_Code, ToothLandmark_IOS_Code, ToothLandmark_PBL_Code } from "./dto_report_landmark_codes_pb.js";
import { Created, Deleted, Revision } from "./dto_base_pb.js";

/**
 * @generated from message com.diagnocat.model.ToothLandmark
 */
export const ToothLandmark = proto3.makeMessageType(
  "com.diagnocat.model.ToothLandmark",
  () => [
    { no: 3, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 1, name: "Tooth", kind: "message", T: ToothNumeration },
    { no: 4, name: "ToothLocalizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "ToothID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "ReportID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ModelPosition", kind: "message", T: FloatPoint3D },
    { no: 5, name: "UserPosition", kind: "message", T: FloatPoint3D },
    { no: 11, name: "CBCT", kind: "enum", T: proto3.getEnumType(ToothLandmark_CBCT_Code), oneof: "Code" },
    { no: 12, name: "IOS", kind: "enum", T: proto3.getEnumType(ToothLandmark_IOS_Code), oneof: "Code" },
    { no: 13, name: "Image", kind: "enum", T: proto3.getEnumType(ToothLandmark_Image_Code), oneof: "Code" },
    { no: 14, name: "PBL", kind: "enum", T: proto3.getEnumType(ToothLandmark_PBL_Code), oneof: "Code" },
    { no: 10030, name: "Created", kind: "message", T: Created },
    { no: 10040, name: "Revision", kind: "message", T: Revision },
    { no: 10050, name: "Deleted", kind: "message", T: Deleted },
  ],
);

/**
 * @generated from message com.diagnocat.model.CephalometricLandmark
 */
export const CephalometricLandmark = proto3.makeMessageType(
  "com.diagnocat.model.CephalometricLandmark",
  () => [
    { no: 3, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 1, name: "Code", kind: "enum", T: proto3.getEnumType(CephalometricLandmark_CBCT_Code) },
    { no: 2, name: "ModelPosition", kind: "message", T: FloatPoint3D },
    { no: 4, name: "UserPosition", kind: "message", T: FloatPoint3D },
  ],
);

