// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file pipelines/report_ios_segmentation.proto (package com.diagnocat.pipelines, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { IOSJawTypeClassifications_Update } from "./report_ios_jaw_classification_pb.js";

/**
 * @generated from message com.diagnocat.pipelines.IOS_Segmentation_Update
 */
export const IOS_Segmentation_Update = proto3.makeMessageType(
  "com.diagnocat.pipelines.IOS_Segmentation_Update",
  () => [
    { no: 1, name: "JawTypeClassifications", kind: "message", T: IOSJawTypeClassifications_Update, oneof: "Update" },
  ],
);

