// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file pipelines/report_condition.proto (package com.diagnocat.pipelines, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { Condition } from "../model/dto_report_condition_pb.js";

/**
 * @generated from message com.diagnocat.pipelines.Conditions_Update
 */
export const Conditions_Update = proto3.makeMessageType(
  "com.diagnocat.pipelines.Conditions_Update",
  () => [
    { no: 1, name: "Conditions", kind: "message", T: Condition, repeated: true },
  ],
);

