import { FC, ReactNode } from 'react';
import cn from 'classnames';

import { useMedia, useWindowSize } from '@/shared/hooks';
import { ViewMIR } from '@/shared/graphics/medicalImageRender/MedicalImageRender';
import { RenderPreviewSettings } from '@/shared/config';

import {
  CBCT_FOOTER_HEIGHT,
  CBCT_HEADER_HEIGHT,
  CBCT_PANO_MAX_HEIGHT,
  CBCT_PANO_MAX_HEIGHT_MOBILE,
  CBCT_PANO_MIN_HEIGHT,
  CBCT_PANO_MIN_HEIGHT_MOBILE,
  CBCT_PANO_PREVIEW_HEIGHT,
  CBCT_TOOTH_CHART_HEIGHT,
} from '../../config/constants';

import styles from './PanoReformatRender.module.scss';

type PanoReformatRenderProps = {
  className?: string;
  children?: ReactNode;
  previewSettings?: RenderPreviewSettings;
};

export const PanoReformatRender: FC<PanoReformatRenderProps> = (props) => {
  const { className, children, previewSettings } = props;

  const { height: windowHeight } = useWindowSize();
  const { isMobile } = useMedia();

  const dynamicContainerHeight =
    windowHeight -
    CBCT_TOOTH_CHART_HEIGHT -
    CBCT_FOOTER_HEIGHT -
    CBCT_HEADER_HEIGHT -
    80; // manual value

  const minHeight = isMobile
    ? CBCT_PANO_MIN_HEIGHT_MOBILE
    : CBCT_PANO_MIN_HEIGHT;
  const maxHeight = isMobile
    ? CBCT_PANO_MAX_HEIGHT_MOBILE
    : CBCT_PANO_MAX_HEIGHT;

  const scaledContainerHeight = Math.min(
    Math.max(dynamicContainerHeight, minHeight),
    maxHeight,
  );

  const containerHeight = previewSettings?.isPreview
    ? CBCT_PANO_PREVIEW_HEIGHT
    : scaledContainerHeight;

  return (
    <ViewMIR
      viewName="main"
      id="report_render"
      style={{
        height: containerHeight,
        display: 'flex',
      }}
      className={cn(
        styles.container,
        previewSettings?.isPreview ? styles.preview : '',
        className,
      )}
    >
      {children}
    </ViewMIR>
  );
};
