import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Button, Input, Modal } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { ModalID, modalModel } from '@/entities/modal';

import { i18n } from '../../config';
import * as treatingDoctorsModel from '../../model';
import { TreatingDoctorOption } from '../TreatingDoctorOption/TreatingDoctorOption';

import styles from './TreatingDoctorsDrawer.module.scss';

type TreatingDoctorsDrawerType = 'filter' | 'select';

type TreatingDoctorsProps = {
  type: TreatingDoctorsDrawerType;
  doctorIDs: string[];
  onChange: (doctorsID: string) => void;
  onSave?: (doctorsIDs: string[]) => void;
};

const drawerConfig = {
  filter: {
    selector: modalModel.selectors.selectTreatingDoctorsFilterDrawer,
    modalID: ModalID.TreatingDoctorsFilterDrawer,
  },
  select: {
    selector: modalModel.selectors.selectTreatingDoctorsSelectDrawer,
    modalID: ModalID.TreatingDoctorsSelectDrawer,
  },
};

export const TreatingDoctorsDrawer = (props: TreatingDoctorsProps) => {
  const { type, doctorIDs, onChange, onSave } = props;

  const [searchValue, setSearchValue] = useState('');
  const [dirty, setDirty] = useState(false);

  const selector = drawerConfig[type].selector;
  const modalID = drawerConfig[type].modalID;

  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { visible } = useAppSelector(selector);
  const doctorsOptions = useAppSelector(
    treatingDoctorsModel.selectors.selectOrganizationDoctorsOptions,
  );

  const doctorsFilteredOptions = doctorsOptions.filter((doctor) =>
    doctor.label.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const handleClose = () => {
    dispatch(modalModel.actions.closeModal(modalID));
    setDirty(false);
  };

  const handleChange = (value: string) => {
    setDirty(true);
    onChange(value);
  };

  const handleSave = () => {
    if (!dirty) {
      handleClose();
      return;
    }

    if (typeof onSave === 'function') {
      onSave(doctorIDs);
    }

    handleClose();
  };

  return (
    <Modal
      containerClassName={styles.container}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      title={formatMessage(i18n.mobileTitle)}
      isOpen={visible}
      onCancel={handleClose}
      footer={
        <Button variant="secondary" onClick={handleSave}>
          {formatMessage({ id: 'global.save', defaultMessage: 'Save' })}
        </Button>
      }
    >
      <Input
        name="filterDoctor"
        type="search"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder={formatMessage(i18n.searchPlaceholder)}
      />

      <div className={styles.doctorsList}>
        {doctorsFilteredOptions.map((doctor) => (
          <TreatingDoctorOption
            key={doctor.value}
            label={doctor.label}
            avatarSrc={doctor.avatarSrc}
            value={doctor.value}
            isSelected={doctorIDs.includes(doctor.value)}
            onClick={handleChange}
          />
        ))}
      </div>
    </Modal>
  );
};
