import { RCContainer } from "../RCContainer/RCContainer";
import styles from './RCGuides.module.scss';

export type Guide = { x1: number; y1: number; x2: number; y2: number, assetID: string };

export type RCGuidesProps = {
    imageSize: { width: number, height: number };
    guides: Guide[];
    hoveredGuide: string;
    onHoveredGuideChanged?: (index: string) => void;
    onGuideClick?: (index: string) => void;
}

// TODO: remove old component after refactoring
export const RCGuides = (props: RCGuidesProps) => {

    const { imageSize, guides, onHoveredGuideChanged, onGuideClick, hoveredGuide } = props;
    const { width, height } = imageSize;

    return (

        <RCContainer style={{ width: "100%", height: "100%", overflow: "hidden" }}>
            <svg
                // css size
                width="100%"
                height="100%"
                // logical size
                viewBox={`0 0 ${width} ${height}`}
                style={{ display: 'block' }}
            >
                <g >
                    {guides.map((guide, index) =>
                        <g className={styles.lineGroup} key={index}>
                            <line
                                x1={guide.x1}
                                y1={guide.y1}
                                x2={guide.x2}
                                y2={guide.y2}
                                className={styles.hoverLine}
                                onClick={() => onGuideClick?.(guide.assetID)}
                                onMouseEnter={() => onHoveredGuideChanged?.(guide.assetID)}
                            />
                            <line
                                x1={guide.x1}
                                y1={guide.y1}
                                x2={guide.x2}
                                y2={guide.y2}
                                className={`${styles.line} ${hoveredGuide === guide.assetID ? styles.highlighted : ''}`}
                            />
                        </g>

                    )}
                </g>
            </svg>
        </RCContainer>
    );
};
