import React, { ReactNode } from 'react';
import cn from 'classnames';
import { UseFormResetField } from 'react-hook-form';

import { useMedia } from '@/shared/hooks';

import { ErrorText } from '../ErrorText/ErrorText';
import { Icon } from '../Icon/Icon';
import { IconNames } from '../Icon/Icon.types';
import { InputLabel } from '../InputLabel/InputLabel';

import styles from './Input.module.scss';
import { FieldProps } from './Input.types';

type InputProps = FieldProps & {
  inputWrapperClassName?: string;
  inputClassName?: string;
  children?: ReactNode;
  autoFocus?: boolean;
  icon?: IconNames;
  autoComplete?: string;
  onResetField?: UseFormResetField<never>;
};

const InternalInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  InputProps
> = (props, ref) => {
  const {
    children,
    name,
    value = '',
    type,
    label,
    disabled,
    required,
    icon,
    onChange,
    error,
    className,
    inputWrapperClassName,
    inputClassName,
    testID,
    onResetField,
    alignText = 'left',
    inputMode = 'text',
    borderless,
    ...restBaseInputProps
  } = props;

  const { isPhone } = useMedia();

  const dateType = type === 'date';

  return (
    <div className={cn(styles.wrapper, className)} data-testid={testID}>
      <label className={styles.labelContainer}>
        <InputLabel label={label} required={required} />
        <div
          className={cn(
            styles.inputWrapper,
            error && styles.isError,
            disabled && styles.inputWrapperDisabled,
            borderless && styles.borderless,
            inputWrapperClassName,
          )}
        >
          {icon && (
            <div
              className={cn(
                styles.iconWrapper,
                disabled && styles.iconDisabled,
                error && styles.iconError,
              )}
            >
              <Icon name={icon} size={24} />
            </div>
          )}
          <input
            {...restBaseInputProps}
            inputMode={inputMode}
            className={cn(
              styles.input,
              error && styles.isError,
              icon && styles.withIcon,
              (onResetField || dateType) && styles.withRightButton,
              isPhone ? 'p3' : 'p2',
              styles[alignText],
              inputClassName,
            )}
            ref={ref}
            type={type}
            value={value}
            name={name}
            onChange={onChange}
            disabled={disabled}
          />
          {onResetField && value && (
            <button
              type="button"
              className={styles.inputControlButton}
              onClick={() => onResetField(name as never)}
            >
              <Icon name="close" size={24} />
            </button>
          )}
          {dateType && (
            <button type="button" className={styles.inputControlButton}>
              <Icon name="calendar" size={24} />
            </button>
          )}
          {children}
        </div>
      </label>
      <ErrorText error={error} withInput />
    </div>
  );
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  InternalInput,
);
