// Types related to 2D GP report ("gp_2d") pipelines method

// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file pipelines/report_photo_gp.proto (package com.diagnocat.pipelines, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { ToothDetection } from "./report_tooth_detection_pb.js";
import { Condition } from "../model/dto_report_condition_pb.js";
import { Photo_GP_TypePrediction } from "../model/dto_report_type_photo_gp_pb.js";

/**
 * @generated from message com.diagnocat.pipelines.DentalPhoto_GP_Update
 */
export const DentalPhoto_GP_Update = proto3.makeMessageType(
  "com.diagnocat.pipelines.DentalPhoto_GP_Update",
  () => [
    { no: 1, name: "ToothDetection", kind: "message", T: ToothDetection, oneof: "Update" },
    { no: 2, name: "Condition", kind: "message", T: Condition, oneof: "Update" },
    { no: 3, name: "TypePrediction", kind: "message", T: Photo_GP_TypePrediction, oneof: "Update" },
  ],
);

