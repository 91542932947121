import React, { FC } from 'react';
import cn from 'classnames';

import styles from './MobileMain.module.scss';

type MobileMainProps = {
  className?: string;
  testID?: string;
  children?: React.ReactNode;
};

export const MobileMain: FC<MobileMainProps> = (props) => {
  const { className, testID, children } = props;

  return (
    <main className={cn(styles.container, className)} data-testid={testID}>
      {children}
    </main>
  );
};
