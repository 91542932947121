// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file pipelines/pipelines.proto (package com.diagnocat.pipelines, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { ReportFailed, ReportType } from "../model/dto_report_pb.js";
import { ReportRequest } from "../model/dto_report_request_pb.js";
import { Asset, GeneratedReportAsset } from "../model/dto_asset_pb.js";
import { EmptyPayload, ReportLegacyPayload } from "../model/dto_legacy_pb.js";
import { ProgressUpdate } from "./report_progress_pb.js";
import { CBCT_GP_Update } from "./report_cbct_gp_pb.js";
import { CBCT_Endo_Update } from "./report_cbct_endo_pb.js";
import { CBCT_Implant_Update } from "./report_cbct_implant_pb.js";
import { CBCT_Molar_Update } from "./report_cbct_molar_pb.js";
import { CBCT_OrthoSlides_Update } from "./report_cbct_ortho_pb.js";
import { Pano_GP_Update } from "./report_pano_gp_pb.js";
import { IOXRay_GP_Update } from "./report_ioxray_gp_pb.js";
import { DentalPhoto_GP_Update } from "./report_photo_gp_pb.js";
import { CBCT_Segmentation_Update } from "./report_cbct_segmentation_pb.js";
import { CBCT_IOS_Superimposition_Update } from "./report_cbct_ios_superimposition_pb.js";
import { Studio_Ortho_Update } from "./report_studio_ortho_pb.js";
import { Studio_Implant_Update } from "./report_studio_implant_pb.js";
import { IOS_Segmentation_Update } from "./report_ios_segmentation_pb.js";
import { File } from "../model/dto_asset_common_pb.js";

/**
 * @generated from message com.diagnocat.pipelines.PipelinesTask
 */
export const PipelinesTask = proto3.makeMessageType(
  "com.diagnocat.pipelines.PipelinesTask",
  () => [
    { no: 1, name: "PipelinesRunID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ReportType", kind: "enum", T: proto3.getEnumType(ReportType) },
    { no: 3, name: "Request", kind: "message", T: ReportRequest },
    { no: 4, name: "Assets", kind: "message", T: Asset, repeated: true },
    { no: 5, name: "AssetFilesDownloadURLs", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 6, name: "Cluster", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9000, name: "IsLegacyTaskmaster", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.diagnocat.pipelines.TaskmasterAgentReportRequest
 */
export const TaskmasterAgentReportRequest = proto3.makeMessageType(
  "com.diagnocat.pipelines.TaskmasterAgentReportRequest",
  () => [
    { no: 1, name: "AgentID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "TaskmasterName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "PipelinesTask", kind: "message", T: PipelinesTask },
    { no: 4, name: "Workspace", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 101, name: "InputFilesLocalPaths", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ],
);

/**
 * @generated from message com.diagnocat.pipelines.TaskmasterAgentReportResponse
 */
export const TaskmasterAgentReportResponse = proto3.makeMessageType(
  "com.diagnocat.pipelines.TaskmasterAgentReportResponse",
  () => [
    { no: 1, name: "Asset", kind: "message", T: ReportAsset, oneof: "Message" },
    { no: 2, name: "Update", kind: "message", T: ReportUpdate, oneof: "Message" },
    { no: 3, name: "AssetBatch", kind: "message", T: TaskmasterAgentReportResponse_ReportAssetBatch, oneof: "Message" },
  ],
);

/**
 * @generated from message com.diagnocat.pipelines.TaskmasterAgentReportResponse.ReportAssetBatch
 */
export const TaskmasterAgentReportResponse_ReportAssetBatch = proto3.makeMessageType(
  "com.diagnocat.pipelines.TaskmasterAgentReportResponse.ReportAssetBatch",
  () => [
    { no: 1, name: "Content", kind: "message", T: ReportAsset, repeated: true },
  ],
  {localName: "TaskmasterAgentReportResponse_ReportAssetBatch"},
);

/**
 * @generated from message com.diagnocat.pipelines.ReportAsset
 */
export const ReportAsset = proto3.makeMessageType(
  "com.diagnocat.pipelines.ReportAsset",
  () => [
    { no: 1, name: "AssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "CreatedAt", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "PipelinesRunID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "GeneratedReport", kind: "message", T: GeneratedReportAsset },
  ],
);

/**
 * @generated from message com.diagnocat.pipelines.ReportUpdate
 */
export const ReportUpdate = proto3.makeMessageType(
  "com.diagnocat.pipelines.ReportUpdate",
  () => [
    { no: 1, name: "PipelinesRunID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "CreatedAt", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "IdempotenceKey", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 50, name: "LegacyPayloadOnly", kind: "message", T: EmptyPayload, oneof: "Payload" },
    { no: 99, name: "ProgressUpdate", kind: "message", T: ProgressUpdate, oneof: "Payload" },
    { no: 100, name: "Started", kind: "message", T: ReportStarted, oneof: "Payload" },
    { no: 201, name: "CBCT_GP_Update", kind: "message", T: CBCT_GP_Update, oneof: "Payload" },
    { no: 202, name: "CBCT_Endo_Update", kind: "message", T: CBCT_Endo_Update, oneof: "Payload" },
    { no: 203, name: "CBCT_Implant_Update", kind: "message", T: CBCT_Implant_Update, oneof: "Payload" },
    { no: 204, name: "CBCT_Molar_Update", kind: "message", T: CBCT_Molar_Update, oneof: "Payload" },
    { no: 205, name: "CBCT_OrthoSlides_Update", kind: "message", T: CBCT_OrthoSlides_Update, oneof: "Payload" },
    { no: 206, name: "Pano_GP_Update", kind: "message", T: Pano_GP_Update, oneof: "Payload" },
    { no: 207, name: "IOXRay_GP_Update", kind: "message", T: IOXRay_GP_Update, oneof: "Payload" },
    { no: 208, name: "DentalPhoto_GP_Update", kind: "message", T: DentalPhoto_GP_Update, oneof: "Payload" },
    { no: 209, name: "CBCT_Segmentation_Update", kind: "message", T: CBCT_Segmentation_Update, oneof: "Payload" },
    { no: 210, name: "CBCT_IOS_Superimposition_Update", kind: "message", T: CBCT_IOS_Superimposition_Update, oneof: "Payload" },
    { no: 211, name: "Studio_Ortho_Update", kind: "message", T: Studio_Ortho_Update, oneof: "Payload" },
    { no: 212, name: "Studio_Implant_Update", kind: "message", T: Studio_Implant_Update, oneof: "Payload" },
    { no: 213, name: "IOS_Segmentation_Update", kind: "message", T: IOS_Segmentation_Update, oneof: "Payload" },
    { no: 110, name: "Failure", kind: "message", T: ReportFailed, oneof: "Payload" },
    { no: 111, name: "Completed", kind: "message", T: ReportCompleted, oneof: "Payload" },
    { no: 200, name: "LegacyPayload", kind: "message", T: ReportLegacyPayload },
  ],
);

/**
 * @generated from message com.diagnocat.pipelines.ReportUpdateFile
 */
export const ReportUpdateFile = proto3.makeMessageType(
  "com.diagnocat.pipelines.ReportUpdateFile",
  () => [
    { no: 1, name: "AssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "File", kind: "message", T: File },
  ],
);

/**
 * @generated from message com.diagnocat.pipelines.ReportStarted
 */
export const ReportStarted = proto3.makeMessageType(
  "com.diagnocat.pipelines.ReportStarted",
  () => [
    { no: 1, name: "AgentID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "DataModel", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "ModelRevisions", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ],
);

/**
 * @generated from message com.diagnocat.pipelines.ReportCompleted
 */
export const ReportCompleted = proto3.makeMessageType(
  "com.diagnocat.pipelines.ReportCompleted",
  [],
);

