import { FC, useRef, useCallback, useState } from 'react';
import cn from 'classnames';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import {
  ErrorCode,
  FileRejection,
  FileWithPath,
  useDropzone,
} from 'react-dropzone';
import * as Accordion from '@radix-ui/react-accordion';
import { Control, Controller, useWatch } from 'react-hook-form';

import { StudyType } from '@/shared/api/protocol-ts/model/dto_study_pb';
import gladCat from '@/shared/assets/images/cats/glad.png';
import intraoralScanPlaceholder from '@/shared/assets/images/intraoralScanPlaceholder.png';
import { useAppSelector, useMedia } from '@/shared/hooks';
import { Icon, InlineFormError, Tooltip } from '@/shared/ui';
import { orderReportName } from '@/shared/i18n';
import { OrderReportType, UploadStudyType } from '@/shared/config/types';
import { AssetType } from '@/shared/api/protocol-ts/model/dto_asset_pb';
import {
  ACCEPTED_FILE_TYPES_FOR_DROPZONE_BY_ASSET_TYPE,
  ACCEPTED_FILE_TYPES_FOR_DROPZONE_FOR_IOS_SEGMENTATION_REPORT,
} from '@/shared/config';

import { ensureRefreshImageSrc } from '@/entities/assets';

import { selectUploadStudyTypes } from '../../config/i18n';
import { OrderReportDropzone } from '../OrderReportDropzone/OrderReportDropzone';
import { UploadStudyFile } from '../UploadStudyFile/UploadStudyFile';
import { selectFilteredStudies } from '../../model/orderReportModal.selectors';
import {
  MAX_BYTES_FOR_CBCT,
  MAX_BYTES_FOR_STUDIES,
  OrderReportModalStep,
  SELECT_INPUT_NAME,
  UploadStudyPayload,
} from '../../config';
import { isDicomFile } from '../../lib';

import styles from './OrderReportStep.module.scss';

type OrderReportStepProps = {
  control: Control<UploadStudyPayload, unknown>;
  step?: OrderReportModalStep;
  orderReportType: OrderReportType;
  studyType: StudyType;
  assetType: UploadStudyType;
  files: FileWithPath[];
  currentStudyID?: string;
  errorMessage?: string;
  isOptional?: boolean;
  onlyUpload?: boolean;
  infoTooltip?: { show: boolean; message: string };
  isAlignmentCBCTReportOrder?: boolean;
  className?: string;
  testID?: string;
  onAddFiles: (files: FileWithPath[]) => void;
  onDeleteFiles: () => void;
  onError: (errorMessage: string) => void;
  onReset?: () => void;
};

export const OrderReportStep: FC<OrderReportStepProps> = (props) => {
  const {
    className,
    testID,
    step,
    orderReportType,
    studyType,
    assetType,
    files,
    control,
    currentStudyID,
    errorMessage,
    isOptional,
    infoTooltip,
    onlyUpload,
    isAlignmentCBCTReportOrder,
    onAddFiles,
    onDeleteFiles,
    onError,
    onReset,
  } = props;
  // NOTE: Need to avoid fade in animation on open OrderReportModal
  const [dirty, setDirty] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const { formatMessage } = useIntl();
  const { isMobile } = useMedia();
  const accordionTriggerRef = useRef<HTMLButtonElement>(null);

  const mainStudyID = useWatch({
    control,
    name: SELECT_INPUT_NAME[1],
  });

  const filteredStudies = useAppSelector(
    selectFilteredStudies({
      studyType,
      mainStudyID: mainStudyID ?? '',
      isAlignmentCBCTReportOrder: isAlignmentCBCTReportOrder ?? false,
    }),
  );

  const isIOSSegmentationOrderReport = orderReportType === 'IOSSegmentation';
  const isPanoOrderReport = orderReportType === 'Pano';
  const isStudySelected = Boolean(currentStudyID);
  const isIOSMeshesStudy = studyType === StudyType.StudyType_IOS_Meshes;
  const previewPlaceholder = isIOSMeshesStudy
    ? intraoralScanPlaceholder
    : gladCat;
  const currentStudy = filteredStudies.find(
    (study) => study.id === currentStudyID,
  );
  const actualStudyExists = filteredStudies.length > 0;
  const filesExist = files.length > 0;
  const showStudySelect = actualStudyExists && !filesExist && !onlyUpload;
  const showDropzone =
    (!actualStudyExists && !filesExist) || (!filesExist && onlyUpload);
  const showUploadStudyFile = filesExist;
  const isMoreThanOneStep = step !== undefined;

  const hadleDropAccepted = async (acceptedFiles: File[]) => {
    const filteredFiles = [];

    for (const file of acceptedFiles) {
      // If the file has no extension, check if it's a DICOM file
      if (!file.name.includes('.')) {
        const isDicom = await isDicomFile(file);
        if (isDicom) {
          filteredFiles.push(file);
        }
        continue;
      }

      // Standard extension check for files with extensions
      const config = isIOSSegmentationOrderReport
        ? ACCEPTED_FILE_TYPES_FOR_DROPZONE_FOR_IOS_SEGMENTATION_REPORT
        : ACCEPTED_FILE_TYPES_FOR_DROPZONE_BY_ASSET_TYPE[assetType];

      if (
        Object.entries(config).some(([mimeType, extensions]) => {
          return (
            file.type === mimeType ||
            extensions.some((ext) =>
              file.name.toLowerCase().endsWith(ext.toLowerCase()),
            )
          );
        })
      ) {
        filteredFiles.push(file);
      }
    }

    // Check if any files remain after filtering
    if (acceptedFiles.length > 0 && filteredFiles.length === 0) {
      onError(
        formatMessage({
          id: 'orderReportModal.wrongFile',
          defaultMessage: 'Wrong File(s) format',
        }),
      );
      return;
    }

    onAddFiles(filteredFiles);
  };

  const handleRejectFiles = useCallback(
    (fileRejections: FileRejection[]) => {
      let currentErrorMessage: string;

      if (fileRejections.length > 0) {
        const errorCode = fileRejections?.at(0)?.errors?.at(0)?.code;

        switch (errorCode) {
          case ErrorCode.FileInvalidType: {
            currentErrorMessage = formatMessage({
              id: 'orderReportModal.wrongFile',
              defaultMessage: 'Wrong File(s) format',
            });
            break;
          }
          case ErrorCode.FileTooLarge: {
            const maxSize =
              assetType === AssetType.AssetType_Study_CBCT ? '10GB' : '250MB';

            currentErrorMessage = formatMessage(
              {
                id: 'orderReportModal.sizeError',
                defaultMessage: 'File(s) size must be less than {maxSize}',
              },
              { maxSize },
            );
            break;
          }
          default: {
            currentErrorMessage = formatMessage({
              id: 'uploadImage.wrongFile',
              defaultMessage: 'Some files are too large or incorrect format',
            });
            break;
          }
        }

        onError(currentErrorMessage);
      }
    },
    [assetType, formatMessage, onError],
  );

  const dropzoneState = useDropzone({
    onDropAccepted: hadleDropAccepted,
    onDropRejected: handleRejectFiles,
    maxSize:
      studyType === StudyType.StudyType_CBCT
        ? MAX_BYTES_FOR_CBCT
        : MAX_BYTES_FOR_STUDIES,
    accept:
      assetType === AssetType.AssetType_Study_DentalPhoto
        ? ACCEPTED_FILE_TYPES_FOR_DROPZONE_BY_ASSET_TYPE[assetType]
        : { '*/*': ['*'] },
    multiple: !isPanoOrderReport,
  });

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      <h5 className={cn(styles.title, isMobile ? 'p3' : 'h5')}>
        {isMoreThanOneStep && !isAlignmentCBCTReportOrder && (
          <FormattedMessage
            id="orderReportModal.step"
            defaultMessage="Step {step}:"
            values={{ step }}
          />
        )}
        {isMoreThanOneStep && ' '}
        {!actualStudyExists || onlyUpload ? (
          <FormattedMessage
            id="orderReportModal.uploadSubtitle"
            defaultMessage="Upload"
          />
        ) : (
          <FormattedMessage
            id="orderReportModal.selectSubtitle"
            defaultMessage="Select"
          />
        )}{' '}
        {isAlignmentCBCTReportOrder ? (
          <FormattedMessage
            id="orderReportModal.additionalCBCTSubtitle"
            defaultMessage="previous CBCT for alignment"
          />
        ) : (
          formatMessage(selectUploadStudyTypes[assetType])
        )}{' '}
        {isOptional &&
          `(${formatMessage({
            id: 'global.optional',
            defaultMessage: 'Optional',
          }).toLowerCase()})`}
        {infoTooltip?.show && (
          <Tooltip.Primary
            content={infoTooltip.message}
            triggerClassName={styles.infoTooltip}
          >
            <Icon name="info" size={28} className={styles.infoIcon} />
          </Tooltip.Primary>
        )}
      </h5>

      {showStudySelect && (
        <Accordion.Root
          className={styles.accordionRoot}
          type="single"
          onValueChange={(value) => {
            setIsAccordionOpen(value === 'study-accordion');

            if (!dirty) {
              setDirty(true);
            }
          }}
          collapsible
        >
          <Accordion.Item
            className={styles.accordionItem}
            value="study-accordion"
          >
            <Accordion.Trigger ref={accordionTriggerRef}>
              <div className={styles.accordionTrigger}>
                <div
                  className={cn(
                    styles.accordionTriggerStudy,
                    dirty && styles.dirty,
                  )}
                >
                  {isStudySelected ? (
                    <>
                      <div
                        className={cn(
                          styles.accordionTriggerImgWrapper,
                          !currentStudy?.previewSrc && styles.placeholder,
                        )}
                      >
                        <img
                          src={currentStudy?.previewSrc || previewPlaceholder}
                          className={cn(
                            currentStudy?.previewSrc
                              ? styles.accordionTriggerImg
                              : styles.accordionTriggerImgFallback,
                            isIOSMeshesStudy &&
                              styles.resetHeightForIOSMeshesImage,
                          )}
                          onError={ensureRefreshImageSrc}
                        />
                      </div>

                      <p className="p2">
                        <FormattedDate
                          value={currentStudy?.createdAt}
                          dateStyle="medium"
                        />
                      </p>
                    </>
                  ) : (
                    <div
                      className={cn(styles.accordionTriggerSelectWrapper, 'p2')}
                    >
                      <FormattedMessage
                        id="orderReportModal.selectSubtitle"
                        defaultMessage="Select"
                      />
                    </div>
                  )}
                </div>

                <div className={styles.accordionChevron}>
                  <Icon name="arrowDown" size={24} />
                </div>
              </div>
            </Accordion.Trigger>

            <Accordion.Content className={styles.accordionContent}>
              <div className={styles.accordionStudies}>
                {filteredStudies.map((study) => (
                  <Controller
                    key={study.id}
                    control={control}
                    name={SELECT_INPUT_NAME[step ?? 1]}
                    render={({ field: { value, name, onChange } }) => (
                      <label className={styles.studyLabel}>
                        <input
                          type="radio"
                          name={name}
                          value={value}
                          checked={value === study.id}
                          onClick={() => {
                            if (
                              accordionTriggerRef.current !== null &&
                              accordionTriggerRef.current.dataset.state ===
                                'open' // WARN: this hack needs to prevent reopen accordion on rappidly clicking on study
                            ) {
                              accordionTriggerRef.current.click();
                            }
                          }}
                          onChange={() => {
                            onChange(study.id);
                          }}
                        />

                        <div className={styles.study}>
                          <div
                            className={cn(
                              styles.studyImgWrapper,
                              !study.previewSrc && styles.placeholder,
                            )}
                          >
                            <img
                              className={cn(
                                study.previewSrc
                                  ? styles.studyImg
                                  : styles.studyImgFallback,
                                studyType ===
                                  StudyType.StudyType_IntraoralXRay &&
                                  styles.ioxRay,
                                isIOSMeshesStudy &&
                                  styles.resetHeightForIOSMeshesImage,
                              )}
                              onError={ensureRefreshImageSrc}
                              src={study.previewSrc || previewPlaceholder}
                            />
                          </div>

                          <div className={styles.studyInfo}>
                            <h6 className={cn(isMobile ? 'h3m' : 'h6')}>
                              {formatMessage(orderReportName[orderReportType])}{' '}
                              <FormattedDate value={study.createdAt} />
                            </h6>

                            <p className={cn(isMobile ? 'p3m' : 'p2')}>
                              <FormattedMessage
                                id="orderReportModal.createdAt"
                                defaultMessage="Created at {date}"
                                values={{
                                  date: (
                                    <FormattedDate
                                      value={study.createdAt}
                                      dateStyle="medium"
                                    />
                                  ),
                                }}
                              />
                            </p>
                          </div>
                        </div>
                      </label>
                    )}
                  />
                ))}
              </div>

              <OrderReportDropzone
                dropzoneState={dropzoneState}
                canUploadFolder={!isPanoOrderReport}
              />
            </Accordion.Content>
          </Accordion.Item>

          {isOptional && isStudySelected && (
            <Icon
              name="delete"
              size={32}
              className={cn(
                styles.deleteIcon,
                isAccordionOpen && styles.fadeOutDeleteIcon,
              )}
              onClick={onReset}
            />
          )}
        </Accordion.Root>
      )}

      {showDropzone && (
        <OrderReportDropzone
          dropzoneState={dropzoneState}
          canUploadFolder={!isPanoOrderReport}
        />
      )}

      {showUploadStudyFile && (
        <UploadStudyFile
          files={files}
          onDelete={onDeleteFiles}
          errorText={errorMessage}
        />
      )}

      {!!errorMessage && <InlineFormError errorMessage={errorMessage} />}
    </div>
  );
};
