import { ToolNames } from '@/shared/config';

export const PANO_TOOLS: ToolNames[] = [
  'expand',
  'split',
  'sharpness',
  'brightness',
  'invert',
  'reset',
  'editNumbers',
];

export const PANO_REPORT_TOOLS: ToolNames[] = [
  'expand',
  'sharpness',
  'brightness',
  'reset',
  'editNumbers',
];

export const PANO_DEFAULT_HEIGHT = 450;
export const MODAL_FIXED_WIDTH = 632;
export const CBCT_TOOTH_CHART_HEIGHT = 308;
export const CBCT_FOOTER_HEIGHT = 64;
export const CBCT_HEADER_HEIGHT = 76;
export const CBCT_PANO_MIN_HEIGHT = 350;
export const CBCT_PANO_MAX_HEIGHT = 600;
export const CBCT_PANO_MIN_HEIGHT_MOBILE = 120;
export const CBCT_PANO_MAX_HEIGHT_MOBILE = 160;
export const CBCT_PANO_PREVIEW_HEIGHT = 400;

export const REPORT_SIGNED_DATE_FORMAT = 'EEE dd, yyyy HH:mm';
