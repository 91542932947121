import { FC, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { Checkbox, Description, Modal, Switch } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { SliceEditor } from '@/shared/graphics/medicalImageRender/MedicalImageRender';
import { MedicalImageViewOptions } from '@/shared/api/protocol-ts/model/dto_common_image_view_options_pb';

import { ModalID, modalModel } from '@/entities/modal';
import { isMedicalImagesTitle, medicalImageTitles } from '@/entities/assets';
import { toolbarMIRModel } from '@/entities/toolbarMIR';
import { toothModel } from '@/entities/tooth';
import { useGetMedicalImage } from '@/entities/assets';

import { useImagePropsSetters } from '@/features/imagePropsSetters';
import { ImageToolbar } from '@/features/imageToolbar';
import { useAddAndRemoveMedicalImage } from '@/features/ToothCard';

import { CONTROLS, RASTER_CONTROLS } from '../config/constants';

import styles from './MIRenderModal.module.scss';

export const MIRenderModal: FC = () => {
  const dispatch = useAppDispatch();

  const {
    visible,
    data: { assetID, toothID },
  } = useAppSelector(modalModel.selectors.selectMIRenderModal);

  const isSelectionEnabled = true;

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.MIRenderModal));
  }, [dispatch]);

  const { formatMessage } = useIntl();

  const { handleSetAnnotations, handleSetMedicalImageViewOptions } =
    useImagePropsSetters(assetID, toothID);

  const displaySlices = useAppSelector(
    toothModel.selectors.selectDisplaySlicesByToothID(toothID),
  );

  const { addMedicalImageToSelected, removeMedicalImageFromSelected } =
    useAddAndRemoveMedicalImage();

  const toggleSelection = () => {
    isTheMedicalImageSelected
      ? removeMedicalImageFromSelected(assetID, toothID)
      : addMedicalImageToSelected(assetID, toothID);
  };

  const isTheMedicalImageSelected = useMemo(
    () => displaySlices.some((item) => item === assetID),
    [displaySlices, assetID],
  );

  const medicalImage = useGetMedicalImage(assetID);

  const tools = medicalImage?.TargetAssetID
    ? CONTROLS.filter((control) => control !== 'ruler')
    : CONTROLS;

  const groupTitle = isMedicalImagesTitle(medicalImage?.groupName)
    ? formatMessage(medicalImageTitles[medicalImage.groupName])
    : undefined;

  const index =
    typeof medicalImage?.order === 'number'
      ? medicalImage.order + 1
      : undefined;

  const strideMm = medicalImage?.strideMm;
  const thicknessMm = medicalImage?.thicknessMm;

  const reset = () => {
    dispatch(
      toolbarMIRModel.actions.statelessAction({
        toolbarID: 'sliceEditor',
        toolName: 'reset',
        action: () => {
          handleSetAnnotations([]);
          handleSetMedicalImageViewOptions(
            new MedicalImageViewOptions({
              Brightness: 0,
              Contrast: 0,
              Sharpness: 0,
            }),
          );
        },
      }),
    );
  };

  const isHideMarks = useSelector(
    toolbarMIRModel.selectors.selectActiveTools('sliceEditor'),
  ).includes('hideMarks');

  const hideMarks = () => {
    dispatch(
      toolbarMIRModel.actions.hideMarks({
        toolbarID: 'sliceEditor',
        value: !isHideMarks,
      }),
    );
  };

  return (
    <Modal
      isOpen={visible}
      onCancel={handleClose}
      title={groupTitle}
      containerClassName={styles.modalContainer}
      bodyClassName={styles.modalBody}
      hideFooter
    >
      {!!strideMm && (
        <Description
          fontType="p2"
          label={
            <FormattedMessage
              id="report.slice_step"
              defaultMessage="Slice step"
            />
          }
          className={styles.thicknessAndStride}
        >
          {strideMm}
          <FormattedMessage id="global.mm" defaultMessage="mm" />,
        </Description>
      )}{' '}
      {!!thicknessMm && (
        <Description
          fontType="p2"
          label={
            <FormattedMessage
              id="report.sliceThickness"
              defaultMessage="slice thickness"
            />
          }
          className={styles.thicknessAndStride}
        >
          {thicknessMm}
          <FormattedMessage id="global.mm" defaultMessage="mm" />
        </Description>
      )}
      <div className={styles.content}>
        <div className={styles.imageWrapper}>
          <div className={styles.imageContainer}>
            {index && (
              <span className={cn(styles.medicalImageSelectedOrderText, 'p1')}>
                {index}
              </span>
            )}
            <SliceEditor
              imageID={assetID}
              className={styles.sliceEditor}
              handleSetAnnotations={handleSetAnnotations}
              handleSetMedicalImageViewOptions={
                handleSetMedicalImageViewOptions
              }
            />
          </div>
          <ImageToolbar
            toolbarId="sliceEditor"
            controls={medicalImage?.kind === 'raster' ? RASTER_CONTROLS : tools}
            className={styles.toolbar}
            tooltipPosition="right"
            stateLessHandlers={{
              reset,
            }}
          />
        </div>

        <div className={styles.actions}>
          {isSelectionEnabled && (
            <Checkbox
              checked={isTheMedicalImageSelected ?? false}
              onChange={toggleSelection}
            >
              <FormattedMessage
                id="medicalImages.Selected"
                defaultMessage="Selected"
              />
            </Checkbox>
          )}

          {!!medicalImage?.annotations?.length && (
            <Switch
              checked={isHideMarks}
              onChange={hideMarks}
              className={styles.switch}
            >
              <FormattedMessage
                id="toolName.hideMarks"
                defaultMessage="Hide marks"
              />
            </Switch>
          )}
        </div>
      </div>
    </Modal>
  );
};
