import { Icon } from '@/shared/ui';

import { DoctorLabel, DoctorOptionType } from '@/entities/doctors';

import styles from './TreatingDoctorOption.module.scss';

type TreatingDoctorOptionProps = DoctorOptionType & {
  isSelected: boolean;
  onClick: (value: string) => void;
};

export const TreatingDoctorOption = (props: TreatingDoctorOptionProps) => {
  const { isSelected, label, avatarSrc, value, onClick } = props;

  return (
    <button
      className={styles.container}
      type="button"
      onClick={() => onClick(value)}
    >
      <DoctorLabel avatarSrc={avatarSrc} fullName={label} />
      {isSelected && (
        <Icon name="check" size={32} className={styles.selectedIcon} />
      )}
    </button>
  );
};
