// @ts-nocheck
import { useState } from "react";
import cn from "classnames";


import { DentalNotationFormat } from "@/shared/api/protocol-ts/model/dto_organization";
import {getDisplayToothNumber, isDuplicate, ToothWithLocalization} from "@/entities/tooth";
import { PendingChange } from "@/shared/config";

import { ObjectFitContain } from "../ObjectFitContain";
import { RCContainer } from "../RCContainer/RCContainer";
import { RCCropImageCrop } from "../RCCropImage/RCCropImage";

import styles from './RCDetecedTooth.module.scss';

export type Localization = {
  tooth: ToothWithLocalization;
  xmin: number;
  ymin: number;
  xmax: number;
  ymax: number;
}

export type RCDetectedToothProps = {
  imageSize: { width: number, height: number };
  localizations: Localization[];
  deletedTeethLocalizations?: Localization[];
  pendingChanges?: PendingChange[];
  crop?: RCCropImageCrop;
  onToothClick?: (tooth: ToothWithLocalization) => void;
  dentalNotationFormat: DentalNotationFormat;
}

export const RCDetectedTeeth = (props: RCDetectedToothProps) => {
  //canvas outside scales down to fit. thats why detection misses the canvas
  const [aspect, setAspect] = useState(1);

  const {
    localizations,
    imageSize,
    onToothClick,
    dentalNotationFormat,
    deletedTeethLocalizations,
    pendingChanges,
  } = props;

  const crop = props.crop || { x: 0, y: 0, w: 1, h: 1 };

  return (
    <ObjectFitContain
      objectWidth={imageSize.width}
      objectHeight={imageSize.height}
      onAspectChanged={(aspect) => { setAspect(aspect) }}
    >
      <RCContainer className={styles.RCContainer}>
        {localizations?.map((localization) => {
           const isInvalidToothNumber = isDuplicate(localizations.map(item => item.tooth), localization.tooth) && localization.tooth.ISONumber !== 404;
           const toothNumber =
             getDisplayToothNumber(localization.tooth.ISONumber, dentalNotationFormat)

             return (
               <div
                 key={localization.tooth.Localization.ID}
                 style={{
                   position: "absolute",
                   top: (localization.ymin - crop.y) * aspect,
                   left: (localization.xmin - crop.x) * aspect,
                   width: (localization.xmax - localization.xmin) * aspect,
                   height: (localization.ymax - localization.ymin) * aspect,
               }}>
                  <button
                      type="button"
                      onClick={() => onToothClick(localization.tooth)}
                      className={cn('p3', styles.toothNumber, isInvalidToothNumber && styles.invalid) }
                      style={{
                      top: `${(localization.ymax - localization.ymin) / 2 * aspect}px`,
                      left: `${(localization.xmax - localization.xmin) / 2 * aspect}px`,
                       }}
                  >{toothNumber}</button>
               </div>
             )
        })}

        {deletedTeethLocalizations?.map((localization) => {
          return (
            <div
              key={localization.tooth.Localization.ID}
              style={{
                position: "absolute",
                top: (localization.ymin - crop.y) * aspect,
                left: (localization.xmin - crop.x) * aspect,
                width: (localization.xmax - localization.xmin) * aspect,
                height: (localization.ymax - localization.ymin) * aspect,
              }}>
              <button
                type="button"
                onClick={() => onToothClick(localization.tooth)}
                className={cn('p3', styles.toothNumber)}
                style={{
                  top: `${(localization.ymax - localization.ymin) / 2 * aspect}px`,
                  left: `${(localization.xmax - localization.xmin) / 2 * aspect}px`,
                }}
              >{pendingChanges?.find(p => p.toothID === localization.tooth.toothID)?.newNumber || 'X'}</button>
            </div>
          )
        })}

      </RCContainer>
    </ObjectFitContain>
  );
};
