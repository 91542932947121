import { useState } from 'react';
import { Code, ConnectError } from '@bufbuild/connect';

import { useAppDispatch } from '@/shared/hooks';
import api from '@/shared/api/api';
import { debug, isConnectNetworkError } from '@/shared/lib';

import { accessModel } from '@/entities/access';

import { SharedByMeFilterSettingsType } from '../config/types';

let abortController: AbortController;

export type UseSharedByMeStreamArgs = {
  organizationID: string;
  searchValue?: string;
};

export const useSharedByMeStream = ({
  organizationID,
  searchValue = '',
}: UseSharedByMeStreamArgs) => {
  // use to restore patients state for infinity scroll
  // must be reset after any filters update
  const [resumeToken, setResumeToken] = useState('');

  const dispatch = useAppDispatch();

  const openSharedByMeStream = async (
    filterSettings: SharedByMeFilterSettingsType = {
      searchString: searchValue,
      id: '',
    },
  ) => {
    abortController = new AbortController();

    const { searchString, id, sortBy } = filterSettings;

    dispatch(accessModel.actions.setLoading('pending'));

    try {
      const sharedByMeStream = api.access.sharedByMeStream(
        {
          OrganizationID: organizationID,
          ResumeToken: resumeToken,
          SearchString: searchString,
          Sort: sortBy,
          StartFromInvitationID: id,
          Limit: 20,
        },
        { signal: abortController.signal },
      );

      for await (const { Update, ResumeToken } of sharedByMeStream) {
        switch (Update.case) {
          case 'InitialInvitationsList': {
            dispatch(accessModel.actions.addMany(Update.value.Invitations));

            dispatch(accessModel.actions.setLoading('succeeded'));
            break;
          }
          case 'TotalOrganizationPatientCount': {
            dispatch(
              accessModel.actions.setTotalOrganizationPatientCount(
                Update.value,
              ),
            );
            break;
          }
          case 'SharedByMeCount': {
            dispatch(accessModel.actions.setSharedByMeCount(Update.value));
            break;
          }
          case 'SharedWithMeCount': {
            dispatch(accessModel.actions.setSharedWithMeCount(Update.value));
            break;
          }
          case 'InvitationUpdated': {
            dispatch(accessModel.actions.setNewestOne(Update.value));
            break;
          }
        }

        if (ResumeToken) {
          setResumeToken(ResumeToken);
        }
      }
    } catch (error) {
      const connectError = ConnectError.from(error);

      if (isConnectNetworkError(connectError)) {
        debug('Network error detected, attempting to restart stream...');
        closeSharedByMeStream(false);
        openSharedByMeStream();
      }

      if (error instanceof ConnectError && error.code !== Code.Canceled) {
        dispatch(accessModel.actions.setLoading('failed'));
      }
    }
  };

  const closeSharedByMeStream = (resetStore = true) => {
    if (abortController) {
      abortController.abort();
    }

    if (resetStore) {
      dispatch(accessModel.actions.removeAll());
    }
  };

  return { openSharedByMeStream, closeSharedByMeStream };
};
