import { FC, useState } from 'react';

import { Popup } from '@/shared/ui';
import { useDebouncedFunction, useWindowSize } from '@/shared/hooks';
import { ToolNames } from '@/shared/config';
import {
  UltimateMedicalImage,
  UltimateMedicalImageMode,
} from '@/shared/graphics/RenderComponents/Presets/UltimateMedicalImage';
import { Annotation } from '@/shared/graphics/RenderComponents/AnnotationsLayer/Annotation';
import { AnnotationOrEraser } from '@/shared/graphics/RenderComponents/Presets/DicomImageWithAnnotations';
import { MedicalImageViewOptions } from '@/shared/api/protocol-ts/model/dto_common_image_view_options_pb';

import { increaseImageHeight, increaseImageWidth } from '@/entities/assets';
import { ActiveControlType } from '@/entities/reports';

import { IContext } from 'graphics';

import styles from './ExpandedPanoModal.module.scss';

export type ExpandedImageProps = {
  src: string;
  isOpen: boolean;
  controls: ToolNames[];
  onClose: () => void;
  toggleIsSplit?: () => void;
  activeControl?: string;
  setActiveControl?: (newControl: ActiveControlType) => void;
  mode: UltimateMedicalImageMode;
  annotations: Annotation[];
  setAnnotations: (newAnnotations: Annotation[]) => void;
  sharpness: number;
  setSharpness: (newSharpness: number) => void;
  currentAnnotation: AnnotationOrEraser;
  wwwc: { ww: number; wc: number };
  setWWWC: (newWWWC: { ww: number; wc: number }) => void;
  viewAnnotations: boolean;
  isInverted: boolean;
  isSplit: boolean;
  toggleIsInverted: () => void;
  handleSetMedicalImageViewOptions: (
    viewOptions: Partial<MedicalImageViewOptions>,
  ) => void;
};

const PANO_IMAGE_ZOOM_COEFFICIENT = 0.75;

export const ExpandedPanoModal: FC<ExpandedImageProps> = (props) => {
  const {
    src,
    isOpen,
    onClose,
    mode,
    annotations,
    setAnnotations,
    sharpness,
    setSharpness,
    currentAnnotation,
    wwwc: currentWwwc,
    setWWWC,
    viewAnnotations,
    isInverted,
    handleSetMedicalImageViewOptions,
  } = props;

  const [size, setSize] = useState({ width: 0, height: 0 });

  const { width: wWidth, height: wHeight } = useWindowSize();

  // const expandedPanoControls = controls.filter(
  //   (control) => control !== 'expand',
  // );

  const onLoaded = (currentContext: IContext) => {
    const containerWidth = wWidth * PANO_IMAGE_ZOOM_COEFFICIENT;
    const imageNewHeight = increaseImageHeight({
      width: currentContext.width,
      height: currentContext.height,
      newWidth: containerWidth,
    });

    if (imageNewHeight > wHeight - 200) {
      const containerHeight = (wHeight - 200) * PANO_IMAGE_ZOOM_COEFFICIENT;
      const imageNewWidth = increaseImageWidth({
        height: currentContext.height,
        width: currentContext.width,
        newHeight: containerHeight,
      });
      setSize({ width: imageNewWidth, height: containerHeight });
    } else {
      setSize({ width: containerWidth, height: imageNewHeight });
    }
  };

  const delayedChangedViewOptions = useDebouncedFunction(
    handleSetMedicalImageViewOptions,
    900,
  );

  return (
    <Popup className={styles.popup} isOpen={isOpen} onClose={onClose}>
      <div className={styles.container}>
        {/* <ImageToolbar
          controls={expandedPanoControls}
          activeControl={activeControl}
          setActiveControl={(value) => {
            if (setActiveControl) {
              setActiveControl(value);
            }
          }}
          className={styles.toolbar}
          isInverted={isInverted}
          isSplit={isSplit}
          toggleIsInverted={toggleIsInverted}
          toggleIsSplit={toggleIsSplit}
          tooltipPosition="right"
        /> */}
        <UltimateMedicalImage
          className={styles.imageContainer}
          src={{ url: src, kind: 'dicom' }}
          mode={mode}
          currentAnnotation={currentAnnotation}
          annotations={viewAnnotations ? annotations : []}
          onAnnotationsChanged={setAnnotations}
          style={{
            width: size.width,
            height: size.height,
            objectFit: 'contain',
          }}
          onLoaded={onLoaded}
          onWWWCChanged={(wwwc) => {
            setWWWC(wwwc);
            delayedChangedViewOptions({
              WindowWidth: currentWwwc?.ww,
              WindowLevel: currentWwwc?.wc,
            });
          }}
          ww={currentWwwc?.ww}
          wc={currentWwwc?.wc}
          sharpness={sharpness}
          onSharpnessChanged={(currentSharpness) => {
            delayedChangedViewOptions({
              Sharpness: sharpness,
            });
            setSharpness(currentSharpness);
          }}
          inverted={isInverted}
          objectFit="cover"
        />
      </div>
    </Popup>
  );
};
