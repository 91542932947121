import cn from 'classnames';
import { useIntl } from 'react-intl';
import { includes } from 'ramda';
import { useCallback } from 'react';

import { Icon, Spinner } from '@/shared/ui';
import { ReportGenerationErrorCode } from '@/shared/api/protocol-ts/model/dto_report_generation_error_codes_pb';
import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';
import { useAppSelector, useMedia } from '@/shared/hooks';

import { reportsModel, useNavigateToReport } from '@/entities/reports';
import { assetsModel } from '@/entities/assets';

import { useDownloadReportPDF } from '@/features/downloadReportPDF';
import { useRemoveReport } from '@/features/removeReport';
import { SupportTooltip } from '@/features/supportTooltip';

import { ReportInfoRowProps } from '../../config/types';

import styles from './ReportInfoRow.module.scss';

export const ReportInfoRow = (props: ReportInfoRowProps) => {
  const { reportID, reportType, patientID, date, status, onRemoveReport } =
    props;

  const canDelete = useAppSelector(
    reportsModel.selectors.selectCanDeleteReportByID(reportID),
  );

  const error = status?.Failed;
  const isPending = status?.Pending;

  const { formatDate } = useIntl();
  const { isMobile } = useMedia();
  const { downloadReportPDF, downloading } = useDownloadReportPDF(reportID);
  const removeReport = useRemoveReport();
  const navigateToReport = useNavigateToReport();

  const CBCTOrthoPDFUrl = useAppSelector(
    assetsModel.selectors.selectCBCTOrthoPDFUrl(reportID),
  );

  const hasError = includes(
    error?.Code,
    Object.values(ReportGenerationErrorCode),
  );

  const errorMessage =
    hasError && error?.Code !== undefined ? error?.Message : '';

  const showDownloadButton = status.Completed && !downloading;

  const downloadReportHandler = useCallback(() => {
    if (
      reportType === ReportType.ReportType_CBCT_OrthoSlides ||
      reportType === ReportType.ReportType_StudioOrtho
    ) {
      window.open(CBCTOrthoPDFUrl, '_blank', 'noopener,noreferrer');
    } else {
      downloadReportPDF();
    }
  }, [reportType, CBCTOrthoPDFUrl, downloadReportPDF]);

  const handleIDClick = useCallback(() => {
    if (status.Completed) {
      navigateToReport({
        reportType,
        reportID,
        patientID,
        CBCTOrthoPDFUrl,
      });
    }
  }, [
    reportID,
    navigateToReport,
    patientID,
    reportType,
    status.Completed,
    CBCTOrthoPDFUrl,
  ]);

  const removeReportHandler = async () => {
    const response = await removeReport([reportID]);

    if (response) {
      onRemoveReport();
    }
  };

  return (
    <div
      className={cn(styles.container, 'p2')}
      data-status-completed={status.Completed}
    >
      <div className={cn(styles.main, 'p2')}>
        <div
          className={cn(
            styles.id,
            status.Completed && styles.completed,
            hasError && styles.error,
            isPending && styles.pending,
          )}
          onClick={handleIDClick}
        >
          ID {reportID}
        </div>
        <div className={styles.status}>
          {isPending && <Icon name="time" size={24} />}

          {hasError && (
            <SupportTooltip
              triggerClassName={cn(styles.status, styles.error)}
              errorMessage={errorMessage}
            >
              <Icon name="info" size={24} />
            </SupportTooltip>
          )}
        </div>
        {!isMobile && <div className={styles.date}>{formatDate(date)}</div>}
        {downloading && (
          <Spinner className={styles.spinner} size={32} primary />
        )}
        {showDownloadButton && (
          <button
            type="button"
            onClick={downloadReportHandler}
            className={cn(styles.button, styles.downloadButton)}
          >
            <Icon name="download" size={32} />
          </button>
        )}

        {canDelete && (
          <button
            type="button"
            onClick={removeReportHandler}
            className={cn(styles.button, styles.deleteButton)}
          >
            <Icon name="delete" size={32} />
          </button>
        )}
      </div>
      {isMobile && <div className={styles.date}>{formatDate(date)}</div>}
    </div>
  );
};
