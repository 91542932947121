import { FC, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Button, Icon, Spinner } from '@/shared/ui';

import styles from './DetailsPopover.module.scss';

type DetailsPopoverProps = {
  isReportGenerationCompleted: boolean;
  isLoadingPDF: boolean;
  canDelete: boolean;
  className?: string;
  testID?: string;
  onDetailsView: () => void;
  onRemoveReport: () => void;
  onDownloadReport: () => Promise<void>;
};

export const DetailsPopover: FC<DetailsPopoverProps> = (props) => {
  const {
    isReportGenerationCompleted,
    isLoadingPDF,
    className,
    testID,
    canDelete,
    onDetailsView,
    onRemoveReport,
    onDownloadReport,
  } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const popoverRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node) &&
        iconRef.current &&
        !iconRef.current.contains(event.target as Node)
      ) {
        setIsPopoverOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDownloadPDF = async () => {
    if (!isLoadingPDF) {
      await onDownloadReport();

      setIsPopoverOpen(false);
    }
  };

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      <div className={styles.detailsIconWrapper} ref={iconRef}>
        <Icon
          name="dots"
          size={32}
          className={cn(
            styles.detailsIcon,
            isPopoverOpen && styles.detailsIconActive,
          )}
          onClick={(event) => {
            event.stopPropagation();
            setIsPopoverOpen(!isPopoverOpen);
          }}
        />
      </div>

      {isPopoverOpen && (
        <div
          className={cn(styles.popover, 'p2')}
          onMouseLeave={() => setIsPopoverOpen(false)}
          ref={popoverRef}
        >
          <Button
            onClick={(event) => {
              event.stopPropagation();
              // TODO: [2/m] Implement reportCardInfoModal
              onDetailsView();
            }}
            variant="tertiary"
            size="small"
            icon={'info'}
            className={styles.popoverButton}
          >
            <FormattedMessage
              id="reportCard.information"
              defaultMessage="Information"
            />
          </Button>

          {isReportGenerationCompleted && (
            <Button
              onClick={(event) => {
                event.stopPropagation();

                handleDownloadPDF();
              }}
              variant="tertiary"
              size="small"
              className={styles.popoverButton}
            >
              {isLoadingPDF ? (
                <Spinner size={18} className={styles.downloadSpinner} />
              ) : (
                <Icon name="download" size={24} />
              )}

              <FormattedMessage
                id="reportCard.download"
                defaultMessage="Download"
              />
            </Button>
          )}

          {canDelete && (
            <Button
              onClick={(event) => {
                event.stopPropagation();
                onRemoveReport();
              }}
              variant="tertiary"
              size="small"
              danger
              icon={'delete'}
              className={cn(styles.popoverButton)}
            >
              <FormattedMessage
                id="reportCard.remove"
                defaultMessage="Remove"
              />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
