import { FC, useCallback } from 'react';

import { MedicalImage } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';
import { MedicalImageInterface } from '@/shared/config';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';

import { MedicalImagesGroups } from '@/entities/assets';
import { organizationModel } from '@/entities/organization';

import { maskFiltersModel } from '@/features/renderMasks';
import { useAddAndRemoveMedicalImage } from '@/features/ToothCard';
import { DragAndDropHint } from '@/features/ToothCard/ui/DragAndDropHint/DragAndDropHint';

import { MedicalImagesDnD } from '../MedicalImagesDnD/MedicalImagesDnD';

type SliceListProps = {
  toothID: string;
  medicalImages?: MedicalImageInterface[];
};

export const SelectedMedicalImageList: FC<SliceListProps> = (props) => {
  const { toothID, medicalImages } = props;

  const { removeMedicalImageFromSelected } = useAddAndRemoveMedicalImage();

  const hidePerioMasksAndSlices = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Hide_PerioMasksAndSlices,
    ),
  );

  const { isConditionsMasksHidden, isCBCTMasksHidden } = useAppSelector(
    maskFiltersModel.selectors.selectMasksFlags,
  );

  const handleDeleteMedicalImage = (medicalImage: MedicalImageInterface) => {
    removeMedicalImageFromSelected(medicalImage.assetID, toothID);
  };

  const checkIsTheMedicalImageSelected = useCallback(
    (assetID: string) => {
      return (
        medicalImages?.some(
          (medicalImage) => medicalImage.assetID === assetID,
        ) ?? false
      );
    },
    [medicalImages],
  );

  if (!medicalImages) {
    return null;
  }

  return (
    <>
      {medicalImages
        ?.filter((medicalImage) => {
          if (medicalImage.groupName === MedicalImagesGroups.PeriapicalLesion) {
            return (
              !hidePerioMasksAndSlices &&
              !isConditionsMasksHidden &&
              !isCBCTMasksHidden
            );
          }

          return true;
        })
        .map((medicalImage) => {
          return (
            <MedicalImage
              key={medicalImage.src}
              medicalImage={medicalImage}
              order={medicalImage.order}
              onRemoveMedicalImage={handleDeleteMedicalImage}
              isImgSelected={true}
              canUserDeleteSlices={true}
            />
          );
        })}
      <MedicalImagesDnD
        toothID={toothID}
        checkIsTheMedicalImageSelected={checkIsTheMedicalImageSelected}
      />
      <DragAndDropHint />
    </>
  );
};
