import { FC, useEffect } from 'react';
import { useLocation } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from 'ramda';
import { ColumnDef } from '@tanstack/react-table';

import { Invitation } from '@/shared/api/protocol-ts/model/dto_access_pb';
import { useAppDispatch, useAppSelector, useMedia } from '@/shared/hooks';
import { Button, Layout, MobileLayout } from '@/shared/ui';
import { trackPageView } from '@/shared/lib';

import { organizationModel } from '@/entities/organization';
import {
  patientModel,
  PatientsFilter,
  PatientsFiltersType,
} from '@/entities/patient';
import { userModel } from '@/entities/user';
import { accessModel } from '@/entities/access';
import { ModalID, modalModel } from '@/entities/modal';

import { Footer } from '@/features/footer';
import { TreatingDoctorsFilterDrawer } from '@/features/treatingDoctors';
import { RevokeSharedPatientModal } from '@/features/sharePatient';

import { Header, MobileHeader } from '@/widgets/Header';
import { PatientModalForm } from '@/widgets/PatientModalForm';
import { GetFullAccessModal } from '@/widgets/GetFullAccessModal';
import { AboutLabelingModal } from '@/widgets/AboutLabelingModal';

import { MobileSharedPatientList } from './ui/MobileSharedPatientList/MobileSharedPatientList.tsx';
import { MobilePatientList } from './ui/MobilePatientList/MobilePatientList';
import { usePatientsSortAndFilters } from './hooks/usePatientsSortAndFilters';
import { PatientList } from './ui/PatientList/PatientList';
import { SharedPatientList } from './ui/SharedPatientList/SharedPatientList';
import { useSharedPatientColumnsScheme } from './hooks/useSharedPatientColumnsScheme';
import { ChangeOrganizationModal } from './ui/ChangeOrganizationModal/ChangeOrganizationModal';
import { PatientListControls } from './ui/PatientListControls/PatientListControls';
import { PatientListSearch } from './ui/PatientListSearch/PatientListSearch';
import { PatientsFilters } from './ui/PatientsFilters/PatientsFilters';
import styles from './Patients.module.scss';

export const Patients: FC = () => {
  const [searchParams] = useSearchParams();

  const { formatMessage } = useIntl();

  const location = useLocation();

  const { isCurrentUserAdmin } = useAppSelector(
    userModel.selectors.selectUserOrganizationLegacy,
  );

  // type casting is necessary for typification location.state
  const invitationOrganizationID = (
    location?.state as { invitationOrganizationID: string }
  )?.invitationOrganizationID;

  const invitationOrganizationName = (
    location?.state as { invitationOrganizationName: string }
  )?.invitationOrganizationName;

  const organizationID = useAppSelector(
    organizationModel.selectors.selectCurrentOrganizationID,
  );

  const allOrganizations = useAppSelector(
    organizationModel.selectors.organizationSelectors.selectAll,
  );

  const { sharedWithMeCount, sharedByMeCount, totalOrganizationPatientCount } =
    useAppSelector(patientModel.selectors.selectCounters);

  const {
    accessSharedWithMeCount,
    accessSharedByMeCount,
    accessTotalOrganizationPatientCount,
  } = useAppSelector(accessModel.selectors.selectCounters);

  const dispatch = useAppDispatch();

  const { isPhone, isMobile } = useMedia();

  const defaultPatientsFilter = isEmpty(allOrganizations)
    ? PatientsFilter.sharedWithMe
    : PatientsFilter.all;

  const patientsFilter =
    (searchParams.get('filters') as PatientsFiltersType) ??
    defaultPatientsFilter;

  //  If there are no organizations, the only tab available will be 'Shared with Me'
  const isAllPatientList =
    (patientsFilter === PatientsFilter.all && !isEmpty(allOrganizations)) ||
    (patientsFilter === null && !isEmpty(allOrganizations));

  const isSharedWithMeList =
    patientsFilter === PatientsFilter.sharedWithMe || isEmpty(allOrganizations);

  const isSharedByMeOrSharedByStaffList =
    patientsFilter === PatientsFilter.sharedByMe ||
    patientsFilter === PatientsFilter.sharedByStaff;

  const patientCounts = isAllPatientList
    ? {
        sharedWithMeCount,
        sharedByMeCount,
        totalOrganizationPatientCount,
      }
    : {
        sharedWithMeCount: accessSharedWithMeCount,
        sharedByMeCount: accessSharedByMeCount,
        totalOrganizationPatientCount: accessTotalOrganizationPatientCount,
      };

  const { sharedByMeColumnsScheme, sharedWithMeColumnsScheme } =
    useSharedPatientColumnsScheme();

  const {
    sortByForSharedWithMe,
    sortByForSharedByMe,
    sortByForAllPatients,
    searchPatients,
    filterByDoctors,
    onFilterPatientsChange,
    fetchMorePatients,
  } = usePatientsSortAndFilters({
    organizationID,
    isSharedWithMeList,
    isSharedByMeOrSharedByStaffList,
    isAllPatientList,
  });

  const openNewPatientModalForm = () => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.PatientModalForm,
        data: {
          patientID: '',
        },
      }),
    );
  };

  useEffect(() => {
    trackPageView('Patients Page Viewed');
  }, []);

  return isMobile ? (
    <MobileLayout>
      <MobileHeader />

      <MobileLayout.Main>
        <PatientsFilters
          amount={patientCounts}
          onFilterChange={(value) =>
            onFilterPatientsChange(value as PatientsFiltersType)
          }
        />
        <div className={styles.mobileSearch}>
          <PatientListSearch
            onInputChange={searchPatients}
            currentPatientsFilter={patientsFilter}
          />
          <Button icon="plus" size="large" onClick={openNewPatientModalForm} />
        </div>

        {isAllPatientList && (
          <MobilePatientList
            organizationID={organizationID}
            reFetchPatients={fetchMorePatients}
            onAddPatient={openNewPatientModalForm}
          />
        )}

        {isSharedWithMeList && (
          <MobileSharedPatientList
            organizationID={organizationID}
            reFetchPatients={fetchMorePatients}
          />
        )}

        {isSharedByMeOrSharedByStaffList && (
          <MobileSharedPatientList
            isSharedByMeOrSharedByStaffList
            organizationID={organizationID}
            reFetchPatients={fetchMorePatients}
          />
        )}
      </MobileLayout.Main>

      <PatientModalForm />
      <RevokeSharedPatientModal />
      <TreatingDoctorsFilterDrawer onSave={filterByDoctors} />
    </MobileLayout>
  ) : (
    <Layout>
      <Layout.Header>
        <Header />
      </Layout.Header>

      <Layout.Content>
        <Layout.Main>
          <section className={styles.container}>
            <main className={styles.main}>
              <div className={styles.header}>
                <h1 className="h1">
                  <FormattedMessage
                    id="patientList.pageTitle"
                    defaultMessage="Patients"
                  />
                </h1>
                <Button
                  className={styles.newPatientButton}
                  onClick={openNewPatientModalForm}
                  icon="plus"
                  size={isPhone ? 'medium' : 'large'}
                >
                  {isMobile ? (
                    <FormattedMessage
                      id="patientList.addNew"
                      defaultMessage="Add new"
                    />
                  ) : (
                    <FormattedMessage
                      id="patientList.addPatient"
                      defaultMessage="Add new patient"
                    />
                  )}
                </Button>
              </div>

              <PatientListControls
                onInputChange={searchPatients}
                onFilterChange={(value) =>
                  onFilterPatientsChange(value as PatientsFiltersType)
                }
                amount={patientCounts}
                currentPatientsFilter={patientsFilter}
              />

              {isAllPatientList && (
                <PatientList
                  handleAddPatient={openNewPatientModalForm}
                  reFetchPatients={fetchMorePatients}
                  sortBy={sortByForAllPatients}
                  onDoctorFilterChange={filterByDoctors}
                />
              )}

              {isSharedWithMeList && (
                <SharedPatientList
                  showSharedByColumn={isCurrentUserAdmin}
                  sharedPatientColumnsScheme={
                    sharedWithMeColumnsScheme as ColumnDef<Invitation>[]
                  }
                  sharedPatientCounter={sharedWithMeCount}
                  sortBy={sortByForSharedWithMe}
                  reFetchPatients={fetchMorePatients}
                  emptyText={formatMessage({
                    id: 'patientList.emptySharedWithMe',
                    defaultMessage: 'No one has shared a patient with you yet',
                  })}
                />
              )}

              {isSharedByMeOrSharedByStaffList && (
                <SharedPatientList
                  sharedPatientColumnsScheme={
                    sharedByMeColumnsScheme as ColumnDef<Invitation>[]
                  }
                  sharedPatientCounter={sharedByMeCount}
                  sortBy={sortByForSharedByMe}
                  reFetchPatients={fetchMorePatients}
                  emptyText={formatMessage({
                    id: 'patientList.emptySharedByMeOrTeam',
                    defaultMessage: 'You have not shared any patients yet',
                  })}
                />
              )}
            </main>
          </section>
        </Layout.Main>
      </Layout.Content>

      <Layout.Footer>
        <Footer />
      </Layout.Footer>

      <AboutLabelingModal />

      <ChangeOrganizationModal
        invitationOrganizationID={invitationOrganizationID}
        invitationOrganizationName={invitationOrganizationName}
      />
      <AboutLabelingModal />
      <GetFullAccessModal />
      <PatientModalForm />
      <RevokeSharedPatientModal />
    </Layout>
  );
};
