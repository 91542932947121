import { FC } from 'react';
import cn from 'classnames';

import { Avatar } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { userModel } from '@/entities/user';
import { ModalID, modalModel } from '@/entities/modal';

import { ThemeSelector } from './ThemeSelector/ThemeSelector';
import { Logo } from './Logo/Logo';
import { LanguageSelector } from './LanguageSelector/LanguageSelector';
import { LearningHub } from './LearningHub/LearningHub';
import styles from './MobileHeader.module.scss';
import { HeaderDrawer } from './HeaderDrawer/HeaderDrawer';

type HeaderProps = {
  isProtectedPages?: boolean;
  className?: string;
};

export const MobileHeader: FC<HeaderProps> = (props) => {
  const { className, isProtectedPages = true } = props;

  const dispatch = useAppDispatch();
  const { userFullName, userInitials, userAvatarAssetSrc } = useAppSelector(
    userModel.selectors.selectUserAvatarData,
  );

  const openDrawer = () => {
    dispatch(modalModel.actions.openModal({ modalID: ModalID.HeaderDrawer }));
  };

  return (
    <>
      <header className={cn(styles.container, className)}>
        <Logo isMobile />

        <div className={styles.settingsWrapper}>
          <LearningHub isMobile />
          {isProtectedPages && <LanguageSelector isMobile />}
          <ThemeSelector isMobile />

          {isProtectedPages && (
            <button
              type="button"
              className={styles.avatarButton}
              onClick={openDrawer}
            >
              <Avatar
                size={32}
                src={userAvatarAssetSrc}
                alt={userFullName}
                initials={userInitials}
              />
            </button>
          )}
        </div>
      </header>
      <HeaderDrawer />
    </>
  );
};
