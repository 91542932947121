import { useCallback } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { Modal } from '@/shared/ui';
import { useAppDispatch, useAppSelector, useMedia } from '@/shared/hooks';

import { ModalID, modalModel } from '@/entities/modal';
import { accessModel } from '@/entities/access';
import { patientModel } from '@/entities/patient';

import { SharedDoctorRow } from '@/features/sharedDoctorRow';

import styles from './ShowAllSharedWithModal.module.scss';

export const ShowAllSharedWithModal = () => {
  const { formatMessage } = useIntl();
  const { isMobile } = useMedia();
  const dispatch = useAppDispatch();
  const {
    visible,
    data: { patientID },
  } = useAppSelector(modalModel.selectors.selectAllSharedWithModal);

  const invitations = useAppSelector(
    accessModel.selectors.selectAcceptedOrPendingSharedPatientInvitations,
  );
  const { CanCancelOthersSharings } = useAppSelector(
    patientModel.selectors.selectPatientYourPermissions(patientID),
  );

  const openSharePatientFormModal = useCallback(() => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.SharePatient,
        data: { patientID },
      }),
    );
  }, [dispatch, patientID]);

  const closeModal = () => {
    dispatch(modalModel.actions.closeModal(ModalID.AllSharedWith));
  };

  return (
    <Modal
      className={cn(styles.container)}
      bodyClassName={styles.content}
      title={formatMessage({
        id: 'patientInfo.sharedWith',
        defaultMessage: 'Shared with',
      })}
      isOpen={visible}
      onOk={openSharePatientFormModal}
      onCancel={closeModal}
      okButtonText={formatMessage({
        id: 'patientInfo.okButtonMobile',
        defaultMessage: 'Share patient file',
      })}
      okButtonProps={{
        variant: 'secondary',
      }}
      shouldRenderCancelButton={false}
      hideFooter={!isMobile}
    >
      {invitations.map((invitation) => (
        <SharedDoctorRow
          key={invitation.ID}
          canCancelSharing={CanCancelOthersSharings}
          sharedPatientInvitation={invitation}
          isMobile={isMobile}
        />
      ))}
    </Modal>
  );
};
