import { FC } from 'react';
import cn from 'classnames';

import { Icon } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { userModel } from '@/entities/user';

import { ICON_SIZE, MOBILE_ICON_SIZE } from '../../config/constants';

import styles from './ThemeSelector.module.scss';

type ThemeSelectorProps = {
  className?: string;
  isMobile?: boolean;
  testID?: string;
};

export const ThemeSelector: FC<ThemeSelectorProps> = (props) => {
  const { className, isMobile, testID } = props;

  const dispatch = useAppDispatch();

  const { theme } = useAppSelector(userModel.selectors.selectSettings);

  const toggleTheme = () => {
    const isDarkTheme = theme === 'dark-theme';

    if (isDarkTheme) {
      document.documentElement.classList.remove('dark-theme');
      document.documentElement.classList.add('light-theme');
    } else {
      document.documentElement.classList.remove('light-theme');
      document.documentElement.classList.add('dark-theme');
    }

    dispatch(
      userModel.thunks.setSettings({
        theme: isDarkTheme ? 'light-theme' : 'dark-theme',
      }),
    );
  };

  const iconSize = isMobile ? MOBILE_ICON_SIZE : ICON_SIZE;

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      {theme === 'light-theme' ? (
        <Icon
          className={styles.icon}
          size={iconSize}
          name="moon"
          onClick={toggleTheme}
        />
      ) : (
        <Icon
          className={styles.icon}
          size={iconSize}
          name="sun"
          onClick={toggleTheme}
        />
      )}
    </div>
  );
};
