import { FC } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';
import { ViewMIR } from '@/shared/graphics/medicalImageRender/MedicalImageRender';

import { conditionText } from '@/entities/condition/config/i18n';
import { conditionModel } from '@/entities/condition';

import styles from './RarePathologies.module.scss';

type NonDentalFindingsProps = {
  conditions: Condition[];
  className?: string;
  isPreviewMode?: boolean;
};

export const RarePathologies: FC<NonDentalFindingsProps> = (props) => {
  const { className, conditions, isPreviewMode = false } = props;

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const handleConditionHover = (id: string) => {
    if (!isPreviewMode) {
      dispatch(conditionModel.actions.setHoveredNonDentalConditionID(id));
    }
  };

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.conditions}>
        {conditions.map((condition, index) => {
          const isLastCondition = index === conditions.length - 1;

          return (
            <p
              key={condition.ID}
              className={cn(
                'p2',
                isPreviewMode && 'blackText',
                !isPreviewMode && styles.isInteractive,
              )}
              onMouseEnter={() => handleConditionHover(condition.ID)}
              onMouseLeave={() => handleConditionHover('')}
            >
              {formatMessage(conditionText[condition.Code])}

              {isPreviewMode && !isLastCondition && `, `}
            </p>
          );
        })}
      </div>
      <ViewMIR viewName="noneDentalFindings" className={styles.viewMIR} />
    </div>
  );
};
