import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { isEmpty } from 'ramda';

import { ReportUserSignature } from '@/shared/api/protocol-ts/model/dto_report_pb';
import { useAppSelector } from '@/shared/hooks';
import { getImageSrc, getName } from '@/shared/lib';

import { organizationModel, i18n } from '@/entities/organization';
import { userModel } from '@/entities/user';

import styles from './Signature.module.scss';

type SignatureProps = {
  className?: string;
  testID?: string;
  signature: ReportUserSignature;
  blackAndWhiteMode?: boolean;
};

export const Signature: FC<SignatureProps> = (props) => {
  const { signature, className, testID, blackAndWhiteMode } = props;

  const { formatMessage } = useIntl();

  const currentOrganization = useAppSelector(
    organizationModel.selectors.selectCurrentOrganization,
  );
  const userLocale = useAppSelector(userModel.selectors.selectUserLocale);

  const allOrganizationDoctors = useAppSelector(
    organizationModel.selectors.selectAllUsers,
  );

  const signedUser = allOrganizationDoctors.find(
    (user) => user.UserID === signature.UserID,
  );

  const { FirstName, LastName } = signedUser?.PersonalData ?? {};
  const speciality = signedUser?.JobPositions;

  const isSpecialityExists = !isEmpty(speciality) && speciality !== undefined;
  const isSignatureImageExists = !isEmpty(signature.SignatureAssetID);
  const isStampImageExists = !isEmpty(signature.StampAssetID);
  const isOrganizationStampExists = !isEmpty(currentOrganization?.StampAssetID);

  const signatureImageSrc = getImageSrc(signature.SignatureAssetID, 'original');
  const stampImageSrc = getImageSrc(signature.StampAssetID, 'original');
  const organizationStampImage = getImageSrc(
    currentOrganization?.StampAssetID ?? '',
    'original',
  );

  const signedUserName = getName(FirstName, LastName, { userLocale });

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      <div className={styles.wrapper}>
        {isSignatureImageExists && (
          <div className={styles.imgFrame}>
            <img
              src={signatureImageSrc}
              alt={formatMessage({
                id: 'global.signature',
                defaultMessage: 'Signature',
              })}
              className={cn(
                styles.signatureOrStampImage,
                blackAndWhiteMode && styles.blackAndWhiteMode,
              )}
            />
          </div>
        )}
        <div className={styles.nameAndSpeciality}>
          <h2 className={cn(styles.signedUserName, 'h5')}>{signedUserName}</h2>

          {isSpecialityExists && (
            <p className={cn(styles.speciality, 'p3')}>
              {formatMessage(i18n.specialityType[speciality[0]])}
            </p>
          )}
        </div>
      </div>
      <div className={styles.wrapperStamps}>
        {isStampImageExists && (
          <div className={styles.imgFrame}>
            <img
              src={stampImageSrc}
              alt={formatMessage({
                id: 'global.stamp',
                defaultMessage: 'Stamp',
              })}
              className={cn(
                styles.signatureOrStampImage,
                blackAndWhiteMode && styles.blackAndWhiteMode,
              )}
            />
          </div>
        )}

        {isOrganizationStampExists && (
          <div className={styles.imgFrame}>
            <img
              src={organizationStampImage}
              alt={formatMessage({
                id: 'imgAltText.stamp',
                defaultMessage: 'Stamp',
              })}
              className={cn(
                styles.signatureOrStampImage,
                blackAndWhiteMode && styles.blackAndWhiteMode,
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};
