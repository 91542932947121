import { defineMessages } from 'react-intl';

import { ToolNames } from '@/shared/config';

export const toolsMessages = defineMessages<ToolNames>({
  ruler: {
    id: 'toolName.ruler',
    defaultMessage: 'Ruler',
  },
  arrow: {
    id: 'toolName.arrow',
    defaultMessage: 'Arrow',
  },
  angle: {
    id: 'toolName.angle',
    defaultMessage: 'Angle',
  },
  eraser: {
    id: 'toolName.eraser',
    defaultMessage: 'Eraser',
  },
  view: {
    id: 'toolName.view',
    defaultMessage: 'View',
  },
  sharpening: {
    id: 'toolName.sharpening',
    defaultMessage: 'Sharpening',
  },
  expand: {
    id: 'toolName.expand',
    defaultMessage: 'Expand',
  },
  brightness: {
    id: 'toolName.brightness',
    defaultMessage: 'Brightness / Contrast',
  },
  sharpness: {
    id: 'toolName.sharpness',
    defaultMessage: 'Sharpness',
  },
  reset: {
    id: 'toolName.reset',
    defaultMessage: 'Reset',
  },
  invert: {
    id: 'toolName.invert',
    defaultMessage: 'Invert',
  },
  move: {
    id: 'toolName.move',
    defaultMessage: 'Move',
  },
  zoom: {
    id: 'toolName.zoom',
    defaultMessage: 'Zoom',
  },
  split: {
    id: 'toolName.split',
    defaultMessage: 'Split',
  },
  download: {
    id: 'toolName.download',
    defaultMessage: 'Download',
  },
  editNumbers: {
    id: 'toolName.editNumbers',
    defaultMessage: 'Edit tooth number',
  },
  ios: {
    id: 'toolName.ios',
    defaultMessage: 'Focus view',
  },
  turn: {
    id: 'toolName.rotateImage',
    defaultMessage: 'Rotate image',
  },
  hideMarks: {
    id: 'toolName.hideMarks',
    defaultMessage: 'Hide marks',
  },
});
