export const PATHS = {
  main: '/',
  patients: '/patients',
  patientProfile_DEPRECATED: '/patients/:patientID',
  organizations: '/organizations',
  personalSettings: '/personal-settings',
  companySettings: '/company-settings',
  landingRouter: '/landing-router',

  patient: '/patients/:patientID',
  // NOTE: It's very hard to stabilize 2D rendering when report is already loaded and user goes from the report page to the preview page.
  // Because of that preview page for 2D reports stays separately.
  preview2D: 'preview2d/:reportID', // relative to patient
  report: 'reports/:reportID', // relative to patient
  gp: 'gp', // relative to report
  gpReportAllSlices: 'tooth/:toothID', // relative to gp
  pano: 'pano', // relative to report
  bitewings: 'bitewings', // relative to report
  ioxRay: 'ioxray', // relative to report
  preview: 'preview', // relative to report

  dentalPhotos: '/patients/:patientID/reports/dentalPhotos',

  gpReport_DEPRECATED: '/patients/:patientID/reports/gp/:reportID',

  endodonticReport: '/patients/:patientID/reports/endodontic/:reportID',
  implantReport: '/patients/:patientID/reports/implant/:reportID',
  molarReport: '/patients/:patientID/reports/molar/:reportID',
  segmentationReport: '/patients/:patientID/reports/segmentation/:reportID',
  previewReport: '/patients/:patientID/reports/:reportID/preview',
  STLViewer: '/patients/:patientID/:STLViewer',
  implantStudio: '/patients/:patientID/implantStudio',
  error: '*',
  login: '/login',
  resetPassword: '/reset-password',
  newPassword: '/new-password',
  disclaimer: '/disclaimer',
  signUp: '/sign-up',
  signIn: '/sign-in', // WARN: If you remove / symbol here, do not forget to fix src/lib/redirectToSignIn.ts
  confirmEmail: '/confirm-email',
  confirmSharing: '/confirm-sharing',
  additionalInfo: '/additional-info',
  invite: '/invite',
  finishRegistration: '/finish-registration',
  organization_invite: '/organization_invite/:token',
};
