import { createSelector } from '@reduxjs/toolkit';

import { User } from '@/shared/api/protocol-ts/model/dto_user_pb';
import { getImageSrc, getName } from '@/shared/lib';

import { RootState } from '@/app/model/store';

export const selectCurrentUser = (state: RootState): User => state.user.user;

export const selectCurrentUserID = (state: RootState): string =>
  state.user.user.ID;

export const selectUserOrganizationLegacy = (state: RootState) =>
  state.user.userOrganizationLegacy;

export const selectSettings = (state: RootState) => state.user.settings;

export const selectUserLocale = (state: RootState) => state.user.locale;

export const selectLoading = (state: RootState) => state.user.loading;

export const selectIsUserAdmin = (state: RootState) => state.user.user.IsAdmin;

export const selectUserAvatarData = createSelector(
  selectCurrentUser,
  selectUserLocale,
  (user, userLocale) => {
    const userFullName = getName(
      user?.PersonalData?.FirstName,
      user?.PersonalData?.LastName,
      {
        userLocale,
      },
    );

    const userInitials = getName(
      user?.PersonalData?.FirstName,
      user?.PersonalData?.LastName,
      {
        userLocale,
        shortFirstName: true,
        shortLastName: true,
      },
    );

    const userAvatarAssetSrc = getImageSrc(user?.AvatarAssetID, 'thumbnail');

    return {
      userFullName,
      userInitials,
      userAvatarAssetSrc,
    };
  },
);
