import { useIntl } from 'react-intl';
import cn from 'classnames';

import { Input } from '@/shared/ui';
import { useSearch } from '@/shared/hooks';

import { PatientsFiltersType } from '@/entities/patient';

import { searchBarPlaceholders } from '../../config/i18n';

import styles from './PatientListSearch.module.scss';

type PatientListSearchProps = {
  onInputChange: (value: string) => void;
  currentPatientsFilter: PatientsFiltersType;
  className?: string;
};

export const PatientListSearch = ({
  onInputChange,
  currentPatientsFilter,
  className,
}: PatientListSearchProps) => {
  const { formatMessage } = useIntl();
  const [search, updateSearch] = useSearch(onInputChange);

  return (
    <Input
      className={cn(styles.search, className)}
      inputWrapperClassName={styles.inputWrapper}
      inputClassName={styles.input}
      name="search"
      type="search"
      value={search}
      icon="search"
      inputMode="search"
      autoComplete="off"
      placeholder={formatMessage(searchBarPlaceholders[currentPatientsFilter])}
      onChange={(event) => updateSearch(event.target.value)}
      onResetField={() => updateSearch('')}
    />
  );
};
