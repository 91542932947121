import React, { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Tooth } from '@/shared/api/protocol-ts/model/dto_report_tooth_pb';
import { useAppSelector } from '@/shared/hooks';
import { DEFAULT_MEDICAL_IMAGE_HEIGHT, ToothStatus } from '@/shared/config';
import {
  Report,
  ReportType,
} from '@/shared/api/protocol-ts/model/dto_report_pb';
import { BWIcons, ColoredIcons } from '@/shared/assets/images/ToothStatusIcon';
import {
  ToothCrops,
  SelectedSlicesMIR,
} from '@/shared/graphics/medicalImageRender/MedicalImageRender';

import {
  getDisplayToothNumber,
  isMissingThirdMolarTooth,
} from '@/entities/tooth';
import { ConditionColor } from '@/entities/condition';
import { organizationModel } from '@/entities/organization';
import { composedModel } from '@/entities/composed';
import { conditionText } from '@/entities/condition/config/i18n';

import styles from './PreviewToothCard.module.scss';

type PreviewToothCardProps = {
  tooth: Tooth;
  showProbability: boolean;
  showConditions: boolean;
  showMedicalImages: boolean;
  inverted: boolean;
  blackAndWhiteMode: boolean;
  isDICOM: boolean;
  report?: Report;
};

const colorStyle: Record<ConditionColor, string> = {
  yellow: styles.colorYellow,
  red: styles.colorRed,
  purple: styles.colorPurple,
  white: styles.colorWhite,
};

export const PreviewToothCard: FC<PreviewToothCardProps> = (props) => {
  const {
    tooth,
    showProbability,
    showConditions,
    showMedicalImages,
    blackAndWhiteMode,
    report,
  } = props;

  const { formatMessage } = useIntl();

  const dentalNotationFormat = useAppSelector(
    organizationModel.selectors.selectDentalNotationFormat,
  );

  const reportType = report?.Type;

  const showToothCard = !!tooth.Comment || showConditions || showMedicalImages;

  const conditionItems = useAppSelector(
    composedModel.selectors.selectPreviewCompactToothConditions(tooth.ID),
  );

  const toothStatus = useAppSelector(
    composedModel.selectors.selectToothStatus(tooth.ID, true),
  );

  const isMissingThirdMolar = isMissingThirdMolarTooth(
    toothStatus,
    tooth.Numeration?.ISO ?? 0,
  );

  const shouldShowCroppedImages =
    (reportType === ReportType.ReportType_IOXRay_GP ||
      reportType === ReportType.ReportType_Pano_GP ||
      reportType === ReportType.ReportType_Pano_Bitewings) &&
    showMedicalImages;

  const displayToothNumber = getDisplayToothNumber(
    tooth?.Numeration?.ISO ?? 0,
    dentalNotationFormat,
  ).toString();

  if (!showToothCard) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div
        className={cn(
          styles.header,
          toothStatus === ToothStatus.missing && styles.missingTooth,
        )}
      >
        <div className={styles.toothNumberWrapper}>
          {!isMissingThirdMolar && (
            <img
              className={styles.blackAndWhiteIcon}
              src={
                blackAndWhiteMode
                  ? BWIcons[toothStatus]
                  : ColoredIcons[toothStatus]
              }
              alt={toothStatus}
            />
          )}

          <h4
            className={cn(
              'h4',
              styles.blackAndWhiteToothNumber,
              !blackAndWhiteMode
                ? !isMissingThirdMolar && `${toothStatus}ToothNumber`
                : styles.blackAndWhiteToothNumber,
            )}
          >
            <FormattedMessage
              id="toothCard.title"
              defaultMessage="Tooth {number}"
              values={{
                number: displayToothNumber,
              }}
            />
          </h4>
        </div>

        {showConditions && (
          <div className={styles.toothConditionContainer}>
            {conditionItems.map((item, index) => {
              const selectedChildConditions = item.childConditionInterfaces;

              const showTags = !!selectedChildConditions?.length;

              const isLastCondition = index === conditionItems.length - 1;
              const withChildConditions = selectedChildConditions?.length;

              const childConditionCloseComma = isLastCondition ? `)  ` : `),  `;

              return (
                <React.Fragment key={item.id}>
                  <span
                    className={cn(
                      styles.toothCondition,
                      !blackAndWhiteMode &&
                        !isMissingThirdMolar &&
                        colorStyle[item.color],
                      'p1',
                    )}
                  >
                    <span className={styles.toothConditionText}>
                      {item.code in conditionText &&
                        formatMessage(conditionText[item.code])}

                      {showProbability && (
                        <sup className={styles.percent}>
                          {item.probabilityText}
                        </sup>
                      )}

                      {!isLastCondition && !withChildConditions && ', '}
                    </span>
                  </span>

                  {showTags &&
                    selectedChildConditions.map((tag, tagIndex) => (
                      <span
                        key={tag.id}
                        className={cn(
                          styles.toothCondition,
                          !blackAndWhiteMode &&
                            !isMissingThirdMolar &&
                            colorStyle[tag.color],
                          'p2',
                          styles.childConditions,
                        )}
                      >
                        {tagIndex === 0 ? `  (` : ``}

                        <span className={styles.toothConditionText}>
                          {tag.code in conditionText &&
                            formatMessage(conditionText[tag.code])}
                        </span>

                        {showProbability && (
                          <sup className={styles.percent}>
                            {tag.probabilityText}
                          </sup>
                        )}

                        {tagIndex !== selectedChildConditions.length - 1
                          ? ',  '
                          : childConditionCloseComma}
                      </span>
                    ))}
                </React.Fragment>
              );
            })}
          </div>
        )}
      </div>

      {shouldShowCroppedImages && (
        <ToothCrops
          toothID={tooth.ID}
          className={cn(
            styles.medicalImages,
            blackAndWhiteMode && styles.grayscale,
          )}
          imageAttributes={{
            style: { height: DEFAULT_MEDICAL_IMAGE_HEIGHT + 'px' },
          }}
        />
      )}

      {showMedicalImages && (
        <SelectedSlicesMIR
          toothID={tooth.ID}
          className={cn(
            styles.medicalImages,
            blackAndWhiteMode && styles.grayscale,
          )}
          imageAttributes={{
            style: { height: DEFAULT_MEDICAL_IMAGE_HEIGHT + 'px' },
          }}
        />
      )}

      {tooth?.Comment && (
        <p className={cn(styles.comment, 'p2')}>{tooth.Comment}</p>
      )}
    </div>
  );
};
