import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useMedia } from '@/shared/hooks';
import { Button } from '@/shared/ui';
import companyName from '@/shared/assets/images/Marketing/companyName.png';
import focusOfInterest from '@/shared/assets/images/Marketing/focusOfInterest.png';
import ownerSpecialization from '@/shared/assets/images/Marketing/ownerSpecialization.png';
import { trackPageView } from '@/shared/lib';
import { OrganizationOnboardingQuestionnaire_V1 } from '@/shared/api/protocol-ts/api/marketing/dto_questionnaire_pb';

import { QuestionnaireSteps } from '@/entities/marketing/config/types';

import { CongratsModal } from '@/widgets/CongratsModal';

import { OwnerSpecializationStep } from './ui/OwnerSpecialization/OwnerSpecialization';
import { CompanyNameStep } from './ui/CompanyNameStep/CompanyNameStep';
import { FocusOfInterestStep } from './ui/FocusOfInterestStep/FocusOfInterestStep';
import { additionalInfoFormSchema, AllStepsSchema } from './config/formSchema';
import styles from './AdditionalInformation.module.scss';
import { AdditionalInfoFormPayload } from './config/types';
import { stepImagesAltText } from './config/i18n';
import { useAdditionalInformation } from './hooks/useAdditionalInformation';

const images: Record<QuestionnaireSteps, string> = {
  [QuestionnaireSteps.CompanyName]: companyName,
  [QuestionnaireSteps.FocusOfInterest]: focusOfInterest,
  [QuestionnaireSteps.OwnerSpecialization]: ownerSpecialization,
};

type AdditionalInformationProps = {
  className?: string;
  testID?: string;
};

// TODO: refactor this component
// The idea is to make it simpler by making separate widget for each step since we send data to the backend on each step.
// Move switch by step logic on the page level to control which widget we should render.
// Each widget have form with own validation schema, picture and text.
export const AdditionalInformation: FC<AdditionalInformationProps> = (
  props,
) => {
  const { className, testID } = props;

  const { formatMessage } = useIntl();

  const [step, setStep] = useState<QuestionnaireSteps>(
    QuestionnaireSteps.FocusOfInterest,
  );

  const { isMobile } = useMedia();

  const {
    isQuestionnaireEmpty,
    currentOrganizationName,
    currentStep,
    hasCurrentFocusOfInterestData,
    isFirstStepCompanyName,
    isLastStepFocusOfInterest,
    requiresTextInputFieldValidation,
    stepsDefaultValues,
    userFirstName,
    changeOrganizationName,
    createNewOrganization,
    getOrganizationOnboardingQuestionnaire,
    handleOpenCongratsModal,
    putOrganizationOnboardingQuestionnaire,
  } = useAdditionalInformation(step);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, isValid, isDirty },
  } = useForm<AdditionalInfoFormPayload>({
    // @ts-expect-error WARN: Payload and schema in conflict. Refactor is needed.
    resolver: yupResolver<AllStepsSchema>(additionalInfoFormSchema[step]),
    mode: 'onChange',
  });

  const isTextInputFieldInvalid = !isValid && !isDirty;

  // TODO: [1|m] remove useless memoization
  const currentStepComponent = () => {
    switch (step) {
      case QuestionnaireSteps.CompanyName:
        return <CompanyNameStep control={control} />;
      case QuestionnaireSteps.OwnerSpecialization:
        return <OwnerSpecializationStep control={control} />;
      case QuestionnaireSteps.FocusOfInterest:
        return <FocusOfInterestStep control={control} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    trackPageView('AdditionalInformation Page Viewed');
  }, []);

  // Set default
  useEffect(() => {
    if (hasCurrentFocusOfInterestData) {
      handleOpenCongratsModal();

      return;
    }

    if (isQuestionnaireEmpty) {
      getOrganizationOnboardingQuestionnaire();
    }

    setStep(currentStep);

    reset(stepsDefaultValues);
  }, [currentOrganizationName, isQuestionnaireEmpty]);

  const handleChangeStep = (stepNumber: QuestionnaireSteps) => {
    if (stepNumber < 1) {
      return;
    }
    setStep(stepNumber);
  };

  const nextStep = () => {
    if (step === QuestionnaireSteps.FocusOfInterest) {
      handleOpenCongratsModal();

      return;
    }

    handleChangeStep(step + 1);
  };

  const backStep = () => {
    handleChangeStep(step - 1);
  };

  const onSubmit: SubmitHandler<AdditionalInfoFormPayload> = async (data) => {
    if (isFirstStepCompanyName) {
      const { CompanyName } = data;

      if (
        currentOrganizationName !== undefined &&
        currentOrganizationName !== CompanyName
      ) {
        changeOrganizationName(CompanyName);
      }

      if (currentOrganizationName === undefined) {
        createNewOrganization(CompanyName);
      }
      // For the proper functioning of the form and completion of the registration,
      // the FocusOfInterest should only be submitted on the last step
    } else if (isLastStepFocusOfInterest) {
      const {
        OwnerSpecialization,
        CompanyProfile,
        CBCTMachineType,
        CBCTImagingSoftware,
        XRayImagingSoftware,
        FocusOfInterest,
      } = data;

      putOrganizationOnboardingQuestionnaire({
        // OwnerSpecialization is string
        OwnerSpecialization: OwnerSpecialization.toString(),
        CompanyProfile,
        CBCTMachineType,
        CBCTImagingSoftware,
        XRayImagingSoftware,
        FocusOfInterest,
      } as OrganizationOnboardingQuestionnaire_V1);
    } else {
      const {
        OwnerSpecialization,
        CompanyProfile,
        CBCTMachineType,
        CBCTImagingSoftware,
        XRayImagingSoftware,
      } = data;

      putOrganizationOnboardingQuestionnaire({
        // OwnerSpecialization is string
        OwnerSpecialization: OwnerSpecialization.toString(),
        CompanyProfile,
        CBCTMachineType,
        CBCTImagingSoftware,
        XRayImagingSoftware,
        FocusOfInterest: [] as string[],
      } as OrganizationOnboardingQuestionnaire_V1);
    }

    nextStep();
  };

  return (
    <div
      className={cn(
        isMobile ? styles.mobileContainer : styles.container,
        className,
      )}
      data-testid={testID}
    >
      <div className={cn(isMobile ? styles.mobileWrapper : styles.wrapper)}>
        <div className={styles.header}>
          {isFirstStepCompanyName && (
            <h5 className={cn(styles.greeting, isMobile ? 'p1m' : 'h5')}>
              {formatMessage(
                {
                  id: 'additionalInfo.title',
                  defaultMessage: 'Nice to meet you, {name}!',
                },
                {
                  name: userFirstName,
                },
              )}
            </h5>
          )}

          <h3 className={isMobile ? 'h5' : 'h3'}>
            {step === QuestionnaireSteps.CompanyName &&
              formatMessage({
                id: 'additionalInfo.companyOrPracticeName',
                defaultMessage: 'Company/practice name',
              })}
            {step === QuestionnaireSteps.OwnerSpecialization &&
              formatMessage({
                id: 'additionalInfo.ownerSpecialization',
                defaultMessage: 'Owner specialization',
              })}
            {step === QuestionnaireSteps.FocusOfInterest &&
              formatMessage({
                id: 'additionalInfo.focusOfInterest',
                defaultMessage:
                  'What is your focus of interest for using dental AI?',
              })}
          </h3>
        </div>

        <img
          src={images[step]}
          alt={formatMessage(stepImagesAltText[step])}
          className={styles.stepImage}
        />

        <form
          id="additionalInfo"
          onSubmit={handleSubmit(onSubmit)}
          className={styles.form}
        >
          {currentStepComponent()}
        </form>

        <div
          className={cn(
            styles.actions,
            isFirstStepCompanyName && styles.justifyContentEnd,
          )}
        >
          {!isFirstStepCompanyName && (
            <Button
              icon={!isMobile ? 'arrowDown' : undefined}
              variant="tertiary"
              onClick={backStep}
              className={styles.backButton}
            >
              {formatMessage({
                id: 'global.back',
                defaultMessage: 'Back',
              })}
            </Button>
          )}

          <Button
            variant="primary"
            type="submit"
            form="additionalInfo"
            loading={isSubmitting}
            disabled={
              requiresTextInputFieldValidation
                ? isTextInputFieldInvalid
                : !isValid
            }
          >
            <FormattedMessage id="global.next" defaultMessage="Next" />
          </Button>
        </div>
      </div>

      <CongratsModal />
    </div>
  );
};
