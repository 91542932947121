import { FC, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { FileWithPath } from 'react-dropzone';

import {
  Avatar,
  Button,
  Column,
  Layout,
  WidgetCard,
  WidgetLayout,
} from '@/shared/ui/';
import { useAppDispatch, useAppSelector, useMedia } from '@/shared/hooks';
import {
  getImageSrc,
  getName,
  trackPageView,
  validateImageFile,
} from '@/shared/lib';
import { AssetType } from '@/shared/api/protocol-ts/model/dto_asset_pb';

import { userModel } from '@/entities/user';
import { ModalID, modalModel } from '@/entities/modal';
import { organizationModel } from '@/entities/organization';

import { useUploadAssetContext } from '@/features/uploadAsset';
import { Footer } from '@/features/footer';
import { UploadImage } from '@/features/uploadImage';
import { RemoveAssetModal, useRemoveAssetModal } from '@/features/removeAsset';

import { Header } from '@/widgets/Header';
import { EditInfoModal } from '@/widgets/EditInfoModal';
import { GetFullAccessModal } from '@/widgets/GetFullAccessModal';
import { AboutLabelingModal } from '@/widgets/AboutLabelingModal';

import styles from './PersonalSettings.module.scss';

type PersonalSettingsProps = {
  className?: string;
};

export const PersonalSettings: FC<PersonalSettingsProps> = (props) => {
  const { className } = props;

  const { isPhone } = useMedia();
  const { formatMessage } = useIntl();

  const user = useAppSelector(userModel.selectors.selectCurrentUser);
  const userLocale = useAppSelector(userModel.selectors.selectUserLocale);

  const organization = useAppSelector(
    organizationModel.selectors.selectCurrentOrganization,
  );

  const organizationUser = organization?.UserRoles.find(
    (item) => item.UserID === user.ID,
  );

  const userSignature = getImageSrc(user.SignatureAssetID, 'preview');
  const userStamp = getImageSrc(
    organizationUser?.StampAssetID ?? '',
    'preview',
  );
  const userAvatarSrc = getImageSrc(user?.AvatarAssetID, 'thumbnail');

  const userName = getName(
    user?.PersonalData?.FirstName,
    user?.PersonalData?.LastName,
    {
      userLocale,
    },
  );
  const userInitials = getName(
    user?.PersonalData?.FirstName,
    user?.PersonalData?.LastName,
    {
      userLocale,
      shortFirstName: true,
      shortLastName: true,
    },
  );

  const [stampLoading, setStampLoading] = useState(false);
  const [signatureLoading, setSignatureLoading] = useState(false);

  const [signatureUploadError, setSignatureUploadError] = useState('');
  const [stampUploadError, setStampUploadError] = useState('');

  const dispatch = useAppDispatch();

  const {
    isRemoveAssetModalOpen,
    openRemoveAssetModal,
    closeRemoveAssetModal,
    currentAssetID,
  } = useRemoveAssetModal();

  const handleOpenModal = useCallback(() => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.EditInfoModal,
      }),
    );
  }, [dispatch]);

  const { startUploadAsset: startUploadImage } = useUploadAssetContext();

  const handleDelete = async (assetID: string) => {
    openRemoveAssetModal(assetID);
    setStampUploadError('');
    setSignatureUploadError('');
  };

  const handelChange = async (image: FileWithPath, assetType: AssetType) => {
    if (assetType === AssetType.AssetType_User_Signature) {
      const isImageInvalid = validateImageFile(
        image,
        (message) => setSignatureUploadError(message),
        formatMessage,
      );

      if (isImageInvalid) return;

      setSignatureLoading(true);
    } else {
      const isImageInvalid = validateImageFile(
        image,
        (message) => setStampUploadError(message),
        formatMessage,
      );

      if (isImageInvalid) return;

      setStampLoading(true);
    }

    try {
      if (image) {
        await startUploadImage(
          {
            files: [image],
            userID: user?.ID,
            assetType,
            meta: {
              studyType: assetType,
              patientName: user?.PersonalData?.FirstName,
            },
          },
          true,
        );
      }
    } catch (error) {
      const errorMessage = formatMessage({
        id: 'uploadImage.serverError',
        defaultMessage:
          'Server error: Image upload failed due to server error. Please retry later',
      });
      if (assetType === AssetType.AssetType_User_Signature) {
        setSignatureUploadError(errorMessage);
      } else {
        setStampUploadError(errorMessage);
      }
    } finally {
      setSignatureLoading(false);
      setStampLoading(false);
    }
  };

  useEffect(() => {
    trackPageView('PersonalSettings Page Viewed');
  }, []);

  return (
    <Layout>
      <Layout.Header>
        <Header back />
      </Layout.Header>

      <Layout.Content>
        <Layout.Main>
          <div className={cn(styles.container, className)}>
            <h1 className={cn('h3', styles.patientName)}>
              <FormattedMessage
                id="settings.myPersonalSettings"
                defaultMessage="My Personal Settings"
              />
            </h1>
          </div>

          <WidgetLayout className={styles.columnsContainer}>
            <Column>
              <WidgetCard className={styles.widgetCard}>
                <div className={styles.accountInfo}>
                  <h4 className={isPhone ? 'h5' : 'h4'}>
                    <FormattedMessage
                      id="settings.accountInfo"
                      defaultMessage="Account info"
                    />
                  </h4>

                  <Button
                    onClick={handleOpenModal}
                    size="medium"
                    variant="tertiary"
                  >
                    <FormattedMessage id="global.edit" defaultMessage="Edit" />
                  </Button>
                </div>

                <div className={styles.personalInfo}>
                  <div className={styles.row}>
                    <p className={cn(styles.subTitle, 'p2')}>
                      <FormattedMessage
                        id="settings.photo"
                        defaultMessage="Photo"
                      />
                    </p>

                    <Avatar
                      src={userAvatarSrc}
                      alt={userName}
                      initials={userInitials}
                    />
                  </div>

                  <div className={styles.row}>
                    <p className={cn(styles.subTitle, 'p2')}>
                      <FormattedMessage
                        id="settings.firstName"
                        defaultMessage="First name"
                      />
                    </p>

                    <p className="p2">{user?.PersonalData?.FirstName}</p>
                  </div>

                  <div className={styles.row}>
                    <p className={cn(styles.subTitle, 'p2')}>
                      <FormattedMessage
                        id="settings.lastName"
                        defaultMessage="Last Name"
                      />
                    </p>

                    <p className="p2">{user?.PersonalData?.LastName}</p>
                  </div>

                  <div className={styles.row}>
                    <p className={cn(styles.subTitle, 'p2')}>
                      <FormattedMessage
                        id="settings.email"
                        defaultMessage="Email"
                      />
                    </p>

                    <p className="p2">{user?.PersonalData?.Emails[0]}</p>
                  </div>
                </div>
              </WidgetCard>
            </Column>
            <Column>
              <WidgetCard className={styles.stampWidget}>
                <h4 className={isPhone ? 'h5' : 'h4'}>
                  <FormattedMessage
                    id="settings.signatureSndStamp"
                    defaultMessage="Signature and stamp"
                  />
                </h4>

                <div className={styles.stampAndSignature}>
                  <UploadImage
                    onChange={(image) =>
                      handelChange(image, AssetType.AssetType_User_Signature)
                    }
                    previewImage={user?.SignatureAssetID && userSignature}
                    onRemove={handleDelete}
                    imageAssetID={user?.SignatureAssetID}
                    error={signatureUploadError}
                    loading={signatureLoading}
                    title={
                      <FormattedMessage
                        id="settings.personalSignature"
                        defaultMessage="Personal signature"
                      />
                    }
                    dropDescription={
                      <FormattedMessage
                        id="global.dragDropPhotos"
                        defaultMessage="Drag image or add from your computer"
                      />
                    }
                  />

                  <UploadImage
                    onChange={(image) =>
                      handelChange(
                        image,
                        AssetType.AssetType_Organization_EmployeeStamp,
                      )
                    }
                    onRemove={handleDelete}
                    imageAssetID={organizationUser?.StampAssetID}
                    previewImage={organizationUser?.StampAssetID && userStamp}
                    error={stampUploadError}
                    loading={stampLoading}
                    title={
                      <FormattedMessage
                        id="settings.doctorStamp"
                        defaultMessage="Doctor stamp"
                      />
                    }
                    dropDescription={
                      <FormattedMessage
                        id="global.dragDropPhotos"
                        defaultMessage="Drag image or add from your computer"
                      />
                    }
                  />
                </div>
              </WidgetCard>
            </Column>
          </WidgetLayout>

          <EditInfoModal userID={user?.ID} />

          <GetFullAccessModal />

          <RemoveAssetModal
            assetID={currentAssetID}
            isOpen={isRemoveAssetModalOpen}
            onCancel={closeRemoveAssetModal}
          />

          <AboutLabelingModal />
        </Layout.Main>
      </Layout.Content>

      <Layout.Footer>
        <Footer />
      </Layout.Footer>
    </Layout>
  );
};
