import { Icon } from '@/shared/ui';
import { CLUSTER, STUDY_PORTAL_LINK } from '@/shared/config';

import { ICON_SIZE, MOBILE_ICON_SIZE } from '../../config/constants';

import styles from './LearningHub.module.scss';

type LearningHubProps = {
  isMobile?: boolean;
};

export const LearningHub = (props: LearningHubProps) =>
  CLUSTER !== 'ru' && (
    <a
      className={styles.studyIcon}
      href={STUDY_PORTAL_LINK}
      referrerPolicy="no-referrer"
      target="_blank"
    >
      <Icon name="study" size={props.isMobile ? MOBILE_ICON_SIZE : ICON_SIZE} />
    </a>
  );
