import { FC } from 'react';
import cn from 'classnames';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { scrollToElementByID } from '@/shared/lib';

import { toothModel } from '@/entities/tooth';
import { FMXNavigationMode, reportsModel } from '@/entities/reports';
import {
  IOXRayImagesInterfaceModel,
  IOXrayImageInterface,
  groupIOXRayImagesByPartition,
} from '@/entities/IOXRayImagesMatrix';

import { RenderViewSlider } from '@/features/renderViewSlider';

import styles from './PanowingImageSlider.module.scss';

type PanowingImageSliderProps = {
  className?: string;
};

export const PanowingImageSlider: FC<PanowingImageSliderProps> = (props) => {
  const { className } = props;
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentReport = useAppSelector(
    reportsModel.selectors.selectCurrentReport,
  );

  const navigationMode = useAppSelector(
    reportsModel.selectors.selectNavigationMode,
  );

  const reportID = currentReport?.ID as string;

  const IOXRayImagesInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectIOXRayImagesInterfaceByReportID(
      reportID,
    ),
  );

  const panoImageInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectPanoImageInterfaceByReportID(
      reportID,
    ),
  ) as IOXrayImageInterface;

  const groupedIOXRayImages = groupIOXRayImagesByPartition(
    IOXRayImagesInterface as IOXrayImageInterface[],
  );

  const bitewingsImagesInterface = [
    ...groupedIOXRayImages['MiddleLeft'],
    ...groupedIOXRayImages['MiddleRight'],
  ];

  const bitewingsImageMetaIDs = bitewingsImagesInterface.map(
    (imageItem) =>
      imageItem.imageMeta?.GeneratedAssetID ||
      imageItem?.imageMeta?.StudyAssetID,
  );

  const panoImageMetaID = panoImageInterface
    ? panoImageInterface.imageMeta?.GeneratedAssetID ||
      panoImageInterface?.imageMeta?.StudyAssetID
    : '';

  const resultImageMetaIDs = panoImageInterface
    ? [panoImageMetaID, ...bitewingsImageMetaIDs]
    : [];

  const focusedImageMetaID = useAppSelector(
    reportsModel.selectors.selectFocusedImageMetaID,
  );

  const teethIDs = useAppSelector(
    toothModel.selectors.selectTeethIDsToShow(reportID as string),
  );
  const activeToothID = useAppSelector(
    reportsModel.selectors.selectActiveToothID,
  );

  // select data by navigation mode
  const isMatrixMode = navigationMode === FMXNavigationMode.MatrixView;
  const itemIDs = isMatrixMode ? resultImageMetaIDs : teethIDs;
  const activeItemID = isMatrixMode ? focusedImageMetaID : activeToothID;

  const updateActiveToothID = (newItemID: string) => {
    dispatch(reportsModel.actions.setToolbarActiveControl('view'));

    if (isMatrixMode) {
      dispatch(reportsModel.actions.setFocusedMetaImageID(newItemID));

      // Update URL with new activeItemID only for bitewings images
      if (newItemID && newItemID !== panoImageMetaID) {
        searchParams.set('activeItemID', newItemID);
      } else {
        searchParams.delete('activeItemID');
      }
      setSearchParams(searchParams);
    } else {
      dispatch(reportsModel.actions.setActiveToothID(newItemID));

      // NOTE: It needs to properly scroll to the tooth card which appears in the DOM later than this function executes if it's just a sync call.
      setTimeout(() => scrollToElementByID(newItemID, 'smooth', 'start'), 0);

      if (newItemID === '') {
        dispatch(
          reportsModel.actions.setNavigationMode(FMXNavigationMode.MatrixView),
        );
        dispatch(reportsModel.actions.setFocusedMetaImageID(newItemID));

        // Remove activeItemID parameter from URL when selection is reset
        searchParams.delete('activeItemID');
        setSearchParams(searchParams);
      }
    }
  };

  return (
    <RenderViewSlider
      updateActiveItem={updateActiveToothID}
      itemIDs={itemIDs as string[]}
      activeItemID={activeItemID}
      className={cn(styles.container, className)}
    />
  );
};
