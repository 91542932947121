import { isEmpty } from 'ramda';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { PATHS } from '@/shared/config';
import { OrganizationOnboardingQuestionnaire_V1 } from '@/shared/api/protocol-ts/api/marketing/dto_questionnaire_pb';
import { OrganizationType } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { CreateOrganizationReq } from '@/shared/api/protocol-ts/api/core/svc_organization_pb';

import { userModel } from '@/entities/user';
import { organizationModel } from '@/entities/organization';
import { marketingModel } from '@/entities/marketing';
import { QuestionnaireSteps } from '@/entities/marketing/config/types';
import { ModalID, modalModel } from '@/entities/modal';

export const useAdditionalInformation = (step: QuestionnaireSteps) => {
  const { state } = useLocation();

  const dispatch = useAppDispatch();

  const user = useAppSelector(userModel.selectors.selectCurrentUser);

  const questionnaire = useAppSelector(
    marketingModel.selectors.selectQuestionnaire,
  );

  const currentOrganization = useAppSelector(
    organizationModel.selectors.selectCurrentOrganization,
  );

  // type casting is necessary for typification location.state
  const organizationPhoneNumber = (state as { phoneNumber: string })
    ?.phoneNumber;

  const userFirstName = user.PersonalData?.FirstName;

  const currentOrganizationID = currentOrganization?.ID ?? '';

  const currentOrganizationName = currentOrganization?.Name;

  const isQuestionnaireEmpty = !questionnaire.OwnerSpecialization;

  const currentOwnerSpecialization = questionnaire.OwnerSpecialization;

  const currentFocusOfInterest = questionnaire.FocusOfInterest;

  const isFirstStepCompanyName = QuestionnaireSteps.CompanyName === step;

  const isLastStepFocusOfInterest = QuestionnaireSteps.FocusOfInterest === step;

  const requiresTextInputFieldValidation =
    isFirstStepCompanyName || QuestionnaireSteps.OwnerSpecialization === step;

  const hasCurrentFocusOfInterestData = !isEmpty(currentFocusOfInterest);

  const stepsDefaultValues = useMemo(
    () => ({
      CompanyName: currentOrganizationName,
      OwnerSpecialization: currentOwnerSpecialization,
      FocusOfInterest: currentFocusOfInterest,
    }),
    [questionnaire, currentOrganizationName],
  );

  const currentStep = useMemo(() => {
    if (step !== QuestionnaireSteps.CompanyName) {
      return step;
    }

    if (
      currentOrganizationName !== undefined &&
      currentOrganizationName !== ''
    ) {
      return QuestionnaireSteps.OwnerSpecialization;
    }

    return QuestionnaireSteps.CompanyName;
  }, [questionnaire, currentOrganizationName, step]);

  const handleOpenCongratsModal = useCallback(() => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.CongratsModal,
        data: { redirectTo: PATHS.main },
      }),
    );
  }, [dispatch]);

  const updateQuestionnaire = useCallback(
    (newQuestionnaire: OrganizationOnboardingQuestionnaire_V1) => {
      dispatch(marketingModel.actions.updateQuestionnaire(newQuestionnaire));
    },
    [dispatch],
  );

  const getOrganizationOnboardingQuestionnaire = async () => {
    if (currentOrganizationID) {
      const { Questionnaire } = await dispatch(
        marketingModel.thunks.getOrganizationOnboardingQuestionnaire({
          OrganizationID: currentOrganizationID,
        }),
      ).unwrap();

      if (Questionnaire) {
        updateQuestionnaire(Questionnaire);
      }
    }
  };

  const putOrganizationOnboardingQuestionnaire = async (
    newQuestionnaire: OrganizationOnboardingQuestionnaire_V1,
  ) => {
    if (currentOrganizationID) {
      const { Questionnaire } = await dispatch(
        marketingModel.thunks.putOrganizationOnboardingQuestionnaire({
          OrganizationID: currentOrganizationID,
          Questionnaire: newQuestionnaire,
        }),
      ).unwrap();

      if (Questionnaire) {
        updateQuestionnaire(Questionnaire);
      }
    }
  };

  const createNewOrganization = async (companyName: string) => {
    const { Organization } = await dispatch(
      organizationModel.thunks.createOrganization({
        Name: companyName,
        Type: OrganizationType.OrganizationTypeClinic,
        Contacts: organizationPhoneNumber
          ? { Phones: [organizationPhoneNumber] }
          : undefined,
      } as CreateOrganizationReq),
    ).unwrap();

    if (Organization) {
      dispatch(organizationModel.actions.addOne(Organization));
    }
  };

  const changeOrganizationName = async (companyName: string) => {
    const { Organization } = await dispatch(
      organizationModel.thunks.setOrganizationName({
        Name: companyName,
        OrganizationID: currentOrganizationID,
      }),
    ).unwrap();

    if (Organization) {
      dispatch(organizationModel.actions.setOne(Organization));
    }
  };

  return {
    isQuestionnaireEmpty,
    currentOrganizationName,
    currentStep,
    hasCurrentFocusOfInterestData,
    isFirstStepCompanyName,
    isLastStepFocusOfInterest,
    requiresTextInputFieldValidation,
    stepsDefaultValues,
    userFirstName,
    changeOrganizationName,
    createNewOrganization,
    getOrganizationOnboardingQuestionnaire,
    handleOpenCongratsModal,
    putOrganizationOnboardingQuestionnaire,
  };
};
