import { createAsyncThunk, DeepPartial } from '@reduxjs/toolkit';
import { CallOptions, ConnectError } from '@bufbuild/connect';

import { SliceName } from '../config';

import { delay } from './delay';
import { debug } from './debug';
import { isConnectNetworkError } from './isConnectNetworkError';

type ThunkRequestFunction<Request, Response> = (
  request: Request,
  options?: CallOptions,
) => Promise<Response>;

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second delay between retries

async function fetchWithRetry<Request, Response>(
  requestFunc: ThunkRequestFunction<Request, Response>,
  request: Request,
  retries = MAX_RETRIES,
) {
  try {
    return await requestFunc(request);
  } catch (error) {
    const connectError = ConnectError.from(error);
    // NOTE: Retry only network errors
    if (retries >= 0 && isConnectNetworkError(connectError)) {
      debug(
        `Retrying request... Attempt ${MAX_RETRIES - retries} of ${MAX_RETRIES}`,
      );
      await delay(RETRY_DELAY * (MAX_RETRIES - retries + 1)); // Exponential backoff
      return fetchWithRetry(requestFunc, request, retries - 1);
    }
    throw error; // Throw error after max retries
  }
}

export const createThunkGenerator =
  <RequestsNames>(sliceName: SliceName) =>
  <Request, Response>(
    requestName: RequestsNames,
    requestFunction: ThunkRequestFunction<Request, Response>,
  ) =>
    createAsyncThunk(
      `${sliceName}/${requestName}`,
      async (request: DeepPartial<Request>, { rejectWithValue }) => {
        try {
          const response = await fetchWithRetry(
            requestFunction,
            request as Request,
          );
          return response;
        } catch (error) {
          const connectErr = ConnectError.from(error);

          return rejectWithValue(connectErr);
        }
      },
    );
