import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Avatar, Button, Modal } from '@/shared/ui';
import { PATHS } from '@/shared/config';

import { ModalID, modalModel } from '@/entities/modal';
import { userModel } from '@/entities/user';
import { organizationModel } from '@/entities/organization';

import { useLogout } from '../../hooks';
import { i18n } from '../../config';
import { ClinicRow } from '../ClinicRow/ClinicRow';

import styles from './HeaderDrawer.module.scss';

export const HeaderDrawer = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { visible } = useAppSelector(modalModel.selectors.selectHeaderDrawer);
  const { userInitials, userFullName, userAvatarAssetSrc } = useAppSelector(
    userModel.selectors.selectUserAvatarData,
  );
  const { id, name, initials, logoSrc } = useAppSelector(
    organizationModel.selectors.selectCurrentOrganizationAvatarData,
  );
  const otherOrganizationsAvatarData = useAppSelector(
    organizationModel.selectors.selectOtherOrganizationsAvatarData,
  );
  const logout = useLogout();

  const handleClose = () => {
    dispatch(modalModel.actions.closeModal(ModalID.HeaderDrawer));
  };

  const navigateToCompanySettings = () => {
    navigate({
      pathname: PATHS.companySettings,
      search: 'tab=CompanyInfo',
    });
    handleClose();
  };

  const changeOrganization = (id: string) => {
    dispatch(organizationModel.thunks.setCurrentOrganizationByID(id));
  };

  return (
    <Modal
      title="Drawer"
      isOpen={visible}
      onCancel={handleClose}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      headerContent={
        <>
          <Link
            className={cn(styles.myProfileLink, 'p2')}
            to={PATHS.personalSettings}
            onClick={handleClose}
          >
            <Avatar
              src={userAvatarAssetSrc}
              alt={userFullName}
              initials={userInitials}
              size={62}
            />
            <div className={styles.myProfileInfo}>
              <h3 className={cn(styles.textEllipsis, 'h3m')}>{userFullName}</h3>
              <p className={cn(styles.myProfile, 'p2')}>
                {formatMessage(i18n.myProfile)}
              </p>
            </div>
          </Link>
        </>
      }
      footer={
        <Button size="medium" variant="secondary" onClick={logout}>
          {formatMessage({ id: 'global.signOut', defaultMessage: 'Sign out' })}
        </Button>
      }
    >
      <div className={styles.content}>
        {id && (
          <div className={styles.contentSection}>
            <h3 className={cn(styles.drawerTitle, 'h3m')}>
              {formatMessage(i18n.currentClinic)}
            </h3>
            <ClinicRow
              id={id}
              organizationName={name}
              initials={initials}
              avatarSrc={logoSrc}
              isCurrent
              onClick={navigateToCompanySettings}
            />
          </div>
        )}
        {otherOrganizationsAvatarData.length > 0 && (
          <div className={styles.contentSection}>
            <h3 className={cn(styles.drawerTitle, 'h3m')}>
              {formatMessage(i18n.otherClinics)}
            </h3>
            {otherOrganizationsAvatarData.map((organization) => (
              <ClinicRow
                key={organization.id}
                id={organization.id}
                organizationName={organization.name}
                initials={organization.initials}
                avatarSrc={organization.logoSrc}
                onClick={changeOrganization}
              />
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};
