import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';

import { Icon, Tooltip } from '@/shared/ui';
import { useAppSelector, useMedia } from '@/shared/hooks';
import { gender } from '@/shared/i18n';
import { getName } from '@/shared/lib';
import { PATHS } from '@/shared/config';

import { userModel } from '@/entities/user';
import { patientModel, useFormatPatientDateOfBirth } from '@/entities/patient';

import styles from './PatientPersonalInfo.module.scss';

interface PatientPersonalInfoProps {
  canEditPatient: boolean;
  onEditPatient: () => void;
}

export const PatientPersonalInfo: FC<PatientPersonalInfoProps> = ({
  canEditPatient,
  onEditPatient,
}) => {
  const { patientID = '' } = useParams();
  const { formatMessage } = useIntl();
  const { isMobile } = useMedia();

  const userLocale = useAppSelector(userModel.selectors.selectUserLocale);

  const patient = useAppSelector(
    patientModel.selectors.selectPatientByID(patientID),
  );

  const patientDateOfBirth = patient?.PersonalData?.DateOfBirth;

  const formattedPatientAge = useFormatPatientDateOfBirth({
    patientDateOfBirth,
    pluralized: true,
  });

  const patientGender = patient?.Gender
    ? formatMessage(gender[patient.Gender])
    : '';

  const patientExternalID = `${formatMessage({
    id: 'patientInfo.externalID',
    defaultMessage: 'ID',
  })}: ${patient?.ExternalID}`;

  const patientEmail = patient?.PersonalData?.Emails[0];
  const showEditButton = canEditPatient && !isMobile;
  const showMobileEditButton = canEditPatient && isMobile;

  return (
    <div className={styles.patientInfo}>
      <div className={styles.patientTitleWrapper}>
        {isMobile && (
          <Link to={PATHS.patients} className={styles.backLink}>
            <Icon name="arrowDown" size={32} />
          </Link>
        )}
        <h1 className={cn(styles.patientName, isMobile ? 'h1m' : 'h3')}>
          {getName(
            patient?.PersonalData?.FirstName,
            patient?.PersonalData?.LastName,
            { userLocale },
          )}
        </h1>
      </div>

      <div className={cn(styles.patientInfoDetails, isMobile ? 'p1m' : 'p2')}>
        <div className={styles.patientInfoDetailsWrapper}>
          <p>{formattedPatientAge}</p>
          <p>{patientGender}</p>
          {patient?.ExternalID ? <p>{patientExternalID}</p> : null}
        </div>
        {patientEmail && !isMobile && (
          <p className={styles.patientEmail}>{patientEmail} </p>
        )}
        {showEditButton && (
          <Tooltip.Primary
            content={
              <FormattedMessage id="global.edit" defaultMessage="Edit" />
            }
          >
            <Icon
              name="pen"
              size={24}
              onClick={onEditPatient}
              className={styles.penIcon}
            />
          </Tooltip.Primary>
        )}
        {showMobileEditButton && (
          <button
            type="button"
            onClick={onEditPatient}
            className={styles.editButton}
          >
            <Icon name="pen" size={24} className={styles.penIcon} />
          </button>
        )}
      </div>
    </div>
  );
};
