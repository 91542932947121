import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { Icon, Tooltip } from '@/shared/ui';

import { patientListRowMessages } from '../../config/i18n';
import { CountsIconNames } from '../../config';

import styles from './StudyCounter.module.scss';

type StudyCounterVariants = 'row' | 'card';

type StudyCounterProps = {
  name: CountsIconNames;
  count: number;
  variant?: StudyCounterVariants;
  className?: string;
  iconSize?: number;
};

export const StudyCounter: FC<StudyCounterProps> = (props) => {
  const { name, count, className, variant = 'row', iconSize } = props;

  const { formatMessage } = useIntl();

  const docSize = variant === 'row' ? 32 : 24;
  const otherSize = variant === 'row' ? 40 : 32;
  const size = name === 'doc' ? docSize : otherSize;

  return (
    <Tooltip.Primary
      content={
        <span className="p3">
          {`${formatMessage(patientListRowMessages[name])} ${count || ''}`}
        </span>
      }
      side="top"
    >
      <Icon
        className={cn(
          styles.icon,
          name === 'ios' && styles.d3icon,
          name === 'doc' && styles.smallIcon,
          // TODO: add active logic with back-end, to provide count
          count && styles.active,
          variant === 'card' && styles.cardIcon,
          className,
        )}
        name={name}
        size={iconSize ?? size}
      />
    </Tooltip.Primary>
  );
};
