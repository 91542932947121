// Types related to CBCT GP ("gp") pipelines method

// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file pipelines/report_cbct_gp.proto (package com.diagnocat.pipelines, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { ToothDetection } from "./report_tooth_detection_pb.js";
import { Condition } from "../model/dto_report_condition_pb.js";
import { Conditions_Update } from "./report_condition_pb.js";
import { CBCTSeriesGeometryData } from "../model/dto_common_geometry_pb.js";
import { Report_CBCT_GP_OrthoScreening } from "../model/dto_report_type_cbct_gp_pb.js";
import { TeethLandmarks_Update } from "./report_landmarks_pb.js";
import { MaxFaxAnatomyLocalizations_Update } from "./report_maxfax_anatomy_localization_pb.js";
import { TeethPeriodontalStatuses_Update } from "./report_periodontal_status_pb.js";
import { TeethAnatomyLocalizations_Update } from "./report_tooth_anatomy_localization_pb.js";
import { Report_Studio_ToothPosition } from "../model/dto_report_type_studio_common_pb.js";
import { Localization } from "../model/dto_report_localization_pb.js";

/**
 * @generated from message com.diagnocat.pipelines.CBCT_GP_Update
 */
export const CBCT_GP_Update = proto3.makeMessageType(
  "com.diagnocat.pipelines.CBCT_GP_Update",
  () => [
    { no: 1, name: "ToothDetection", kind: "message", T: ToothDetection, oneof: "Update" },
    { no: 2, name: "Condition", kind: "message", T: Condition, oneof: "Update" },
    { no: 3, name: "Conditions", kind: "message", T: Conditions_Update, oneof: "Update" },
    { no: 4, name: "CropGeometryData", kind: "message", T: CBCTSeriesGeometryData, oneof: "Update" },
    { no: 5, name: "OrthoScreening", kind: "message", T: Report_CBCT_GP_OrthoScreening, oneof: "Update" },
    { no: 6, name: "ToothPathologyLocalization", kind: "message", T: CBCT_GP_Update_ToothPathologySliceLocalization, oneof: "Update" },
    { no: 7, name: "TeethLandmarks", kind: "message", T: TeethLandmarks_Update, oneof: "Update" },
    { no: 8, name: "MaxFaxAnatomyLocalizations", kind: "message", T: MaxFaxAnatomyLocalizations_Update, oneof: "Update" },
    { no: 9, name: "TeethPeriodontalStatuses", kind: "message", T: TeethPeriodontalStatuses_Update, oneof: "Update" },
    { no: 10, name: "TeethAnatomyLocalizations", kind: "message", T: TeethAnatomyLocalizations_Update, oneof: "Update" },
    { no: 11, name: "ToothPosition", kind: "message", T: Report_Studio_ToothPosition, oneof: "Update" },
  ],
);

/**
 * Pathology slices are prepared after main diagnostic models' inference. On backend side,
 * these localizations will be plugged into the main diagnostic models' localizations by `ConditionID`.
 *
 * @generated from message com.diagnocat.pipelines.CBCT_GP_Update.ToothPathologySliceLocalization
 */
export const CBCT_GP_Update_ToothPathologySliceLocalization = proto3.makeMessageType(
  "com.diagnocat.pipelines.CBCT_GP_Update.ToothPathologySliceLocalization",
  () => [
    { no: 1, name: "ConditionID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Localization", kind: "message", T: Localization },
  ],
  {localName: "CBCT_GP_Update_ToothPathologySliceLocalization"},
);

