import { Annotation } from '@/shared/graphics/RenderComponents/AnnotationsLayer/Annotation';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { MedicalImageViewOptions } from '@/shared/api/protocol-ts/model/dto_common_image_view_options_pb';

import { useCheckReportSignature } from '@/entities/reports';
// TODO: fix imports for fsd
import * as toothModel from '@/entities/tooth/model';
import * as reportsModel from '@/entities/reports/model';
import { assetsModel, formatAnnotations } from '@/entities/assets';

export const useImagePropsSetters = (assetID: string, toothID?: string) => {
  const { checkReportSignature } = useCheckReportSignature();

  const dispatch = useAppDispatch();

  const tooth = useAppSelector((state) =>
    toothModel.selectors.selectById(state, toothID ?? ''),
  );
  const isReportSigned = useAppSelector(
    reportsModel.selectors.selectCurrentReportSigned,
  );

  const handleSetAnnotations = async (changedAnnotations: Annotation[]) => {
    checkReportSignature({
      toothID,
      onSignatureChecked: async () => {
        // NOTE: In the checkReportSignature function, tooth disapproval only occurs when the report is signed.
        // However, in handleSetAnnotations, the tooth gets disapproved regardless of report signature status.
        // This, combined with the closure of the tooth?.IsApproved check in the case of a signed report,
        // leads to the tooth being disapproved twice.
        // Therefore, we need to add a !isReportSigned check to prevent this double disapproval.
        if (tooth?.IsApproved && !isReportSigned) {
          const { Tooth } = await dispatch(
            reportsModel.thunks.setReportToothDisapproved({
              ToothID: tooth.ID,
            }),
          ).unwrap();

          if (Tooth) {
            dispatch(toothModel.actions.setNewestOne(Tooth));
          }
        }

        const { Asset } = await dispatch(
          assetsModel.thunks.setTopLayerAnnotations({
            AssetID: assetID,
            Annotations: formatAnnotations(changedAnnotations),
          }),
        ).unwrap();

        if (Asset) {
          dispatch(assetsModel.actions.setNewestOne(Asset));
        }
      },
      onCancel: () => {},
    });
  };

  const handleSetMedicalImageViewOptions = async (
    viewOption: MedicalImageViewOptions,
  ) => {
    const { Asset } = await dispatch(
      assetsModel.thunks.setMedicalImageViewOptions({
        AssetID: assetID,
        MedicalImageViewOptions: viewOption,
      }),
    ).unwrap();

    if (Asset) {
      dispatch(assetsModel.actions.setNewestOne(Asset));
    }
  };

  const handleSetReportMedicalImageViewOptions = async (
    viewOption: Partial<MedicalImageViewOptions>,
    reportID: string,
  ) => {
    const { Report } = await dispatch(
      reportsModel.thunks.setReportMedicalImageViewOptions({
        ReportID: reportID,
        MedicalImageViewOptions: viewOption as MedicalImageViewOptions,
      }),
    ).unwrap();

    if (Report) {
      dispatch(reportsModel.actions.setNewestOne(Report));
    }
  };

  return {
    handleSetAnnotations,
    handleSetMedicalImageViewOptions,
    handleSetReportMedicalImageViewOptions,
  };
};
