// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_type_2D_common.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { FloatTuple2D } from "./dto_common_geometry_pb.js";
import { MedicalImageFeatures } from "./dto_common_image_medical_features_pb.js";

/**
 * @generated from enum com.diagnocat.model.Report_2D_ImageType
 */
export const Report_2D_ImageType = proto3.makeEnum(
  "com.diagnocat.model.Report_2D_ImageType",
  [
    {no: 0, name: "Report_2D_ImageType_InvalidValue"},
    {no: 1, name: "Report_2D_ImageType_IntraoralXRay"},
    {no: 2, name: "Report_2D_ImageType_DentalPhoto"},
    {no: 3, name: "Report_2D_ImageType_PanoramicXRay"},
    {no: 4, name: "Report_2D_ImageType_CBCTSlice"},
  ],
);

/**
 * @generated from enum com.diagnocat.model.IOXRay_Anatomy
 */
export const IOXRay_Anatomy = proto3.makeEnum(
  "com.diagnocat.model.IOXRay_Anatomy",
  [
    {no: 0, name: "IOXRay_Anatomy_InvalidValue"},
    {no: 1, name: "IOXRay_Anatomy_AlveolarBone"},
    {no: 2, name: "IOXRay_Anatomy_MaxillarySinus"},
    {no: 3, name: "IOXRay_Anatomy_MandibularCanal"},
    {no: 4, name: "IOXRay_Anatomy_NasalCavity"},
    {no: 5, name: "IOXRay_Anatomy_VestibularBone"},
    {no: 6, name: "IOXRay_Anatomy_OralBone"},
  ],
);

/**
 * @generated from enum com.diagnocat.model.IOXRay_Tooth_Anatomy
 */
export const IOXRay_Tooth_Anatomy = proto3.makeEnum(
  "com.diagnocat.model.IOXRay_Tooth_Anatomy",
  [
    {no: 0, name: "IOXRay_Tooth_Anatomy_InvalidValue"},
    {no: 1, name: "IOXRay_Tooth_Anatomy_Root"},
    {no: 2, name: "IOXRay_Tooth_Anatomy_RootCanal"},
    {no: 3, name: "IOXRay_Tooth_Anatomy_Crown"},
    {no: 4, name: "IOXRay_Tooth_Anatomy_IncisalEdge"},
    {no: 5, name: "IOXRay_Tooth_Anatomy_PulpChamber"},
    {no: 6, name: "IOXRay_Tooth_Anatomy_Enamel"},
  ],
);

/**
 * @generated from enum com.diagnocat.model.Pano_Anatomy
 */
export const Pano_Anatomy = proto3.makeEnum(
  "com.diagnocat.model.Pano_Anatomy",
  [
    {no: 0, name: "Pano_Anatomy_InvalidValue"},
    {no: 1, name: "Pano_Anatomy_Maxilla"},
    {no: 2, name: "Pano_Anatomy_Mandible"},
    {no: 3, name: "Pano_Anatomy_MaxillarySinus"},
    {no: 4, name: "Pano_Anatomy_MandibularCanal"},
  ],
);

/**
 * @generated from enum com.diagnocat.model.Pano_Tooth_Anatomy
 */
export const Pano_Tooth_Anatomy = proto3.makeEnum(
  "com.diagnocat.model.Pano_Tooth_Anatomy",
  [
    {no: 0, name: "Pano_Tooth_Anatomy_InvalidValue"},
    {no: 1, name: "Pano_Tooth_Anatomy_Root"},
    {no: 2, name: "Pano_Tooth_Anatomy_RootCanal"},
    {no: 3, name: "Pano_Tooth_Anatomy_Crown"},
    {no: 4, name: "Pano_Tooth_Anatomy_PulpChamber"},
    {no: 5, name: "Pano_Tooth_Anatomy_Enamel"},
  ],
);

/**
 * @generated from message com.diagnocat.model.Report_2D_Image_Meta
 */
export const Report_2D_Image_Meta = proto3.makeMessageType(
  "com.diagnocat.model.Report_2D_Image_Meta",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Type", kind: "enum", T: proto3.getEnumType(Report_2D_ImageType) },
    { no: 3, name: "StudyAssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "GeneratedAssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "Scale", kind: "message", T: FloatTuple2D },
    { no: 6, name: "OrientationAngle", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 11, name: "MedicalImageFeatures", kind: "message", T: MedicalImageFeatures },
  ],
);

