import { FC, memo, useMemo } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { WidgetCard } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { MedicalImageInterface } from '@/shared/config';
import { SlicesMIR } from '@/shared/graphics/medicalImageRender/MedicalImageRender';

import { assetsModel, useGetSelectedMedicalImages } from '@/entities/assets';
import { ModalID, modalModel } from '@/entities/modal';

import { transformUserAssetsToMedicalImages } from '../lib/transformUserAssetsToMedicalImages';

import { UploadedSlicesList } from './UploadSlicesList/UploadedSlicesList';
import { UploadSlicesDropzone } from './UploadSlicesDropzone/UploadSlicesDropzone';
import styles from './UploadedSlices.module.scss';

type UploadedSlicesProps = {
  toothID: string;
  toggleSelected: (medicalImage: MedicalImageInterface) => void;
  className?: string;
  testID?: string;
};

export const UploadedSlices: FC<UploadedSlicesProps> = memo((props) => {
  const { className, testID, toothID, toggleSelected } = props;

  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const selectUserAssets =
    assetsModel.selectors.makeSelectorUserUploadedAssetsByToothID(toothID);

  const userAssets = useAppSelector(selectUserAssets);

  const selectedMedicalImages = useGetSelectedMedicalImages(toothID);

  const medicalImages = useMemo(
    () => transformUserAssetsToMedicalImages(userAssets),
    [userAssets],
  );

  const useHandleOpenModal = (assetID: string) => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.MIRenderModal,
        data: { assetID, toothID },
      }),
    );
  };

  return (
    <WidgetCard
      data-testid={testID}
      className={cn(styles.container, className)}
    >
      <h4 className={cn(styles.header, 'h4')}>
        {formatMessage({
          id: 'tooth.uploadedSlices.header',
          defaultMessage: 'Uploaded slices',
        })}
      </h4>
      {/* TODO: [16|h] refactor this into normal components */}
      {medicalImages.length > 0 && (
        <SlicesMIR toothID={toothID} onClick={useHandleOpenModal}>
          <div className={styles.userSlices}>
            <UploadedSlicesList
              selectedMedicalImages={selectedMedicalImages}
              toggleSelected={toggleSelected}
              medicalImages={medicalImages}
              canUserDeleteSlices
            />
          </div>
        </SlicesMIR>
      )}

      <UploadSlicesDropzone toothID={toothID} />
    </WidgetCard>
  );
});
