import { Record } from 'immutable';

import { AssetType } from '@/shared/api/protocol-ts/model/dto_asset_pb';
import { StudyType } from '@/shared/api/protocol-ts/model/dto_study_pb';
import { OrderReportType, UploadStudyType } from '@/shared/config';

import { OrderReportModalStep } from './types';
import { UploadStudyPayload } from './formSchema';

export const UPLOAD_STUDY_FORM_ID = 'upload-study-form';

export const MAX_BYTES_FOR_STUDIES = 262144000; // 250MB
export const MAX_BYTES_FOR_CBCT = 10737418240; // 10GB

export const ORDER_REPORT_STUDY_TYPE: Record<OrderReportType, StudyType> = {
  CBCT: StudyType.StudyType_CBCT,
  Pano: StudyType.StudyType_PanoramicXRay,
  IOXRay: StudyType.StudyType_IntraoralXRay,
  '3DStudio': StudyType.StudyType_CBCT,
  ImplantStudio: StudyType.StudyType_CBCT,
  OrthoStudio: StudyType.StudyType_CBCT,
  IOSSegmentation: StudyType.StudyType_IOS_Meshes,
};

export const ORDER_REPORT_ASSET_TYPE: Record<OrderReportType, UploadStudyType> =
  {
    CBCT: AssetType.AssetType_Study_CBCT,
    Pano: AssetType.AssetType_Study_PanoramicXRay,
    IOXRay: AssetType.AssetType_Study_IntraoralXRay,
    '3DStudio': AssetType.AssetType_Study_CBCT,
    ImplantStudio: AssetType.AssetType_Study_CBCT,
    OrthoStudio: AssetType.AssetType_Study_CBCT,
    IOSSegmentation: AssetType.AssetType_Study_IOS_Meshes,
  };

export const SELECT_INPUT_NAME: Record<
  OrderReportModalStep,
  keyof UploadStudyPayload
> = {
  '1': 'mainStudyID',
  '2': 'IOSMeshesStudyID',
  '3': 'DentalPhotoStudyID',
  '4': 'SecondaryCBCTStudyID',
};
