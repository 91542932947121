import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';

import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';
import { trackPageView } from '@/shared/lib';
import { MobileLayout } from '@/shared/ui';
import { useMedia } from '@/shared/hooks';

import { i18n, ReportLayout } from '@/entities/reports';

import { MaskFilters, MaskTooltip } from '@/features/renderMasks';

import { Conclusion } from '@/widgets/Conclusion';
import { ReportActions } from '@/widgets/ReportActions';
import { Report2DToothChart } from '@/widgets/Report2DToothChart';
import { ReportToothCardList } from '@/widgets/ReportToothCardList';
import { NonDentalFindings } from '@/widgets/NonDentalFindings';
import { MobileHeader } from '@/widgets/Header';

import { PanoReportRender } from './PanoReportRender/PanoReportRender';
import { PanoChangeTeethNumberModal } from './PanoChangeTeethNumberModal/PanoChangeTeethNumberModal';
import styles from './PanoReport.module.scss';
import { Toolbar } from './Toolbar/Toolbar';
import { PanoImageSlider } from './PanoImageSlider/PanoImageSlider';

export const PanoReport = () => {
  const { patientID, reportID } = useParams();
  const { formatMessage } = useIntl();
  const { isMobile } = useMedia();

  useEffect(() => {
    trackPageView('PanoReport Page Viewed');
  }, []);

  if (isMobile) {
    return (
      <MobileLayout>
        <MobileHeader />
        <MobileLayout.Main>
          <PanoReportRender>
            <MaskFilters className={styles.maskFilters} />
            <MaskTooltip />
            <Toolbar />
            <PanoImageSlider reportID={reportID as string} />
          </PanoReportRender>
          <Report2DToothChart />
          <NonDentalFindings />
          <ReportToothCardList />
          <Conclusion />
        </MobileLayout.Main>
        <MobileLayout.Footer>
          <ReportActions
            className={styles.mobileReportActions}
            patientID={patientID as string}
            reportID={reportID as string}
          />
        </MobileLayout.Footer>

        {/* Modals */}
        <PanoChangeTeethNumberModal />
      </MobileLayout>
    );
  }

  return (
    <>
      <ReportLayout.LeftColumn>
        <h1>{formatMessage(i18n[ReportType.ReportType_Pano_GP])}</h1>
        <PanoReportRender>
          <MaskFilters className={styles.maskFilters} />
          <MaskTooltip />
          <Toolbar />
          <PanoImageSlider reportID={reportID as string} />
        </PanoReportRender>
      </ReportLayout.LeftColumn>

      <ReportLayout.RightColumn>
        <Report2DToothChart />

        <NonDentalFindings />

        <ReportToothCardList />

        <Conclusion />

        <ReportActions
          className={styles.reportActions}
          patientID={patientID as string}
          reportID={reportID as string}
        />
      </ReportLayout.RightColumn>

      {/* Modals */}
      <PanoChangeTeethNumberModal />
    </>
  );
};
