import { MessageDescriptor } from 'react-intl';

import { Annotation } from '@/shared/graphics/RenderComponents/AnnotationsLayer/Annotation';
import { BBox } from '@/shared/api/protocol-ts/model/dto_common_geometry_pb';

import { PatientPermissions } from '../api/protocol-ts/model/dto_patient_permissions_pb';
import { ReportPermissions } from '../api/protocol-ts/model/dto_report_permissions_pb';
import { PolygonMask } from '../graphics/RenderComponents/RCPolygonMask/RCPolygonMask';
import { ConditionCode } from '../api/protocol-ts/model/dto_report_condition_codes_pb';
import {
  IOXRay_Anatomy,
  IOXRay_Tooth_Anatomy,
  Pano_Anatomy,
  Pano_Tooth_Anatomy,
} from '../api/protocol-ts/model/dto_report_type_2D_common_pb';
import {
  CBCT_Anatomy,
  CBCT_Tooth_Anatomy,
} from '../api/protocol-ts/model/dto_common_anatomy_pb';
import { OrganizationPermissions } from '../api/protocol-ts/model/dto_organization_permissions_pb';
import { UserPermissions } from '../api/protocol-ts/model/dto_user_permissions_pb';
import { StudyPermissions } from '../api/protocol-ts/model/dto_study_permissions_pb';
import { AssetType } from '../api/protocol-ts/model/dto_asset_pb';

export type UserLocale = string;

export enum LocalStorageKey {
  theme = 'theme',
  view = 'view',
  organizationID = 'organizationID',
  signUpFormData = 'signUpFormData',
  UTMParameters = 'UTMParameters',
  unauthenticatedLocale = 'unauthenticatedLocale',
}

export type LoadingStateType = 'idle' | 'pending' | 'failed' | 'succeeded';

export type SortingType = 'default' | 'asc' | 'desc';

export type PrimitiveType = string | number | boolean | null | undefined;

export type DefaultInitialState = {
  loading: LoadingStateType;
};

export enum StreamDataAccumulatorKey {
  study = 'study',
  reports = 'reports',
  teeth = 'teeth',
  conditions = 'conditions',
  conditionsTeethGroup = 'conditionsTeehGroup',
  conditionsMaxFaxGroup = 'conditionsMaxFaxGroup',
  assets = 'assets',
  patientProfileGuardedAssetsIDs = 'patientProfileGuardedAssetsIDs',
  access = 'access',
  /**
   * @deprecated
   */
  initialROITeethISONumbers = 'initialROITeethISONumbers',
  toothLandmarks = 'toothLandmarks',
  initialROITeethIDs = 'initialROITeethIDs',
  dentalPhotoAlbum = 'dentalPhotoAlbum',
}

export enum AccountInfoStreamDataAccumulatorKeys {
  inventory = 'inventory',
  orders = 'orders',
}

export type Mask = {
  url: string;
  id: string;
};

export type MedicalImageInterface = {
  id: string;
  src: string;
  assetID: string;
  image?: {
    Height: number;
    Width: number;
  };
  annotations?: Annotation[];
  strideMm?: number;
  thicknessMm?: number | string;
  masks?: PolygonMask[];
  path?: string;
  localizationID?: string;
  rightSideOrient?: string;
  leftSideOrient?: string;
  TargetAssetID?: string;
  BBox?: BBox;
  viewOptions?: Partial<{
    sharpness: number;
    invert: boolean;
    brightness: number;
    contrast: number;
    wwwc: { ww: number; wc: number };
  }>;
  groupName?: string | number;
  order?: number;
  kind?: 'dicom' | 'raster';
};

export type ToolNames =
  | CombinedToolNames
  | ToggledToolNames
  | StatelessToolNames;

export const combinedToolNames = [
  'invert',
  'split',
  'expand',
  'hideMarks',
] as const;
export type CombinedToolNames = (typeof combinedToolNames)[number];

export const statelessToolNames = [
  'reset',
  'download',
  'editNumbers',
  'ios',
  'turn',
] as const;
export type StatelessToolNames = (typeof statelessToolNames)[number];

export const toggledToolNames = [
  'ruler',
  'arrow',
  'angle',
  'eraser',
  'view',
  'sharpening',
  'brightness',
  'move',
  'zoom',
  'sharpness',
] as const;
export type ToggledToolNames = (typeof toggledToolNames)[number];

export type LocationStateType = {
  lastPositionPatientProfile: number;
  lastPositionReport: number;
  recentReportIndex: number;
  toothID: string;
  lastOpenTabStudyID: string;
};

export type SortByType = {
  patientName?: boolean;
  clinicName?: boolean;
  senderName?: boolean;
  senderEmail?: boolean;
  sharingDate?: boolean;
  status?: boolean;
  recipientEmail?: boolean;
  dateOfBirth?: boolean;
  externalID?: boolean;
};

export type SortKeyType = {
  [key in PatientsListColumnName]: SortByType;
};

export type SortByFunctionType = (
  sortKey: keyof SortKeyType,
  leastOrGreatest: boolean,
) => void;

export enum PatientsListColumnName {
  ClinicName = 'Clinic name',
  SharedBy = 'Shared by',
  SenderEmail = 'Sender Email',
  SharedWith = 'Shared with',
  SharingDate = 'Sharing date',
  Status = 'Status',
  PatientName = 'PatientName',
  DateOfBirth = 'DateOfBirth',
  ExternalID = 'ExternalID',
  Doctors = 'Doctors',
  Studies = 'Studies',
  Delete = 'Delete',
  Default = 'Default',
}

export type SelectOptionType = {
  value: string;
  label: string;
};

export type BreadcrumbElementType = {
  label: string | JSX.Element;
  path: string;
  state?: LocationStateType;
};

export type FormatMessageFunction = (
  messageDescriptor: MessageDescriptor,
  values?: Record<string, PrimitiveType>,
) => string;

export type PatientPermissionsKeys = keyof PatientPermissions;
export type ReportPermissionsKeys = keyof ReportPermissions;
export type OrganizationPermissionsKeys = keyof OrganizationPermissions;
export type UserPermissionsKeys = keyof UserPermissions;
export type StudyPermissionsKeys = keyof StudyPermissions;

export type RenderPreviewSettings = {
  isPreview: boolean;
  isInverted: boolean;
  isBW: boolean;
  showMasks?: boolean;
};

export type OrderReportType =
  | 'CBCT'
  | 'Pano'
  | 'IOXRay'
  | '3DStudio'
  | 'ImplantStudio'
  | 'OrthoStudio'
  | 'IOSSegmentation';

export type MaskGroup = 'perio' | 'restorative' | 'endo' | 'chart';
export type MaskFilterParams = Record<
  'isActive' | 'disabled' | 'hide',
  boolean
>;
export type MaskFilter = Record<MaskGroup, MaskFilterParams>;

export type Mask2DConfigCodes = {
  conditionCode?: ConditionCode;
  IOXRayToothAnatomy?: IOXRay_Tooth_Anatomy;
  PanoToothAnatomy?: Pano_Tooth_Anatomy;
  CBCTToothAnatomy?: CBCT_Tooth_Anatomy;
  IOXRayMaxFaxAnatomy?: IOXRay_Anatomy;
  PanoMaxFaxAnatomy?: Pano_Anatomy;
  CBCTMaxFaxAnatomy?: CBCT_Anatomy;
};

export type PendingChange = {
  toothID: string;
  oldNumber: number;
  newNumber: number;
  SupernumeraryIndex: number;
};

export type ImageSizeType = 'original' | 'thumbnail' | 'preview' | 'optimized';

export type UploadStudyType =
  | AssetType.AssetType_Study_CBCT
  | AssetType.AssetType_Document
  | AssetType.AssetType_Study_IntraoralXRay
  | AssetType.AssetType_Study_DentalPhoto
  | AssetType.AssetType_Study_PanoramicXRay
  | AssetType.AssetType_Study_IOS_Meshes;
