import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import styles from './DragAndDropHint.module.scss';

type DragAndDropHintProps = {
  className?: string;
  testID?: string;
};

export const DragAndDropHint: FC<DragAndDropHintProps> = (props) => {
  const { className, testID } = props;

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      <h6 className={cn(styles.medicalImagesTitle, 'h6')}>
        <FormattedMessage
          id="selectedMedicalImages.addImage"
          defaultMessage="Add image"
        />
      </h6>

      <span className={cn(styles.medicalImagesDescription, 'p3')}>
        <FormattedMessage
          id="selectedMedicalImages.dragDropDescription"
          defaultMessage="drag&drop images here"
        />
      </span>
    </div>
  );
};
