import { FC, memo } from 'react';
import { useIntl } from 'react-intl';

import sadCatImg from '@/shared/assets/images/sad-cat.png';
import { Result } from '@/shared/ui';

import { MPR2Widget } from '@/widgets/MPR2';

import styles from './ToothMPR.module.scss';

type ToothMPRProps = {
  className?: string;
  isMissingTooth: boolean;
  mprUrl: string;
  toothID: string;
  initialWw?: number;
  initialWc?: number;
  toothISONumber: number;
};

export const ToothMPR: FC<ToothMPRProps> = memo((props) => {
  const { isMissingTooth, toothID } = props;

  const { formatMessage } = useIntl();

  if (isMissingTooth) {
    return (
      <Result
        className={styles.empty}
        icon={
          <img
            src={sadCatImg}
            width={130}
            height={130}
            alt={formatMessage({
              id: 'imgAltText.sadCat',
              defaultMessage: 'Sad cat',
            })}
          />
        }
        text={formatMessage({
          id: 'ToothMPR.noMPR',
          defaultMessage: `There are no MPR for Missing tooth`,
        })}
      />
    );
  }

  return <MPR2Widget toothID={toothID} />;
});
