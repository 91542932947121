import { useState, FC, useRef, useEffect } from 'react';

import { Skeleton } from '@/shared/ui';
import { RCGuides } from '@/shared/graphics/RenderComponents/RCGuides/RCGuides';
import { DEFAULT_MEDICAL_IMAGE_HEIGHT } from '@/shared/config';

import { PathologyGroup, Group, Guide } from '@/entities/assets';

import { getGuideLines } from '../../helpers/getGuideLines';
import styles from '../MedicalImageGroupSection/MedicalImageGroupSection.module.scss';

type MedicalImageGuideProps = {
  group: Group | PathologyGroup;
  guide: Guide;
  hoveredGuide: string;
  handleZoomImg?: (assetID: string) => void;
  setHoveredGuide: (guideId: string) => void;
  guideIndex: number;
  firstGuideWidth: number;
};

const MedicalImageGuide: FC<MedicalImageGuideProps> = (props) => {
  const {
    group,
    guide,
    hoveredGuide,
    handleZoomImg,
    setHoveredGuide,
    guideIndex,
    firstGuideWidth,
  } = props;

  const imgRef = useRef<HTMLImageElement>(null);

  const [isImageReady, setIsImageReady] = useState(false);

  useEffect(() => {
    if (imgRef.current) {
      imgRef.current.onload = () => {
        setIsImageReady(true);
      };
    }
  }, [imgRef.current]);

  return (
    <div
      className={styles.guidesLayerWrapper}
      style={{
        width: firstGuideWidth,
        height: guideIndex === 0 ? DEFAULT_MEDICAL_IMAGE_HEIGHT : undefined,
      }}
    >
      {!isImageReady && (
        <Skeleton
          width="100%"
          borderRadius="12px"
          height="100%"
          className={styles.imageSkeleton}
        />
      )}
      {guide?.guideAxesName && (
        <div className={styles.axesName}>
          <span className="p3">{guide.guideAxesName}</span>
        </div>
      )}

      <img
        ref={imgRef}
        id={guide.assetID}
        style={{ width: '100%', borderRadius: '8px', position: 'absolute' }}
      />

      {guide.guide &&
        'Lines' in guide.guide &&
        guide.guide?.Lines &&
        isImageReady && (
          <RCGuides
            imageSize={{
              width: Number(guide.image.Width) ?? 0,
              height: Number(guide.image.Height) ?? 0,
            }}
            guides={getGuideLines(
              guide.guide?.Lines,
              group.medicalImages ?? [],
            )}
            onGuideClick={handleZoomImg}
            hoveredGuide={hoveredGuide}
            onHoveredGuideChanged={(index) => setHoveredGuide(index)}
          />
        )}
    </div>
  );
};

export default MedicalImageGuide;
