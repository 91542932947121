import { FC, ReactNode } from 'react';
import cn from 'classnames';

import { RenderPreviewSettings } from '@/shared/config';
import { ViewMIR } from '@/shared/graphics/medicalImageRender/MedicalImageRender';

import styles from './PanoReportRender.module.scss';

type PanoReportRenderProps = {
  className?: string;
  children?: ReactNode;
  previewSettings?: RenderPreviewSettings;
};

export const PanoReportRender: FC<PanoReportRenderProps> = (props) => {
  const { className, children, previewSettings } = props;

  return (
    <ViewMIR
      viewName="main"
      id="report_render"
      className={cn(
        styles.container,
        previewSettings?.isPreview ? styles.preview : '',
        className,
      )}
    >
      {children}
    </ViewMIR>
  );
};
