import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TreatingDoctorsDrawer } from '../TreatingDoctorsDrawer/TreatingDoctorsDrawer';

type TreatingDoctorsFilterDrawerProps = {
  onSave: (doctorsIDs: string[]) => void;
};

export const TreatingDoctorsFilterDrawer = (
  props: TreatingDoctorsFilterDrawerProps,
) => {
  const { onSave } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const doctorIDs = searchParams.get('doctorIDs')?.split(',') || [];
  const [doctors, setDoctors] = useState<string[]>(doctorIDs);

  const handleChange = (value: string) => {
    if (doctors.includes(value)) {
      setDoctors(doctors.filter((doctor) => doctor !== value));
      return;
    }
    setDoctors([...doctors, value]);
  };

  const handleSave = () => {
    onSave(doctors);

    if (doctors.length === 0) {
      searchParams.delete('doctorIDs');
      setSearchParams(searchParams);
      return;
    }

    searchParams.set('doctorIDs', doctors.join(','));
    setSearchParams(searchParams);
  };
  return (
    <TreatingDoctorsDrawer
      type="filter"
      doctorIDs={doctors}
      onChange={handleChange}
      onSave={handleSave}
    />
  );
};
