// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_patient_permissions.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message com.diagnocat.model.PatientPermissions
 */
export const PatientPermissions = proto3.makeMessageType(
  "com.diagnocat.model.PatientPermissions",
  () => [
    { no: 1, name: "CanView", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "CanViewPersonalData", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "CanEditPersonalData", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "CanEditPatient", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "CanViewTeam", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "CanEditTeam", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "CanViewSharings", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "CanShare", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "CanCancelOthersSharings", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "CanViewClinicalCaseDescription", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "CanEditClinicalCaseDescription", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 21, name: "CanViewTasks", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 22, name: "CanEditTasks", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 23, name: "CanViewAlbums", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 24, name: "CanEditAlbums", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 51, name: "CanRequestReport", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 101, name: "CanUploadCBCT", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 102, name: "CanUploadIOXRay", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 103, name: "CanUploadPanoramicXRay", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 104, name: "CanUploadSTL", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 105, name: "CanUploadDentalPhoto", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 106, name: "CanUploadDocuments", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 201, name: "CanViewStudies", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 202, name: "CanViewReports", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 301, name: "CanDeletePatient", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

