import { TreatingDoctorsDrawer } from '../TreatingDoctorsDrawer/TreatingDoctorsDrawer';

type TreatingDoctorsSelectDrawerProps = {
  doctorIDs: string[];
  onSave: (doctorsIDs: string[]) => void;
};

export const TreatingDoctorsSelectDrawer = (
  props: TreatingDoctorsSelectDrawerProps,
) => {
  const { onSave, doctorIDs } = props;

  const handleChange = (value: string) => {
    if (doctorIDs.includes(value)) {
      onSave(doctorIDs.filter((doctor) => doctor !== value));
      return;
    }
    onSave([...doctorIDs, value]);
  };

  return (
    <TreatingDoctorsDrawer
      type="select"
      doctorIDs={doctorIDs}
      onChange={handleChange}
    />
  );
};
