import { createSelector } from '@reduxjs/toolkit';

import { AssetContent_Generated_CBCT_GP_MPR_SubVolume } from '@/shared/api/protocol-ts/model/dto_asset_cbct_gp_pb';

import { reportsModel } from '@/entities/reports';
import { assetsAdapter } from '@/entities/assets/model/assetsSlice';
import { toothModel } from '@/entities/tooth';

import { RootState } from '@/app/model/store';

import { transformDataToMPRLauncherRequest } from '../utils/transformDataToMPRLauncherRequest';

// This selector is for MPREmbedded for debugging purposes only
export const selectMPREmbeddedLauncherRequestAllSubVolumes = (
  toothID: string,
) =>
  createSelector(
    toothModel.selectors.selectToothByID(toothID),
    selectAllMPRAssets(),
    reportsModel.selectors.selectCurrentReport,
    (tooth, allsubvolumes, report) => {
      if (!tooth) {
        console.error('[MPREmbedded] Tooth is undefined. Cannot start MPR2');
        return null;
      }

      const cbctgp =
        report?.Specific.case === 'DataCBCTGP'
          ? report?.Specific.value
          : undefined;

      if (!cbctgp) {
        console.error(
          '[MPREmbedded] Report DataCBCTGP is undefined. Cannot start MPR2',
        );
        return null;
      }

      const mprLauncherRequest = transformDataToMPRLauncherRequest(
        cbctgp,
        allsubvolumes,
        tooth,
      );

      return mprLauncherRequest;
    },
  );

const assetsSelectors = assetsAdapter.getSelectors(
  (state: RootState) => state.assets,
);

// This selector is for MPREmbedded for debugging purposes only
export const selectAllMPRAssets = () =>
  createSelector([assetsSelectors.selectAll], (assets) => {
    const targetAssets = assets.filter(
      (asset) =>
        asset.Kind.case === 'GeneratedReport' &&
        asset.Kind.value.Content.case === 'CBCTGPMPRSubVolume',
    );

    return targetAssets.map(
      (targetAsset) => targetAsset.Kind.value!.Content.value,
    ) as AssetContent_Generated_CBCT_GP_MPR_SubVolume[];
  });
