// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file pipelines/report_maxfax_anatomy_localization.proto (package com.diagnocat.pipelines, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { MaxFaxAnatomyLocalization } from "../model/dto_report_maxfax_anatomy_localization_pb.js";

/**
 * @generated from message com.diagnocat.pipelines.MaxFaxAnatomyLocalizations_Update
 */
export const MaxFaxAnatomyLocalizations_Update = proto3.makeMessageType(
  "com.diagnocat.pipelines.MaxFaxAnatomyLocalizations_Update",
  () => [
    { no: 1, name: "Localizations", kind: "message", T: MaxFaxAnatomyLocalization, repeated: true },
  ],
);

