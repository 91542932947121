import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import cn from 'classnames';

import { Button, Icon, Modal } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { ToothNumeration } from '@/shared/api/protocol-ts/model/dto_report_common_pb';
import { SUPERNUMERARY_INDEX_BY_USER } from '@/shared/config';
import { getImageSrc } from '@/shared/lib';

import { ModalID, modalModel } from '@/entities/modal';
import { reportsModel, useCheckReportSignature } from '@/entities/reports';
import {
  getDisplayToothNumber,
  LocalizationWithISO,
  TeethNumbersPopup,
  toothModel,
} from '@/entities/tooth';
import { organizationModel } from '@/entities/organization';
import {
  getMatrixMaxHeight,
  getMatrixMaxWidth,
  groupIOXRayImagesByPartition,
  ImageSizeType,
  IOXrayImageInterface,
  IOXRayImagesInterfaceModel,
} from '@/entities/IOXRayImagesMatrix';
import { ensureRefreshImageSrc } from '@/entities/assets';
import { conditionModel } from '@/entities/condition';

import { getImageSizeDataByType } from '@/features/IOXRayMatrixPreview';

import styles from './IOXRayChangeTeethNumberModal.module.scss';

type IOXRayChangeTeethNumberModalProps = {
  className?: string;
};

// TODO: [m|8] write universal HOC component for modals to prevent hidden modals rendering
export const IOXRayChangeTeethNumberModal = () => {
  const { visible } = useAppSelector(
    modalModel.selectors.selectTeethNumberingModal,
  );

  return visible ? <IOXRayChangeTeethNumberModalComponent /> : null;
};

export const IOXRayChangeTeethNumberModalComponent: FC<
  IOXRayChangeTeethNumberModalProps
> = (props) => {
  const { className } = props;

  const { reportID } = useParams();

  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const { visible } = useAppSelector(
    modalModel.selectors.selectTeethNumberingModal,
  );
  const report = useAppSelector(reportsModel.selectors.selectCurrentReport);
  const reportTeeth = useAppSelector(
    toothModel.selectors.selectByReportID(reportID as string),
  );
  const deletedTooth404 = useAppSelector(
    toothModel.selectors.select404ByReportID(reportID as string),
  );
  const dentalNotationFormat = useAppSelector(
    organizationModel.selectors.selectDentalNotationFormat,
  );

  const IOXRayImagesInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectIOXRayImagesInterfaceByReportID(
      report?.ID as string,
    ),
  ) as IOXrayImageInterface[];

  const [selectedLocalization, setSelectedToothLocalization] = useState<
    LocalizationWithISO | undefined
  >(undefined);
  const [selectedAssetISONumbers, setSelectedAssetISONumbers] = useState<
    number[] | undefined
  >(undefined);
  const [isToothRemoving, setIsToothRemoving] = useState(false);
  const [animatedToothNumber, setAnimatedToothNumber] = useState<number>(0);
  const [{ contWidth, contHeight }, setContSize] = useState({
    contWidth: 0,
    contHeight: 0,
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const { checkReportSignature } = useCheckReportSignature();

  const allReportTeeth = deletedTooth404
    ? [...reportTeeth, deletedTooth404]
    : reportTeeth;

  const localizationItems = allReportTeeth.flatMap((tooth) =>
    tooth.Detections.map((detection) => {
      const localization = detection.Localizations;

      return {
        isRemoved: detection?.IsRemoved,
        ISONumber: tooth.Numeration?.ISO,
        SupernumeraryIndex: tooth.Numeration?.SupernumeraryIndex,
        ...localization,
      };
    }),
  ) as LocalizationWithISO[];

  const title = formatMessage({
    id: 'report.EditTeethNumbers',
    defaultMessage: 'Edit teeth numbers',
  });

  const groupedIOXRayImages = groupIOXRayImagesByPartition(
    IOXRayImagesInterface,
  );

  const disabledByDuplicates = useMemo(() => {
    return IOXRayImagesInterface.some((image) => image.hasDuplicates);
  }, [IOXRayImagesInterface]);

  const PREVIEW_IMAGE_SIZE: ImageSizeType = 'original';

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.TeethNumberingModal));
  }, [dispatch]);

  const handleToothClick = (localization: LocalizationWithISO | undefined) => {
    setSelectedToothLocalization(localization);
  };

  // TODO?: mb move to separated hook
  const handleChangeToothNumber = (
    toothLocalization: LocalizationWithISO,
    newToothISONumber: number,
  ) => {
    checkReportSignature({
      onSignatureChecked: async () => {
        // TODO: [2/m] add SupernumeraryIndex in ToothNumeration, otherwise it will be equal to 0
        try {
          setAnimatedToothNumber(newToothISONumber);

          const teethWithSameNumber = allReportTeeth.filter(
            (tooth) =>
              tooth.Numeration?.ISO === newToothISONumber &&
              tooth.ID !== toothLocalization.toothID &&
              !tooth.IsRemoved,
          );

          const supernumeraryIndex =
            teethWithSameNumber.length > 0 ? SUPERNUMERARY_INDEX_BY_USER : 0;

          const { Teeth, Conditions, Report } = await dispatch(
            reportsModel.thunks.setToothDetectionsNumerationAndRevive({
              ToothDetectionID: toothLocalization.ID,
              Numeration: {
                ISO: newToothISONumber,
                SupernumeraryIndex: supernumeraryIndex,
              } as ToothNumeration,
            }),
          ).unwrap();

          dispatch(toothModel.actions.addMany(Teeth));
          dispatch(conditionModel.actions.setNewestMany(Conditions));

          if (Report) {
            dispatch(reportsModel.actions.setNewestOne(Report));
          }
          setAnimatedToothNumber(0);
        } finally {
          setSelectedToothLocalization(undefined);
        }
      },
    });
  };

  const handleRemoveTooth = (toothLocalization: LocalizationWithISO) => {
    checkReportSignature({
      onSignatureChecked: async () => {
        setIsToothRemoving(true);
        try {
          const { Tooth, Report } = await dispatch(
            reportsModel.thunks.markToothDetectionAsDeleted({
              ToothDetectionID: toothLocalization.ID,
            }),
          ).unwrap();

          if (Tooth) {
            dispatch(toothModel.actions.setNewestOne(Tooth));
          }
          if (Report) {
            dispatch(reportsModel.actions.setNewestOne(Report));
          }
        } finally {
          setSelectedToothLocalization(undefined);
          setIsToothRemoving(false);
        }
      },
    });
  };

  // TODO: move calculate scale to hook (mb with obsever) and try to reuse for all reports renders and previews
  const calculateScale = useCallback(() => {
    const wrapperWidth = contWidth;
    const wrapperHeight = contHeight;

    if (!wrapperWidth && !wrapperHeight) return 0;

    const IOXRaysOriginalWidth = getMatrixMaxWidth(
      groupedIOXRayImages,
      PREVIEW_IMAGE_SIZE,
    );
    const IOXRaysOriginalHeight = getMatrixMaxHeight(
      groupedIOXRayImages,
      PREVIEW_IMAGE_SIZE,
    );

    const IOXRAY_MATRIX_PADDINGS = 30;

    const scaleByWidth =
      (wrapperWidth - IOXRAY_MATRIX_PADDINGS) / IOXRaysOriginalWidth;
    const scaleByHeight =
      (wrapperHeight - IOXRAY_MATRIX_PADDINGS) / IOXRaysOriginalHeight;

    const IOXRaysOriginalAspect = IOXRaysOriginalHeight / IOXRaysOriginalWidth;

    const wrapperAspect = wrapperHeight / wrapperWidth;

    if (IOXRaysOriginalAspect >= wrapperAspect) {
      return scaleByHeight;
    }

    return scaleByWidth;
  }, [groupedIOXRayImages, contHeight, contWidth]);

  const scale = calculateScale();

  // TODO: move observer to hook
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;

        setContSize({ contWidth: width, contHeight: height });
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <Modal
      title={title}
      isOpen={visible}
      onCancel={handleClose}
      className={styles.modal}
      bodyClassName={styles.modalBody}
      containerClassName={styles.modalContainer}
      hideFooter
      borderless
      shouldRenderCloseIconButton={false}
      shouldCloseOnOverlayClick={!disabledByDuplicates}
      shouldRenderCancelButton={false}
    >
      <div className={cn(styles.container, className)} ref={containerRef}>
        <div className={styles.matrixContainer}>
          {Object.entries(groupedIOXRayImages).map(
            ([group, IOXrayImagesInterfaceList]) => (
              <div className={styles[group]} key={group}>
                {IOXrayImagesInterfaceList?.map((imageItem) => {
                  // TODO: [h|2] remove into separate reusable component for another reports modals
                  const assetLocalizations = localizationItems.filter(
                    (localizationItem) =>
                      localizationItem.TargetAssetID === imageItem.asset.ID,
                  ) as LocalizationWithISO[];

                  const { duplicatedISONumbers, viewedISONumbers } =
                    assetLocalizations.reduce(
                      (accum, localization) => {
                        if (localization.isRemoved) {
                          return accum;
                        }

                        if (
                          accum.viewedISONumbers.includes(
                            localization.ISONumber,
                          ) &&
                          localization.ISONumber !== 404
                        ) {
                          accum.duplicatedISONumbers.push(
                            localization.ISONumber,
                          );
                        } else {
                          accum.viewedISONumbers.push(localization.ISONumber);
                        }

                        return accum;
                      },
                      { duplicatedISONumbers: [], viewedISONumbers: [] } as {
                        duplicatedISONumbers: number[];
                        viewedISONumbers: number[];
                      },
                    );

                  const imageSize = getImageSizeDataByType(
                    imageItem,
                    PREVIEW_IMAGE_SIZE,
                  );

                  return (
                    <div
                      className={styles.imageWrap}
                      style={{
                        width: imageSize.width * scale,
                        height: imageSize.height * scale,
                      }}
                    >
                      <img
                        ref={imageRef}
                        src={getImageSrc(imageItem.asset.ID, 'optimized')}
                        onError={ensureRefreshImageSrc}
                        key={imageItem?.imageMeta.ID}
                        className={styles.image}
                        style={{
                          width: imageSize.width * scale,
                          height: imageSize.height * scale,
                        }}
                      />

                      {assetLocalizations.map((localization) => {
                        const Xmin = localization.BBox?.X?.Min ?? 0;
                        const Xmax = localization.BBox?.X?.Max ?? 0;
                        const Ymin = localization.BBox?.Y?.Min ?? 0;
                        const Ymax = localization.BBox?.Y?.Max ?? 0;

                        const centroidX = Xmin + (Xmax - Xmin) / 2;
                        const centroidY = Ymin + (Ymax - Ymin) / 2;

                        const isDuplicated =
                          !localization.isRemoved &&
                          duplicatedISONumbers.includes(localization.ISONumber);

                        return (
                          <div
                            onClick={() => {
                              setSelectedAssetISONumbers(viewedISONumbers);
                              handleToothClick(localization);
                            }}
                            key={localization.ID}
                            className={cn(
                              styles.centroid,
                              isDuplicated ? styles.duplicated : '',
                            )}
                            style={{
                              left: centroidX * scale,
                              top: centroidY * scale,
                            }}
                          >
                            {localization.ISONumber === 404 ||
                            localization?.isRemoved ? (
                              <Icon name="close" size={20} />
                            ) : (
                              getDisplayToothNumber(
                                localization.ISONumber,
                                dentalNotationFormat,
                              )
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            ),
          )}
        </div>

        <TeethNumbersPopup
          isOpen={!!selectedLocalization}
          onChangeToothNumber={handleChangeToothNumber}
          onChangeSelectedLocalization={handleToothClick}
          title={title}
          selectedToothLocalization={selectedLocalization}
          selectedAssetISONumbers={selectedAssetISONumbers}
          isToothRemoving={isToothRemoving}
          dentalNotationFormat={dentalNotationFormat}
          onRemoveTooth={handleRemoveTooth}
          animatedToothNumber={animatedToothNumber}
        />
      </div>

      <div className={styles.footer}>
        <Button
          size="medium"
          onClick={handleClose}
          className={styles.approveButton}
        >
          <FormattedMessage id="patient.confirm" defaultMessage="Confirm" />
        </Button>
      </div>
    </Modal>
  );
};
