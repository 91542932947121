import { createSelector } from '@reduxjs/toolkit';

import {
  StudyStatus,
  StudyType,
} from '@/shared/api/protocol-ts/model/dto_study_pb';
import { getImageSrc } from '@/shared/lib';

import { reportsModel } from '@/entities/reports';
import { studyModel } from '@/entities/study';

import {
  isStudyUsedOnlyInIOSSegmentation,
  reportHasStudyIDAndNotPanowing,
} from '../lib';

type StudyOrderItem = {
  id: string;
  createdAt?: Date;
  previewSrc: string;
};

export const selectStudyList = (studyType: StudyType) =>
  createSelector(
    studyModel.selectors.selectByType(studyType),
    reportsModel.selectors.selectAll,
    (studies, reports): StudyOrderItem[] => {
      return studies
        .filter(
          (study) =>
            study.Status === StudyStatus.StudyStatus_Finished &&
            !isStudyUsedOnlyInIOSSegmentation(study.ID, reports),
        )
        .map((study) => {
          const studyReports = reports.filter(
            reportHasStudyIDAndNotPanowing(study.ID),
          );

          const firstStudyReport = studyReports[0];
          const previewSrc = getImageSrc(
            firstStudyReport?.DisplayAssetID || study.DisplayAssetID,
            'preview',
          );

          return {
            id: study.ID,
            createdAt: study.Time
              ? study.Time.toDate()
              : study.Created?.At && study.Created.At.toDate(),
            previewSrc,
          };
        });
    },
  );

export const selectFirstStudyID = (studyType: StudyType) =>
  createSelector(selectStudyList(studyType), (studyList) => studyList[0]?.id);

export const selectSecondaryCBCTStudyID = (mainCBCTStudyID: string) =>
  createSelector(
    selectStudyList(StudyType.StudyType_CBCT),
    (studyList) => studyList.find((study) => study.id !== mainCBCTStudyID)?.id,
  );

export const selectFilteredStudies = ({
  studyType,
  mainStudyID,
  isAlignmentCBCTReportOrder,
}: {
  studyType: StudyType;
  mainStudyID: string;
  isAlignmentCBCTReportOrder: boolean;
}) =>
  createSelector(selectStudyList(studyType), (studyList) => {
    const filteredStudies = isAlignmentCBCTReportOrder
      ? studyList.filter((study) => study.id !== mainStudyID)
      : studyList;
    return filteredStudies;
  });
