import { FormattedMessage } from 'react-intl';
import { ConnectError } from '@bufbuild/connect';

import { toastCaller } from '@/shared/ui';

import { getErrorMessage } from './getErrorMessage';

let errorDebounceTimer: ReturnType<typeof setTimeout> | null = null;

export const errorToastCaller = (error: ConnectError) => {
  const errorMessage = getErrorMessage(error);

  if (errorDebounceTimer) {
    clearTimeout(errorDebounceTimer);
  }

  errorDebounceTimer = setTimeout(() => {
    toastCaller({
      type: 'error',
      heading: <FormattedMessage id="error.heading" defaultMessage="Error" />,
      message: errorMessage,
      autoClose: 10000,
    });
  }, 100);
};
