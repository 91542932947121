import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { useLocation, useParams } from 'react-router';
import { useIntl } from 'react-intl/lib';
import { equals } from 'ramda';

import { LocationStateType } from '@/shared/config';
import {
  Column,
  Layout,
  MobileLayout,
  Spinner,
  WidgetCard,
  WidgetLayout,
} from '@/shared/ui';
import { useAppSelector, useMedia } from '@/shared/hooks';
import {
  Report,
  ReportType,
} from '@/shared/api/protocol-ts/model/dto_report_pb';
import { scrollToElementByID, trackPageView } from '@/shared/lib';

import { conditionModel } from '@/entities/condition';
import { toothModel } from '@/entities/tooth';
import { ConfirmModal } from '@/entities/modal';

import { useReport_DEPRECATED } from '@/features/reportStream';
import { MaskFilters, MaskTooltip } from '@/features/renderMasks';

import { AddConditionModal } from '@/widgets/AddConditionModal';
import { Conclusion } from '@/widgets/Conclusion';
import { ReportHeading } from '@/widgets/ReportHeading';
import { NonDentalFindings } from '@/widgets/NonDentalFindings';
import {
  TeethNumberingModal,
  useGetTeethNumberingModalImage,
} from '@/widgets/TeethNumberingModal';
import { GetFullAccessModal } from '@/widgets/GetFullAccessModal';
import { MIRenderModal } from '@/widgets/MIRenderModal';
import { ReportToothCardList } from '@/widgets/ReportToothCardList';
import { Toolbar } from '@/widgets/MedicalImageToolbar';
import { MobileHeader } from '@/widgets/Header';

import styles from './GpReport.module.scss';
import { GpToothChart } from './ui/GpToothChart/GpToothChart';
import { PanoReformatRender } from './ui/PanoReformatRender/PanoReformatRender';
import { FooterButtons } from './ui/FooterButtons';

type ReportProps = {
  className?: string;
};

export const GpReport: FC<ReportProps> = (props) => {
  const { className } = props;

  const { patientID = '', reportID = '' } = useParams();
  const { formatMessage } = useIntl();
  const location = useLocation();

  const conditionLoading = useAppSelector(
    conditionModel.selectors.selectLoading,
  );

  const toothLoading = useAppSelector(toothModel.selectors.selectLoading);

  const [, setCurrentToothID] = useState<string>('');

  const { isMobile } = useMedia();

  // type casting is necessary for typification location.state
  const locationState = location?.state as LocationStateType;

  const scrollToElementID = locationState?.toothID;

  const lastPositionReport = locationState?.lastPositionReport;

  const { report, isReportSigned, isReportLoading, isAllTeethApproved } =
    useReport_DEPRECATED(reportID, patientID);

  const {
    panoramicImage,
    panoImageURL,
    panoAssetID,
    CBCTPanoImage,
    reportViewOptions,
    CBCTPanoramaSplitURL,
    CBCTPanoramaURL,
    CBCTPanoramaAssetID,
    modalWidth,
  } = useGetTeethNumberingModalImage(report as Report);

  const handleSlicesClick = (toothID: string) => {
    // TODO: is it usable??
    setCurrentToothID((prevState) => {
      if (equals(toothID, prevState)) {
        return prevState;
      }

      return toothID;
    });
  };

  const showToothChart =
    conditionLoading === 'succeeded' && toothLoading === 'succeeded';

  useEffect(() => {
    trackPageView('GpReport Page Viewed');

    if (scrollToElementID) {
      scrollToElementByID(scrollToElementID, 'auto');
    }

    if (!scrollToElementID && lastPositionReport) {
      window.scrollTo(0, lastPositionReport);
    }
  }, []);

  if (isReportLoading) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner className={styles.spinner} size={96} />
      </div>
    );
  }

  if (!report) {
    return null;
  }

  const teethNumberingImage =
    report.Type === ReportType.ReportType_Pano_GP
      ? panoramicImage
      : CBCTPanoImage;

  const panoImage =
    report.Type === ReportType.ReportType_CBCT_GP
      ? {
          viewOptions: reportViewOptions,
          assetID: CBCTPanoramaAssetID ?? '',
          panoramaURL: CBCTPanoramaURL,
          panoramaSplitURL: CBCTPanoramaSplitURL,
        }
      : {
          viewOptions: reportViewOptions,
          assetID: panoAssetID ?? '',
          panoramaURL: panoImageURL,
        };

  const reportImageLoading = !panoImage.assetID;

  if (isMobile) {
    return (
      <MobileLayout>
        <MobileHeader />
        <MobileLayout.Main>
          <WidgetCard
            className={styles.mobilePanoImageWidget}
            loading={reportImageLoading}
          >
            <PanoReformatRender>
              <Toolbar toolbarID="main" />
              <MaskFilters />
              <MaskTooltip />
            </PanoReformatRender>
          </WidgetCard>

          <WidgetCard className={styles.mobileToothChart}>
            {showToothChart && <GpToothChart />}
          </WidgetCard>

          <NonDentalFindings />
          <ReportToothCardList onSlicesClick={handleSlicesClick} />
          <Conclusion />
        </MobileLayout.Main>
        <MobileLayout.Footer>
          <FooterButtons
            isReportSigned={isReportSigned}
            isAllTeethApproved={isAllTeethApproved}
            report={report}
          />
        </MobileLayout.Footer>

        {/* Modals */}
        <AddConditionModal />
        <MIRenderModal />
        <GetFullAccessModal />
        <ConfirmModal />
        <TeethNumberingModal
          title={formatMessage({
            id: 'report.EditTeethNumbers',
            defaultMessage: 'Edit teeth numbers',
          })}
          patientID={patientID}
          reportID={reportID}
          modalWidth={modalWidth}
          reportImage={teethNumberingImage}
        />
      </MobileLayout>
    );
  }

  return (
    <>
      <Layout.Content>
        <Layout.Main>
          <Layout.Content className={cn(styles.container, className)}>
            <WidgetLayout className={styles.columnWrapper}>
              <Column className={styles.rightColumn}>
                <NonDentalFindings />
                <ReportToothCardList onSlicesClick={handleSlicesClick} />
                <Conclusion />
              </Column>
              <Column className={styles.column}>
                <div className={styles.leftFixedColumn}>
                  <ReportHeading
                    report={report}
                    isReportLoading={isReportLoading}
                  />
                  <WidgetCard
                    className={styles.panoImageWidget}
                    loading={reportImageLoading}
                  >
                    <PanoReformatRender>
                      <Toolbar toolbarID="main" />
                      <MaskFilters className={styles.maskFilters} />
                      <MaskTooltip />
                    </PanoReformatRender>
                  </WidgetCard>

                  <WidgetCard className={styles.toothChart}>
                    {showToothChart && <GpToothChart />}
                  </WidgetCard>
                </div>
              </Column>
            </WidgetLayout>
          </Layout.Content>
        </Layout.Main>
      </Layout.Content>

      <Layout.Footer settingsFooter className={styles.footerContainer}>
        <FooterButtons
          isReportSigned={isReportSigned}
          isAllTeethApproved={isAllTeethApproved}
          report={report}
        />
      </Layout.Footer>

      {/* Modals */}
      <AddConditionModal />
      <MIRenderModal />
      <GetFullAccessModal />
      <ConfirmModal />
      <TeethNumberingModal
        title={formatMessage({
          id: 'report.EditTeethNumbers',
          defaultMessage: 'Edit teeth numbers',
        })}
        patientID={patientID}
        reportID={reportID}
        modalWidth={modalWidth}
        reportImage={teethNumberingImage}
      />
    </>
  );
};
