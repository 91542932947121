import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';

import { DropdownSelect } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';
import { OrganizationUserRoleType } from '@/shared/api/protocol-ts/model/dto_organization_pb';

import { userModel } from '@/entities/user';
import { organizationModel } from '@/entities/organization';

import { useOrganizationUsersOptions } from '../hooks';

import styles from './DoctorsDropdownFilter.module.scss';

type DoctorsDropdownFilterProps = {
  onChange: (doctorsIDs: string[]) => void;
};

// TODO: add updating search params and connect with patient list stream reopening
export const DoctorsDropdownFilter: FC<DoctorsDropdownFilterProps> = (
  props,
) => {
  const { onChange } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const { formatMessage } = useIntl();

  const doctorIDs = searchParams.get('doctorIDs')?.split(',') || [];

  const [doctors, setDoctors] = useState<string[]>(doctorIDs);

  const userID = useAppSelector(userModel.selectors.selectCurrentUserID);
  const currentUser = useAppSelector(
    organizationModel.selectors.selectUserByID(userID),
  );

  const currentUserRoles = currentUser?.Roles || [];

  const hasOnlyTreatingDoctorRole =
    currentUserRoles.includes(
      OrganizationUserRoleType.OrganizationUserRoleType_LimitedClinicalAccess,
    ) &&
    !currentUserRoles.includes(
      OrganizationUserRoleType.OrganizationUserRoleType_FullAccess,
    ) &&
    !currentUserRoles.includes(
      OrganizationUserRoleType.OrganizationUserRoleType_ClinicalAccess,
    );

  const usersOptions = useOrganizationUsersOptions(userID);

  const selectedDoctorsQuantity = doctors.length;

  const handleChange = (value: string | string[]) => {
    if (typeof value === 'string') {
      return;
    }

    setDoctors(value);
  };

  const handleApply = (filters: string | string[]) => {
    if (typeof filters === 'string') {
      return;
    }

    onChange(filters);
    searchParams.set('doctorIDs', filters.join(','));
    setSearchParams(searchParams);
  };

  const handleClear = () => {
    setDoctors([]);
    onChange([]);
    searchParams.delete('doctorIDs');
    setSearchParams(searchParams);
  };

  if (usersOptions.length === 1) {
    return null;
  }

  if (hasOnlyTreatingDoctorRole) {
    return (
      <div className={styles.container}>
        <span className={cn(styles.label, 'p2')}>
          {formatMessage({
            id: 'patientList.treatingDoctors',
            defaultMessage: 'Treating doctors',
          })}
        </span>
      </div>
    );
  }

  return (
    <DropdownSelect
      isMulti
      value={doctors}
      label={formatMessage({
        id: 'patientList.treatingDoctors',
        defaultMessage: 'Treating doctors',
      })}
      options={usersOptions}
      className={styles.container}
      stylesOptions={{ listMaxHeight: 206 }}
      icon="filter"
      multiValuesCount={
        selectedDoctorsQuantity > 0 ? selectedDoctorsQuantity : undefined
      }
      onChange={handleChange}
      onApply={handleApply}
      onClear={handleClear}
    />
  );
};
