import { FC, useCallback } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Avatar, Button, Icon, Tooltip, WidgetCard } from '@/shared/ui';
import {
  useAppDispatch,
  useAppSelector,
  useMedia,
  useModal,
} from '@/shared/hooks';

import { DoctorRow, DoctorsAvatars } from '@/entities/doctors';
import { patientModel } from '@/entities/patient';
import { ModalID, modalModel } from '@/entities/modal';

import {
  RemoveDoctorModal,
  useRemoveDoctorModal,
} from '@/features/removeDoctor';
import { AddDoctorsModal } from '@/features/treatingDoctors';
import { ShowAllTreatingDoctorsModal } from '@/features/showAllTreatingDoctors';
import { patientDoctorsModel } from '@/features/patientDoctors';

import { getShortedList } from '../../utils/getShortedList';
import { MAX_ROWS_COUNT } from '../../config/constants';

import styles from './TreatingDoctors.module.scss';

type TreatingDoctorsProps = {
  className?: string;
  patientID: string;
};

export const TreatingDoctors: FC<TreatingDoctorsProps> = (props) => {
  const { className, patientID } = props;

  const { isMobile } = useMedia();
  const dispatch = useAppDispatch();
  const patientTreatingDoctors = useAppSelector(
    patientDoctorsModel.selectors.selectPatientThreatingDoctors(patientID),
  );
  const { CanViewTeam, CanEditTeam } = useAppSelector(
    patientModel.selectors.selectPatientYourPermissions(patientID),
  );
  const {
    openRemoveDoctorModal,
    closeRemoveDoctorModal,
    isRemoveDoctorModalOpen,
    currentDoctorID,
  } = useRemoveDoctorModal();

  const {
    isOpen: isShowAllModalOpen,
    openModal: openShowAllModal,
    closeModal: closeShowAllModal,
  } = useModal();

  const openAddDoctorsModal = useCallback(() => {
    const currentDoctorIDs = patientTreatingDoctors?.map(
      (treatingDoctor) => treatingDoctor?.UserID,
    );
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.TreatingDoctorsModal,
        data: { patientID, currentDoctorIDs },
      }),
    );
  }, [dispatch, patientID, patientTreatingDoctors]);

  const onCardClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      if (isMobile) {
        openAddDoctorsModal();
      }
    },
    [isMobile, openAddDoctorsModal],
  );

  if (!CanViewTeam || !patientTreatingDoctors) {
    return null;
  }

  const shouldShowAllAppear = patientTreatingDoctors.length > MAX_ROWS_COUNT;

  const shortedTreatingDoctorsList = getShortedList(patientTreatingDoctors);

  return (
    <>
      <WidgetCard
        className={cn(styles.container, className)}
        onClick={onCardClick}
      >
        <div className={styles.header}>
          <h4 className={cn(isMobile ? 'h4m' : 'h4')}>
            <FormattedMessage
              id="patientInfo.treatingDoctors"
              defaultMessage="Treating doctors"
            />
          </h4>

          {CanEditTeam && (
            <Tooltip.Primary
              content={
                <FormattedMessage
                  id="patientInfo.addTreatingDoctor"
                  defaultMessage="Add a treating doctor"
                />
              }
            >
              <Icon
                name="boldPlus"
                className={styles.plusIcon}
                onClick={openAddDoctorsModal}
                size={isMobile ? 24 : 32}
              />
            </Tooltip.Primary>
          )}
        </div>

        {isMobile && (
          <div className={styles.mobileDoctorsList}>
            <Avatar.Group>
              {shortedTreatingDoctorsList.map((treatingDoctor) => (
                <DoctorsAvatars
                  doctor={treatingDoctor}
                  isOnlyOneDoctor={shortedTreatingDoctorsList.length === 1}
                  key={treatingDoctor.UserID}
                />
              ))}

              {patientTreatingDoctors.length >
                shortedTreatingDoctorsList.length && (
                <Avatar
                  size={32}
                  count={
                    patientTreatingDoctors.length -
                    shortedTreatingDoctorsList.length
                  }
                />
              )}
            </Avatar.Group>
          </div>
        )}

        {!isMobile && (
          <>
            {shortedTreatingDoctorsList.length > 0 ? (
              <div className={styles.doctorsList}>
                {shortedTreatingDoctorsList?.map((treatingDoctor) => (
                  <DoctorRow
                    key={treatingDoctor.UserID}
                    doctor={treatingDoctor}
                    onDelete={() =>
                      openRemoveDoctorModal(treatingDoctor?.UserID)
                    }
                  />
                ))}
              </div>
            ) : (
              <div className={styles.emptyList}>
                <Icon className={styles.emptyListIcon} name="users" size={64} />
              </div>
            )}

            {shouldShowAllAppear && (
              <Button
                variant="tertiary"
                size="medium"
                onClick={openShowAllModal}
              >
                <FormattedMessage
                  id="patientInfo.showAll"
                  defaultMessage="Show all"
                />
              </Button>
            )}
          </>
        )}
      </WidgetCard>

      <RemoveDoctorModal
        patientID={patientID}
        doctorID={currentDoctorID}
        isOpen={isRemoveDoctorModalOpen}
        onCancel={closeRemoveDoctorModal}
      />

      <AddDoctorsModal />

      <ShowAllTreatingDoctorsModal
        isOpen={isShowAllModalOpen}
        onClose={closeShowAllModal}
        treatingDoctors={patientTreatingDoctors}
        openRemoveDoctorModal={openRemoveDoctorModal}
      />
    </>
  );
};
