// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file pipelines/report_studio_implant.proto (package com.diagnocat.pipelines, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { CBCTSeriesGeometryData, StudioColorProfile } from "../model/dto_common_geometry_pb.js";
import { Report_Studio_ToothPosition } from "../model/dto_report_type_studio_common_pb.js";
import { CephalometricLandmarks_Update, TeethLandmarks_Update } from "./report_landmarks_pb.js";
import { Segmentron_PhotoIOSSuperimposition } from "../model/dto_report_segmentron_common_pb.js";
import { Report_Studio_Implant_CrownImplantPlan } from "../model/dto_report_type_studio_implant_pb.js";

/**
 * @generated from message com.diagnocat.pipelines.Studio_Implant_Update
 */
export const Studio_Implant_Update = proto3.makeMessageType(
  "com.diagnocat.pipelines.Studio_Implant_Update",
  () => [
    { no: 1, name: "CropGeometryData", kind: "message", T: CBCTSeriesGeometryData, oneof: "Update" },
    { no: 2, name: "ColorProfile", kind: "message", T: StudioColorProfile, oneof: "Update" },
    { no: 3, name: "ToothPosition", kind: "message", T: Report_Studio_ToothPosition, oneof: "Update" },
    { no: 5, name: "Plans", kind: "message", T: Studio_Implant_Update_Plans_Update, oneof: "Update" },
    { no: 9, name: "TeethLandmarks", kind: "message", T: TeethLandmarks_Update, oneof: "Update" },
    { no: 7, name: "CephalometricLandmarks", kind: "message", T: CephalometricLandmarks_Update, oneof: "Update" },
    { no: 8, name: "PhotoIOSSuperimposition", kind: "message", T: Segmentron_PhotoIOSSuperimposition, oneof: "Update" },
  ],
);

/**
 * @generated from message com.diagnocat.pipelines.Studio_Implant_Update.Plans_Update
 */
export const Studio_Implant_Update_Plans_Update = proto3.makeMessageType(
  "com.diagnocat.pipelines.Studio_Implant_Update.Plans_Update",
  () => [
    { no: 1, name: "Plans", kind: "message", T: Report_Studio_Implant_CrownImplantPlan, repeated: true },
  ],
  {localName: "Studio_Implant_Update_Plans_Update"},
);

