import { defineMessages } from 'react-intl';

export const i18n = defineMessages({
  myProfile: {
    id: 'controlPanel.myProfile',
    defaultMessage: 'My profile',
  },
  myClinic: {
    id: 'controlPanel.myClinic',
    defaultMessage: 'My clinic',
  },
  otherClinics: {
    id: 'controlPanel.otherClinics',
    defaultMessage: 'Other clinics',
  },
  currentClinic: {
    id: 'controlPanel.currentClinic',
    defaultMessage: 'Current clinic',
  },
});
