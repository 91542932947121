import { useMemo } from 'react';

import { useAppSelector } from '@/shared/hooks';
import { MedicalImageInterface } from '@/shared/config';
import { Asset } from '@/shared/api/protocol-ts/model/dto_asset_pb';

import { makeAnnotations } from '../lib/makeAnnotations';
import { makeImageViewOptions } from '../lib/makeImageViewOptions';
import { toothModel } from '../../tooth';
import { getFileSrc } from '../lib/getFileSrc';
import * as assetsModel from '../model';
import { TOOTH_PAGE_GROUPS, TOOTH_PAGE_PATHOLOGY_GROUPS } from '../config';
import {
  getGeneratedReportOrder,
  getMedicalImageInfo,
  getMedicalImageKind,
} from '../lib';

export const getMedicalImageGroupName = (medicalImage: Asset): string => {
  switch (medicalImage.Kind.case) {
    case 'GeneratedReport': {
      const generatedReport = medicalImage.Kind.value;

      switch (generatedReport.Content.case) {
        case 'CBCTGPToothSlice': {
          const groupCode = generatedReport.Content.value.Orientation;
          const group = TOOTH_PAGE_GROUPS.find(
            (group) => group.groupCode === groupCode,
          );

          return group?.groupName ?? '';
        }
        case 'CBCTToothPathologySlice': {
          const groupCode = generatedReport.Content.value.Pathology;
          const group = TOOTH_PAGE_PATHOLOGY_GROUPS.find(
            (group) => group.groupCode === groupCode,
          );

          return group?.groupName ?? '';
        }
        default:
          return '';
      }
    }
    case 'Report': {
      const report = medicalImage.Kind.value;

      switch (report.Content.case) {
        case 'UserUploadedToothSlice':
          return 'UserUpload';
        case 'UserUploadedMPRPlane':
          return 'SavedMPR';
        default:
          return '';
      }
    }
    default:
      return '';
  }
};

export const useGetMedicalImage = (
  assetID: string,
): MedicalImageInterface | undefined => {
  const asset = useAppSelector(assetsModel.selectors.selectEntities)[assetID];
  if (!asset) {
    return;
  }
  const reportAsset =
    asset?.Kind.case === 'Report' ? asset.Kind.value : undefined;

  const { medicalImageID, medicalImagePath } = getMedicalImageInfo(asset);
  if (!medicalImageID) {
    return;
  }
  const generatedReportAsset =
    asset?.Kind.case === 'GeneratedReport' ? asset.Kind.value : undefined;

  const annotations = makeAnnotations(
    generatedReportAsset?.MedicalImageFeatures?.Annotations?.Layers ??
      reportAsset?.MedicalImageFeatures?.Annotations?.Layers,
  );

  const viewOptions = makeImageViewOptions(
    generatedReportAsset?.MedicalImageFeatures?.ViewOptions ??
      reportAsset?.MedicalImageFeatures?.ViewOptions,
  );

  const slice =
    generatedReportAsset?.Content.case === 'CBCTGPToothSlice'
      ? generatedReportAsset.Content.value
      : undefined;

  const regularMedicalImage =
    slice?.Metadata.case == 'Regular' ? slice?.Metadata.value : undefined;

  return {
    id: slice?.Slice?.ID || '',
    src: getFileSrc(medicalImageID),
    kind: getMedicalImageKind(reportAsset?.Content),
    image:
      slice && slice.Slice?.Metadata.case === 'Image'
        ? {
            Height: Number(slice.Slice.Metadata.value.Height),
            Width: Number(slice.Slice.Metadata.value.Width),
          }
        : undefined,
    path: medicalImagePath,
    assetID: asset.ID,
    groupName: getMedicalImageGroupName(asset),
    thicknessMm: regularMedicalImage?.Thickness,
    strideMm: regularMedicalImage?.Stride,
    annotations,
    viewOptions,
    order: getGeneratedReportOrder(generatedReportAsset),
  };
};

export const useGetSelectedMedicalImages = (
  toothID: string,
): MedicalImageInterface[] => {
  const assets = useAppSelector(assetsModel.selectors.selectEntities);

  const selectUserAssets =
    assetsModel.selectors.makeSelectorUserUploadedAssetsByToothID(toothID);

  const userAssets = useAppSelector(selectUserAssets);

  const displaySlices = useAppSelector(
    toothModel.selectors.selectDisplaySlicesByToothID(toothID),
  );

  const userAssetsIDs = userAssets.map((asset) => asset.ID);

  return useMemo(
    () =>
      displaySlices?.reduce((acc, selectedMedicalImageID) => {
        const currentMedicalImage = assets[selectedMedicalImageID];

        const { medicalImageID, medicalImagePath } =
          getMedicalImageInfo(currentMedicalImage);

        const currentImageID = currentMedicalImage?.ID ?? '';

        const generatedReportAsset =
          currentMedicalImage?.Kind.case === 'GeneratedReport'
            ? currentMedicalImage.Kind.value
            : undefined;

        const reportAsset =
          currentMedicalImage?.Kind.case === 'Report'
            ? currentMedicalImage.Kind.value
            : undefined;

        const generatedReportOrder =
          getGeneratedReportOrder(generatedReportAsset);

        const order =
          generatedReportOrder ?? userAssetsIDs.indexOf(currentImageID);

        const annotations = makeAnnotations(
          generatedReportAsset?.MedicalImageFeatures?.Annotations?.Layers ??
            reportAsset?.MedicalImageFeatures?.Annotations?.Layers,
        );

        const viewOptions = makeImageViewOptions(
          generatedReportAsset?.MedicalImageFeatures?.ViewOptions ??
            reportAsset?.MedicalImageFeatures?.ViewOptions,
        );

        if (medicalImageID && currentMedicalImage) {
          acc.push({
            src: getFileSrc(medicalImageID),
            kind: getMedicalImageKind(reportAsset?.Content),
            id: selectedMedicalImageID,
            path: medicalImagePath,
            assetID: currentImageID,
            groupName: getMedicalImageGroupName(currentMedicalImage),
            annotations,
            viewOptions,
            order,
          });
        }

        return acc;
      }, [] as MedicalImageInterface[]) ?? [],
    [displaySlices, assets, userAssetsIDs],
  ).sort((a, b) => a?.order ?? 0 - (b?.order ?? 0));
};
