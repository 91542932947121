import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';

import { ServiceType } from '@/shared/api/protocol-ts/api/billing_new/dto_services_new_pb.js';
import { AssetType } from '@/shared/api/protocol-ts/model/dto_asset_pb';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb.js';
import {
  ReportRequest_InputType_CBCT_IOS_Superimposition,
  ReportRequest_InputType_StudioImplant,
  ReportRequest_InputType_StudioOrtho,
} from '@/shared/api/protocol-ts/model/dto_report_request_pb.js';
import { StudyType } from '@/shared/api/protocol-ts/model/dto_study_pb';
import { useAppDispatch, useAppSelector, useMedia } from '@/shared/hooks';
import { Checkbox, Icon, Modal, Tooltip } from '@/shared/ui';

import { billingModel } from '@/entities/billing/index.ts';
import { ModalID, modalModel } from '@/entities/modal';
import { organizationModel } from '@/entities/organization/index.ts';
import { patientModel } from '@/entities/patient';
import { studyModel } from '@/entities/study';

import { useNewReport } from '@/features/addNewReport';
import { useUploadAssetContext } from '@/features/uploadAsset';

import {
  ORDER_REPORT_ASSET_TYPE,
  ORDER_REPORT_STUDY_TYPE,
  OrderReportModalStep,
  UPLOAD_STUDY_FORM_ID,
  UploadStudyPayload,
  uploadStudySchema,
} from '../../config';
import { orderReportTitle } from '../../config/i18n';
import {
  selectFirstStudyID,
  selectSecondaryCBCTStudyID,
} from '../../model/orderReportModal.selectors';
import { DentalPhotosStep } from '../DentalPhotosStep/DentalPhotosStep.tsx';
import { OrderReportStep } from '../OrderReportStep/OrderReportStep.tsx';

import styles from './OrderReportModal.module.scss';

export const OrderReportModal = () => {
  const dispatch = useAppDispatch();
  const { patientID } = useParams();
  const { formatMessage } = useIntl();
  const { isMobile } = useMedia();

  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [IOSMeshesFiles, setIOSMeshesFFiles] = useState<FileWithPath[]>([]);
  const [dentalPhotoFiles, setDentalPhotoFiles] = useState<FileWithPath[]>([]);
  const [secondaryCBCTFiles, setSecondaryCBCTFiles] = useState<FileWithPath[]>(
    [],
  );
  const [filesUploadErrorMessage, setFilesUploadErrorMessage] = useState('');
  const [
    IOSMeshesFilesUploadErrorMessage,
    setIOSMeshesFilesUploadErrorMessage,
  ] = useState('');
  const [
    dentalPhotoFilesUploadErrorMessage,
    setDentalPhotoFilesUploadErrorMessage,
  ] = useState('');
  const [
    secondaryCBCTFilesUploadErrorMessage,
    setSecondaryCBCTFilesUploadErrorMessage,
  ] = useState('');
  const [showSecondaryCBCTSelect, setShowSecondaryCBCTSelect] = useState(false);
  const [selectedDentalPhotoIDs, setSelectedDentalPhotoIDs] = useState<
    string[]
  >([]);

  const {
    visible,
    data: { reportType },
  } = useAppSelector(modalModel.selectors.selectOrderReportModal);

  const isImplantStudioAnalysisAvailable = useAppSelector(
    billingModel.selectors.selectIsServiceActiveByType(
      ServiceType.ServiceType_Order_ImplantStudio_Analysis,
    ),
  );
  const isCBCTIOSSuperimpositionAnalysisAvailable = useAppSelector(
    billingModel.selectors.selectIsServiceActiveByType(
      ServiceType.ServiceType_Order_CBCT_IOS_Superimposition_Analysis,
    ),
  );
  const isOrthoStudioAnalysisAvailable = useAppSelector(
    billingModel.selectors.selectIsServiceActiveByType(
      ServiceType.ServiceType_Order_OrthoStudio_Analysis,
    ),
  );
  const showOrthoStudioAddSecondaryCBCT = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Show_OrthoStudioAddSecondaryCBCT,
    ),
  );
  const showDentalPhotosWidget = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Show_DentalPhotosWidget,
    ),
  );
  const patientName = useAppSelector(
    patientModel.selectors.selectPatientFullName(patientID as string),
  );
  const firstMainStudyID = useAppSelector(
    selectFirstStudyID(ORDER_REPORT_STUDY_TYPE[reportType]),
  );
  const firstIOSMeshesStudyID = useAppSelector(
    selectFirstStudyID(StudyType.StudyType_IOS_Meshes),
  );
  const dentalPhotoStudies = useAppSelector(
    studyModel.selectors.selectDentalPhotoStudies,
  );
  const isIOSMeshesOptionalForImplantStudio = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Enable_ImplantStudioOptionalIOS,
    ),
  );

  const { startUploadAsset: startUploadStudy } = useUploadAssetContext();

  const {
    requestCBCTGPReport,
    requestIOXRayGPReport,
    requestPanoGPReport,
    requestCBCTSegmentationReport,
    requestStudioOrthoReport,
    requestStudioImplantReport,
    requestCBCTIOSSuperimpositionReport,
    requestCBCTOrthoReport,
    requestIOSSegmentationReport,
  } = useNewReport();

  const { watch, control, handleSubmit, reset, resetField, setValue } =
    useForm<UploadStudyPayload>({
      resolver: yupResolver(uploadStudySchema),
    });

  const canOrderImplantStudioReport =
    isImplantStudioAnalysisAvailable && reportType === 'ImplantStudio';
  const canOrderCBCTIOSSuperimpositionReport =
    isCBCTIOSSuperimpositionAnalysisAvailable && reportType === '3DStudio';
  const canOrderOrthoStudioAnalysis =
    isOrthoStudioAnalysisAvailable && reportType === 'OrthoStudio';

  const firstStepStudyAssetType = ORDER_REPORT_ASSET_TYPE[reportType];

  const isSecondStepAvailable =
    canOrderImplantStudioReport ||
    canOrderCBCTIOSSuperimpositionReport ||
    canOrderOrthoStudioAnalysis;

  const isSecondStepOptional =
    canOrderCBCTIOSSuperimpositionReport ||
    canOrderOrthoStudioAnalysis ||
    (isIOSMeshesOptionalForImplantStudio && canOrderImplantStudioReport);

  const currentMainStudyID = watch('mainStudyID');

  const secondaryCBCTStudyID = useAppSelector(
    selectSecondaryCBCTStudyID(currentMainStudyID ?? ''),
  );

  const currentIOSMeshesStudyID = watch('IOSMeshesStudyID');
  const currentDentalPhotoStudyID = watch('DentalPhotoStudyID');
  const currentSecondaryCBCTStudyID = watch('SecondaryCBCTStudyID');

  const uploadFilesWithError = !!(
    filesUploadErrorMessage ||
    IOSMeshesFilesUploadErrorMessage ||
    dentalPhotoFilesUploadErrorMessage ||
    secondaryCBCTFilesUploadErrorMessage
  );

  const isMainFilesExists = files.length > 0;
  const isIOSMeshesFilesExists = IOSMeshesFiles.length > 0;
  const isDentalPhotoFilesExists = dentalPhotoFiles.length > 0;
  const isSecondaryCBCTFilesExists = secondaryCBCTFiles.length > 0;

  const isMainStudySelectedOrFilesToUpload =
    currentMainStudyID || isMainFilesExists;
  const isMeshedStudySelectedOrFilesToUpload =
    currentIOSMeshesStudyID || isIOSMeshesFilesExists;
  const isSecondaryCBCTSelectedOrFilesToUpload =
    currentSecondaryCBCTStudyID || isSecondaryCBCTFilesExists;

  const showDentalPhotoUpload =
    (canOrderImplantStudioReport ||
      canOrderCBCTIOSSuperimpositionReport ||
      canOrderOrthoStudioAnalysis) &&
    isMeshedStudySelectedOrFilesToUpload;

  const isSubmitEnabled =
    isMainStudySelectedOrFilesToUpload &&
    (!isSecondStepAvailable ||
      isSecondStepOptional ||
      isMeshedStudySelectedOrFilesToUpload) &&
    (!showSecondaryCBCTSelect || isSecondaryCBCTSelectedOrFilesToUpload) &&
    !uploadFilesWithError;

  const setErrorMessage =
    (filesStep: OrderReportModalStep) => (errorMessage: string) => {
      switch (filesStep) {
        case 1: {
          setFilesUploadErrorMessage(errorMessage);
          break;
        }
        case 2: {
          setIOSMeshesFilesUploadErrorMessage(errorMessage);
          break;
        }
        case 3: {
          setDentalPhotoFilesUploadErrorMessage(errorMessage);
          break;
        }
        case 4: {
          setSecondaryCBCTFilesUploadErrorMessage(errorMessage);
        }
      }
    };

  const addFiles =
    (filesStep: OrderReportModalStep) => (acceptedFiles: FileWithPath[]) => {
      switch (filesStep) {
        case 1: {
          setFilesUploadErrorMessage('');
          setFiles((currentFiles) => currentFiles.concat(acceptedFiles));
          break;
        }
        case 2: {
          setIOSMeshesFilesUploadErrorMessage('');
          setIOSMeshesFFiles((currentFiles) =>
            currentFiles.concat(acceptedFiles),
          );
          break;
        }
        case 3: {
          setDentalPhotoFilesUploadErrorMessage('');
          setDentalPhotoFiles((currentFiles) =>
            currentFiles.concat(acceptedFiles),
          );
          break;
        }
        case 4: {
          setSecondaryCBCTFilesUploadErrorMessage('');
          setSecondaryCBCTFiles((currentFiles) =>
            currentFiles.concat(acceptedFiles),
          );
        }
      }
    };

  const removeFiles = (filesStep: OrderReportModalStep) => () => {
    switch (filesStep) {
      case 1: {
        setFiles([]);
        resetField('mainStudyID');
        break;
      }
      case 2: {
        setIOSMeshesFFiles([]);
        resetField('IOSMeshesStudyID');
        break;
      }
      case 3: {
        setDentalPhotoFiles([]);
        resetField('DentalPhotoStudyID');
        break;
      }
      case 4: {
        setSecondaryCBCTFiles([]);
        resetField('SecondaryCBCTStudyID');
      }
    }
  };

  const removeAllFiles = () => {
    setFiles([]);
    setIOSMeshesFFiles([]);
    setDentalPhotoFiles([]);
    setSecondaryCBCTFiles([]);
  };

  const showSecondaryCBCTSelectToggle = () => {
    setShowSecondaryCBCTSelect(!showSecondaryCBCTSelect);

    if (showSecondaryCBCTSelect) {
      resetField('SecondaryCBCTStudyID');
    } else {
      setValue('SecondaryCBCTStudyID', secondaryCBCTStudyID);
    }
  };

  const handleClose = useCallback(() => {
    reset();

    setFilesUploadErrorMessage('');
    setIOSMeshesFilesUploadErrorMessage('');
    setDentalPhotoFilesUploadErrorMessage('');
    setSecondaryCBCTFilesUploadErrorMessage('');

    dispatch(modalModel.actions.closeModal(ModalID.OrderReport));

    removeAllFiles();

    if (showSecondaryCBCTSelect) {
      showSecondaryCBCTSelectToggle();
    }
  }, [dispatch, removeAllFiles]);

  const onSubmit: SubmitHandler<UploadStudyPayload> = async (data) => {
    handleClose();

    const uploadStudyPromises: Promise<void>[] = [];
    const {
      mainStudyID: currentMainStudyID,
      IOSMeshesStudyID: currentIOSMeshesStudyID,
      DentalPhotoStudyID: currentDentalPhotoStudyID,
      SecondaryCBCTStudyID: currentSecondaryCBCTStudyID,
    } = data;

    let mainStudyID = currentMainStudyID;
    let IOSMeshesStudyID = currentIOSMeshesStudyID;
    let DentalPhotoStudyID = currentDentalPhotoStudyID
      ? [currentDentalPhotoStudyID]
      : [];
    let SecondaryCBCTStudyID = currentSecondaryCBCTStudyID;

    if (isMainFilesExists) {
      const uploadStudyPromise = startUploadStudy({
        patientID,
        files,
        assetType: firstStepStudyAssetType,
        meta: {
          studyType: firstStepStudyAssetType,
          patientName,
        },
      }).then(({ studyID }) => {
        mainStudyID = studyID;
      });

      uploadStudyPromises.push(uploadStudyPromise);
    }

    if (isIOSMeshesFilesExists) {
      const uploadIOSMeshesStudyPromise = startUploadStudy({
        patientID,
        files: IOSMeshesFiles,
        assetType: AssetType.AssetType_Study_IOS_Meshes,
        meta: {
          studyType: AssetType.AssetType_Study_IOS_Meshes,
          patientName,
        },
      }).then(({ studyID }) => {
        IOSMeshesStudyID = studyID;
      });

      uploadStudyPromises.push(uploadIOSMeshesStudyPromise);
    }

    // Add the uploadDentalPhotoStudy promise if there are dental photo files
    if (isDentalPhotoFilesExists) {
      const uploadDentalPhotoStudyPromise = startUploadStudy({
        patientID,
        files: dentalPhotoFiles,
        assetType: AssetType.AssetType_Study_DentalPhoto,
        meta: {
          studyType: AssetType.AssetType_Study_DentalPhoto,
          patientName,
        },
      }).then(({ studyID }) => {
        DentalPhotoStudyID = studyID ? [studyID] : [];
      });

      uploadStudyPromises.push(uploadDentalPhotoStudyPromise);
    }

    if (isSecondaryCBCTFilesExists) {
      const uploadSecondaryCBCTStudyPromise = startUploadStudy({
        patientID,
        files: secondaryCBCTFiles,
        assetType: AssetType.AssetType_Study_CBCT,
        meta: {
          studyType: AssetType.AssetType_Study_CBCT,
          patientName,
        },
      }).then(({ studyID }) => {
        SecondaryCBCTStudyID = studyID;
      });

      uploadStudyPromises.push(uploadSecondaryCBCTStudyPromise);
    }

    const uploadStudyResults = await Promise.allSettled(uploadStudyPromises);
    // TODO: remove logic from DentalPhotoStudyIDs to StudyAssetIDs after protocol update
    if (showDentalPhotosWidget && dentalPhotoStudies.length > 0) {
      // @ts-expect-error WARN: after protocol refactoring type will change
      DentalPhotoStudyID = selectedDentalPhotoIDs.map(
        (selectedAssetID) =>
          dentalPhotoStudies.find(
            (dentalPhotoStudy) =>
              selectedAssetID === dentalPhotoStudy.AssetIDs.at(0),
          )?.ID,
      );
    }

    if (
      mainStudyID &&
      !uploadStudyResults.some((result) => result.status === 'rejected')
    ) {
      switch (reportType) {
        case 'CBCT': {
          requestCBCTGPReport(mainStudyID);
          break;
        }
        case 'Pano': {
          requestPanoGPReport(mainStudyID);
          break;
        }
        case 'IOXRay': {
          requestIOXRayGPReport(mainStudyID);
          break;
        }
        case 'IOSSegmentation': {
          requestIOSSegmentationReport(mainStudyID);
          break;
        }
        case '3DStudio': {
          isCBCTIOSSuperimpositionAnalysisAvailable && IOSMeshesStudyID
            ? requestCBCTIOSSuperimpositionReport(
                new ReportRequest_InputType_CBCT_IOS_Superimposition({
                  CBCTStudyID: mainStudyID,
                  STLStudyID: IOSMeshesStudyID,
                  DentalPhotoStudyIDs: DentalPhotoStudyID ?? [],
                }),
              )
            : requestCBCTSegmentationReport(mainStudyID);
          break;
        }
        case 'ImplantStudio': {
          requestStudioImplantReport(
            new ReportRequest_InputType_StudioImplant({
              CBCTStudyID: mainStudyID,
              IOSMeshesStudyID,
              DentalPhotoStudyIDs: DentalPhotoStudyID ?? [],
            }),
          );
          break;
        }
        case 'OrthoStudio': {
          isOrthoStudioAnalysisAvailable
            ? requestStudioOrthoReport(
                new ReportRequest_InputType_StudioOrtho({
                  CBCTStudyID: mainStudyID,
                  IOSMeshesStudyID,
                  DentalPhotoStudyIDs: DentalPhotoStudyID ?? [],
                  SecondaryCBCTStudyID,
                }),
              )
            : requestCBCTOrthoReport(mainStudyID);

          if (showSecondaryCBCTSelect) {
            showSecondaryCBCTSelectToggle();
          }
          break;
        }

        default: {
          break;
        }
      }

      setSelectedDentalPhotoIDs([]);
    }
  };

  useEffect(() => {
    reset({
      mainStudyID: firstMainStudyID,
      IOSMeshesStudyID: isSecondStepOptional
        ? undefined
        : firstIOSMeshesStudyID,
      SecondaryCBCTStudyID: undefined,
    });
  }, [firstMainStudyID, firstIOSMeshesStudyID, isSecondStepOptional]);

  useEffect(() => {
    if (
      showSecondaryCBCTSelect &&
      currentMainStudyID === currentSecondaryCBCTStudyID
    ) {
      setValue('SecondaryCBCTStudyID', secondaryCBCTStudyID);
    }
  }, [currentMainStudyID]);

  return (
    <Modal
      containerClassName={styles.modalContainer}
      bodyClassName={styles.modalBody}
      title={formatMessage(orderReportTitle[reportType])}
      isOpen={visible}
      onCancel={handleClose}
      borderless
      okButtonText={
        <FormattedMessage
          id="orderReportModal.applyButtonText"
          defaultMessage="Plan"
        />
      }
      okButtonProps={{
        type: 'submit',
        form: UPLOAD_STUDY_FORM_ID,
        disabled: !isSubmitEnabled,
      }}
      cancelButtonProps={{
        variant: 'tertiary',
      }}
      shouldRenderCancelButton={!isMobile}
    >
      <form
        id={UPLOAD_STUDY_FORM_ID}
        onSubmit={handleSubmit(onSubmit)}
        className={styles.container}
      >
        <OrderReportStep
          control={control}
          currentStudyID={currentMainStudyID}
          step={isSecondStepAvailable ? 1 : undefined}
          orderReportType={reportType}
          studyType={ORDER_REPORT_STUDY_TYPE[reportType]}
          assetType={firstStepStudyAssetType}
          errorMessage={filesUploadErrorMessage}
          files={files}
          onlyUpload={reportType === 'IOSSegmentation'}
          onAddFiles={addFiles(1)}
          onDeleteFiles={removeFiles(1)}
          onError={setErrorMessage(1)}
        />

        {isSecondStepAvailable && (
          <OrderReportStep
            control={control}
            currentStudyID={currentIOSMeshesStudyID}
            step={2}
            orderReportType={reportType}
            studyType={StudyType.StudyType_IOS_Meshes}
            assetType={AssetType.AssetType_Study_IOS_Meshes}
            errorMessage={IOSMeshesFilesUploadErrorMessage}
            files={IOSMeshesFiles}
            onAddFiles={addFiles(2)}
            onDeleteFiles={removeFiles(2)}
            onError={setErrorMessage(2)}
            onReset={() => resetField('IOSMeshesStudyID')}
            isOptional={isSecondStepOptional}
            infoTooltip={{
              show: isSecondStepAvailable,
              message: formatMessage({
                id: 'orderReportModal.superimpositionInfo',
                defaultMessage:
                  'Requires 1 file for upper jaw and 1 file for lower jaw',
              }),
            }}
          />
        )}

        {showDentalPhotoUpload &&
          (showDentalPhotosWidget ? (
            <DentalPhotosStep
              selectedIDs={selectedDentalPhotoIDs}
              setSelectedIDs={setSelectedDentalPhotoIDs}
            />
          ) : (
            <OrderReportStep
              control={control}
              currentStudyID={currentDentalPhotoStudyID}
              step={3}
              orderReportType={reportType}
              studyType={StudyType.StudyType_DentalPhoto}
              assetType={AssetType.AssetType_Study_DentalPhoto}
              errorMessage={dentalPhotoFilesUploadErrorMessage}
              files={dentalPhotoFiles}
              isOptional
              onlyUpload
              onAddFiles={addFiles(3)}
              onDeleteFiles={removeFiles(3)}
              onReset={() => resetField('DentalPhotoStudyID')}
              onError={setErrorMessage(3)}
            />
          ))}

        {canOrderOrthoStudioAnalysis && showOrthoStudioAddSecondaryCBCT && (
          <>
            <Checkbox
              checked={showSecondaryCBCTSelect}
              onChange={showSecondaryCBCTSelectToggle}
              className={styles.checkbox}
            >
              <div className={styles.checkboxContent}>
                <FormattedMessage
                  id="orderReportModal.addPreviousCBCT"
                  defaultMessage="Add previous CBCT"
                />

                <Tooltip.Primary
                  content={formatMessage({
                    id: 'orderReportModal.addPreviousCBCTInfo',
                    defaultMessage:
                      'Secondary CBCT will be superimposed with the main CBCT',
                  })}
                  triggerClassName={styles.infoTooltip}
                >
                  <Icon name="info" size={28} className={styles.infoIcon} />
                </Tooltip.Primary>
              </div>
            </Checkbox>

            {showSecondaryCBCTSelect && (
              <OrderReportStep
                control={control}
                currentStudyID={currentSecondaryCBCTStudyID}
                step={4}
                orderReportType={'CBCT'}
                studyType={StudyType.StudyType_CBCT}
                assetType={AssetType.AssetType_Study_CBCT}
                errorMessage={secondaryCBCTFilesUploadErrorMessage}
                files={secondaryCBCTFiles}
                isAlignmentCBCTReportOrder={showSecondaryCBCTSelect}
                onAddFiles={addFiles(4)}
                onDeleteFiles={removeFiles(4)}
                onError={setErrorMessage(4)}
              />
            )}
          </>
        )}
      </form>
    </Modal>
  );
};
