import { DEFAULT_LOCALE } from '../config';

const getInitial = (name: string) => (name ? name[0].toUpperCase() + '.' : '');

type NameConfig = {
  userLocale?: string;
  shortFirstName?: boolean;
  shortLastName?: boolean;
};

export const getName = (
  firstName: string = ' ',
  lastName: string = ' ',
  config?: NameConfig,
): string => {
  const {
    userLocale = DEFAULT_LOCALE,
    shortFirstName = false,
    shortLastName = false,
  } = config || {};

  if (userLocale === 'ru') {
    const first = shortLastName ? getInitial(firstName) : firstName;
    const last = shortFirstName ? getInitial(lastName) : lastName;
    return `${last} ${first}`;
  }

  const first = shortFirstName ? getInitial(firstName) : firstName;
  const last = shortLastName ? getInitial(lastName) : lastName;
  return `${first} ${last}`;
};
