// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file pipelines/report_studio_ortho.proto (package com.diagnocat.pipelines, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { CBCTSeriesGeometryData, ProjectionTransform, StudioColorProfile } from "../model/dto_common_geometry_pb.js";
import { Report_Studio_ToothPosition } from "../model/dto_report_type_studio_common_pb.js";
import { Report_Studio_Ortho_CephalometricMeasurements, Report_Studio_Ortho_OrthoPlanes, Report_Studio_Ortho_TeethAnalysis, Report_Studio_Ortho_TeethMovementPlan } from "../model/dto_report_type_studio_ortho_pb.js";
import { CephalometricLandmarks_Update, TeethLandmarks_Update } from "./report_landmarks_pb.js";
import { Segmentron_PhotoIOSSuperimposition } from "../model/dto_report_segmentron_common_pb.js";

/**
 * @generated from message com.diagnocat.pipelines.Studio_Ortho_Update
 */
export const Studio_Ortho_Update = proto3.makeMessageType(
  "com.diagnocat.pipelines.Studio_Ortho_Update",
  () => [
    { no: 1, name: "CropGeometryData", kind: "message", T: CBCTSeriesGeometryData, oneof: "Update" },
    { no: 2, name: "ColorProfile", kind: "message", T: StudioColorProfile, oneof: "Update" },
    { no: 3, name: "ToothPosition", kind: "message", T: Report_Studio_ToothPosition, oneof: "Update" },
    { no: 5, name: "NaturalHeadPositionTransform", kind: "message", T: ProjectionTransform, oneof: "Update" },
    { no: 6, name: "OrthoPlanes", kind: "message", T: Report_Studio_Ortho_OrthoPlanes, oneof: "Update" },
    { no: 7, name: "TeethMovementPlans", kind: "message", T: Studio_Ortho_Update_TeethMovementPlans_Update, oneof: "Update" },
    { no: 8, name: "CephalometricMeasurements", kind: "message", T: Studio_Ortho_Update_CephalometricMeasurements_Update, oneof: "Update" },
    { no: 9, name: "TeethLandmarks", kind: "message", T: TeethLandmarks_Update, oneof: "Update" },
    { no: 10, name: "CephalometricLandmarks", kind: "message", T: CephalometricLandmarks_Update, oneof: "Update" },
    { no: 11, name: "PhotoIOSSuperimposition", kind: "message", T: Segmentron_PhotoIOSSuperimposition, oneof: "Update" },
    { no: 12, name: "TeethAnalysis", kind: "message", T: Report_Studio_Ortho_TeethAnalysis, oneof: "Update" },
  ],
);

/**
 * @generated from message com.diagnocat.pipelines.Studio_Ortho_Update.TeethMovementPlans_Update
 */
export const Studio_Ortho_Update_TeethMovementPlans_Update = proto3.makeMessageType(
  "com.diagnocat.pipelines.Studio_Ortho_Update.TeethMovementPlans_Update",
  () => [
    { no: 1, name: "Plans", kind: "message", T: Report_Studio_Ortho_TeethMovementPlan, repeated: true },
  ],
  {localName: "Studio_Ortho_Update_TeethMovementPlans_Update"},
);

/**
 * @generated from message com.diagnocat.pipelines.Studio_Ortho_Update.CephalometricMeasurements_Update
 */
export const Studio_Ortho_Update_CephalometricMeasurements_Update = proto3.makeMessageType(
  "com.diagnocat.pipelines.Studio_Ortho_Update.CephalometricMeasurements_Update",
  () => [
    { no: 1, name: "Measurements", kind: "message", T: Report_Studio_Ortho_CephalometricMeasurements, repeated: true },
  ],
  {localName: "Studio_Ortho_Update_CephalometricMeasurements_Update"},
);

