import { FC } from 'react';
import cn from 'classnames';
import { generatePath, Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Avatar } from '@/shared/ui';
import { PATHS } from '@/shared/config';
import { useAppSelector } from '@/shared/hooks';
import { getImageSrc } from '@/shared/lib';

import { StudiesCounters } from '@/entities/patient';
import { ensureRefreshImageSrc } from '@/entities/assets';
import { DoctorsAvatars } from '@/entities/doctors';

import { patientDoctorsModel } from '@/features/patientDoctors';

import styles from './PatientListCard.module.scss';

type PatientListCardProps = {
  patientID: string;
  patientExternalID: string;
  patientName: string;
  patientBirthDate: string;
  displayAssetID: string;
  className?: string;
  testID?: string;
  disableStudies?: boolean;
};

export const PatientListCard: FC<PatientListCardProps> = (props) => {
  const {
    testID,
    className,
    patientID,
    patientName,
    disableStudies,
    displayAssetID,
    patientBirthDate,
    patientExternalID,
  } = props;

  const { formatMessage } = useIntl();

  const allPatientDoctors = useAppSelector(
    patientDoctorsModel.selectors.selectAllPatientDoctors(patientID),
  );

  const isOnlyOneDoctor = allPatientDoctors?.length === 1;

  const isEmptyPreview = !displayAssetID;

  const moreThanFiveDoctors =
    allPatientDoctors && allPatientDoctors?.length > 5;

  const visibleDoctors = moreThanFiveDoctors
    ? allPatientDoctors.slice(0, 4)
    : allPatientDoctors;

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      <header className={styles.patientCardHeader}>
        <Link to={generatePath(PATHS.patientProfile_DEPRECATED, { patientID })}>
          <div
            className={cn(
              styles.previewContainer,
              isEmptyPreview && styles.empty,
            )}
          >
            {isEmptyPreview ? (
              <div className={styles.previewDefaultImage} />
            ) : (
              <img
                className={styles.preview}
                src={getImageSrc(displayAssetID, 'preview')}
                onError={ensureRefreshImageSrc}
                alt={formatMessage({
                  id: 'imgAltText.patientPreview',
                  defaultMessage: 'Patient preview',
                })}
              />
            )}
          </div>
        </Link>
      </header>

      <div className={styles.patientInfo}>
        <Link to={generatePath(PATHS.patientProfile_DEPRECATED, { patientID })}>
          <h4 className={cn(styles.patientName, 'h4')}>{patientName}</h4>

          <p className={cn(styles.patientID, 'p2')}>
            {formatMessage({
              id: 'patientInfo.externalID',
              defaultMessage: 'ID',
            })}

            {': '}

            {patientExternalID}
          </p>

          <p className={cn(styles.date, 'p2')}>
            {formatMessage({
              id: 'patientInfo.DoB',
              defaultMessage: 'DoB',
            })}

            {': '}

            {patientBirthDate}
          </p>

          <Avatar.Group className={cn(styles.avatars)}>
            {visibleDoctors &&
              visibleDoctors.map((doctor) => (
                <DoctorsAvatars
                  key={doctor?.UserID}
                  doctor={doctor}
                  isOnlyOneDoctor={isOnlyOneDoctor}
                />
              ))}

            {allPatientDoctors && visibleDoctors && moreThanFiveDoctors && (
              <Avatar
                count={allPatientDoctors.length - visibleDoctors.length}
              />
            )}
          </Avatar.Group>

          {!disableStudies && <StudiesCounters patientID={patientID} />}
        </Link>
      </div>
    </div>
  );
};
