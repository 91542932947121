import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  DefaultInitialState,
  LoadingStateType,
  SliceName,
} from '@/shared/config';
import { DentalPhotoAlbum } from '@/shared/api/protocol-ts/model/dto_dental_photo_album_pb';

const sortAlbums = (a: DentalPhotoAlbum, b: DentalPhotoAlbum) => {
  const aUpdatedAt = a.Revision?.UpdatedAt?.toDate().getTime() ?? 0;
  const bUpdatedAt = b.Revision?.UpdatedAt?.toDate().getTime() ?? 0;

  return bUpdatedAt - aUpdatedAt;
};

export const albumAdapter = createEntityAdapter<DentalPhotoAlbum>({
  selectId: (album) => album.ID,
  sortComparer: sortAlbums,
});

type AlbumSliceInitialState = DefaultInitialState & {
  isDeleteAssetPending: boolean;
};

const initialStateFlags = {
  isDeleteAssetPending: false,
};

const initialState: AlbumSliceInitialState = {
  loading: 'idle',
  ...initialStateFlags,
};

const albumState = albumAdapter.getInitialState(initialState);

type AlbumState = typeof albumState;

const albumSlice = createSlice({
  name: SliceName.album,
  initialState: albumAdapter.getInitialState(initialState),
  reducers: {
    addOne: (state, action: PayloadAction<DentalPhotoAlbum>) => {
      albumAdapter.addOne(state as AlbumState, action.payload);
    },
    addMany: (state, action: PayloadAction<DentalPhotoAlbum[]>) => {
      albumAdapter.addMany(state as AlbumState, action.payload);
    },
    setMany: (state, action: PayloadAction<DentalPhotoAlbum[]>) => {
      albumAdapter.setMany(state as AlbumState, action.payload);
    },
    setNewestOne: (state, action: PayloadAction<DentalPhotoAlbum>) => {
      const currentRevisionNumber =
        state.entities[action.payload.ID]?.Revision?.Number ?? 0;
      const payloadRevisionNumber = action.payload.Revision?.Number ?? 0;

      if (
        payloadRevisionNumber > currentRevisionNumber &&
        !action.payload.Deleted?.Deleted
      ) {
        albumAdapter.setOne(state as AlbumState, action.payload);
      }
    },
    removeOne: (state, action: PayloadAction<string>) => {
      albumAdapter.removeOne(state as AlbumState, action.payload);
    },
    removeAll: (state) => {
      albumAdapter.removeAll(state as AlbumState);
    },
    setLoading: (state, action: PayloadAction<LoadingStateType>) => {
      state.loading = action.payload;
    },
  },
});

export const { actions } = albumSlice;

export default albumSlice.reducer;
