import { OrganizationUserRoleType } from '@/shared/api/protocol-ts/model/dto_organization_pb';

import { TabValue } from '../config/constants';

export const getTabValuesByDoctorRoles = (
  roles: OrganizationUserRoleType[],
  isShopAvailable = false,
) => {
  if (
    roles?.includes(
      OrganizationUserRoleType.OrganizationUserRoleType_FullAccess,
    )
  ) {
    const tabs = [TabValue.CompanyInfo, TabValue.Staff, TabValue.BillingInfo];

    if (isShopAvailable) {
      tabs.push(TabValue.SubscriptionPlans);
    }

    return tabs;
  }
  if (
    roles?.includes(
      OrganizationUserRoleType.OrganizationUserRoleType_ClinicalAccess,
    )
  ) {
    return [TabValue.CompanyInfo, TabValue.Staff];
  }

  return [TabValue.CompanyInfo];
};
