import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

import { Button, Layout, Result, Spinner } from '@/shared/ui';
import sadCatImg from '@/shared/assets/images/sad-cat.png';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { ModalID, modalModel } from '@/entities/modal';
import { albumModel } from '@/entities/album';
import { patientModel } from '@/entities/patient';

import { UploadDentalPhotosModal } from '@/features/uploadDentalPhotos/';

import { Header } from '@/widgets/Header';
import { EditAlbumModal } from '@/widgets/EditAlbumModal';

import { Album, PhotoSlider } from '@/pages/DentalPhotos/ui';

import styles from './DentalPhotos.module.scss';

export const DentalPhotos: FC = () => {
  const { formatMessage } = useIntl();
  const { patientID = '' } = useParams<{ patientID: string }>();
  const dispatch = useAppDispatch();

  const { CanUploadDentalPhoto, CanEditAlbums } = useAppSelector(
    patientModel.selectors.selectPatientYourPermissions(patientID),
  );

  const dentalPhotoAlbums = useAppSelector(albumModel.selectors.selectAll);

  const loading = useAppSelector(albumModel.selectors.selectLoading);

  if (loading === 'pending') {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner className={styles.spinner} size={96} />
      </div>
    );
  }

  return (
    <Layout>
      <Layout.Header>
        <Header back />
      </Layout.Header>

      <Layout.Content>
        <Layout.Main>
          <div className={styles.container}>
            <header className={styles.header}>
              <h2 className="h3">
                {formatMessage({
                  id: 'dentalPhotosPage.title',
                  defaultMessage: 'Dental photos',
                })}
              </h2>

              {CanUploadDentalPhoto && (
                <Button
                  variant="secondary"
                  onClick={() =>
                    dispatch(
                      modalModel.actions.openModal({
                        modalID: ModalID.DentalPhotosUpload,
                      }),
                    )
                  }
                >
                  {formatMessage({
                    id: 'global.upload',
                    defaultMessage: 'Upload',
                  })}
                </Button>
              )}
            </header>

            <div className={styles.innerContainer}>
              {dentalPhotoAlbums?.length > 0 &&
                dentalPhotoAlbums.map((album) => (
                  <Album
                    key={album.ID}
                    album={album}
                    canEditAlbums={CanEditAlbums}
                  />
                ))}
            </div>

            {dentalPhotoAlbums.length === 0 && (
              <div className={styles.emptyResult}>
                <Result
                  icon={
                    <img
                      src={sadCatImg}
                      width={130}
                      height={130}
                      alt={formatMessage({
                        id: 'imgAltText.sadCat',
                        defaultMessage: 'Sad cat',
                      })}
                    />
                  }
                  text={formatMessage({
                    id: 'dentalPhotosPage.emptyResult.text',
                    defaultMessage: 'There are no dental photos yet',
                  })}
                />
              </div>
            )}
          </div>

          {patientID && <UploadDentalPhotosModal patientID={patientID} />}

          {dentalPhotoAlbums.length > 0 && <EditAlbumModal />}

          <PhotoSlider />
        </Layout.Main>
      </Layout.Content>
    </Layout>
  );
};
