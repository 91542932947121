// Types related to 2D GP report ("ioxray_gp") pipelines method

// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file pipelines/report_ioxray_gp.proto (package com.diagnocat.pipelines, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { Report_2D_Image_Meta } from "../model/dto_report_type_2D_common_pb.js";
import { ToothDetection } from "./report_tooth_detection_pb.js";
import { Condition } from "../model/dto_report_condition_pb.js";
import { Conditions_Update } from "./report_condition_pb.js";
import { TeethLandmarks_Update } from "./report_landmarks_pb.js";
import { MaxFaxAnatomyLocalizations_Update } from "./report_maxfax_anatomy_localization_pb.js";
import { TeethPeriodontalStatuses_Update } from "./report_periodontal_status_pb.js";
import { TeethAnatomyLocalizations_Update } from "./report_tooth_anatomy_localization_pb.js";

/**
 * @generated from message com.diagnocat.pipelines.IOXRay_GP_Update
 */
export const IOXRay_GP_Update = proto3.makeMessageType(
  "com.diagnocat.pipelines.IOXRay_GP_Update",
  () => [
    { no: 1, name: "IOXRay_Image_Meta", kind: "message", T: Report_2D_Image_Meta, oneof: "Update" },
    { no: 2, name: "ToothDetection", kind: "message", T: ToothDetection, oneof: "Update" },
    { no: 3, name: "Condition", kind: "message", T: Condition, oneof: "Update" },
    { no: 8, name: "Conditions", kind: "message", T: Conditions_Update, oneof: "Update" },
    { no: 4, name: "TeethLandmarks", kind: "message", T: TeethLandmarks_Update, oneof: "Update" },
    { no: 5, name: "MaxFaxAnatomyLocalizations", kind: "message", T: MaxFaxAnatomyLocalizations_Update, oneof: "Update" },
    { no: 6, name: "TeethPeriodontalStatuses", kind: "message", T: TeethPeriodontalStatuses_Update, oneof: "Update" },
    { no: 7, name: "TeethAnatomyLocalizations", kind: "message", T: TeethAnatomyLocalizations_Update, oneof: "Update" },
  ],
);

