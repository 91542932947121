// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_condition.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { ConditionCode } from "./dto_report_condition_codes_pb.js";
import { Certainty } from "./dto_report_certainty_pb.js";
import { Localization } from "./dto_report_localization_pb.js";
import { Created, Deleted, Revision } from "./dto_base_pb.js";
import { ToothNumeration } from "./dto_report_common_pb.js";

/**
 * //////////////////////////////////////////////////////////////
 * //  Condition
 * //
 * //  Medical condition. That is.
 * //
 * //  Note: Either `ParentID` or `Localizations` are present. Reason for not
 * //  not making it `oneof` is high frequency of `Localizations` field usage.
 *
 * @generated from message com.diagnocat.model.Condition
 */
export const Condition = proto3.makeMessageType(
  "com.diagnocat.model.Condition",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ReportID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "ParentID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "ParentCode", kind: "enum", T: proto3.getEnumType(ConditionCode) },
    { no: 10, name: "Code", kind: "enum", T: proto3.getEnumType(ConditionCode) },
    { no: 11, name: "Certainty", kind: "message", T: Certainty },
    { no: 201, name: "Localizations", kind: "message", T: Localization, repeated: true },
    { no: 100, name: "Tooth", kind: "message", T: Condition_ToothAttribution, oneof: "Attribution" },
    { no: 101, name: "MaxFax", kind: "message", T: Condition_MaxFaxAttribution, oneof: "Attribution" },
    { no: 10030, name: "Created", kind: "message", T: Created },
    { no: 10040, name: "Revision", kind: "message", T: Revision },
    { no: 10050, name: "Deleted", kind: "message", T: Deleted },
  ],
);

/**
 * @generated from message com.diagnocat.model.Condition.ToothAttribution
 */
export const Condition_ToothAttribution = proto3.makeMessageType(
  "com.diagnocat.model.Condition.ToothAttribution",
  () => [
    { no: 1, name: "ToothID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ToothLocalizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "ToothNumeration", kind: "message", T: ToothNumeration },
  ],
  {localName: "Condition_ToothAttribution"},
);

/**
 * @generated from message com.diagnocat.model.Condition.MaxFaxAttribution
 */
export const Condition_MaxFaxAttribution = proto3.makeMessageType(
  "com.diagnocat.model.Condition.MaxFaxAttribution",
  [],
  {localName: "Condition_MaxFaxAttribution"},
);

