import { createSelector } from '@reduxjs/toolkit';

import { CombinedToolNames, ToggledToolNames } from '@/shared/config';

import { RootState } from '@/app/model/store';

const selectToolbarMIR = (state: RootState) => state.toolbarMIR;

export const selectToolbarById = (toolbarId: string) =>
  createSelector(
    selectToolbarMIR,
    (toolbar) =>
      toolbar[toolbarId] ?? {
        split: false,
        invert: false,
        activeControl: undefined,
      },
  );

export const selectActiveTools = (toolbarId: string) =>
  createSelector(
    selectToolbarById(toolbarId),
    (toolbar): (CombinedToolNames | ToggledToolNames)[] => {
      return Object.entries(toolbar).reduce(
        (acc, [key, value]) => {
          if (typeof value === 'boolean' && value) {
            acc.push(key as CombinedToolNames);
          } else if (typeof value === 'string') {
            acc.push(value);
          }
          return acc;
        },
        [] as (CombinedToolNames | ToggledToolNames)[],
      );
    },
  );

export const selectToolbarMIRState = createSelector(
  selectToolbarMIR,
  (toolbarMIR) => toolbarMIR,
);
