import { DetailedHTMLProps, FC, ReactNode } from 'react';
import cn from 'classnames';

import { Skeleton } from '../Skeleton/Skeleton';

import styles from './WidgetCard.module.scss';

type WidgetCardProps = DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
> & {
  className?: string;
  children?: ReactNode;
  loading?: boolean;
  id?: string;
};

export const WidgetCard: FC<WidgetCardProps> = (props) => {
  const { className, children, loading, id, ...rest } = props;

  return loading ? (
    <Skeleton width="100%" borderRadius="16px" height="320px" />
  ) : (
    <section className={cn(styles.container, className)} id={id} {...rest}>
      {children}
    </section>
  );
};
