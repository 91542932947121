import { useState } from "react";
import { ObjectFitContain } from "../ObjectFitContain";
import { RCContainer } from "../RCContainer/RCContainer";
import { RCCropImageCrop } from "../RCCropImage/RCCropImage";

export type Localization = {
  conditionID?: string;
  xmin: number;
  ymin: number;
  xmax: number;
  ymax: number;
}

export type RCLocalizationsProps = {
  imageSize: { width: number, height: number };
  detections: Localization[];
  crop?: RCCropImageCrop;
  isNonDentalFindings?: boolean;
  color?: 'white' | 'purple' | 'red' | 'yellow';
  borderRadius?: number;
  hoveredConditionID?: string;
}

export const RCLocalizations = (props: RCLocalizationsProps) => {

  //canvas outside scales down to fit. thats why detection misses the canvas
  const [aspect, setAspect] = useState(1);

  const { detections, imageSize, isNonDentalFindings = false, color, borderRadius, hoveredConditionID } = props;
  /* 
      Sometimes developers forget to provide crop when it's needed
      and it's become a deep problem to figure out why something is not working

      Currently crop is not required prop, but it should be
      but for now I cannot make it required because it maybe 
      will break compatibility with other components

      TODO Make crop a requied prop
  */
  const crop = props.crop || { x: 0, y: 0, w: 1, h: 1 };

  const getBorderColor = () => {
    if(color) {
      switch (color) {
        case 'purple':
          return 'var(--purpletext1)';
        case 'red':
          return 'var(--redrose)';
        case 'yellow':
          return 'var(--orange)';
        default:
          return 'var(--white)';
      }
    }
    return isNonDentalFindings ? 'var(--yellow)' : 'var(--green)'
  }

  return (
    <ObjectFitContain
      objectWidth={imageSize.width}
      objectHeight={imageSize.height}
      onAspectChanged={(aspect) => { setAspect(aspect) }}
    >
      <RCContainer style={{ width: "100%", height: "100%", overflow: "hidden" }}>
        {detections.map((detection, index) =>
        (<div key={index} style={{
          position: "absolute",
          top: (detection.ymin - crop.y) * aspect,
          left: (detection.xmin - crop.x) * aspect,
          width: (detection.xmax - detection.xmin) * aspect,
          height: (detection.ymax - detection.ymin) * aspect,
          pointerEvents: "none",
          // backgroundColor: "red",
          // opacity: 0.5,
          borderRadius: `${borderRadius ?? '4'}px`,
          border: `${hoveredConditionID === detection.conditionID ? 2 : 1}px solid ${getBorderColor()}`,
        }}></div>)
        )}
      </RCContainer>
    </ObjectFitContain>
  );
};
