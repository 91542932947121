import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useLocation, useParams } from 'react-router';
import { useIntl } from 'react-intl/lib';
import { equals } from 'ramda';

import { LocationStateType } from '@/shared/config';
import {
  Button,
  Column,
  Layout,
  Spinner,
  WidgetCard,
  WidgetLayout,
} from '@/shared/ui';
import { useAppSelector, useMedia } from '@/shared/hooks';
import { Report } from '@/shared/api/protocol-ts/model/dto_report_pb';
import { scrollToElementByID } from '@/shared/lib';

import { getReportSignStatus } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';
import { ConfirmModal } from '@/entities/modal';
import { conditionModel } from '@/entities/condition';

import { useReport_DEPRECATED } from '@/features/reportStream';
import { ToothCardFDA } from '@/features/ToothCard/ui/ToothCardFDA/ToothCardFDA';

import { Conclusion } from '@/widgets/Conclusion';
import { ReportHeading } from '@/widgets/ReportHeading';
import {
  TeethNumberingModal,
  useGetTeethNumberingModalImage,
} from '@/widgets/TeethNumberingModal';
import { GetFullAccessModal } from '@/widgets/GetFullAccessModal';
import { MIRenderModal } from '@/widgets/MIRenderModal';
import { Toolbar } from '@/widgets/MedicalImageToolbar';

import { PanoReformatRender } from '../GpReport/ui/PanoReformatRender/PanoReformatRender';

import { ConfirmSignReportModal } from './ui/ConfirmSignReportModal/ConfirmSignReportModal';
import { useConfirmSignReportModal } from './hooks/useConfirmSignReportModal';
import { GpFDAToothChart } from './ui/GpToothChart/GpFDAToothChart';
import styles from './GpFDAReport.module.scss';

type ReportProps = {
  className?: string;
};

export const GpFDAReport: FC<ReportProps> = (props) => {
  const { className } = props;

  const { patientID = '', reportID = '' } = useParams();
  const { formatMessage } = useIntl();
  const location = useLocation();

  const conditionLoading = useAppSelector(
    conditionModel.selectors.selectLoading,
  );

  const toothLoading = useAppSelector(toothModel.selectors.selectLoading);

  const [, setCurrentToothID] = useState<string>('');

  const { isMobile } = useMedia();

  // type casting is necessary for typification location.state
  const locationState = location?.state as LocationStateType;

  const scrollToElementID = locationState?.toothID;

  const lastPositionReport = locationState?.lastPositionReport;

  const {
    isConfirmSignReportModalOpen,
    openConfirmSignReportModal,
    closeConfirmSignReportModal,
  } = useConfirmSignReportModal();

  const { report, isReportSigned, isReportLoading, isAllTeethApproved } =
    useReport_DEPRECATED(reportID, patientID);

  const teeth = useAppSelector(
    toothModel.selectors.selectTeethToShow(reportID),
  );

  const {
    CBCTPanoImage,
    reportViewOptions,
    CBCTPanoramaSplitURL,
    CBCTPanoramaURL,
    CBCTPanoramaAssetID,
    modalWidth,
  } = useGetTeethNumberingModalImage(report as Report);

  const reportSignStatus = getReportSignStatus(report);

  const handleSlicesClick = (toothID: string) => {
    // TODO: is it usable??
    setCurrentToothID((prevState) => {
      if (equals(toothID, prevState)) {
        return prevState;
      }

      return toothID;
    });
  };

  const showToothChart =
    conditionLoading === 'succeeded' && toothLoading === 'succeeded';

  useEffect(() => {
    if (scrollToElementID) {
      scrollToElementByID(scrollToElementID, 'auto');
    }

    if (!scrollToElementID && lastPositionReport) {
      window.scrollTo(0, lastPositionReport);
    }
  }, []);

  if (isReportLoading) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner className={styles.spinner} size={96} />
      </div>
    );
  }

  if (!report) {
    return null;
  }

  const teethNumberingImage = CBCTPanoImage;

  const panoImage = {
    viewOptions: reportViewOptions,
    assetID: CBCTPanoramaAssetID ?? '',
    panoramaURL: CBCTPanoramaURL,
    panoramaSplitURL: CBCTPanoramaSplitURL,
  };

  const reportImageLoading = !panoImage.assetID;

  return (
    <>
      <Layout.Content>
        <Layout.Main>
          <Layout.Content className={cn(styles.container, className)}>
            <WidgetLayout className={styles.columnWrapper}>
              <Column className={styles.column}>
                <div className={styles.leftFixedColumn}>
                  <ReportHeading
                    report={report}
                    isReportLoading={isReportLoading}
                  />
                  <WidgetCard
                    className={styles.panoImageWidget}
                    loading={reportImageLoading}
                  >
                    <PanoReformatRender>
                      <Toolbar toolbarID="main" />
                    </PanoReformatRender>
                  </WidgetCard>

                  <WidgetCard className={styles.toothChart}>
                    {showToothChart && <GpFDAToothChart />}
                  </WidgetCard>
                </div>
              </Column>

              <Column className={styles.rightColumn}>
                {teeth.map((tooth) => (
                  <ToothCardFDA
                    key={tooth.ID}
                    id={tooth.ID}
                    patientID={patientID}
                    reportID={reportID}
                    onSlicesClick={handleSlicesClick}
                  />
                ))}

                <Conclusion />
              </Column>
            </WidgetLayout>
          </Layout.Content>
        </Layout.Main>
      </Layout.Content>

      <Layout.Footer settingsFooter className={styles.footerContainer}>
        <div className={styles.footerWrapper}>
          {!isReportSigned && (
            <Button
              icon="pen"
              disabled={
                reportSignStatus === 'withoutSign' ||
                !report.YourPermissions?.CanChangeToothApproved
              }
              onClick={openConfirmSignReportModal}
              size={isMobile ? 'medium' : 'large'}
            >
              <FormattedMessage id="report.sign" defaultMessage="Sign" />
            </Button>
          )}
        </div>
      </Layout.Footer>

      <ConfirmSignReportModal
        isAllTeethApproved={isAllTeethApproved}
        reportID={reportID}
        isOpen={isConfirmSignReportModalOpen}
        onCancel={closeConfirmSignReportModal}
      />

      <TeethNumberingModal
        title={formatMessage({
          id: 'report.EditTeethNumbers',
          defaultMessage: 'Edit teeth numbers',
        })}
        patientID={patientID}
        reportID={reportID}
        modalWidth={modalWidth}
        reportImage={teethNumberingImage}
      />

      <MIRenderModal />

      <GetFullAccessModal />

      <ConfirmModal />
    </>
  );
};
