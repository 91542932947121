import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl/lib';

import { Description } from '@/shared/ui';
import { MedicalImageInterface } from '@/shared/config';
import { useAppDispatch, useMedia } from '@/shared/hooks';

import {
  PathologyGroup,
  Group,
  medicalImageTitles,
  isMedicalImagesTitle,
} from '@/entities/assets';
import { ModalID, modalModel } from '@/entities/modal';

import { GroupMedicalImagesList } from '../GroupMedicalImagesList/GroupMedicalImagesList';
import GuidesList from '../GuidesList/GuidesList';

import styles from './MedicalImageGroupSection.module.scss';

type MedicalImageGroupSectionProps = {
  toothID: string;
  group: Group | PathologyGroup;
  selectedMedicalImages?: MedicalImageInterface[];
  toggleSelected?: (medicalImage: MedicalImageInterface) => void;
  className?: string;
};

export const MedicalImageGroupSection: FC<MedicalImageGroupSectionProps> = (
  props,
) => {
  const { className, group, selectedMedicalImages, toggleSelected, toothID } =
    props;
  const [hoveredGuide, setHoveredGuide] = useState('');
  const [medicalImages, setMedicalImages] = useState<MedicalImageInterface[]>(
    [],
  );
  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const { isMobile } = useMedia();

  const { type } = group;

  const title = isMedicalImagesTitle(type)
    ? formatMessage(medicalImageTitles[type])
    : '';

  const handleZoomImg = (assetID: string) => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.MIRenderModal,
        data: { assetID, toothID },
      }),
    );
  };

  useEffect(() => {
    if (group?.medicalImages?.length) {
      setMedicalImages(group.medicalImages);
    }
  }, [group.medicalImages]);

  if (!group?.guides?.length && !group?.medicalImages?.length) {
    return null;
  }

  const firstMedicalImage = (group?.medicalImages || [
    {} as MedicalImageInterface,
  ])[0];
  const { thicknessMm, strideMm } = firstMedicalImage;

  const hasMedicalImages = !!(
    group?.medicalImages?.length && medicalImages.length
  );

  return (
    <div className={(styles.container, className)}>
      <div className={styles.sliceWrapper}>
        <h4 className={isMobile ? 'h5' : 'h4'}>{title}</h4>
        <div className={styles.sliceConfigsWrapper}>
          {strideMm && (
            <Description
              label={
                <FormattedMessage
                  id="report.slice_step"
                  defaultMessage="Slice step"
                />
              }
            >
              {strideMm}
              <FormattedMessage id="global.mm" defaultMessage="mm" />
            </Description>
          )}
          {thicknessMm && (
            <Description
              label={
                <FormattedMessage
                  id="report.slice_thickness"
                  defaultMessage="Slice thickness"
                />
              }
            >
              {thicknessMm}
              <FormattedMessage id="global.mm" defaultMessage="mm" />
            </Description>
          )}
        </div>
      </div>
      <div className={styles.medicalImageSection}>
        {!!group?.guides?.length && (
          <GuidesList
            group={group}
            hoveredGuide={hoveredGuide}
            handleZoomImg={handleZoomImg}
            setHoveredGuide={setHoveredGuide}
            className={styles.guidesWrapper}
          />
        )}
        <div className={styles.medicalImagesColumn}>
          {hasMedicalImages && (
            <GroupMedicalImagesList
              medicalImages={medicalImages}
              focusedImageID={hoveredGuide}
              selectedMedicalImages={selectedMedicalImages}
              toggleSelected={toggleSelected}
              onHoveredGuideChanged={setHoveredGuide}
            />
          )}
        </div>
      </div>
    </div>
  );
};
