import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { useNavigate, useSearchParams, generatePath } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { FormattedMessage } from 'react-intl';

import { Attribute } from '@/shared/api/protocol-ts/model/dto_base_pb';
import {
  Report,
  ReportType,
} from '@/shared/api/protocol-ts/model/dto_report_pb';
import { useAppDispatch } from '@/shared/hooks';
import sadCat from '@/shared/assets/images/cats/sad.png';
import loadingCat from '@/shared/assets/images/cats/loading.png';
import { Asset } from '@/shared/api/protocol-ts/model/dto_asset_pb';

import { getReportPath, reportsModel } from '@/entities/reports';

import styles from './LandingRouter.module.scss';

type LandingRouterProps = {
  className?: string;
};

const PREFIX = 'attr_';

export const LandingRouter: FC<LandingRouterProps> = (props) => {
  const { className } = props;
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState<Report[]>([]);
  const [studyAssets, setStudyAssets] = useState<Asset[]>([]);

  useEffect(() => {
    const getTrackerAttributes = () =>
      Array.from(searchParams)
        .filter(([key]) => key.startsWith(PREFIX))
        .map(
          ([key, value]) =>
            new Attribute({
              Key: key.substring(PREFIX.length),
              Value: value,
            }),
        );

    const fetchReports = async () => {
      try {
        const trackerAttributes = getTrackerAttributes();

        if (trackerAttributes.length === 0) {
          setReports([]);
          return;
        }

        const { Reports, StudyAssets } = await dispatch(
          reportsModel.thunks.getReportsByStudyAssetAttributes({
            AssetAttributes: trackerAttributes,
          }),
        ).unwrap();

        setReports(Reports);
        setStudyAssets(StudyAssets);
      } catch (error) {
        console.error('Error fetching reports:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, [searchParams, dispatch, navigate]);

  // Sort reports by creation date (newest first)
  const sortedReports = reports.sort(
    (a, b) =>
      b.Created!.At!.toDate().getTime() - a.Created!.At!.toDate().getTime(),
  );

  // Find the latest successfully generated report
  const lastCompletedReport = sortedReports.find(
    (report) => report.Status?.Completed,
  );

  // Find the latest report in generation process
  const lastGeneratingReport = sortedReports.find(
    (report) => !report.Status?.Completed,
  );

  useEffect(() => {
    // If there is a completed report, redirect to it
    if (lastCompletedReport) {
      const baseUrl = generatePath(getReportPath(lastCompletedReport.Type), {
        patientID: lastCompletedReport.PatientID,
        reportID: lastCompletedReport.ID,
      });

      if (
        lastCompletedReport.Type === ReportType.ReportType_Pano_Bitewings ||
        lastCompletedReport.Type === ReportType.ReportType_IOXRay_GP
      ) {
        const activeItemID = studyAssets.at(0)?.ID;

        navigate(`${baseUrl}?activeItemID=${activeItemID}`);
      } else {
        navigate(baseUrl);
      }
    }
  }, [lastCompletedReport, navigate]);

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.wrap}>
        <div className={styles.content}>
          {loading && (
            <>
              <img
                src={loadingCat}
                width={130}
                height={130}
                alt="Loading cat"
                className={styles.icon}
              />
              <div className={cn(styles.loading, 'p1')}>
                <FormattedMessage
                  id="landingRouter.loading"
                  defaultMessage="Looking for reports..."
                />
                <ClipLoader size={32} color={styles.indigo700} />
              </div>
            </>
          )}
          {!loading && reports.length === 0 && (
            <>
              <img
                src={sadCat}
                width={130}
                height={130}
                alt="Sad cat"
                className={styles.icon}
              />
              <div className="p1">
                <FormattedMessage
                  id="landingRouter.notFound.label"
                  defaultMessage="Not found"
                />
              </div>
              <div className="p2">
                <FormattedMessage
                  id="landingRouter.notFound.message"
                  defaultMessage="It seems that your image isn't yet in Diagnocat. If you just took it, please wait a few minutes; otherwise, please check that your Diagnocat Desktop integration is running."
                />
              </div>
            </>
          )}
          {!loading && !lastCompletedReport && lastGeneratingReport && (
            <>
              <img
                src={loadingCat}
                width={130}
                height={130}
                alt="Loading cat"
                className={styles.icon}
              />
              <div className="p1">
                <FormattedMessage
                  id="landingRouter.generating"
                  defaultMessage="The report generation is in progress"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
