import {
  AnyAction,
  // Don't remove, need to debug dispatch spam situation from streams
  // autoBatchEnhancer,
  combineReducers,
  configureStore,
  DevToolsEnhancerOptions,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import { ENVIRONMENT, SliceName } from '@/shared/config';
import { MIRenderMiddleware } from '@/shared/graphics/medicalImageRender/MedicalImageRender';

// Legacy for client
import notificationReducer, {
  changeHasUnreadTo,
  addNotification,
  changeNotificationIsOpenTo,
  clearNotification,
} from '@/entities/notification/model/notificationSlice';
import billingReducer, {
  actions as billingActions,
} from '@/entities/billing/model/billingSlice';
import userReducer, {
  actions as userActions,
} from '@/entities/user/model/userSlice';
import authReducer, {
  actions as authActions,
} from '@/entities/auth/model/authSlice';
import themeReducer, {
  setTheme as themeActions,
} from '@/entities/theme/model/themeSlice';
import reportsReducer, {
  actions as reportsActions,
} from '@/entities/reports/model/reportSlice';
import patientReducer, {
  actions as patientActions,
} from '@/entities/patient/model/patientSlice';
import studyReducer, {
  actions as studyActions,
} from '@/entities/study/model/studySlice';
import organizationReducer, {
  actions as organizationActions,
} from '@/entities/organization/model/organizationSlice';
import studyCountReducer, {
  actions as studyCountActions,
} from '@/entities/studyCount/model/studyCountSlice';
import accessReducer, {
  actions as accessActions,
} from '@/entities/access/model/accessSlice';
import allowedToothConditionsReducer, {
  actions as allowedToothConditionsActions,
} from '@/entities/allowedToothConditions/model/allowedToothConditionsSlice';
import conditionReducer, {
  actions as conditionActions,
} from '@/entities/condition/model/conditionSlice';
import toothReducer, {
  actions as toothActions,
} from '@/entities/tooth/model/toothSlice';
import modalReducer, {
  actions as modalActions,
} from '@/entities/modal/model/modalSlice';
import assetsReduce, {
  actions as assetsActions,
} from '@/entities/assets/model/assetsSlice';
import marketingReducer, {
  actions as marketingActions,
} from '@/entities/marketing/model/marketingSlice';
import toothLandmarksReducer, {
  actions as toothLandmarksActions,
} from '@/entities/toothLandmarks/model/toothLandmarksSlice';
import slicesReducer, {
  actions as slicesActions,
} from '@/entities/slices/model/slicesSlice';
import toolbarMIRReducer, {
  actions as toolbarMIRActions,
} from '@/entities/toolbarMIR/model/toolbarMIRSlice';
import albumReducer, {
  actions as albumActions,
} from '@/entities/album/model/albumSlice.ts';
import {
  deleteAsset,
  setMedicalImageViewOptions,
} from '@/entities/assets/model/assetsSlice.thunks';

import renderMasksReducer, {
  actions as renderMasksActions,
} from '@/features/renderMasks/model/renderMasksSlice';

import { errorMiddleware } from './errorMiddleware';

const appReducer = combineReducers({
  [SliceName.access]: accessReducer,
  [SliceName.condition]: conditionReducer,
  [SliceName.marketing]: marketingReducer,
  [SliceName.modal]: modalReducer,
  [SliceName.auth]: authReducer,
  [SliceName.assets]: assetsReduce,
  [SliceName.patient]: patientReducer,
  [SliceName.notification]: notificationReducer,
  [SliceName.billing]: billingReducer,
  [SliceName.user]: userReducer,
  [SliceName.theme]: themeReducer,
  [SliceName.reports]: reportsReducer,
  [SliceName.study]: studyReducer,
  [SliceName.organization]: organizationReducer,
  [SliceName.studyCount]: studyCountReducer,
  [SliceName.tooth]: toothReducer,
  [SliceName.allowedToothConditions]: allowedToothConditionsReducer,
  [SliceName.toothLandmarks]: toothLandmarksReducer,
  [SliceName.maskFilters]: renderMasksReducer,
  [SliceName.slices]: slicesReducer,
  [SliceName.toolbarMIR]: toolbarMIRReducer,
  [SliceName.album]: albumReducer,
});

const rootReducer = (
  state: ReturnType<typeof appReducer> | undefined,
  action: AnyAction,
) => {
  if (action.type === 'store/reset') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const devToolsConfig: DevToolsEnhancerOptions = {
  maxAge: 10,
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: ENVIRONMENT !== 'production' && devToolsConfig,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(errorMiddleware)
      .concat(MIRenderMiddleware),
  // Don't remove, need to debug dispatch spam situation from streams
  // enhancers: (defaultEnchancers) =>
  //   defaultEnchancers.concat(autoBatchEnhancer({ type: 'tick' })),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const actions = {
  [SliceName.access]: accessActions,
  [SliceName.condition]: conditionActions,
  [SliceName.marketing]: marketingActions,
  [SliceName.modal]: modalActions,
  [SliceName.auth]: authActions,
  [SliceName.assets]: {
    ...assetsActions,
    deleteAsset,
    setMedicalImageViewOptions,
  },
  [SliceName.patient]: patientActions,
  [SliceName.notification]: {
    changeHasUnreadTo,
    addNotification,
    changeNotificationIsOpenTo,
    clearNotification,
  },
  [SliceName.billing]: billingActions,
  [SliceName.user]: userActions,
  [SliceName.theme]: { setTheme: themeActions },
  [SliceName.reports]: reportsActions,
  [SliceName.study]: studyActions,
  [SliceName.organization]: organizationActions,
  [SliceName.studyCount]: studyCountActions,
  [SliceName.tooth]: toothActions,
  [SliceName.allowedToothConditions]: allowedToothConditionsActions,
  [SliceName.toothLandmarks]: toothLandmarksActions,
  [SliceName.maskFilters]: renderMasksActions,
  [SliceName.slices]: slicesActions,
  [SliceName.toolbarMIR]: toolbarMIRActions,
  [SliceName.album]: albumActions,
} as const;

export type Actions = typeof actions;
