import { RootState } from '@/app/model/store';

import { ModalID } from '../config';

// TODO: Try to fix TS return type
export const selectModal = (modalID: ModalID) => (state: RootState) =>
  state.modal[modalID];

export const selectAddConditionModal = (state: RootState) =>
  state.modal.AddCondition;

export const selectMPRViewerModal = (state: RootState) =>
  state.modal.MPRFullScreenModal;

export const selectBillingInfoModal = (state: RootState) =>
  state.modal[ModalID.BillingInformationModal];

export const selectCancelSubscriptionModalData = (state: RootState) =>
  state.modal[ModalID.CancelSubscriptionModal];

export const selectUploadModal = (state: RootState) =>
  state.modal[ModalID.UploadStudy_DEPRECATED];

export const selectOrderReportModal = (state: RootState) =>
  state.modal[ModalID.OrderReport];

export const selectResetPasswordErrorModal = (state: RootState) =>
  state.modal[ModalID.ResetPasswordErrorModal];

export const selectPatientRemovalConfirmationModal = (state: RootState) =>
  state.modal[ModalID.PatientRemovalConfirmationModal];

export const selectPatientModalForm = (state: RootState) =>
  state.modal[ModalID.PatientModalForm];

export const selectMIRenderModal = (state: RootState) =>
  state.modal[ModalID.MIRenderModal];

export const selectTopUpReportsModal = (state: RootState) =>
  state.modal[ModalID.TopUpReportLimitsModal];

export const selectPauseOrActivateSubscriptionModal = (state: RootState) =>
  state.modal[ModalID.PauseOrActivateSubscriptionModal];

export const selectConfirmModal = (state: RootState) =>
  state.modal[ModalID.Confirm];

export const selectAboutLabelingModal = (state: RootState) =>
  state.modal[ModalID.AboutLabelingModal];

export const selectTeethNumberingModal = (state: RootState) =>
  state.modal[ModalID.TeethNumberingModal];

export const selectReportInfoModal = (state: RootState) =>
  state.modal[ModalID.ReportInfo];

export const selectDentalPhotosUploadModal = (state: RootState) =>
  state.modal[ModalID.DentalPhotosUpload];

export const selectEditAlbumModal = (state: RootState) =>
  state.modal[ModalID.EditAlbumModal];

export const selectDentalPhotoSliderModal = (state: RootState) =>
  state.modal[ModalID.DentalPhotoSlider];

export const selectMobileOrderReportModal = (state: RootState) =>
  state.modal[ModalID.MobileOrderReportModal];

export const selectTreatingDoctorsModal = (state: RootState) =>
  state.modal[ModalID.TreatingDoctorsModal];

export const selectTreatingDoctorsFilterDrawer = (state: RootState) =>
  state.modal[ModalID.TreatingDoctorsFilterDrawer];

export const selectTreatingDoctorsSelectDrawer = (state: RootState) =>
  state.modal[ModalID.TreatingDoctorsSelectDrawer];

export const selectHeaderDrawer = (state: RootState) =>
  state.modal[ModalID.HeaderDrawer];

export const selectAllSharedWithModal = (state: RootState) =>
  state.modal[ModalID.AllSharedWith];
