import { FC, memo, useCallback } from 'react';
import cn from 'classnames';

import { MedicalImage } from '@/shared/ui';
import { MedicalImageInterface } from '@/shared/config';

import styles from './UploadedSlicesList.module.scss';

type GroupMedicalImagesListProps = {
  className?: string;
  canUserDeleteSlices: boolean;
  medicalImages: MedicalImageInterface[];
  selectedMedicalImages: MedicalImageInterface[];
  toggleSelected: (medicalImage: MedicalImageInterface) => void;
};

export const UploadedSlicesList: FC<GroupMedicalImagesListProps> = memo(
  (props) => {
    const {
      className,
      selectedMedicalImages,
      medicalImages = [],
      canUserDeleteSlices,
      toggleSelected,
    } = props;

    const checkIsTheMedicalImageSelected = useCallback(
      (src: string) =>
        selectedMedicalImages?.some(
          (medicalImage) => medicalImage.src === src,
        ) ?? false,
      [selectedMedicalImages],
    );

    return (
      <div className={cn(styles.container, className)}>
        {medicalImages.map((medicalImage) => (
          <div key={medicalImage.src} className={styles.medicalImageWrapper}>
            <MedicalImage
              draggable
              key={medicalImage.src}
              medicalImage={medicalImage}
              toggleSelected={toggleSelected}
              order={medicalImage.order}
              canUserDeleteSlices={canUserDeleteSlices}
              isImgSelected={checkIsTheMedicalImageSelected(medicalImage.src)}
            />
          </div>
        ))}
      </div>
    );
  },
);
