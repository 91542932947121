import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  DefaultInitialState,
  LoadingStateType,
  SliceName,
} from '@/shared/config';
import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';

import { sortConditionsByGroupOrder } from '../lib/sortConditionsByGroupOrder';

export const conditionAdapter = createEntityAdapter<Condition>({
  selectId: (condition: Condition) => condition.ID,
  sortComparer: sortConditionsByGroupOrder,
});

export type ConditionIDGroup = Record<string, string[]>;

type ConditionInitialState = DefaultInitialState & {
  teethGroup: ConditionIDGroup;
  maxFaxGroup: string[];
  hoveredConditionIDs: string[];
  hoveredNonDentalConditionID: string;
};

const conditionInitialState: ConditionInitialState = {
  loading: 'idle',
  teethGroup: {},
  maxFaxGroup: [],
  hoveredConditionIDs: [],
  hoveredNonDentalConditionID: '',
};

const initialState = conditionAdapter.getInitialState(conditionInitialState);

type ConditionState = typeof initialState;

const conditionSlice = createSlice({
  name: SliceName.condition,
  initialState: conditionAdapter.getInitialState(conditionInitialState),
  reducers: {
    addOne: (state, action: PayloadAction<Condition>) =>
      conditionAdapter.addOne(state as ConditionState, action.payload),
    addMany: (state, action: PayloadAction<Condition[]>) =>
      conditionAdapter.addMany(state as ConditionState, action.payload),
    setNewestOne: (state, action: PayloadAction<Condition>) => {
      if (action.payload.Deleted?.Deleted) {
        conditionAdapter.removeOne(state as ConditionState, action.payload.ID);
      }

      const currentCondition = state.entities[action.payload.ID];

      const payloadToothID =
        action.payload.Attribution.case === 'Tooth'
          ? action.payload.Attribution.value.ToothID
          : '';

      const currentToothID =
        currentCondition && currentCondition.Attribution.case === 'Tooth'
          ? currentCondition.Attribution.value.ToothID
          : '';

      // NOTE: this is necessary for the correct handling of the teethGroup update when changing the tooth number
      if (payloadToothID !== currentToothID) {
        if (payloadToothID in state.teethGroup) {
          state.teethGroup[payloadToothID].push(action.payload.ID);
        } else {
          state.teethGroup[payloadToothID] = [action.payload.ID];
        }

        state.teethGroup[currentToothID] = state.teethGroup[
          currentToothID
        ].filter((conditionID) => conditionID !== action.payload.ID);
      }

      const currentRevisionNumber = currentCondition?.Revision?.Number ?? 0;
      const payloadRevisionNumber = action.payload.Revision?.Number ?? 0;

      if (payloadRevisionNumber > currentRevisionNumber) {
        conditionAdapter.setOne(state as ConditionState, action.payload);
      }
    },
    setNewestMany: (state, action: PayloadAction<Condition[]>) => {
      const updatedConditionsFromPayload = action.payload.filter(
        (updateCondition) => {
          const currentRevisionNumber =
            state.entities[updateCondition.ID]?.Revision?.Number ?? 0;
          const payloadRevisionNumber = updateCondition.Revision?.Number ?? 0;

          return payloadRevisionNumber > currentRevisionNumber;
        },
      );

      // NOTE: this is necessary for the correct handling of the teethGroup update when changing the tooth number
      updatedConditionsFromPayload.forEach((updatedCondition) => {
        const currentCondition = state.entities[updatedCondition.ID];

        const payloadToothID =
          updatedCondition.Attribution.case === 'Tooth'
            ? updatedCondition.Attribution.value.ToothID
            : '';

        const currentToothID =
          currentCondition && currentCondition.Attribution.case === 'Tooth'
            ? currentCondition.Attribution.value.ToothID
            : '';

        if (payloadToothID !== currentToothID) {
          if (payloadToothID in state.teethGroup) {
            state.teethGroup[payloadToothID].push(updatedCondition.ID);
          } else {
            state.teethGroup[payloadToothID] = [updatedCondition.ID];
          }

          state.teethGroup[currentToothID] = state.teethGroup[
            currentToothID
          ].filter((conditionID) => conditionID !== updatedCondition.ID);
        }
      });

      conditionAdapter.setMany(
        state as ConditionState,
        updatedConditionsFromPayload,
      );
    },
    setMany: (state, action: PayloadAction<Condition[]>) =>
      conditionAdapter.setMany(state as ConditionState, action.payload),
    removeOne: (state, action: PayloadAction<string>) =>
      conditionAdapter.removeOne(state as ConditionState, action.payload),
    removeAll: (state) => conditionAdapter.removeAll(state as ConditionState),
    setLoading: (state, action: PayloadAction<LoadingStateType>) => {
      state.loading = action.payload;
    },
    initializeTeethGroup: (state, action: PayloadAction<ConditionIDGroup>) => {
      state.teethGroup = action.payload;
    },
    initializeMaxFaxGroup: (state, action: PayloadAction<string[]>) => {
      state.maxFaxGroup = action.payload;
    },
    addTeethGroup: (state, action: PayloadAction<Condition[]>) => {
      const teethGroup = state.teethGroup;

      action.payload.forEach((condition) => {
        const conditionToothID =
          condition.Attribution.case === 'Tooth'
            ? condition.Attribution.value.ToothID
            : '';

        if (conditionToothID in teethGroup) {
          teethGroup[conditionToothID].push(condition.ID);
        }
      });
    },
    setHoveredConditionIDs: (state, action: PayloadAction<string[]>) => {
      state.hoveredConditionIDs = action.payload;
    },
    setHoveredNonDentalConditionID: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.hoveredNonDentalConditionID = payload;
    },
    reset: () => conditionAdapter.getInitialState(conditionInitialState),
  },
});

export const { actions } = conditionSlice;

export default conditionSlice.reducer;
