import { useState } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { Tooltip } from '@/shared/ui';

import { i18n } from '../../config';

import { Row } from './Row';
import styles from './CurrentOrganizationRow.module.scss';

type CurrentOrganizationRowProps = {
  className?: string;
  organizationName: string;
  initials: string;
  avatarSrc: string;
  onClick: () => void;
};

export const CurrentOrganizationRow = (props: CurrentOrganizationRowProps) => {
  const { className, organizationName, avatarSrc, initials, onClick } = props;
  const { formatMessage } = useIntl();

  const [shouldShowTooltip, setShouldShowTooltip] = useState(false);

  return (
    <div className={cn(styles.container, className)}>
      <h3 className="h3m">{formatMessage(i18n.currentClinic)}</h3>

      {shouldShowTooltip ? (
        <Tooltip.Primary content={organizationName}>
          <Row
            organizationName={organizationName}
            initials={initials}
            onClick={onClick}
            avatarSrc={avatarSrc}
            showTooltipHandler={setShouldShowTooltip}
          />
        </Tooltip.Primary>
      ) : (
        <Row
          organizationName={organizationName}
          initials={initials}
          onClick={onClick}
          avatarSrc={avatarSrc}
          showTooltipHandler={setShouldShowTooltip}
        />
      )}
    </div>
  );
};
