import { useNavigate } from 'react-router-dom';

import { LogoutReq_LogoutOption } from '@/shared/api/protocol-ts/api/auth/svc_authentication_pb';
import { useAppDispatch } from '@/shared/hooks';
import { resetState } from '@/shared/model';
import { PATHS } from '@/shared/config';

import { authModel } from '@/entities/auth';
import { organizationModel } from '@/entities/organization';

export const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return async () => {
    await dispatch(authModel.thunks.refresh({}));

    await dispatch(
      authModel.thunks.logout({ Option: LogoutReq_LogoutOption.None }),
    ).unwrap();

    // TODO: REMOVE dispatch and localstorage cleaner when auth will work on new gRPC lib.
    // Think about to encapsulate this into thunk
    localStorage.removeItem('user');
    window.dispatchEvent(new Event('storage'));
    dispatch(organizationModel.actions.removeAll());
    dispatch(resetState());

    // NOTE: clear hubspot chat after logout
    window.HubSpotConversations?.clear({ resetWidget: true });

    navigate(PATHS.signIn);
  };
};
