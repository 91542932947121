// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file pipelines/report_cbct_ios_superimposition.proto (package com.diagnocat.pipelines, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { Segmentron_PhotoIOSSuperimposition } from "../model/dto_report_segmentron_common_pb.js";
import { TeethLandmarks_Update } from "./report_landmarks_pb.js";
import { Report_Studio_ToothPosition } from "../model/dto_report_type_studio_common_pb.js";
import { TeethPeriodontalStatuses_Update } from "./report_periodontal_status_pb.js";

/**
 * @generated from message com.diagnocat.pipelines.CBCT_IOS_Superimposition_Update
 */
export const CBCT_IOS_Superimposition_Update = proto3.makeMessageType(
  "com.diagnocat.pipelines.CBCT_IOS_Superimposition_Update",
  () => [
    { no: 1, name: "AlignmentSuccessful", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "Update" },
    { no: 2, name: "PhotoIOSSuperimposition", kind: "message", T: Segmentron_PhotoIOSSuperimposition, oneof: "Update" },
    { no: 3, name: "TeethLandmarks", kind: "message", T: TeethLandmarks_Update, oneof: "Update" },
    { no: 4, name: "ToothPosition", kind: "message", T: Report_Studio_ToothPosition, oneof: "Update" },
    { no: 5, name: "TeethPeriodontalStatuses", kind: "message", T: TeethPeriodontalStatuses_Update, oneof: "Update" },
  ],
);

