// Types related to 2D GP report ("gp_2d") pipelines method

// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file pipelines/report_pano_gp.proto (package com.diagnocat.pipelines, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { ToothDetection } from "./report_tooth_detection_pb.js";
import { Condition } from "../model/dto_report_condition_pb.js";
import { Conditions_Update } from "./report_condition_pb.js";
import { MaxFaxAnatomyLocalizations_Update } from "./report_maxfax_anatomy_localization_pb.js";
import { TeethAnatomyLocalizations_Update } from "./report_tooth_anatomy_localization_pb.js";

/**
 * @generated from message com.diagnocat.pipelines.Pano_GP_Update
 */
export const Pano_GP_Update = proto3.makeMessageType(
  "com.diagnocat.pipelines.Pano_GP_Update",
  () => [
    { no: 1, name: "ToothDetection", kind: "message", T: ToothDetection, oneof: "Update" },
    { no: 2, name: "Condition", kind: "message", T: Condition, oneof: "Update" },
    { no: 5, name: "Conditions", kind: "message", T: Conditions_Update, oneof: "Update" },
    { no: 3, name: "MaxFaxAnatomyLocalizations", kind: "message", T: MaxFaxAnatomyLocalizations_Update, oneof: "Update" },
    { no: 4, name: "TeethAnatomyLocalizations", kind: "message", T: TeethAnatomyLocalizations_Update, oneof: "Update" },
  ],
);

