import { Outlet } from 'react-router';

import { Layout } from '@/shared/ui';
import { useMedia } from '@/shared/hooks';

import { Header } from '@/widgets/Header';

export const GPReportLayout = () => {
  const { isMobile } = useMedia();

  // For mobile layout, MobileLayout is used inside GpReport component
  // Here we just return Outlet to avoid double Layout
  if (isMobile) {
    return <Outlet />;
  }

  return (
    <Layout footerWithSettings fullWidth>
      <Layout.Header>
        <Header fullWidth />
      </Layout.Header>
      <Outlet />
    </Layout>
  );
};
