import { ColorProfile, MPRLauncherRequest, SubVolume } from 'MPREmbedded';
import { Box3, Vector3 } from 'three';

import { AssetContent_Generated_CBCT_GP_MPR_SubVolume } from '@/shared/api/protocol-ts/model/dto_asset_cbct_gp_pb';
import { BBox } from '@/shared/api/protocol-ts/model/dto_common_geometry_pb';
import { Report_CBCT_GP } from '@/shared/api/protocol-ts/model/dto_report_type_cbct_gp_pb';
import { Tooth } from '@/shared/api/protocol-ts/model/dto_report_tooth_pb';

export const transformDataToMPRLauncherRequest = (
  _: Report_CBCT_GP,
  protocolSubVolumes: AssetContent_Generated_CBCT_GP_MPR_SubVolume[],
  tooth: Tooth,
): MPRLauncherRequest | null => {
  if (protocolSubVolumes.length === 0) {
    console.error('[MPREmbedded] No subvolumes found');
    return null;
  }

  const colorProfile: ColorProfile = {
    ww: protocolSubVolumes[0].WindowWidth,
    wc: protocolSubVolumes[0].WindowLevel,
  };

  const subVolumes = readSubVolumes(protocolSubVolumes);
  if (!subVolumes) {
    console.error('[MPREmbedded] Subvolumes are undefined');
    return null;
  }

  return {
    colorProfile,
    volumes: subVolumes,
    tooth: {
      numeration: {
        iso: tooth.Numeration!.ISO,
        idx: tooth.Numeration!.SupernumeraryIndex,
      },
      anatomicalBasis: tooth.OriginatedPosition?.AnatomicalBasis,
      origin: tooth.OriginatedPosition?.Origin,
    },
    debug: {
      includeMainViewport: false,
    },
  };
};

const BBoxToBox3 = (workingCrop: BBox) => {
  if (!workingCrop.X || !workingCrop.Y || !workingCrop.Z) {
    console.error('[MPREmbedded] Working Crop is incomplete for a tile');
    return null;
  }

  return new Box3(
    new Vector3(workingCrop.X.Min, workingCrop.Y.Min, workingCrop.Z.Min),
    new Vector3(workingCrop.X.Max, workingCrop.Y.Max, workingCrop.Z.Max),
  );
};

const readSubVolumes = (
  protocolSubVolumes: AssetContent_Generated_CBCT_GP_MPR_SubVolume[],
): SubVolume[] | null => {
  const subVolumes = protocolSubVolumes
    .map((protocolSubVolume) => {
      const workingCrop = protocolSubVolume.Position;
      if (!workingCrop) {
        console.error('[MPREmbedded] Working Crop is undefined for a tile');
        return null;
      }

      const volume = protocolSubVolume.Volume;
      if (!volume) {
        console.error('[MPREmbedded] Volume is undefined for a tile');
        return null;
      }

      if (!protocolSubVolume.Numeration) {
        console.error('[MPREmbedded] Numeration is undefined for a tile');
        return null;
      }

      return {
        box: BBoxToBox3(workingCrop),
        fileId: volume.ID,
        numeration: {
          iso: protocolSubVolume.Numeration.ISO,
          idx: protocolSubVolume.Numeration.SupernumeraryIndex,
        },
      };
    })
    .filter((subVolume) => subVolume !== null) as SubVolume[];

  return subVolumes;
};
