// Types related to CBCT Implant report ("implantviz") pipelines method

// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file pipelines/report_cbct_implant.proto (package com.diagnocat.pipelines, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { CBCTSeriesGeometryData } from "../model/dto_common_geometry_pb.js";

/**
 * @generated from message com.diagnocat.pipelines.CBCT_Implant_Update
 */
export const CBCT_Implant_Update = proto3.makeMessageType(
  "com.diagnocat.pipelines.CBCT_Implant_Update",
  () => [
    { no: 1, name: "CropGeometryData", kind: "message", T: CBCTSeriesGeometryData, oneof: "Update" },
    { no: 2, name: "SliceStep", kind: "scalar", T: 2 /* ScalarType.FLOAT */, oneof: "Update" },
    { no: 3, name: "SliceThickness", kind: "scalar", T: 2 /* ScalarType.FLOAT */, oneof: "Update" },
    { no: 4, name: "MeasurementScale", kind: "scalar", T: 2 /* ScalarType.FLOAT */, oneof: "Update" },
  ],
);

