import { createSelector } from '@reduxjs/toolkit';

import { getName } from '@/shared/lib';

import { organizationModel } from '@/entities/organization';
import { userModel } from '@/entities/user';
import { getAvatarSrcByUserID } from '@/entities/assets';

import { isTreatingDoctor } from '../lib/isTreatingDoctor';

export const selectOrganizationDoctorsOptions = createSelector(
  organizationModel.selectors.selectTreatingDoctors,
  userModel.selectors.selectCurrentUser,
  userModel.selectors.selectUserLocale,
  (organizationDoctorsAsUserRole = [], user, userLocale) => {
    const currentUserAsDoctor = organizationDoctorsAsUserRole.find(
      (doctor) => doctor.UserID === user.ID,
    );

    const isCurrentUserTreatingDoctor = isTreatingDoctor(
      currentUserAsDoctor?.Roles || [],
    );

    if (isCurrentUserTreatingDoctor) {
      const avatarSrc = getAvatarSrcByUserID(
        currentUserAsDoctor?.UserID as string,
        'thumbnail',
      );
      const doctorName = getName(
        currentUserAsDoctor?.PersonalData?.FirstName,
        currentUserAsDoctor?.PersonalData?.LastName,
        {
          userLocale,
        },
      );

      return [
        {
          label: doctorName,
          value: currentUserAsDoctor?.UserID as string,
          avatarSrc,
        },
      ];
    }

    return organizationDoctorsAsUserRole.map((doctor) => {
      const avatarSrc = getAvatarSrcByUserID(doctor?.UserID, 'thumbnail');
      const doctorName = getName(
        doctor?.PersonalData?.FirstName,
        doctor?.PersonalData?.LastName,
        {
          userLocale,
        },
      );

      return {
        label: doctorName,
        value: doctor?.UserID,
        avatarSrc,
      };
    });
  },
);
