import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { ConnectError } from '@bufbuild/connect';

import { Icon, Spinner } from '@/shared/ui';
import { PATHS } from '@/shared/config';
import smileCat from '@/shared/assets/images/cats/smile.png';
import { useAppDispatch, useMedia } from '@/shared/hooks';
import { AuthState_AuthStatus } from '@/shared/api/protocol-ts/api/auth/dto_auth_pb';
import { trackPageView } from '@/shared/lib';

import { authModel } from '@/entities/auth';

import { MobileHeader } from '@/widgets/Header';

import { useConfirmEmailError } from './hooks/useConfirmEmailError';
import styles from './ConfirmEmail.module.scss';

type ConfirmEmailProps = {
  className?: string;
  testID?: string;
};

export const ConfirmEmail: FC<ConfirmEmailProps> = (props) => {
  const { className, testID } = props;

  const location = useLocation();

  const email = (location?.state as { email: string })?.email;

  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isMobile } = useMedia();

  const [searchParams] = useSearchParams();

  const [isVerifiedSuccessfully, setIsVerifiedSuccessfully] = useState(false);

  const { callConfirmEmailError } = useConfirmEmailError();

  const confirmationCode = searchParams.get('code');

  const confirmEmail = async (confirmationCode: string) => {
    try {
      const confirmEmailResponse = await dispatch(
        authModel.thunks.confirmEmail({
          Action: {
            value: confirmationCode,
            case: 'ConfirmationCode',
          },
        }),
      ).unwrap();

      if (confirmEmailResponse.Status === AuthState_AuthStatus.SUCCESSFUL) {
        setIsVerifiedSuccessfully(true);

        setTimeout(() => navigate(PATHS.main), 2000);
      }

      if (confirmEmailResponse.Status === AuthState_AuthStatus.IN_PROGRESS) {
        setIsVerifiedSuccessfully(true);

        setTimeout(() => navigate(PATHS.finishRegistration), 2000);
      }
    } catch (error) {
      if (error instanceof ConnectError) {
        callConfirmEmailError(error);
      }
    }
  };

  useEffect(() => {
    if (confirmationCode) {
      confirmEmail(confirmationCode);
    }

    trackPageView('ConfirmEmail Page Viewed');
  }, []);

  if (isMobile) {
    return (
      <div className={className}>
        <MobileHeader isProtectedPages={false} />

        <div className={styles.mobileWrapper}>
          <h3 className={cn(styles.mobileHeading, 'h2m')}>
            {formatMessage({
              id: 'confirmEmail.verification',
              defaultMessage: 'Verification',
            })}
          </h3>

          <div className={styles.mobileContent}>
            <img
              src={smileCat}
              width={130}
              height={130}
              alt={formatMessage({
                id: 'imgAltText.smileCat',
                defaultMessage: 'Smile cat',
              })}
            />

            {isVerifiedSuccessfully && (
              <>
                <h5 className={cn(styles.centeredText, 'h5')}>
                  <FormattedMessage
                    id="confirmEmail.verifiedSuccessfully"
                    defaultMessage="Congrats! Your Diagnocat account is verified successfully."
                  />
                </h5>

                <Icon
                  name="check"
                  size={48}
                  className={styles.verifiedSuccessfullyIcon}
                />
              </>
            )}

            {!isVerifiedSuccessfully && confirmationCode && (
              <Spinner size={48} className={styles.spinner} />
            )}

            {!confirmationCode && (
              <p className={cn(styles.centeredText, 'p2')}>
                <FormattedMessage
                  id="confirmEmail.we_sent_link"
                  defaultMessage="We have sent you a link to verify your email {email}. Please check your mailbox."
                  values={{
                    email: email || 'your@mail.com',
                  }}
                />
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      <div className={styles.wrapper}>
        <h3 className={cn(styles.heading, 'h3')}>
          <FormattedMessage
            id="confirmEmail.verification"
            defaultMessage="Verification"
          />
        </h3>

        <div className={styles.content}>
          <img
            src={smileCat}
            width={130}
            height={130}
            alt={formatMessage({
              id: 'imgAltText.smileCat',
              defaultMessage: 'Smile cat',
            })}
          />

          {isVerifiedSuccessfully && (
            <>
              <h5 className={cn(styles.centeredText, 'h5')}>
                <FormattedMessage
                  id="confirmEmail.verifiedSuccessfully"
                  defaultMessage="Congrats! Your Diagnocat account is verified successfully."
                />
              </h5>

              <Icon
                name="check"
                size={48}
                className={styles.verifiedSuccessfullyIcon}
              />
            </>
          )}

          {!isVerifiedSuccessfully && confirmationCode && (
            <Spinner size={48} className={styles.spinner} />
          )}

          {!confirmationCode && (
            <p className={cn(styles.centeredText, 'p2')}>
              <FormattedMessage
                id="confirmEmail.we_sent_link"
                defaultMessage="We have sent you a link to verify your email {email}. Please check your mailbox."
                values={{
                  email: email || 'your@mail.com',
                }}
              />
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
