import { MPREmbeddedApi } from 'MPREmbedded';
import { useState, useRef, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { FileWithPath } from 'react-dropzone';

import { AssetType } from '@/shared/api/protocol-ts/model/dto_asset_pb';
import { Button } from '@/shared/ui';

import { useCheckReportSignature } from '@/entities/reports';

import { useUploadAssetContext } from '@/features/uploadAsset';

import styles from './AddToReport.module.scss';

const DEBUG_DONT_UPLOAD = localStorage.getItem('DEBUG_DONT_UPLOAD') === 'true';

export const AddToReport = (props: {
  toothID: string;
  className?: string;
  mprEmbedded: MPREmbeddedApi;
}) => {
  const { toothID, mprEmbedded } = props;

  const ref = useRef<HTMLDivElement>(null);

  const { checkReportSignature } = useCheckReportSignature();
  const { startUploadAsset } = useUploadAssetContext();

  const [loading, setLoading] = useState<boolean>(false);

  const uploadAsset = useCallback(
    async (files: FileWithPath[]) => {
      await startUploadAsset(
        {
          files,
          assetType: AssetType.AssetType_Report_Uploaded_MPRPlane,
          toothID,
        },
        true,
      );
    },
    [toothID, startUploadAsset],
  );

  const handleAddToReport = () => {
    checkReportSignature({
      toothID,
      onSignatureChecked: async () => {
        setLoading(true);
        const blob = await mprEmbedded.makeActiveViewportScreenshot();
        if (blob) {
          const file = new File([blob], `${Date.now()}.png`, {
            type: 'image/png',
          });

          if (DEBUG_DONT_UPLOAD) {
            setLoading(false);

            // open blob as an image in a new tab
            const url = URL.createObjectURL(file);
            window.open(url, '_blank');

            // skip upload asset
            return;
          }

          uploadAsset([file]).finally(() => {
            setLoading(false);
          });
        } else {
          setLoading(false);
          console.error('Failed to make screenshot');
        }
      },
    });
  };

  useEffect(() => {
    const unsubscribe = mprEmbedded.activeViewportChanged.on(
      ({ /*name,*/ layoutElement }) => {
        layoutElement.appendChild(ref.current!);
      },
    );

    return () => {
      unsubscribe();
    };
  }, [props.mprEmbedded]);

  const { formatMessage } = useIntl();

  return (
    <div ref={ref} className={styles.self}>
      <Button
        size="small"
        loading={loading}
        onClick={(event) => {
          event.stopPropagation();
          handleAddToReport();
        }}
      >
        {formatMessage({
          id: 'mpr.button.addToReport',
          defaultMessage: 'Add to report',
        })}
      </Button>
    </div>
  );
};
