import { FC } from 'react';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { MedicalImageViewOptions } from '@/shared/api/protocol-ts/model/dto_common_image_view_options_pb';
import { ToolNames } from '@/shared/config';

import { reportsModel } from '@/entities/reports';
import { ModalID, modalModel } from '@/entities/modal';
import { toolbarMIRModel } from '@/entities/toolbarMIR';

import { MedicalImageToolbar } from '../MedicalImageToolbar/MedicalImageToolbar';
import { ToolbarOptions } from '../../config/types';

type ToolbarProps = {
  toolbarID: string;
};

export const Toolbar: FC<ToolbarProps> = (props) => {
  const { toolbarID } = props;

  const dispatch = useAppDispatch();

  const currentReport = useAppSelector(
    reportsModel.selectors.selectCurrentReport,
  );

  const isInvert = currentReport?.MedicalImageFeatures?.ViewOptions?.Invert;

  const controlsOptions: ToolbarOptions[] = [
    {
      control: 'expand',
    },
    {
      control: 'split',
    },
    {
      control: 'sharpness',
    },
    {
      control: 'brightness',
    },
    {
      control: 'invert',
      isActive: isInvert,
      onClick: async (_: ToolNames, isActive: boolean) => {
        dispatch(
          toolbarMIRModel.actions.invert({
            toolbarID,
            value: !isActive,
          }),
        );

        const { Report } = await dispatch(
          reportsModel.thunks.setReportMedicalImageViewOptions({
            ReportID: currentReport?.ID as string,
            MedicalImageViewOptions: new MedicalImageViewOptions({
              ...(currentReport?.MedicalImageFeatures
                ?.ViewOptions as MedicalImageViewOptions),
              Invert: !isInvert,
            }),
          }),
        ).unwrap();

        if (Report) {
          dispatch(reportsModel.actions.setNewestOne(Report));
        }
      },
    },
    {
      control: 'reset',
      notToggle: true,
    },
    {
      control: 'editNumbers',
      notToggle: true,
      onClick: () => {
        dispatch(
          modalModel.actions.openModal({
            modalID: ModalID.TeethNumberingModal,
            data: {},
          }),
        );
      },
    },
  ];

  return (
    <MedicalImageToolbar
      toolbarID={toolbarID}
      controlsOptions={controlsOptions}
    />
  );
};
