import { defineMessages } from 'react-intl';

import { PatientsListColumnName } from '@/shared/config';

import { PatientsFilter } from '@/entities/patient';

export const ColumnValues = defineMessages({
  [PatientsListColumnName.PatientName]: {
    id: 'patientList.patientName',
    defaultMessage: 'Patient name',
  },
  [PatientsListColumnName.ClinicName]: {
    id: 'sharedPatient.clinicName',
    defaultMessage: 'Clinic name',
  },
  [PatientsListColumnName.SharedBy]: {
    id: 'sharedPatient.sharedBy',
    defaultMessage: 'Shared by',
  },
  [PatientsListColumnName.SenderEmail]: {
    id: 'sharedPatient.senderEmail',
    defaultMessage: 'Sender Email',
  },
  [PatientsListColumnName.SharingDate]: {
    id: 'sharedPatient.sharedDate',
    defaultMessage: 'Sharing date',
  },
  [PatientsListColumnName.SharedWith]: {
    id: 'sharedPatient.sharedWith',
    defaultMessage: 'Shared with',
  },
  [PatientsListColumnName.Status]: {
    id: 'sharedPatient.Status',
    defaultMessage: 'Status',
  },
  [PatientsListColumnName.ExternalID]: {
    id: 'patientList.patientID',
    defaultMessage: 'Patient ID',
  },
  [PatientsListColumnName.DateOfBirth]: {
    id: 'patientList.dateOfBirth',
    defaultMessage: 'Date of birth',
  },
  [PatientsListColumnName.Doctors]: {
    id: 'patientList.treatingDoctors',
    defaultMessage: 'Treating doctors',
  },
  [PatientsListColumnName.Studies]: {
    id: 'patientList.studies',
    defaultMessage: 'Studies',
  },
});

export const searchBarPlaceholders = defineMessages<PatientsFilter>({
  [PatientsFilter.all]: {
    id: 'patientList.filters.searchBarPlaceholderAll',
    defaultMessage: ' Search by Patient name or ID',
  },
  [PatientsFilter.sharedWithMe]: {
    id: 'patientList.filters.searchBarPlaceholderSharedWithMe',
    defaultMessage: 'Search by Patient name, Clinic name or Shared by',
  },
  [PatientsFilter.sharedByMe]: {
    id: 'patientList.filters.searchBarPlaceholderSharedByMeOrStaff',
    defaultMessage: 'Search by Patient name, Shared by or Shared with',
  },
  [PatientsFilter.sharedByStaff]: {
    id: 'patientList.filters.searchBarPlaceholderSharedByMeOrStaff',
    defaultMessage: 'Search by Patient name, Shared by or Shared with',
  },
});
