import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import {
  ExitFromFullscreenButton,
  Icon,
  IconNames,
  Tooltip,
} from '@/shared/ui';
import {
  statelessToolNames,
  StatelessToolNames,
  ToggledToolNames,
  ToolNames,
} from '@/shared/config';
import { useAppDispatch } from '@/shared/hooks';

import { ModalID, modalModel } from '@/entities/modal';
import { toolbarMIRModel, ToolbarMIRState } from '@/entities/toolbarMIR';

import { toolsMessages } from '../../config/i18n';

import styles from './ImageToolbar.module.scss';

const TOOL_NAME_TO_ICON_MAPPING: Record<ToolNames, IconNames> = {
  ruler: 'ruler',
  arrow: 'arrow',
  angle: 'angle',
  eraser: 'erase',
  view: 'eyeFilled',
  brightness: 'brightness',
  sharpening: 'sharpness',
  expand: 'expand',
  invert: 'invert',
  reset: 'back',
  move: 'move',
  zoom: 'zoom',
  split: 'split',
  download: 'download',
  sharpness: 'sharpness',
  editNumbers: 'toothnum',
  ios: 'ios',
  turn: 'turn',
  hideMarks: 'close',
} as const;

type SliceToolbarProps = {
  toolbarId: string;
  controls: ToolNames[];
  tooltipPosition?: 'top' | 'right' | 'left' | 'bottom';
  className?: string;
  stateLessHandlers?: Partial<{
    [key in StatelessToolNames]: () => void;
  }>;
  initialState?: ToolbarMIRState[string];
};

const isStatelessToolName = (
  toolName: ToolNames,
): toolName is StatelessToolNames =>
  (statelessToolNames as readonly string[]).includes(toolName);

export const ImageToolbar: FC<SliceToolbarProps> = (props) => {
  const {
    toolbarId,
    controls,
    tooltipPosition,
    className,
    stateLessHandlers,
    initialState,
  } = props;

  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      toolbarMIRModel.actions.initToolbarMIR({
        toolbarID: toolbarId,
        initialState,
      }),
    );

    return () => {
      dispatch(toolbarMIRModel.actions.deleteToolbarMIR(toolbarId));
    };
  }, [toolbarId]);

  const defaultHandlers: Partial<Record<StatelessToolNames, () => void>> = {
    editNumbers: () => {
      dispatch(
        modalModel.actions.openModal({
          modalID: ModalID.TeethNumberingModal,
          data: {},
        }),
      );
    },
  };

  const activeTools = useSelector(
    toolbarMIRModel.selectors.selectActiveTools(toolbarId),
  );

  const handleControlClick = (control: ToolNames, currentValue: boolean) => {
    if (isStatelessToolName(control)) {
      stateLessHandlers?.[control]?.() || defaultHandlers[control]?.();
      return;
    }

    if (control === 'expand') {
      if (!currentValue) {
        dispatch(
          toolbarMIRModel.actions.expand({ toolbarID: toolbarId, value: true }),
        );

        const fullscreenOverlay = document.getElementById('report_render');

        if (fullscreenOverlay) {
          fullscreenOverlay.requestFullscreen();
        }
      } else {
        dispatch(
          toolbarMIRModel.actions.expand({
            toolbarID: toolbarId,
            value: false,
          }),
        );
        document.exitFullscreen();
      }
      return;
    }

    if (control === 'invert') {
      dispatch(
        toolbarMIRModel.actions.invert({
          toolbarID: toolbarId,
          value: !currentValue,
        }),
      );
      return;
    }

    if (control === 'split') {
      dispatch(
        toolbarMIRModel.actions.split({
          toolbarID: toolbarId,
          value: !currentValue,
        }),
      );
      return;
    }

    dispatch(
      toolbarMIRModel.actions.activateTool({
        toolbarID: toolbarId,
        control,
        value: !currentValue,
      }),
    );
  };

  const isIconActive = (control: ToolNames) => {
    return activeTools.includes(control as ToggledToolNames);
  };

  return (
    <>
      <div className={cn(styles.container, className)}>
        {controls.map((control) => {
          const isActive = isIconActive(control);
          const toolIcon = (
            <Icon
              key={control}
              name={TOOL_NAME_TO_ICON_MAPPING[control]}
              className={cn(styles.icon, isActive && styles.active)}
              size={32}
              onClick={() => handleControlClick(control, isActive)}
            />
          );
          if (tooltipPosition) {
            return (
              <Tooltip.Primary
                key={control}
                side={tooltipPosition}
                content={
                  <span className="p3">
                    {formatMessage(toolsMessages[control])}
                  </span>
                }
              >
                {toolIcon}
              </Tooltip.Primary>
            );
          }
          return toolIcon;
        })}
      </div>
      {activeTools.includes('expand') && (
        <ExitFromFullscreenButton
          onClick={() =>
            dispatch(
              toolbarMIRModel.actions.expand({
                toolbarID: toolbarId,
                value: false,
              }),
            )
          }
        />
      )}
    </>
  );
};
