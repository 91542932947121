import { FC } from 'react';
import cn from 'classnames';

import styles from './FooterDate.module.scss';

type FooterDateProps = {
  date: string;
  label: string;
  className?: string;
};

export const FooterDate: FC<FooterDateProps> = (props) => {
  const { date, label, className } = props;

  return (
    <div className={cn(styles.container, className, 'p2')}>
      {label} {date}
    </div>
  );
};
