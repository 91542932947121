import { useEffect } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { isEmpty } from 'ramda';

import { Avatar, Button } from '@/shared/ui';
import { PATHS } from '@/shared/config';
import { useAppDispatch } from '@/shared/hooks';
import catImg from '@/shared/assets/images/cat.png';
import { getImageSrc } from '@/shared/lib';

import { getOrganizationInitials } from '@/entities/organization';
import { marketingModel } from '@/entities/marketing';

import { useControlPanel } from '../../hooks/useControlPanel';
import { CurrentOrganizationRow } from '../CurrentOrganizationRow/CurrentOrganizationRow';
import { OrganizationRow } from '../OrganizationRow/OrganizationRow';
import { i18n } from '../../config';

import styles from './ControlPanel.module.scss';

type MenuProps = {
  className?: string;
};

export const ControlPanel = (props: MenuProps) => {
  const { className } = props;

  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    allOrganizations,
    profileAssetSrc,
    profileInitials,
    currentOrganization,
    currentOrganizationName = '',
    userAvatarAssetSrc,
    userFullName,
    userInitials,
    openGetFullAccessModalHandle,
    logoutHandle,
    navigateToCompanySettingsHandle,
    organizationChangeHandle,
  } = useControlPanel();

  useEffect(() => {
    const checkMarketingQuestionnaire = async () => {
      if (currentOrganization) {
        const { Questionnaire } = await dispatch(
          marketingModel.thunks.getOrganizationOnboardingQuestionnaire({
            OrganizationID: currentOrganization.ID,
          }),
        ).unwrap();

        if (isEmpty(Questionnaire?.FocusOfInterest)) {
          navigate(PATHS.additionalInfo);
        }
      }
    };

    checkMarketingQuestionnaire();
  }, [currentOrganization]);

  return (
    <div className={cn(styles.container, className)}>
      <Popup
        trigger={
          // Popup does not work without a div
          <div>
            <div className={styles.profileWrapper}>
              <Avatar
                src={userAvatarAssetSrc}
                alt={userFullName}
                initials={userInitials}
              />

              <p className={cn(styles.textEllipsis, 'p2')}>{userFullName}</p>
            </div>
          </div>
        }
        on="click"
        position="bottom right"
        arrow={false}
      >
        <div className={styles.dropdownContainer}>
          <div className={styles.borderBottom}>
            <Link
              className={cn(
                styles.myProfile,
                styles.link,
                styles.innerRow,
                'p2',
              )}
              to={PATHS.personalSettings}
            >
              <Avatar
                size={72}
                src={userAvatarAssetSrc}
                alt={userFullName}
                initials={userInitials}
              />

              <div className={styles.info}>
                <h3 className={cn(styles.userName, styles.textEllipsis, 'h6')}>
                  {userFullName}
                </h3>

                <p className={cn(styles.myProfileLabel, 'p2')}>
                  {formatMessage(i18n.myProfile)}
                </p>
              </div>
            </Link>
          </div>

          {currentOrganization ? (
            <CurrentOrganizationRow
              organizationName={currentOrganizationName}
              initials={profileInitials}
              avatarSrc={profileAssetSrc}
              onClick={navigateToCompanySettingsHandle}
            />
          ) : (
            <div className={styles.getFullAccess}>
              <img
                src={catImg}
                width={110}
                height={110}
                alt={formatMessage({
                  id: 'imgAltText.catImage',
                  defaultMessage: 'Cat image',
                })}
              />

              <div className={styles.fullAccessInfo}>
                <p className={cn(styles.fullAccessTitle, 'h3m')}>
                  {formatMessage({
                    id: 'controlPanel.getFullAccessToDiagnocat',
                    defaultMessage: 'Get full access to Diagnocat',
                  })}
                </p>

                <Button size="medium" onClick={openGetFullAccessModalHandle}>
                  {formatMessage({
                    id: 'controlPanel.getNow',
                    defaultMessage: 'Get now',
                  })}
                </Button>
              </div>
            </div>
          )}

          {allOrganizations?.length > 1 && (
            <div className={styles.myOrganizations}>
              <p
                className={cn(
                  styles.myOrganizationsTitle,
                  allOrganizations.length > 5 &&
                    styles.organizationsTitleBorderBottom,
                  'h6',
                )}
              >
                {formatMessage(i18n.otherClinics)}
              </p>

              <div
                className={cn(
                  styles.organizationsContainer,
                  allOrganizations.length > 5 && styles.borderBottom,
                )}
              >
                {allOrganizations.map((organization) => {
                  const isCurrentOrganization =
                    currentOrganization?.ID === organization?.ID;

                  const organizationInitials =
                    getOrganizationInitials(organization);

                  const organizationName = organization?.Name;

                  const organizationAsset = getImageSrc(
                    organization?.LogoAssetID,
                    'thumbnail',
                  );

                  return (
                    <OrganizationRow
                      key={organization?.ID}
                      organizationName={organizationName}
                      initials={organizationInitials}
                      avatarSrc={organizationAsset}
                      onClick={() => organizationChangeHandle(organization?.ID)}
                      isCurrentOrganization={isCurrentOrganization}
                    />
                  );
                })}
              </div>
            </div>
          )}

          <Button
            size="medium"
            variant="secondary"
            onClick={() => logoutHandle()}
            className={styles.signOutButton}
          >
            <FormattedMessage id="global.signOut" defaultMessage="Sign out" />
          </Button>
        </div>
      </Popup>
    </div>
  );
};
