// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file pipelines/report_cbct_ortho.proto (package com.diagnocat.pipelines, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message com.diagnocat.pipelines.CBCT_OrthoSlides_Update
 */
export const CBCT_OrthoSlides_Update = proto3.makeMessageType(
  "com.diagnocat.pipelines.CBCT_OrthoSlides_Update",
  [],
);

