import { useIntl } from 'react-intl';
import cn from 'classnames';

import { Avatar } from '@/shared/ui';

import styles from './ClinicRow.module.scss';

type ClinicRowProps = {
  id: string;
  organizationName: string;
  initials: string;
  avatarSrc: string;
  isCurrent?: boolean;
  onClick: (id: string) => void;
};

export const ClinicRow = (props: ClinicRowProps) => {
  const { id, organizationName, avatarSrc, initials, isCurrent, onClick } =
    props;

  const { formatMessage } = useIntl();

  const handleClick = () => {
    onClick(id);
  };

  return (
    <button type="button" className={styles.container} onClick={handleClick}>
      <Avatar
        className={styles.avatar}
        src={avatarSrc}
        initials={initials}
        alt={organizationName}
        size={isCurrent ? 62 : 48}
      />
      <div className={styles.info}>
        <p className="p1m">{organizationName}</p>
        {isCurrent && (
          <span className={cn(styles.settings, 'p2')}>
            {formatMessage({
              id: 'global.settings',
              defaultMessage: 'Settings',
            })}
          </span>
        )}
      </div>
    </button>
  );
};
