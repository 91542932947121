import { defineMessages } from 'react-intl';

import { ConditionCode } from '@/shared/api/protocol-ts/model/dto_report_condition_codes_pb';

import { ConditionGroups, ChildConditionGroups } from './const';
import { CombinedConditionGroupCode } from './combinedConditions';

export const conditionText = defineMessages<ConditionCode>({
  [ConditionCode.ConditionCodeInvalidValue]: {
    id: 'ConditionCode.ConditionCodeInvalidValue ',
    defaultMessage: 'Invalid Value',
  },
  [ConditionCode.MucosalThickening]: {
    id: 'ConditionCode.MucosalThickening',
    defaultMessage: 'Mucosal thickening',
  },
  [ConditionCode.PreEruptiveResorption]: {
    id: 'ConditionCode.PreEruptiveResorption',
    defaultMessage: 'Pre-eruptive resorption',
  },
  [ConditionCode.PDLSpaceWidening_AlongTheRoot]: {
    id: 'ConditionCode.PDLSpaceWidening_AlongTheRoot',
    defaultMessage: 'PDL space widening along the root',
  },
  [ConditionCode.PDLSpaceWidening_InPeriapicalRegion]: {
    id: 'ConditionCode.PDLSpaceWidening_InPeriapicalRegion',
    defaultMessage: 'PDL space widening in the periapical region',
  },

  [ConditionCode.Child_EndoTreated_Obturation_ShortFilling]: {
    id: 'Child_EndoTreated_Obturation_ShortFilling',
    defaultMessage: 'Short filling',
  },
  [ConditionCode.Child_EndoTreated_Obturation_Overfilling]: {
    id: 'Child_EndoTreated_Obturation_Overfilling',
    defaultMessage: 'Overfilling',
  },
  [ConditionCode.Child_EndoTreated_Obturation_MissedCanal]: {
    id: 'Child_EndoTreated_Obturation_MissedCanal',
    defaultMessage: 'Missed canal',
  },
  [ConditionCode.Child_EndoTreated_Quality_AdequateDensity]: {
    id: 'Child_EndoTreated_Quality_AdequateDensity',
    defaultMessage: 'Adequate density',
  },
  [ConditionCode.Child_EndoTreated_Quality_VoidsPresentInTheRootFilling]: {
    id: 'Child_EndoTreated_Quality_VoidsPresentInTheRootFilling',
    defaultMessage: 'Voids present in the root filling',
  },
  [ConditionCode.Child_EndoTreated_Quality_VoidsInThePostArea]: {
    id: 'Child_EndoTreated_Quality_VoidsInThePostArea',
    defaultMessage: 'Voids in the post area',
  },
  [ConditionCode.Child_EndoTreated_PostAndCore_CastPostAndCore]: {
    id: 'Child_EndoTreated_PostAndCore_CastPostAndCore',
    defaultMessage: 'Cast post and core',
  },
  [ConditionCode.Child_EndoTreated_PostAndCore_FiberglassPost]: {
    id: 'Child_EndoTreated_PostAndCore_FiberglassPost',
    defaultMessage: 'Fiberglass post',
  },
  [ConditionCode.Child_EndoTreated_PostAndCore_MetalPost]: {
    id: 'Child_EndoTreated_PostAndCore_MetalPost',
    defaultMessage: 'Metal post',
  },
  [ConditionCode.Child_EndoTreated_Complications_RootPerforation]: {
    id: 'Child_EndoTreated_Complications_RootPerforation',
    defaultMessage: 'Root perforation',
  },
  [ConditionCode.Child_EndoTreated_Complications_SeparatedInstrumentInTheRootCanal]:
    {
      id: 'Child_EndoTreated_Complications_SeparatedInstrumentInTheRootCanal',
      defaultMessage: 'Separated instrument in the root canal',
    },
  [ConditionCode.CodeTooth]: {
    id: 'ConditionCode.CodeTooth',
    defaultMessage: 'Tooth',
  },
  [ConditionCode.Missing]: {
    id: 'ConditionCode.Missing',
    defaultMessage: 'Missing',
  },
  [ConditionCode.Implant]: {
    id: 'ConditionCode.Implant',
    defaultMessage: 'Implant',
  },
  [ConditionCode.Pontic]: {
    id: 'ConditionCode.Pontic',
    defaultMessage: 'Pontic',
  },
  [ConditionCode.RootFragment]: {
    id: 'ConditionCode.RootFragment',
    defaultMessage: 'Root fragment',
  },
  [ConditionCode.PartiallyVisible]: {
    id: 'ConditionCode.PartiallyVisible',
    defaultMessage: 'Not fully visible',
  },
  [ConditionCode.Filling]: {
    id: 'ConditionCode.Filling',
    defaultMessage: 'Filling',
  },
  [ConditionCode.Hypercementosis]: {
    id: 'ConditionCode.Hypercementosis',
    defaultMessage: 'Hypercementosis',
  },
  [ConditionCode.Impaction]: {
    id: 'ConditionCode.Impaction',
    defaultMessage: 'Impaction',
  },
  [ConditionCode.Roots1]: {
    id: 'ConditionCode.Roots1',
    defaultMessage: '1 root',
  },
  [ConditionCode.Roots2]: {
    id: 'ConditionCode.Roots2',
    defaultMessage: '2 roots',
  },
  [ConditionCode.Roots3]: {
    id: 'ConditionCode.Roots3',
    defaultMessage: '3 roots',
  },
  [ConditionCode.Roots4]: {
    id: 'ConditionCode.Roots4',
    defaultMessage: '4 roots',
  },
  [ConditionCode.Canals1]: {
    id: 'ConditionCode.Canals1',
    defaultMessage: '1 canals',
  },
  [ConditionCode.Canals2]: {
    id: 'ConditionCode.Canals2',
    defaultMessage: '2 canals',
  },
  [ConditionCode.Canals3]: {
    id: 'ConditionCode.Canals3',
    defaultMessage: '3 canals',
  },
  [ConditionCode.Canals4]: {
    id: 'ConditionCode.Canals4',
    defaultMessage: '4 canals',
  },
  [ConditionCode.Canals5]: {
    id: 'ConditionCode.Canals5',
    defaultMessage: '5 canals',
  },
  [ConditionCode.ArtificialCrown]: {
    id: 'ConditionCode.ArtificialCrown',
    defaultMessage: 'Artificial Crown',
  },
  [ConditionCode.CariesSigns]: {
    id: 'ConditionCode.CariesSigns',
    defaultMessage: 'Caries signs',
  },
  [ConditionCode.EctopicEruption]: {
    id: 'ConditionCode.EctopicEruption',
    defaultMessage: 'Ectopic eruption',
  },
  [ConditionCode.EndoTreated]: {
    id: 'ConditionCode.EndoTreated',
    defaultMessage: 'Endodontically treated tooth',
  },
  [ConditionCode.VerticalRootFracture]: {
    id: 'ConditionCode.VerticalRootFracture',
    defaultMessage: 'Vertical root fracture',
  },
  [ConditionCode.CanalObliteration]: {
    id: 'ConditionCode.CanalObliteration',
    defaultMessage: 'Canal obliteration',
  },
  [ConditionCode.InternalResorption]: {
    id: 'ConditionCode.InternalResorption',
    defaultMessage: 'Internal resorption',
  },
  [ConditionCode.OrthodonticAppliance]: {
    id: 'ConditionCode.OrthodonticAppliance',
    defaultMessage: 'Orthodontic appliance',
  },
  [ConditionCode.PeriapicalRadiopacity]: {
    id: 'ConditionCode.PeriapicalRadiopacity',
    defaultMessage: 'Periapical radiopacity',
  },
  [ConditionCode.PeriapicalRadiolucency]: {
    id: 'ConditionCode.PeriapicalRadiolucency',
    defaultMessage: 'Periapical radiolucency',
  },
  [ConditionCode.FurcationLesion]: {
    id: 'ConditionCode.FurcationLesion',
    defaultMessage: 'Furcation lesion',
  },
  [ConditionCode.PulpStone]: {
    id: 'ConditionCode.PulpStone',
    defaultMessage: 'Pulp stone',
  },
  [ConditionCode.ExternalResorption]: {
    id: 'ConditionCode.ExternalResorption',
    defaultMessage: 'External resorption',
  },
  [ConditionCode.HorizontalRootFracture]: {
    id: 'ConditionCode.HorizontalRootFracture',
    defaultMessage: 'Horizontal root fracture',
  },
  [ConditionCode.CrownFracture]: {
    id: 'ConditionCode.CrownFracture',
    defaultMessage: 'Crown fracture',
  },
  [ConditionCode.Abfraction]: {
    id: 'ConditionCode.Abfraction',
    defaultMessage: 'Abfraction',
  },
  [ConditionCode.Attrition]: {
    id: 'ConditionCode.Attrition',
    defaultMessage: 'Attrition',
  },
  [ConditionCode.PeriImplantitis]: {
    id: 'ConditionCode.PeriImplantitis',
    defaultMessage: 'Peri-implantitis',
  },
  [ConditionCode.DentalCalculus]: {
    id: 'ConditionCode.DentalCalculus',
    defaultMessage: 'Dental calculus',
  },
  [ConditionCode.HorizontalDisplacement]: {
    id: 'ConditionCode.HorizontalDisplacement',
    defaultMessage: 'Horizontal displacement',
  },
  [ConditionCode.Overeruption]: {
    id: 'ConditionCode.Overeruption',
    defaultMessage: 'Overeruption',
  },
  [ConditionCode.OpenMargin]: {
    id: 'ConditionCode.OpenMargin',
    defaultMessage: 'Open margin',
  },
  [ConditionCode.Overhang]: {
    id: 'ConditionCode.Overhang',
    defaultMessage: 'Overhang',
  },
  [ConditionCode.LackOfInterproximalContact]: {
    id: 'ConditionCode.LackOfInterproximalContact',
    defaultMessage: 'Lack of the interproximal contact',
  },
  [ConditionCode.SecondaryCaries]: {
    id: 'ConditionCode.SecondaryCaries',
    defaultMessage: 'Secondary caries',
  },
  [ConditionCode.CrownDefectOver50pct]: {
    id: 'ConditionCode.CrownDefectOver50pct',
    defaultMessage: 'Crown defect >50%',
  },
  [ConditionCode.PeriodontalBoneLoss]: {
    id: 'ConditionCode.PeriodontalBoneLoss',
    defaultMessage: 'Periodontal bone loss',
  },
  [ConditionCode.EndodonticAccess]: {
    id: 'ConditionCode.EndodonticAccess',
    defaultMessage: 'Endodontic access',
  },
  [ConditionCode.Pulpotomy]: {
    id: 'ConditionCode.Pulpotomy',
    defaultMessage: 'Pulpotomy',
  },
  [ConditionCode.Clamp]: {
    id: 'ConditionCode.Clamp',
    defaultMessage: 'Clamp',
  },
  [ConditionCode.ForeignBody]: {
    id: 'ConditionCode.ForeignBody',
    defaultMessage: 'Foreign body',
  },
  [ConditionCode.EndodonticInstrument]: {
    id: 'ConditionCode.EndodonticInstrument',
    defaultMessage: 'Endodontic instrument',
  },
  [ConditionCode.IndirectRestoration]: {
    id: 'ConditionCode.IndirectRestoration',
    defaultMessage: 'Indirect restoration',
  },
  [ConditionCode.RestorationFracture]: {
    id: 'ConditionCode.RestorationFracture',
    defaultMessage: 'Restoration fracture',
  },
  [ConditionCode.LateralRadiolucency]: {
    id: 'ConditionCode.LateralRadiolucency',
    defaultMessage: 'Lateral radiolucency',
  },
  [ConditionCode.SingsOfCommunicationWithMaxillarySinus]: {
    id: 'ConditionCode.SingsOfCommunicationWithMaxillarySinus',
    defaultMessage: 'Signs of communication with maxillary sinus',
  },
  [ConditionCode.SignsOfInferiorAlveolarCanalInvolvement]: {
    id: 'ConditionCode.SignsOfInferiorAlveolarCanalInvolvement',
    defaultMessage: 'Signs of inferior alveolar canal involvement',
  },
  [ConditionCode.Apicoectomy]: {
    id: 'ConditionCode.Apicoectomy',
    defaultMessage: 'Apicoectomy',
  },
  [ConditionCode.ToothGerm]: {
    id: 'ConditionCode.ToothGerm',
    defaultMessage: 'Tooth germ',
  },
  [ConditionCode.SinusAbnormality]: {
    id: 'ConditionCode.SinusAbnormality',
    defaultMessage: 'Sinus abnormality',
  },
  [ConditionCode.BoneStructureDisorder]: {
    id: 'ConditionCode.BoneStructureDisorder',
    defaultMessage: 'Signs of bone structure disorder',
  },
  [ConditionCode.ToothStump]: {
    id: 'ConditionCode.ToothStump',
    defaultMessage: 'Tooth stump',
  },
  [ConditionCode.VoidsInTheFilling]: {
    id: 'ConditionCode.VoidsInTheFilling',
    defaultMessage: 'Voids in the filling',
  },
  [ConditionCode.Extrusion]: {
    id: 'ConditionCode.Extrusion',
    defaultMessage: 'Extrusion',
  },
  [ConditionCode.Intrusion]: {
    id: 'ConditionCode.Intrusion',
    defaultMessage: 'Intrusion',
  },
  [ConditionCode.LateralLuxation]: {
    id: 'ConditionCode.LateralLuxation',
    defaultMessage: 'Lateral luxation',
  },
  [ConditionCode.HealingAbutment]: {
    id: 'ConditionCode.HealingAbutment',
    defaultMessage: 'Healing abutment',
  },
  [ConditionCode.CervicalResorption]: {
    id: 'ConditionCode.CervicalResorption',
    defaultMessage: 'Cervical resorption',
  },
  [ConditionCode.Child_DefectDepth_NotDefined]: {
    id: 'ConditionCode.Child_DefectDepth_NotDefined',
    defaultMessage: 'Depth is not defined',
  },
  [ConditionCode.Child_DefectDepth_Initial]: {
    id: 'ConditionCode.Child_DefectDepth_Initial',
    defaultMessage: 'Initial',
  },
  [ConditionCode.Child_DefectDepth_Enamel]: {
    id: 'ConditionCode.Child_DefectDepth_Enamel',
    defaultMessage: 'Enamel',
  },
  [ConditionCode.Child_DefectDepth_Dentin]: {
    id: 'ConditionCode.Child_DefectDepth_Dentin',
    defaultMessage: 'Dentin',
  },
  [ConditionCode.Child_DefectDepth_WithPulpExposure]: {
    id: 'ConditionCode.Child_DefectDepth_WithPulpExposure',
    defaultMessage: 'With pulp exposure',
  },
  [ConditionCode.Child_DefectDepth_Root]: {
    id: 'ConditionCode.Child_DefectDepth_Root',
    defaultMessage: 'Root',
  },
  [ConditionCode.Child_DefectSurface_NotDefined]: {
    id: 'ConditionCode.Child_DefectSurface_NotDefined',
    defaultMessage: 'Surface is not defined',
  },
  [ConditionCode.Child_DefectSurface_Mesial]: {
    id: 'ConditionCode.Child_DefectSurface_Mesial',
    defaultMessage: 'Mesial',
  },
  [ConditionCode.Child_DefectSurface_Distal]: {
    id: 'ConditionCode.Child_DefectSurface_Distal',
    defaultMessage: 'Distal',
  },
  [ConditionCode.Child_DefectSurface_Lingual]: {
    id: 'ConditionCode.Child_DefectSurface_Lingual',
    defaultMessage: 'Lingual',
  },
  [ConditionCode.Child_DefectSurface_Buccal]: {
    id: 'ConditionCode.Child_DefectSurface_Buccal',
    defaultMessage: 'Buccal',
  },
  [ConditionCode.Child_DefectSurface_Occlusial]: {
    id: 'ConditionCode.Child_DefectSurface_Occlusial',
    defaultMessage: 'Occlusial',
  },
  [ConditionCode.Child_DefectSurface_Vestibular]: {
    id: 'ConditionCode.Child_DefectSurface_Vestibular',
    defaultMessage: 'Vestibular',
  },
  [ConditionCode.Child_DefectSurface_IncisalEdge]: {
    id: 'ConditionCode.Child_DefectSurface_IncisalEdge',
    defaultMessage: 'Incisal edge',
  },
  [ConditionCode.Child_PeriodontalBoneLoss_Severity_Mild]: {
    id: 'ConditionCode.Child_PeriodontalBoneLoss_Severity_Mild',
    defaultMessage: 'Mild',
  },
  [ConditionCode.Child_PeriodontalBoneLoss_Severity_Moderate]: {
    id: 'ConditionCode.Child_PeriodontalBoneLoss_Severity_Moderate',
    defaultMessage: 'Moderate',
  },
  [ConditionCode.Child_PeriodontalBoneLoss_Severity_Severe]: {
    id: 'ConditionCode.Child_PeriodontalBoneLoss_Severity_Severe',
    defaultMessage: 'Severe',
  },
  [ConditionCode.Child_PeriodontalBoneLoss_Type_Vertical]: {
    id: 'ConditionCode.Child_PeriodontalBoneLoss_Type_Vertical',
    defaultMessage: 'Vertical',
  },
  [ConditionCode.Child_PeriodontalBoneLoss_Type_Horizontal]: {
    id: 'ConditionCode.Child_PeriodontalBoneLoss_Type_Horizontal',
    defaultMessage: 'Horizontal',
  },
  [ConditionCode.Child_PeriodontalBoneLoss_Type_Mixed]: {
    id: 'ConditionCode.Child_PeriodontalBoneLoss_Type_Mixed',
    defaultMessage: 'Mixed',
  },
  [ConditionCode.Child_Filling_BuildUp]: {
    id: 'ConditionCode.Child_Filling_BuildUp',
    defaultMessage: 'Build up',
  },
  [ConditionCode.Child_MaxFaxBoneStructureDisorder_Type_Hyperdense]: {
    id: 'ConditionCode.Child_MaxFaxBoneStructureDisorder_Type_Hyperdense',
    defaultMessage: 'Radiopacity',
  },
  [ConditionCode.Child_MaxFaxBoneStructureDisorder_Type_Hypodense]: {
    id: 'ConditionCode.Child_MaxFaxBoneStructureDisorder_Type_Hypodense',
    defaultMessage: 'Radiolucency',
  },
  [ConditionCode.Child_MaxFaxBoneStructureDisorder_Type_Mix]: {
    id: 'ConditionCode.Child_MaxFaxBoneStructureDisorder_Type_Mix',
    defaultMessage: 'Mixed',
  },
  [ConditionCode.Child_Impaction_Subtype_CompleteBony]: {
    id: 'ConditionCode.Child_Impaction_Subtype_CompleteBony',
    defaultMessage: 'Complete bony',
  },
  [ConditionCode.Child_Impaction_Subtype_PartiallyBony]: {
    id: 'ConditionCode.Child_Impaction_Subtype_PartiallyBony',
    defaultMessage: 'Partially bony',
  },
  [ConditionCode.Child_MucosalThickening_Type_Focal]: {
    id: 'ConditionCode.Child_MucosalThickening_Type_Focal',
    defaultMessage: 'Focal',
  },
  [ConditionCode.Child_MucosalThickening_Type_Diffused]: {
    id: 'ConditionCode.Child_MucosalThickening_Type_Diffused',
    defaultMessage: 'Diffused',
  },
  [ConditionCode.Child_MucosalThickening_Type_CompleteOrPartialObstruction]: {
    id: 'ConditionCode.Child_MucosalThickening_Type_CompleteOrPartialObstruction',
    defaultMessage: 'Complete/Partial obstruction',
  },
  [ConditionCode.EndoAntralSyndrome]: {
    id: 'ConditionCode.EndoAntralSyndrome',
    defaultMessage: 'Endo-antral syndrome',
  },
  [ConditionCode.OroantralCommunication]: {
    id: 'ConditionCode.OroantralCommunication',
    defaultMessage: 'Oroantral communication',
  },
  [ConditionCode.MaxFaxArtifact]: {
    id: 'ConditionCode.MaxFaxArtifact',
    defaultMessage: 'Artifact',
  },
  [ConditionCode.MaxFaxBoneDisruption]: {
    id: 'ConditionCode.MaxFaxBoneDisruption',
    defaultMessage: 'Bone disruption',
  },
  [ConditionCode.MaxFaxMetalConstruction]: {
    id: 'ConditionCode.MaxFaxMetalConstruction',
    defaultMessage: 'Metal construction',
  },
  [ConditionCode.MaxFaxBoneAugmentation]: {
    id: 'ConditionCode.MaxFaxBoneAugmentation',
    defaultMessage: 'Bone augmentation',
  },
  [ConditionCode.MaxFaxSoftTissueThickening]: {
    id: 'ConditionCode.MaxFaxSoftTissueThickening',
    defaultMessage: 'Soft tissue thickening',
  },
  [ConditionCode.OstialObstruction]: {
    id: 'ConditionCode.OstialObstruction',
    defaultMessage: 'Ostial obstruction',
  },

  [ConditionCode.Child_EndoTreated_Obturation_AdequateObturation]: {
    id: 'ConditionCode.Child_EndoTreated_Obturation_AdequateObturation',
    defaultMessage: 'Adequate obturation',
  },

  [ConditionCode.Child_DefectDepth_CrownRoot]: {
    id: 'ConditionCode.Child_DefectDepth_CrownRoot',
    defaultMessage: 'Crown-root',
  },
  [ConditionCode.Child_EndoTreated_PostAndCore_Post]: {
    id: 'ConditionCode.Child_EndoTreated_PostAndCore_Post',
    defaultMessage: 'Post',
  },
  [ConditionCode.MarginalDiscrepancy]: {
    id: 'ConditionCode.MarginalDiscrepancy',
    defaultMessage: 'Marginal-discrepancy',
  },
  [ConditionCode.Child_RootType_SingleRoot]: {
    id: 'ConditionCode.Child_RootType_SingleRoot',
    defaultMessage: 'Single-root',
  },
  [ConditionCode.Child_RootType_Buccal]: {
    id: 'ConditionCode.Child_RootType_Buccal',
    defaultMessage: 'Buccal',
  },
  [ConditionCode.Child_RootType_Lingual]: {
    id: 'ConditionCode.Child_RootType_Lingual',
    defaultMessage: 'Lingual',
  },
  [ConditionCode.Child_RootType_Palatal]: {
    id: 'ConditionCode.Child_RootType_Palatal',
    defaultMessage: 'Palatal',
  },
  [ConditionCode.Child_RootType_Mesial]: {
    id: 'ConditionCode.Child_RootType_Mesial',
    defaultMessage: 'Mesial',
  },
  [ConditionCode.Child_RootType_Mesial1]: {
    id: 'ConditionCode.Child_RootType_Mesial1',
    defaultMessage: 'Mesial-1',
  },
  [ConditionCode.Child_RootType_Mesial2]: {
    id: 'ConditionCode.Child_RootType_Mesial2',
    defaultMessage: 'Mesial-2',
  },
  [ConditionCode.Child_RootType_MesialBuccal]: {
    id: 'ConditionCode.Child_RootType_MesialBuccal',
    defaultMessage: 'Mesial-buccal',
  },
  [ConditionCode.Child_RootType_MesialLingual]: {
    id: 'ConditionCode.Child_RootType_MesialLingual',
    defaultMessage: 'Mesial-lingual',
  },
  [ConditionCode.Child_RootType_Distal]: {
    id: 'ConditionCode.Child_RootType_Distal',
    defaultMessage: 'Distal',
  },
  [ConditionCode.Child_RootType_DistalBuccal]: {
    id: 'ConditionCode.Child_RootType_DistalBuccal',
    defaultMessage: 'Distal-buccal',
  },
  [ConditionCode.Child_RootType_DistalLingual]: {
    id: 'ConditionCode.Child_RootType_DistalLingual',
    defaultMessage: 'Distal-lingual',
  },
});

/**
 * @deprecated
 */
export const conditionGroupText = defineMessages<ConditionGroups>({
  [ConditionGroups.toothType]: {
    id: 'ConditionGroups.toothType',
    defaultMessage: 'Type',
  },
  [ConditionGroups.rootsNumbers]: {
    id: 'ConditionGroups.rootsNumbers',
    defaultMessage: 'Roots number',
  },
  [ConditionGroups.canalsNumbers]: {
    id: 'ConditionGroups.canalsNumbers',
    defaultMessage: 'Canal number',
  },
  [ConditionGroups.perio]: {
    id: 'ConditionGroups.perio',
    defaultMessage: 'Periodontium',
  },
  [ConditionGroups.position]: {
    id: 'ConditionGroups.position',
    defaultMessage: 'Position',
  },
  [ConditionGroups.crown]: {
    id: 'ConditionGroups.crown',
    defaultMessage: 'Crown state',
  },
  [ConditionGroups.root]: {
    id: 'ConditionGroups.root',
    defaultMessage: 'Roots',
  },
  [ConditionGroups.endo]: {
    id: 'ConditionGroups.endo',
    defaultMessage: 'Endo',
  },
  [ConditionGroups.implant]: {
    id: 'ConditionGroups.implant',
    defaultMessage: 'Implant',
  },
  [ConditionGroups.periradicularPathologies]: {
    id: 'ConditionGroups.periradicularPathologies',
    defaultMessage: 'Periradicular pathologies',
  },
  [ConditionGroups.mucosalThickening]: {
    id: 'ConditionGroups.mucosalThickening',
    defaultMessage: 'Mucosal Thickening',
  },
});

/**
 * @deprecated
 */
export const childConditionGroupText = defineMessages<ChildConditionGroups>({
  [ChildConditionGroups.depth]: {
    id: 'ChildConditionGroups.depth',
    defaultMessage: 'Depth',
  },
  [ChildConditionGroups.surface]: {
    id: 'ChildConditionGroups.surface',
    defaultMessage: 'Surface',
  },
  [ChildConditionGroups.perioSeverity]: {
    id: 'ChildConditionGroups.perioSeverity',
    defaultMessage: 'Severity',
  },
  [ChildConditionGroups.perioType]: {
    id: 'ChildConditionGroups.perioType',
    defaultMessage: 'Type',
  },
  [ChildConditionGroups.impaction]: {
    id: 'ChildConditionGroups.impaction',
    defaultMessage: 'Impaction',
  },
  [ChildConditionGroups.obturation]: {
    id: 'ChildConditionGroups.obturation',
    defaultMessage: 'Obturation',
  },
  [ChildConditionGroups.quality]: {
    id: 'ChildConditionGroups.quality',
    defaultMessage: 'Quality',
  },
  [ChildConditionGroups.complications]: {
    id: 'ChildConditionGroups.complications',
    defaultMessage: 'Complications',
  },
  [ChildConditionGroups.buildup]: {
    id: 'ChildConditionGroups.buildup',
    defaultMessage: 'Build up',
  },
  [ChildConditionGroups.construction]: {
    id: 'ChildConditionGroups.construction',
    defaultMessage: 'Post and core',
  },
  [ChildConditionGroups.involvement]: {
    id: 'ChildConditionGroups.involvement',
    defaultMessage: 'Involvement',
  },
  [ChildConditionGroups.mucosalThickeningType]: {
    id: 'ChildConditionGroups.mucosalThickeningType',
    defaultMessage: 'Type',
  },
});

export const globalConditionGroupText = defineMessages<
  ConditionGroups | ChildConditionGroups
>({
  [ConditionGroups.toothType]: {
    id: 'ConditionGroups.toothType',
    defaultMessage: 'Type',
  },
  [ConditionGroups.rootsNumbers]: {
    id: 'ConditionGroups.rootsNumbers',
    defaultMessage: 'Roots number',
  },
  [ConditionGroups.canalsNumbers]: {
    id: 'ConditionGroups.canalsNumbers',
    defaultMessage: 'Canal number',
  },
  [ConditionGroups.perio]: {
    id: 'ConditionGroups.perio',
    defaultMessage: 'Periodontium',
  },
  [ConditionGroups.position]: {
    id: 'ConditionGroups.position',
    defaultMessage: 'Position',
  },
  [ConditionGroups.crown]: {
    id: 'ConditionGroups.crown',
    defaultMessage: 'Crown state',
  },
  [ConditionGroups.root]: {
    id: 'ConditionGroups.root',
    defaultMessage: 'Roots',
  },
  [ConditionGroups.endo]: {
    id: 'ConditionGroups.endo',
    defaultMessage: 'Endo',
  },
  [ConditionGroups.implant]: {
    id: 'ConditionGroups.implant',
    defaultMessage: 'Implant',
  },
  [ConditionGroups.periradicularPathologies]: {
    id: 'ConditionGroups.periradicularPathologies',
    defaultMessage: 'Periradicular pathologies',
  },
  [ConditionGroups.mucosalThickening]: {
    id: 'ConditionGroups.mucosalThickening',
    defaultMessage: 'Mucosal Thickening',
  },
  [ChildConditionGroups.depth]: {
    id: 'ChildConditionGroups.depth',
    defaultMessage: 'Depth',
  },
  [ChildConditionGroups.surface]: {
    id: 'ChildConditionGroups.surface',
    defaultMessage: 'Surface',
  },
  [ChildConditionGroups.perioSeverity]: {
    id: 'ChildConditionGroups.perioSeverity',
    defaultMessage: 'Severity',
  },
  [ChildConditionGroups.perioType]: {
    id: 'ChildConditionGroups.perioType',
    defaultMessage: 'Type',
  },
  [ChildConditionGroups.impaction]: {
    id: 'ChildConditionGroups.impaction',
    defaultMessage: 'Impaction',
  },
  [ChildConditionGroups.obturation]: {
    id: 'ChildConditionGroups.obturation',
    defaultMessage: 'Obturation',
  },
  [ChildConditionGroups.quality]: {
    id: 'ChildConditionGroups.quality',
    defaultMessage: 'Quality',
  },
  [ChildConditionGroups.complications]: {
    id: 'ChildConditionGroups.complications',
    defaultMessage: 'Complications',
  },
  [ChildConditionGroups.buildup]: {
    id: 'ChildConditionGroups.buildup',
    defaultMessage: 'Build up',
  },
  [ChildConditionGroups.construction]: {
    id: 'ChildConditionGroups.construction',
    defaultMessage: 'Post and core',
  },
  [ChildConditionGroups.involvement]: {
    id: 'ChildConditionGroups.involvement',
    defaultMessage: 'Involvement',
  },
  [ChildConditionGroups.mucosalThickeningType]: {
    id: 'ChildConditionGroups.mucosalThickeningType',
    defaultMessage: 'Type',
  },
});

/**
 * @deprecated
 */
export const combinedConditionCodesGroup =
  defineMessages<CombinedConditionGroupCode>({
    [CombinedConditionGroupCode.ANATOMY]: {
      id: 'combinedConditionGroupCode.anatomy',
      defaultMessage: 'Anatomy',
    },
    [CombinedConditionGroupCode.CARIES_AND_CROWN]: {
      id: 'combinedConditionGroupCode.cariesAndCrown',
      defaultMessage: 'Caries or tooth crown defect',
    },
    [CombinedConditionGroupCode.ENDO]: {
      id: 'combinedConditionGroupCode.endo',
      defaultMessage: 'Endodontic pathology',
    },
    [CombinedConditionGroupCode.PERIO]: {
      id: 'combinedConditionGroupCode.perio',
      defaultMessage: 'Periodontal pathology',
    },
    [CombinedConditionGroupCode.MISSING_NON_RESTORABLE]: {
      id: 'combinedConditionGroupCode.missingNonRestorable',
      defaultMessage: 'Missing or non-restorable tooth',
    },
    [CombinedConditionGroupCode.ORTHO]: {
      id: 'combinedConditionGroupCode.ortho',
      defaultMessage: 'Orthodontic problem',
    },
    [CombinedConditionGroupCode.CROWN_INDICATION]: {
      id: 'combinedConditionGroupCode.crownIndication',
      defaultMessage: 'Indications for an artificial crown',
    },
    [CombinedConditionGroupCode.TREATMENT]: {
      id: 'combinedConditionGroupCode.treatment',
      defaultMessage: 'Previous treatment',
    },
    [CombinedConditionGroupCode.SURGICAL]: {
      id: 'combinedConditionGroupCode.surgical',
      defaultMessage: 'Surgical pathology',
    },
  });
