// Types related to CBCT EndoViz ("endoviz") pipelines method

// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file pipelines/report_cbct_endo.proto (package com.diagnocat.pipelines, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { CBCTSeriesGeometryData } from "../model/dto_common_geometry_pb.js";
import { Report_CBCT_Endo_PeriapicalLesionLocalization } from "../model/dto_report_type_specific_pb.js";

/**
 * @generated from message com.diagnocat.pipelines.CBCT_Endo_Update
 */
export const CBCT_Endo_Update = proto3.makeMessageType(
  "com.diagnocat.pipelines.CBCT_Endo_Update",
  () => [
    { no: 1, name: "CropGeometryData", kind: "message", T: CBCTSeriesGeometryData, oneof: "Update" },
    { no: 2, name: "PeriapicalLesionLocalization", kind: "message", T: Report_CBCT_Endo_PeriapicalLesionLocalization, oneof: "Update" },
  ],
);

