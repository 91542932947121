import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { ReactNode, useEffect, useState, FC } from 'react';

import { useAppSelector } from '@/shared/hooks';
import { getAvailableLanguages } from '@/shared/lib';
import { DEFAULT_LOCALE, LocalStorageKey } from '@/shared/config';

import { userModel } from '@/entities/user';

import messages_en_US from '@/features/intl/lang/en-US.json';
import { getMessages, isLanguageRTL } from '@/features/intl';

type IntlProviderProps = {
  children: ReactNode;
};

export const IntlProvider: FC<IntlProviderProps> = (props) => {
  const { children } = props;

  const [messages, setMessages] = useState<Record<string, string>>(
    () => messages_en_US,
  );

  const backendLocale = useAppSelector(userModel.selectors.selectUserLocale);

  const unauthenticatedLocale = localStorage.getItem(
    LocalStorageKey.unauthenticatedLocale,
  );

  const locale = backendLocale ?? unauthenticatedLocale ?? DEFAULT_LOCALE;

  const availableLanguages = getAvailableLanguages();

  useEffect(() => {
    const init = async () => {
      const msg = await getMessages(locale);

      setMessages(msg);
    };

    init();
  }, [locale]);

  useEffect(() => {
    if (isLanguageRTL(locale, availableLanguages)) {
      document.body.setAttribute('dir', 'rtl');
    } else {
      document.body.setAttribute('dir', 'ltr');
    }
  }, [locale, availableLanguages]);

  return (
    <ReactIntlProvider
      defaultLocale="en"
      locale={locale}
      messages={messages}
      onError={() => {}}
    >
      {children}
    </ReactIntlProvider>
  );
};
