import { ChangeEvent, FC } from 'react';
import cn from 'classnames';

import { Image } from '@/shared/ui';

import styles from './DentalPhotoCheckbox.module.scss';

type DentalPhotoCheckboxProps = {
  src: string;
  checked: boolean;
  onChange: (event: boolean | ChangeEvent<Element>) => void;
  name?: string;
  id?: string;
  required?: boolean;
  boxClassName?: string;
  disabled?: boolean;
  className?: string;
  testID?: string;
};

export const DentalPhotoCheckbox: FC<DentalPhotoCheckboxProps> = (props) => {
  const {
    src,
    checked,
    className,
    boxClassName,
    testID,
    disabled = false,
    onChange,
    name,
    id,
  } = props;

  return (
    <label
      id={id}
      className={cn(
        styles.container,
        disabled && styles.disabled,
        'p2',
        className,
      )}
      data-testid={testID}
    >
      <input
        type="checkbox"
        className={styles.input}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        name={name}
      />

      <div className={cn(styles.checkbox, boxClassName)} />

      <Image
        src={src}
        alt={name}
        className={styles.content}
        width={150}
        height={150}
      />
    </label>
  );
};
