import { Outlet } from 'react-router';

import { useMedia } from '@/shared/hooks';

import { ReportLayout } from '@/entities/reports';
import { ConfirmModal } from '@/entities/modal';

import { Header } from '@/widgets/Header';
import { AddConditionModal } from '@/widgets/AddConditionModal';

export const Report2DLayout = () => {
  const { isMobile } = useMedia();

  // For mobile layout, MobileLayout is used inside IOXRayReport component
  // Here we just return Outlet to avoid double Layout
  if (isMobile) {
    return (
      <>
        <Outlet />
        <ConfirmModal />
        <AddConditionModal />
      </>
    );
  }

  return (
    <ReportLayout.Container>
      <Header fullWidth />
      <ReportLayout.Main>
        <Outlet />
      </ReportLayout.Main>
      <ConfirmModal />
      <AddConditionModal />
    </ReportLayout.Container>
  );
};
