import { useIntl } from 'react-intl';
import { isEmpty } from 'ramda';
import cn from 'classnames';

import { Skeleton } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';

import {
  PatientsFilter as PatientsFiltersEnum,
  PatientsFiltersType,
} from '@/entities/patient';
import { userModel } from '@/entities/user';
import { organizationModel } from '@/entities/organization';

import { Filters } from '@/features/filters';

import styles from './PatientsFilters.module.scss';

type PatientsFiltersProps = {
  className?: string;
  amount: {
    sharedWithMeCount: number;
    sharedByMeCount: number;
    totalOrganizationPatientCount: number;
  };
  onFilterChange: (searchValue: string) => void;
};

export const PatientsFilters = ({
  onFilterChange,
  amount,
  className,
}: PatientsFiltersProps) => {
  const { sharedWithMeCount, sharedByMeCount, totalOrganizationPatientCount } =
    amount;

  const { formatMessage } = useIntl();

  const allOrganizations = useAppSelector(
    organizationModel.selectors.organizationSelectors.selectAll,
  );

  const organizationLoading = useAppSelector(
    (state) => state.organization.loading,
  );

  const showSkeleton =
    organizationLoading === 'pending' || organizationLoading === 'idle';

  const selectUserOrganizationLegacy = useAppSelector(
    userModel.selectors.selectUserOrganizationLegacy,
  );

  const { isCurrentUserAdmin } = selectUserOrganizationLegacy;

  const isAllOrganizations = !isEmpty(allOrganizations);

  const filtersItemsConfig = isAllOrganizations
    ? [
        {
          value: PatientsFiltersEnum.all,
          label: formatMessage({ id: 'global.all', defaultMessage: 'All' }),
          amount: totalOrganizationPatientCount,
        },
        {
          value: PatientsFiltersEnum.sharedWithMe,
          label: formatMessage({
            id: 'patientList.filters.sharedWithMe',
            defaultMessage: 'Shared with me',
          }),
          amount: sharedWithMeCount,
        },
        isCurrentUserAdmin
          ? {
              value: PatientsFiltersEnum.sharedByStaff,
              label: formatMessage({
                id: 'patientList.filters.sharedByStaff',
                defaultMessage: 'Shared by staff',
              }),
              amount: sharedByMeCount,
            }
          : {
              value: PatientsFiltersEnum.sharedByMe,
              label: formatMessage({
                id: 'patientList.filters.sharedByMe',
                defaultMessage: 'Shared by me',
              }),
              amount: sharedByMeCount,
            },
      ]
    : [
        {
          value: PatientsFiltersEnum.sharedWithMe,
          label: formatMessage({
            id: 'patientList.filters.sharedWithMe',
            defaultMessage: 'Shared with me',
          }),
          amount: sharedWithMeCount,
        },
      ];

  return (
    <>
      {showSkeleton ? (
        <Skeleton.Filter
          filtersQuantity={3}
          height={48}
          gap={8}
          borderRadius={12}
        />
      ) : (
        <Filters<PatientsFiltersType>
          className={cn(styles.container, className)}
          filterName="filters"
          defaultActiveKey={
            (filtersItemsConfig.length === 1 && filtersItemsConfig[0].value) ||
            PatientsFiltersEnum.all
          }
          items={filtersItemsConfig}
          onChange={(event) => onFilterChange(event)}
        />
      )}
    </>
  );
};
