import { createSelector } from '@reduxjs/toolkit';

import { reportsModel } from '@/entities/reports';
import { assetsModel } from '@/entities/assets';
import { toothModel } from '@/entities/tooth';

import { transformDataToMPRLauncherRequest } from '../utils/transformDataToMPRLauncherRequest';

export const selectMPREmbeddedLauncherRequest = (toothID: string) =>
  createSelector(
    toothModel.selectors.selectToothByID(toothID),
    assetsModel.selectors.selectMPRByToothID(toothID),
    reportsModel.selectors.selectCurrentReport,
    (tooth, CBCTGPMPRSubVolume, report) => {
      if (!CBCTGPMPRSubVolume) {
        console.error(
          '[MPREmbedded] Volume is undefined. Cannot start MPREmbedded',
        );
        return null;
      }

      if (!tooth) {
        console.error(
          '[MPREmbedded] Tooth is undefined. Cannot start MPREmbedded',
        );
        return null;
      }

      const cbctgp =
        report?.Specific.case === 'DataCBCTGP'
          ? report?.Specific.value
          : undefined;

      if (!cbctgp) {
        console.error(
          '[MPREmbedded] Report DataCBCTGP is undefined. Cannot start MPREmbedded',
        );
        return null;
      }

      const mprLauncherRequest = transformDataToMPRLauncherRequest(
        cbctgp,
        [CBCTGPMPRSubVolume],
        tooth,
      );

      return mprLauncherRequest;
    },
  );
