// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file pipelines/report_tooth_detection.proto (package com.diagnocat.pipelines, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { Localization } from "../model/dto_report_localization_pb.js";
import { ToothNumeration } from "../model/dto_report_common_pb.js";

/**
 * These teeth are, in fact, a teeth localizations on image, not a real teeth, just regions on image.
 * DNNs found them.
 *
 * @generated from message com.diagnocat.pipelines.ToothDetection
 */
export const ToothDetection = proto3.makeMessageType(
  "com.diagnocat.pipelines.ToothDetection",
  () => [
    { no: 2, name: "Localization", kind: "message", T: Localization },
    { no: 3, name: "Numeration", kind: "message", T: ToothNumeration },
  ],
);

