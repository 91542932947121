// DICOM files have a header that starts with a 128-byte preamble,
// followed by a DICOM prefix 'DICM' at positions 128-132
const DICOM_PREFIX = 'DICM';
const DICOM_PREFIX_OFFSET = 128;
const BYTES_TO_READ = DICOM_PREFIX_OFFSET + DICOM_PREFIX.length; // 132 bytes

export const isDicomFile = async (file: File): Promise<boolean> => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      if (!e.target?.result) {
        resolve(false);
        return;
      }

      const buffer = e.target.result as ArrayBuffer;
      if (buffer.byteLength < BYTES_TO_READ) {
        resolve(false);
        return;
      }

      // Check for DICOM prefix
      const prefixBytes = new Uint8Array(
        buffer,
        DICOM_PREFIX_OFFSET,
        DICOM_PREFIX.length,
      );
      const prefixString = String.fromCharCode.apply(
        null,
        Array.from(prefixBytes),
      );

      resolve(prefixString === DICOM_PREFIX);
    };

    reader.onerror = () => {
      resolve(false);
    };

    // Read only the necessary bytes to check the DICOM prefix
    const blob = file.slice(0, BYTES_TO_READ);
    reader.readAsArrayBuffer(blob);
  });
};
