import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { take } from 'ramda';

import { PATHS } from '@/shared/config';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { getImageSrc, getName } from '@/shared/lib';
import { Organization } from '@/shared/api/protocol-ts/model/dto_organization_pb';

import { ModalID, modalModel } from '@/entities/modal';
import {
  getOrganizationInitials,
  organizationModel,
} from '@/entities/organization';
import { userModel } from '@/entities/user';

import { useLogout } from './useLogout';

/**
 * @deprecated
 * NOTE: This hook incapsulate too much logic and should be refactored and decomposed.
 * For example, the logic related to the user have been moved to the userModel.selectors.selectUserAvatarData.
 * On rework desktop control panel, you should use selectors and/or hooks which are using in the mobile components or useLogout hook.
 */
export const useControlPanel = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector(userModel.selectors.selectCurrentUser);
  const userLocale = useAppSelector(userModel.selectors.selectUserLocale);

  const currentOrganization = useAppSelector(
    organizationModel.selectors.selectCurrentOrganization,
  );

  const allOrganizations = useAppSelector(
    organizationModel.selectors.organizationSelectors.selectAll,
  );

  const isSingleOrganizationsUser = allOrganizations?.length === 1;

  const currentOrganizationInitials = getOrganizationInitials(
    currentOrganization as Organization,
  );

  const currentOrganizationName = currentOrganization?.Name;

  const userFullName = getName(
    user?.PersonalData?.FirstName,
    user?.PersonalData?.LastName,
    {
      userLocale,
    },
  );

  const userInitials = getName(
    user?.PersonalData?.FirstName,
    user?.PersonalData?.LastName,
    {
      userLocale,
      shortFirstName: true,
      shortLastName: true,
    },
  );

  const userAvatarAssetSrc = getImageSrc(user?.AvatarAssetID, 'thumbnail');

  const profileFullInitials = currentOrganization
    ? currentOrganizationInitials
    : userInitials;

  const profileInitials = take(2, profileFullInitials);

  const profileName = currentOrganization
    ? currentOrganizationName
    : userFullName;

  const profileAssetSrc = currentOrganization
    ? getImageSrc(currentOrganization?.LogoAssetID ?? '', 'thumbnail')
    : userAvatarAssetSrc;

  const profileAvatarSize = isSingleOrganizationsUser ? 44 : 40;

  const organizationChangeHandle = (organizationID: string) => {
    dispatch(
      organizationModel.thunks.setCurrentOrganizationByID(organizationID),
    );
  };

  const logoutHandle = useLogout();

  const openGetFullAccessModalHandle = useCallback(() => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.GetFullAccessModal,
      }),
    );
  }, [dispatch]);

  const navigateToCompanySettingsHandle = () =>
    navigate({
      pathname: PATHS.companySettings,
      search: 'tab=CompanyInfo',
    });

  return {
    allOrganizations,
    profileAssetSrc,
    profileInitials,
    profileName,
    profileAvatarSize,
    currentOrganization,
    currentOrganizationInitials,
    currentOrganizationName,
    isSingleOrganizationsUser,
    userAvatarAssetSrc,
    userFullName,
    userInitials,
    openGetFullAccessModalHandle,
    logoutHandle,
    navigateToCompanySettingsHandle,
    organizationChangeHandle,
  };
};
