import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { useLocation, useNavigate, useParams } from 'react-router';
import classNames from 'classnames';

import { Button } from '@/shared/ui';
import { LocationStateType, PATHS } from '@/shared/config';
import { useMedia } from '@/shared/hooks';
import { Report } from '@/shared/api/protocol-ts/model/dto_report_pb';

import { getReportSignStatus, ReportSettingsToggle } from '@/entities/reports';

import { useSignReport } from '@/features/signReport';

import styles from './FooterButtons.module.scss';

interface FooterButtonsProps {
  isReportSigned: boolean;
  isAllTeethApproved: boolean;
  report: Report;
  className?: string;
}

export const FooterButtons: FC<FooterButtonsProps> = ({
  isReportSigned,
  isAllTeethApproved,
  report,
  className,
}) => {
  const { patientID = '', reportID = '' } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useMedia();

  const reportSignStatus = getReportSignStatus(report);

  // type casting is necessary for typification location.state
  const locationState = location?.state as LocationStateType;

  const openConfirmSignReportModal = useSignReport(isAllTeethApproved);

  const handlePrintButton = () => {
    navigate(generatePath(`../${PATHS.preview}`, { patientID, reportID }), {
      state: {
        ...locationState,
        lastPositionReport: window.pageYOffset,
        toothID: undefined,
      },
      relative: 'path',
    });
  };

  return (
    <div className={classNames(styles.container, className)}>
      <ReportSettingsToggle className={styles.settingsToggle} />

      <Button
        variant={isReportSigned ? 'primary' : 'tertiary'}
        onClick={handlePrintButton}
        icon={isReportSigned ? 'check' : undefined}
        size={isMobile ? 'medium' : 'large'}
      >
        {isReportSigned ? (
          <FormattedMessage
            id="report.printReport"
            defaultMessage="Print report"
          />
        ) : (
          <FormattedMessage
            id="report.printReportWithoutSignature"
            defaultMessage="Print report without signature"
          />
        )}
      </Button>

      {!isReportSigned && (
        <Button
          icon="pen"
          disabled={
            reportSignStatus === 'withoutSign' ||
            !report.YourPermissions?.CanChangeToothApproved
          }
          onClick={openConfirmSignReportModal}
          size={isMobile ? 'medium' : 'large'}
        >
          {isAllTeethApproved ? (
            <FormattedMessage id="report.sign" defaultMessage="Sign" />
          ) : (
            <FormattedMessage
              id="report.approveAndSign"
              defaultMessage="Approve all and sign"
            />
          )}
        </Button>
      )}
    </div>
  );
};
