import { FC, ReactNode, useEffect } from 'react';
import cn from 'classnames';
import { useSearchParams } from 'react-router-dom';

import { RenderPreviewSettings } from '@/shared/config';
import { useAppDispatch } from '@/shared/hooks';
import {
  MedicalImageRender,
  ViewMIR,
} from '@/shared/graphics/medicalImageRender/MedicalImageRender';

import { reportsModel } from '@/entities/reports';

import styles from './IOXRayReportRender.module.scss';

type IOXRayReportRenderProps = {
  className?: string;
  children?: ReactNode;
  previewSettings?: RenderPreviewSettings;
};

export const IOXRayReportRender: FC<IOXRayReportRenderProps> = (props) => {
  const { className, children, previewSettings } = props;
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    MedicalImageRender.addEventListener('layout', (event) => {
      if (event.mode === 'focus') {
        dispatch(reportsModel.actions.setToolbarActiveControl('view'));
        dispatch(reportsModel.actions.setFocusedMetaImageID(event.id));

        // Update URL with new activeItemID
        if (event.id) {
          searchParams.set('activeItemID', event.id);
        } else {
          searchParams.delete('activeItemID');
        }
        setSearchParams(searchParams);
      }
    });
  }, []);

  return (
    <ViewMIR
      viewName="main"
      id="report_render"
      className={cn(
        styles.container,
        previewSettings?.isPreview ? styles.preview : '',
        className,
      )}
    >
      {children}
    </ViewMIR>
  );
};
