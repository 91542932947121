import { FC } from 'react';

import {
  MobileBasicLayout,
  MobileBasicLayoutProps,
} from './components/MobileBasicLayout/MobileBasicLayout';
import { MobileMain } from './components/MobileMain/MobileMain';
import { MobileFooter } from './components/MobileFooter/MobileFooter';

type CompoundedComponent = FC<MobileBasicLayoutProps> & {
  Main: typeof MobileMain;
  Footer: typeof MobileFooter;
};

const MobileLayout = MobileBasicLayout as CompoundedComponent;

MobileLayout.Main = MobileMain;
MobileLayout.Footer = MobileFooter;

export { MobileLayout };
