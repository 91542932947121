import { PATHS } from '@/shared/config';

export const SHOW_AGE_IN_MONTHS_THRESHOLD = 3;
export const ADULT_AGE = 18;
export const ONE_YEAR = 1;
export const MONTHS_IN_YEAR = 12;

export enum PatientsFilter {
  all = 'all',
  sharedWithMe = 'sharedWithMe',
  sharedByMe = 'sharedByMe',
  sharedByStaff = 'sharedByStaff',
}

export type CountsIconNames =
  | 'cbctNew'
  | 'ioxray'
  | 'pano'
  | 'photo'
  | 'ios'
  | 'doc';

export const PATH_TO_PATIENTS_SHARED_WITH_ME_LIST = `${PATHS.patients}?search=&filters=${PatientsFilter.sharedWithMe}`;
