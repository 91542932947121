import { FC, useCallback } from 'react';
import cn from 'classnames';

import { scrollToElementByID } from '@/shared/lib';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Skeleton } from '@/shared/ui';

import { FMXNavigationMode, reportsModel } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';
import { IOXRayImagesInterfaceModel } from '@/entities/IOXRayImagesMatrix';

import { ToothCard } from '@/features/ToothCard';

import styles from './ReportToothCardList.module.scss';

type ReportToothCardListProps = {
  onSlicesClick?: (toothID: string) => void;
  className?: string;
};

export const ReportToothCardList: FC<ReportToothCardListProps> = (props) => {
  const { className, onSlicesClick } = props;

  const dispatch = useAppDispatch();

  const currentReport = useAppSelector(
    reportsModel.selectors.selectCurrentReport,
  );

  const navigationMode = useAppSelector(
    reportsModel.selectors.selectNavigationMode,
  );

  const { ID: reportID = '', PatientID: patientID = '' } = currentReport ?? {};

  const isToothChartMode = navigationMode === FMXNavigationMode.ToothChart;

  const teethLoadingState = useAppSelector(toothModel.selectors.selectLoading);

  const teethIDsForShow = useAppSelector(
    toothModel.selectors.selectTeethIDsToShow(reportID),
  );

  const teethIDsForFocusedImage = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectTeethIDsForFocusedImage(
      reportID,
    ),
  );

  const displayedTeethIDs = isToothChartMode
    ? teethIDsForShow
    : teethIDsForFocusedImage;

  const handleToothClick = useCallback(
    (id: string) => {
      dispatch(reportsModel.actions.setActiveToothID(id));

      scrollToElementByID(id, 'smooth', 'start');
    },
    [dispatch],
  );

  if (teethLoadingState !== 'succeeded' && !reportID) {
    return <Skeleton width="100%" height={160} borderRadius={16} />;
  }

  return displayedTeethIDs?.map((toothID) => (
    <ToothCard
      key={toothID}
      id={toothID}
      className={cn(styles.toothCard, className)}
      patientID={patientID}
      reportID={reportID}
      handleCardClick={handleToothClick}
      isToothChartMode={isToothChartMode}
      onSlicesClick={onSlicesClick}
    />
  ));
};
