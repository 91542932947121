import { FormattedMessage } from 'react-intl';

import { array, InferType, object, string } from '@/shared/config/yup';

export const addDoctorsSchema = object({
  treatingDoctors: array()
    .of(string())
    .min(1, () => (
      <FormattedMessage
        id="addDoctors.validationError"
        defaultMessage="You should select at least one doctor"
      />
    ))
    .required(),
});

export type AddDoctorsPayload = InferType<typeof addDoctorsSchema>;
