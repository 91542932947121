import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { Button } from '@/shared/ui';
import { DentalPhotoAlbum } from '@/shared/api/protocol-ts/model/dto_dental_photo_album_pb';
import { useAppDispatch } from '@/shared/hooks';
import { getImageSrc } from '@/shared/lib';

import { ModalID, modalModel } from '@/entities/modal';

import { ImageWithSkeleton } from '../ImageWIthSkeleton/ImageWithSkeleton.tsx';

import styles from './Album.module.scss';

type AlbumProps = {
  album: DentalPhotoAlbum;
  canEditAlbums?: boolean;
  className?: string;
  testID?: string;
};

export const Album: FC<AlbumProps> = (props) => {
  const { className, testID, album, canEditAlbums } = props;
  const { formatDate } = useIntl();

  const dispatch = useAppDispatch();

  const handleEditAlbumClick = () =>
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.EditAlbumModal,
        data: {
          albumID: album?.ID,
          albumName: album?.Name ?? '',
          time: album?.Time?.toDate()?.toDateString() ?? '',
        },
      }),
    );

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      <header className={cn(styles.albumHeader, 'p1')}>
        {album?.Name && `${album.Name},`}{' '}
        {album?.Time && formatDate(album.Time.toDate(), { dateStyle: 'long' })}
        {canEditAlbums && (
          <Button
            icon="pen"
            size="medium"
            variant="tertiary"
            onClick={handleEditAlbumClick}
          />
        )}
      </header>

      <div className={styles.photos}>
        {album?.StudyAssetIDs?.map((assetID) => (
          <ImageWithSkeleton
            key={`photo_${assetID}`}
            skeletonWidth={218}
            skeletonHeight={288}
            onClick={() =>
              dispatch(
                modalModel.actions.openModal({
                  modalID: ModalID.DentalPhotoSlider,
                  data: {
                    albumID: album.ID,
                    activePhoto: assetID,
                    canEditAlbums: canEditAlbums ?? false,
                  },
                }),
              )
            }
            alt={`photo: ${assetID}`}
            src={getImageSrc(assetID, 'preview')}
          />
        ))}
      </div>
    </div>
  );
};
