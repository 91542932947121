import { Organization } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { getImageSrc } from '@/shared/lib';

import { getOrganizationInitials } from './getOrganizationInitials';

export type OrganizationAvatarData = {
  id: string;
  initials: string;
  name: string;
  logoSrc: string;
};

export const getOrganizationAvatarData = (
  organization: Organization,
): OrganizationAvatarData => {
  return {
    id: organization?.ID,
    initials: getOrganizationInitials(organization),
    name: organization?.Name,
    logoSrc: getImageSrc(organization?.LogoAssetID, 'thumbnail'),
  };
};
