import { FC, memo, useMemo } from 'react';
import cn from 'classnames';

import { MedicalImageInterface } from '@/shared/config';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { SlicesMIR } from '@/shared/graphics/medicalImageRender/MedicalImageRender';

import { Group, MedicalImagesGroups, PathologyGroup } from '@/entities/assets';
import { organizationModel } from '@/entities/organization';
import { ModalID, modalModel } from '@/entities/modal';

import { maskFiltersModel } from '@/features/renderMasks';

import { MedicalImageGroupSection } from '../MedicalImageGroupSection/MedicalImageGroupSection';

import styles from './MedicalImagesGroup.module.scss';

type MedicalImagesGroupProps = {
  toothID: string;
  selectedMedicalImages?: MedicalImageInterface[];
  groups: (Group | PathologyGroup)[];
  toggleSelected?: (medicalImage: MedicalImageInterface) => void;
  className?: string;
};

export const MedicalImagesGroup: FC<MedicalImagesGroupProps> = memo((props) => {
  const { className, selectedMedicalImages, toggleSelected, groups, toothID } =
    props;

  const dispatch = useAppDispatch();

  const isFDA = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_FDA_SubmissionView,
    ),
  );
  const isFDANonAided = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_FDA_NonAidedVersion,
    ),
  );

  const hidePerioMasksAndSlicesFF = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Hide_PerioMasksAndSlices,
    ),
  );

  const { isConditionsMasksHidden, isCBCTMasksHidden } = useAppSelector(
    maskFiltersModel.selectors.selectMasksFlags,
  );

  const showPerioMasksAndSlices = useMemo(() => {
    if (isFDA) {
      return !isFDANonAided;
    }

    return (
      !hidePerioMasksAndSlicesFF &&
      !isConditionsMasksHidden &&
      !isCBCTMasksHidden
    );
  }, [
    hidePerioMasksAndSlicesFF,
    isFDA,
    isFDANonAided,
    isConditionsMasksHidden,
    isCBCTMasksHidden,
  ]);

  const hideCariesSigns = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Hide_CariesSigns,
    ),
  );

  const filteredGroups = groups.filter((group) => {
    if (group.type === MedicalImagesGroups.Caries) {
      return !hideCariesSigns && !isFDA;
    }

    if (group.type === MedicalImagesGroups.PeriapicalLesion) {
      return showPerioMasksAndSlices;
    }

    return true;
  });

  const useHandleOpenModal = (assetID: string) => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.MIRenderModal,
        data: { assetID, toothID },
      }),
    );
  };

  return (
    <div className={cn(styles.container, className)}>
      {filteredGroups.map((group) => (
        <SlicesMIR toothID={toothID} onClick={useHandleOpenModal}>
          <MedicalImageGroupSection
            key={group.type}
            toothID={toothID}
            group={group}
            selectedMedicalImages={selectedMedicalImages}
            toggleSelected={toggleSelected}
          />
        </SlicesMIR>
      ))}
    </div>
  );
});
